import { action, computed, observable } from 'mobx';
import { RootStore } from './RootStore';
import { Api } from './Api';
import { asyncAction } from 'mobx-utils';
import apiConfigs from '../apiConfigs';
import UserModel from '../models/person/UserModel';
import PersonModel, { PersonDTO } from '../models/person/PersonModel';
import { AxiosResponse } from 'axios';
import clientRoute from '../clientRoute';
import { IntlStore } from './IntlStore';
import { di } from 'react-magnetic-di';

export interface PersonInfo {
    user: UserModel;
    person: PersonDTO;
    roles: string[];
}

const loginRedirectByRole: { [role: string]: string } = {
    Expert: clientRoute.expertiseTasks,
    Curator: clientRoute.campaigns,
};

const rolePriorities: { [role: string]: number } = {
    Curator: 1,
    Expert: 0,
};

export const getRouteByRoles = (
    roles: string[],
    loginRedirectByRole: { [role: string]: string },
    rolePriorities: { [role: string]: number },
): string => {
    const rolesLength = roles.length;
    let redirect = '';
    let priority = 0;
    for (let index = 0; index < rolesLength; index++) {
        const role = roles[index];
        const rolePriority = rolePriorities[role];
        if ((rolePriority || rolePriority === 0) && rolePriority >= priority) {
            priority = rolePriority;
            redirect = loginRedirectByRole[role];
        }
    }

    if (redirect) {
        return redirect;
    }

    return clientRoute.campaigns;
};

export class PersonStore {
    @observable private rootStore: RootStore;
    @observable protected api: Api;
    @observable private intl: IntlStore;

    @observable user: UserModel = new UserModel();
    @observable person: PersonModel = new PersonModel();
    @observable roles: string[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.intl = rootStore.intlStore;

        this.getInfo().then((r) => r);
    }

    @asyncAction
    @action.bound
    async getInfo(): Promise<void> {
        const response: AxiosResponse<PersonInfo> = await this.api.client(apiConfigs.person);
        const { user, person, roles } = response.data;
        this.user = user;
        this.person.load(person);
        this.roles = roles;
        const { locale } = this.intl;
        if (locale !== person.lang) {
            this.intl.changeLocale(locale);
        }
    }

    @action.bound
    clearPerson() {
        this.user = new UserModel();
        this.person = new PersonModel();
        this.roles = [];
    }

    @computed
    get redirectPath(): string {
        return getRouteByRoles(this.roles, loginRedirectByRole, rolePriorities);
    }

    @computed
    get isExpert() {
        return this.roles.findIndex((role) => role === 'Expert') !== -1;
    }

    @computed
    get isAdmin() {
        return this.roles.findIndex((role) => role === 'Admin') !== -1;
    }

    @computed
    get isCurator() {
        return this.roles.findIndex((role) => role === 'Curator') !== -1;
    }
}

export const getPersonStore = (): any => {
    const [_PersonStore] = di([PersonStore], getPersonStore);
    return _PersonStore;
};
