import { action, computed, observable } from 'mobx';
import { Api } from '../../store/Api';
import { asyncAction } from 'mobx-utils';
import { ChangeEvent, FormEvent } from 'react';
import EmailModel from './fields/EmailModel';
import PasswordModel from './fields/PasswordModel';
import { IntlStore } from '../../store/IntlStore';
import apiConfigs from '../../apiConfigs';
import { PersonStore } from '../../store/PersonStore';

export interface LoginDTO {
    email: string;
    password: string;
    rememberMe: boolean;
}

export default class LoginModel implements EmailModel, PasswordModel {
    @observable protected api: Api;
    @observable intl: IntlStore;
    @observable personStore: PersonStore;

    @observable email = '';
    @observable password = '';
    @observable rememberMe = false;

    @observable validationStarted = false;
    @observable loginStatus?: 'success' | 'failed';

    constructor(api: Api, intlStore: IntlStore, personStore: PersonStore) {
        this.api = api;
        this.intl = intlStore;
        this.personStore = personStore;
    }

    @asyncAction
    @action.bound
    async login(event: FormEvent<HTMLFormElement>): Promise<void> {
        event.preventDefault();
        this.validationStarted = true;
        if (this.isValid) {
            try {
                await this.api.client(apiConfigs.login(this.asJson));
                await this.api.authVerify();
                await this.personStore.getInfo();
                this.loginStatus = 'success';
            } catch (e) {
                this.loginStatus = 'failed';
            }
        }
    }

    @action.bound
    onChangeEmail(event: ChangeEvent<HTMLInputElement>): void {
        this.email = event.target.value;
    }

    @action.bound
    onPasswordChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.password = event.target.value;
    }

    @computed
    get asJson(): LoginDTO {
        return {
            email: this.email,
            password: this.password,
            rememberMe: this.rememberMe,
        };
    }

    @computed
    get loginFailedMessage(): string {
        if (this.loginStatus === 'failed') {
            return this.intl.formatMessage('authentication.loginFailed');
        }
        return '';
    }

    @computed
    get loginSucceed(): boolean {
        return this.loginStatus === 'success';
    }

    @computed
    get errorEmail(): string {
        if (this.validationStarted && !this.email) {
            return this.intl.formatMessage('validation.required');
        }
        return '';
    }

    @computed
    get errorPassword(): string {
        if (this.validationStarted && !this.password) {
            return this.intl.formatMessage('validation.required');
        }
        return '';
    }

    @computed
    get isValid(): boolean {
        return !this.errorEmail && !this.errorPassword;
    }
}
