import { CampaignSubjects, CampaignExpertise, CampaignTasks, CampaignStatisticDTO } from './../../store/CampaignStore';
import { observable, action } from 'mobx';

export default class CampaignStatisticModel {
    @observable subjects: CampaignSubjects = {
        total: 0,
        active: 0,
        withExpertise: 0,
        withoutExpertise: 0,
    };
    @observable expertises: CampaignExpertise = {
        total: 0,
        active: 0,
        done: 0,
        canceled: 0,
    };
    @observable tasks: CampaignTasks = {
        total: 0,
        withoutExpert: 0,
        responseWaiting: 0,
        startWaiting: 0,
        active: 0,
        overdue: 0,
        done: 0,
        notCompleted: 0,
    };

    @action.bound
    load(dto: CampaignStatisticDTO): void {
        this.subjects = dto.subjects;
        this.expertises = dto.expertise;
        this.tasks = dto.tasks;
    }
}
