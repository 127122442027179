import React from 'react';
import { AuthorizationCheck } from '../../../components/AuthorizationCheck';
import { TotObjectDeleteButton } from '../../../components/tot-object/TotObjectActionsBar';
import { FormattedMessage } from 'react-intl';
import { entities, permissions } from '../../../authSchemeConfig';
import { CampaignPageRouteParams } from '../CampaignPage';
import { generatePath, NavLink, Route } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import { Grid, Tooltip } from '@material-ui/core';
import { IconButtonLarge } from '../../../components/buttons/IconButtonLarge';
import { ReactComponent as EditIcon } from '../../../resources/images/icons/edit.svg';

interface Props {
    id: CampaignPageRouteParams['id'];
    deleteCampaign: () => Promise<void>;
    title: string;
}

export const ControlPanelLeft = ({ id, deleteCampaign, title }: Props): JSX.Element => (
    <Grid spacing={3} alignItems="center" justify="center" container>
        <Route path={clientRoute.campaign} exact>
            <AuthorizationCheck
                entityCode={entities.ExpertiseCampaign}
                permCode={permissions.ExpertiseCampaign.Delete}
                entityId={id}
            >
                <TotObjectDeleteButton
                    id="delete-campaign"
                    title={<FormattedMessage id="common.confirmDeletion" />}
                    message={<FormattedMessage id="campaign.confirmDeletionInfoText" values={{ title }} />}
                    onConfirm={deleteCampaign}
                    wrappedComponentProps={{
                        tooltipTitleId: 'campaign.actions.deleteCampaign',
                    }}
                />
            </AuthorizationCheck>
            <AuthorizationCheck
                entityCode={entities.ExpertiseCampaign}
                permCode={permissions.ExpertiseCampaign.Edit}
                entityId={id}
            >
                <Grid item>
                    <Tooltip title={<FormattedMessage id="common.edit" />}>
                        <div>
                            <IconButtonLarge
                                component={NavLink}
                                to={generatePath(clientRoute.campaignEdit, {
                                    id,
                                })}
                            >
                                <EditIcon />
                            </IconButtonLarge>
                        </div>
                    </Tooltip>
                </Grid>
            </AuthorizationCheck>
        </Route>
    </Grid>
);
