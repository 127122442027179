import { action, computed, observable } from 'mobx';
import FileDTO from './FileDTO';

export class FileModel {
    @observable fileForUpload?: File;
    @observable fileDTO?: FileDTO;

    acceptedFiles?: string[];

    constructor(acceptedFiles?: string[]) {
        this.acceptedFiles = acceptedFiles;
    }

    @action.bound
    onChangeFile([file]: File[]): void {
        if (file) {
            this.fileDTO = undefined;
            this.fileForUpload = file;
        }
    }

    @action.bound
    onDeleteFile(): void {
        this.fileDTO = undefined;
        this.fileForUpload = undefined;
    }

    @computed
    get fileName(): string | undefined {
        return this.fileForUpload?.name || this.fileDTO?.filename;
    }

    @computed
    get dropzoneInitialFiles(): string[] | undefined {
        if (this.fileName) {
            return [this.fileName];
        }
        return undefined;
    }
}
