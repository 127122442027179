import React from 'react';
import { Grid } from '@material-ui/core';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { RowData } from '../../../components/PageHeader/RowData';
import { getOrElse } from '../../../utils/getOrElse';
import CampaignModel from '../../../models/campaign/CampaignModel';

export type CampaignHeaderLeftProps = {
    identifier: CampaignModel['identifier'];
    created: CampaignModel['metaInfo']['created'];
    stateTitle: CampaignModel['metaInfo']['stateTitle'];
    managerName: CampaignModel['manager']['name'];
};

export const CampaignHeaderLeft = (props: CampaignHeaderLeftProps): JSX.Element => {
    const { identifier, created, stateTitle, managerName } = props;
    return (
        <React.Fragment>
            <Grid item>
                <RowData
                    label={<FormattedMessage id="campaign.fields.identifier" />}
                    value={identifier || <FormattedMessage id="common.withoutNumber" />}
                />
            </Grid>
            <Grid item>
                <RowData
                    label={<FormattedMessage id="campaign.fields.created" />}
                    value={getOrElse(created, <FormattedDate value={created} />)}
                />
            </Grid>
            <Grid item>
                <RowData label={<FormattedMessage id="campaign.fields.state" />} value={stateTitle} />
            </Grid>
            <Grid item>
                <RowData label={<FormattedMessage id="campaign.fields.responsible" />} value={managerName} />
            </Grid>
        </React.Fragment>
    );
};
