import React from 'react';
import { Route, Switch } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { CategoriesOfSubjectsListPage } from './CategoriesOfSubjectsListPage';
import { CategoryOfSubjectsPage } from './CategoryOfSubjectsPage';

class CategoriesOfSubjectsPages extends React.Component<{}, {}> {
    render(): JSX.Element {
        return (
            <Switch>
                <Route path={clientRoute.category}>
                    <CategoryOfSubjectsPage />
                </Route>
                <Route>
                    <CategoriesOfSubjectsListPage />
                </Route>
            </Switch>
        );
    }
}

export default CategoriesOfSubjectsPages;
