import React, { useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useLogo } from '../../hooks/useLogo';

export type LogoConfig = {
    isEnabled: boolean;
    srcRu: string;
    srcEn: string;
    style: {};
    withText: boolean;
    altRu: string;
    altEn: string;
};

export type LogoImgProps = {
    logoConfig: LogoConfig;
};

const TotXLogo = withTheme(
    styled.span`
        color: ${({ theme }): string => theme.palette.primary.contrastText};
        font-size: 30px;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 0.6px;
    `,
);

export const LogoImg = (props: LogoImgProps): JSX.Element => {
    const { logoConfig } = props;

    const logoProps = useLogo();
    const logoImg = useMemo((): JSX.Element => {
        const { alt, src } = logoProps;
        return <img src={src} alt={alt} style={logoConfig.style} />;
    }, [logoProps, logoConfig]);

    return logoConfig.withText ? (
        <React.Fragment>
            <Grid item style={{ paddingBottom: '0' }}>
                <Box pr={3}>{logoImg}</Box>
            </Grid>
            <Grid item>
                <TotXLogo>
                    <FormattedMessage id="productTitle" />
                </TotXLogo>
            </Grid>
        </React.Fragment>
    ) : (
        <Grid item>{logoImg}</Grid>
    );
};
