import React from 'react';
import { observer } from 'mobx-react';
import { ExpertiseTaskModel } from '../../../../models/expertise-task/ExpertiseTaskModel';
import { PageHeader } from '../../../../components/PageHeader/PageHeader';
import { ExpertiseTaskHeaderDescriptionLeft as ExpertiseTaskHeaderDescriptionLeftInj } from './ExpertiseTaskHeaderDescriptionLeft';
import { ExpertiseTaskHeaderDescriptionRight as ExpertiseTaskHeaderDescriptionRightInj } from './ExpertiseTaskHeaderDescriptionRight';
import { di } from 'react-magnetic-di';

export type ExpertiseTaskHeaderDescriptionProps = {
    expertiseTaskModel: ExpertiseTaskModel;
    toggleIsOpen?: () => void;
    toggleIsOpenStartDateDialog?: () => void;
    toggleIsOpenDueDateDialog?: () => void;
};

export const ExpertiseTaskHeaderDescription = observer(
    (props: ExpertiseTaskHeaderDescriptionProps): JSX.Element => {
        const { expertiseTaskModel, toggleIsOpen, toggleIsOpenStartDateDialog, toggleIsOpenDueDateDialog } = props;
        const { expert, expertCandidate, deadline, metaInfo, expertise, viewPoint, started, id } = expertiseTaskModel;
        const { stateTitle } = metaInfo;
        const expertId = expert.userId;
        const expertCandidateId = expertCandidate.userId;

        const [ExpertiseTaskHeaderDescriptionLeft] = di(
            [ExpertiseTaskHeaderDescriptionLeftInj],
            ExpertiseTaskHeaderDescription,
        );
        const [ExpertiseTaskHeaderDescriptionRight] = di(
            [ExpertiseTaskHeaderDescriptionRightInj],
            ExpertiseTaskHeaderDescription,
        );

        return (
            <PageHeader
                left={
                    <ExpertiseTaskHeaderDescriptionLeft
                        entityId={id}
                        started={started}
                        toggleIsOpenStartDateDialog={toggleIsOpenStartDateDialog}
                        toggleIsOpenDueDateDialog={toggleIsOpenDueDateDialog}
                        deadline={deadline}
                        stateTitle={stateTitle}
                        expertise={expertise}
                        viewPoint={viewPoint}
                    />
                }
                right={
                    <ExpertiseTaskHeaderDescriptionRight
                        expertId={expertId}
                        expert={expert}
                        expertCandidateId={expertCandidateId}
                        expertiseTaskModel={expertiseTaskModel}
                        expertCandidate={expertCandidate}
                        toggleIsOpen={toggleIsOpen}
                    />
                }
            />
        );
    },
);
