import React, { MouseEventHandler } from 'react';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import IconButton from '@material-ui/core/IconButton';

type ToggleHeaderButtonProps = {
    collapsed: boolean;
    onClick: MouseEventHandler;
};

const ToggleHeaderButton = (props: ToggleHeaderButtonProps) => {
    const tooltip = props.collapsed
        ? 'expertiseTask.headerActions.expandHeader'
        : 'expertiseTask.headerActions.collapseHeader';
    return (
        <Tooltip title={<FormattedMessage id={tooltip} />}>
            <div>
                <IconButton onClick={props.onClick}>
                    {props.collapsed ? <VerticalAlignBottomIcon /> : <VerticalAlignTopIcon />}
                </IconButton>
            </div>
        </Tooltip>
    );
};

export default ToggleHeaderButton;
