import { TableModel, RowsData, TableQueryData } from '../list/TableModel';
import { action, observable } from 'mobx';
import { History } from 'history';
import { Api } from '../../store/Api';
import apiConfigs from '../../apiConfigs';
import { handleAxiosErrorByResponseStatus } from '../../utils/errorHandleUtils';
import clientRoute from '../../clientRoute';

export interface TemplatesOfExpertiseRowDTO {
    id: string;
    title: string;
    created: string; //date
    state: string;
}

class TemplatesOfExpertiseListModel extends TableModel<TemplatesOfExpertiseRowDTO, {}, {}> {
    @observable protected api: Api;
    @observable history: History;

    constructor(api: Api, history: History) {
        super({}, {});

        this.api = api;
        this.history = history;
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<TemplatesOfExpertiseRowDTO>> {
        return this.api
            .client(apiConfigs.templatesOfExpertiseListData(queryData))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            );
    }
}

export default TemplatesOfExpertiseListModel;
