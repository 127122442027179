import downloadFile from 'js-file-download';
import { TableQueryData } from '../models/list/TableModel';
import { UserPersonDTO } from '../models/person/UserNameModel';
import { action, computed, observable } from 'mobx';
import { RootStore } from './RootStore';
import { Api } from './Api';
import { IdTitle } from '../models/IdTitle';
import apiConfigs from '../apiConfigs';
import { History } from 'history';
import { handleAxiosErrorByResponseStatus } from '../utils/errorHandleUtils';
import clientRoute from '../clientRoute';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { MetaInfoDTO } from '../models/MetaInfo';
import { ExpertiseTaskModel } from '../models/expertise-task/ExpertiseTaskModel';
import { FormDTO, FullSubmission } from '@platform/formiojs-react';
import { ExpertiseTasksRowDTO } from '../models/expertise-task/ExpertiseTasksListByExpertiseModel';
import { ExpertiseTaskViewModel, ExpertiseTaskViewDTO } from '../models/expertise-task/ExpertiseTaskViewModel';
import { TransitionsDTO } from '../models/tot-object/TotObjectTransitions';
import { ExpertiseTaskSubjectDTO } from '../models/expertise-task/ExpertiseTaskSubjectModel';
import { ExpertiseTaskPrintFormDTO, PrintFormFileDTO } from '../models/expertise-task/ExpertiseTaskPrintFormModel';
import { ExpertiseTaskPrintFormsDTO } from '../models/expertise-task/ExpertiseTaskPrintFormsModel';
import { CodeTitle } from '../models/CodeTitle';
import { formatISO } from 'date-fns';
import { ColumnData, RowsData, TTableQueryData, TTableRow } from '@platform/ttable';
import fileDownload from 'js-file-download';

export type Contacts = {
    phone?: string;
    email?: string;
};

export type Curator = UserPersonDTO & { contacts: Contacts };

// dto страниц редактирования часть dto просмотра задания на экспертизу
export interface ExpertiseTaskDTO {
    id: string;
    identifier: string;
    expertise: IdTitle;
    metaInfo: MetaInfoDTO;
    formInfo: FormDTO;
    expert?: UserPersonDTO;
    curator?: Curator;
    activeInviteId?: string;
    expertCandidate?: UserPersonDTO;
    deadline?: string; //date
    viewPoint?: CodeTitle;
}

export interface ExpertiseTaskCuratorRegistryRow {
    id: string;
    number: string;
    subject: IdTitle;
    expertise: IdTitle;
    expert: {
        id?: string;
        fio?: string;
    };
    curator: {
        id: string;
        fio: string;
    };
}

const filePanelCLass = 'file-tab';

export class ExpertiseTaskStore {
    @observable private rootStore: RootStore;
    @observable protected api: Api;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;

        this.loadPrintForms = this.loadPrintForms.bind(this);
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    exportListXls(queryData: TableQueryData): AxiosPromise<Blob> {
        return this.api.client(apiConfigs.loadExpertiseTasksXls(queryData));
    }

    @action.bound
    loadExpertiseTasksListByExpertise(expertiseId: string): Promise<ExpertiseTasksRowDTO[]> {
        return this.api.client(apiConfigs.expertiseTasksListByExpertise(expertiseId)).then((r) => r.data.rows);
    }

    @action.bound
    deleteExpertiseTask(taskId: string): Promise<void> {
        return this.api.client(apiConfigs.deleteExpertiseTask(taskId)).then((r) => r.data);
    }

    @action.bound
    createExpertiseTask(planEntryId: string): Promise<string> {
        return this.api
            .client(apiConfigs.createExpertiseTask(planEntryId))
            .then((r) => r.data.id)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    loadExpertisePlanEntriesSelectOptions(expertiseId: string): Promise<IdTitle[]> {
        return this.api
            .client(apiConfigs.expertisePlanEntriesSelectOptions(expertiseId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    getExpertiseTaskModel(id: string): ExpertiseTaskModel {
        const model = new ExpertiseTaskModel(id);
        this.loadExpertiseTaskDTO(id).then(model.load);
        return observable(model);
    }

    @action.bound
    loadExpertiseTaskDTO(id: string): Promise<ExpertiseTaskDTO> {
        return this.api
            .client(apiConfigs.loadExpertiseTaskDTO(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    saveExpertiseTask(id: string, submission: FullSubmission): Promise<void> {
        return this.api.client(apiConfigs.saveExpertiseTask(id, submission)).then((r) => r.data);
    }

    @action.bound
    delete(id: string): Promise<void> {
        return this.api
            .client(apiConfigs.deleteExpertiseTask(id))
            .then((r) => r.data)
            .catch(({ code, message }: AxiosError) => console.error(`${code} ${message}`));
    }

    @action.bound
    getExpertiseTaskViewModel(id: string): ExpertiseTaskViewModel {
        const model = new ExpertiseTaskViewModel(id);
        this.loadTaskView(id).then(model.load);
        return observable(model);
    }

    @action.bound
    loadTaskView(taskId: string, backUrl?: string): Promise<ExpertiseTaskViewDTO> {
        return this.api
            .client(apiConfigs.loadExpertiseTaskView(taskId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(backUrl || clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    updateTaskReportForm(taskId: string, submission: FullSubmission, expertiseInfo?: object): Promise<void> {
        return this.api.client(apiConfigs.updateTaskReportForm(taskId, submission, expertiseInfo)).then((r) => r.data);
    }

    @action.bound
    getLifeCycleTransitions(taskId: string): Promise<TransitionsDTO> {
        return this.api.client(apiConfigs.loadExpertiseTaskTransitions(taskId)).then((r) => r.data);
    }

    @action.bound
    lifeCycleTransition(transitionId: string, taskId: string, backUrl: string): Promise<void> {
        return this.api
            .client(apiConfigs.expertiseTaskLifeCycleTransition(transitionId, taskId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(backUrl || clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    sendToRework(transitionId: string, taskId: string, message: string): Promise<void> {
        return this.api.client(apiConfigs.sendToRework(transitionId, taskId, message)).then((r) => r.data);
    }

    @action.bound
    loadTaskReportForm(taskId: string): Promise<FormDTO> {
        return this.api.client(apiConfigs.loadTaskReportForm(taskId)).then((r) => r.data);
    }

    @action.bound
    loadTaskSubject(taskId: string): Promise<ExpertiseTaskSubjectDTO> {
        return this.api.client(apiConfigs.loadExpertiseTaskSubject(taskId)).then((r) => r.data);
    }

    @action.bound
    loadTaskSubjectView(taskId: string, isFiles: boolean): Promise<ExpertiseTaskSubjectDTO> {
        return this.api.client(apiConfigs.loadExpertiseTaskSubject(taskId)).then((r: AxiosResponse) => {
            const tasksSubjectWithoutFiles = r.data;
            const commonFormInfoForms = tasksSubjectWithoutFiles.commonFormInfo.form;

            commonFormInfoForms.components = [
                ...commonFormInfoForms.components.filter((component: { customClass: string }) => {
                    if (isFiles) {
                        return component.customClass === filePanelCLass;
                    }
                    return component.customClass !== filePanelCLass;
                }),
            ];

            if (tasksSubjectWithoutFiles.hiddenFormInfo) {
                const hiddenFormInfoForms = tasksSubjectWithoutFiles.hiddenFormInfo.form;

                hiddenFormInfoForms.components = [
                    ...hiddenFormInfoForms.components.filter((component: { customClass: string }) => {
                        if (isFiles) {
                            return component.customClass === filePanelCLass;
                        }
                        return component.customClass !== filePanelCLass;
                    }),
                ];
            }

            return tasksSubjectWithoutFiles;
        });
    }

    @action.bound
    getTaskInviteLifeCycleTransitions(inviteId: string): Promise<TransitionsDTO> {
        return this.api
            .client(apiConfigs.loadTaskInviteTransitions(inviteId))
            .then((r) => r.data)
            .catch((error) => {
                console.error(error);
            });
    }

    @action.bound
    taskInviteLifeCycleTransition(transitionId: string, inviteId: string): Promise<void> {
        return this.api.client(apiConfigs.taskInviteLifeCycleTransition(transitionId, inviteId)).then((r) => r.data);
    }

    @action.bound
    rejectTaskInvite(transitionId: string, inviteId: string, reason: string): Promise<void> {
        return this.api.client(apiConfigs.rejectTaskInvite(transitionId, inviteId, reason)).then((r) => r.data);
    }

    loadPrintForms(expertiseTaskId: string): Promise<ExpertiseTaskPrintFormsDTO> {
        return this.api.client(apiConfigs.expertiseTaskPrintForms(expertiseTaskId)).then((r) => r.data);
    }

    @action.bound
    createPrintForm(taskId: string, pfCode: string): Promise<ExpertiseTaskPrintFormDTO> {
        return this.api.client(apiConfigs.createExpertiseTaskPrintForm(taskId, pfCode)).then((r) => r.data);
    }

    @action.bound
    updatePrintForm(taskPrintFormId: string): Promise<ExpertiseTaskPrintFormDTO> {
        return this.api.client(apiConfigs.updateExpertiseTaskPrintForm(taskPrintFormId)).then((r) => r.data);
    }

    @action.bound
    downloadPrintFormFile(fileDTO: PrintFormFileDTO): void {
        this.api
            .client(apiConfigs.downloadPrintFormFile(fileDTO.pfId))
            .then((r) => r.data)
            .then((f) => downloadFile(f, fileDTO.filename, fileDTO.mimeType));
    }

    @action.bound
    changeCurator(expertiseTaskId: string, curatorUserId: string): Promise<void> {
        return this.api
            .client(apiConfigs.changeExpertiseTaskCurator(expertiseTaskId, curatorUserId))
            .then((r) => r.data);
    }

    @action.bound
    async changeStartedDate(expertiseTaskId: string, started: Date): Promise<void> {
        await this.api.client(apiConfigs.changeStartedDate(expertiseTaskId, formatISO(started)));
    }

    @action.bound
    async changeDueDate(expertiseTaskId: string, deadline: Date): Promise<void> {
        await this.api.client(apiConfigs.changeDueDate(expertiseTaskId, formatISO(deadline)));
    }

    @action.bound
    registry(
        registryCode: string,
        query: TTableQueryData<ExpertiseTaskCuratorRegistryRow>,
    ): Promise<RowsData<ExpertiseTaskCuratorRegistryRow>> {
        return this.api
            .client(apiConfigs.registryTasks(registryCode, query))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            );
    }

    @action.bound
    registryColumns(registryCode: string): Promise<ColumnData<ExpertiseTaskCuratorRegistryRow>[]> {
        return this.api.client(apiConfigs.registryTasksColumns(registryCode)).then((r) => r.data);
    }

    @action.bound
    async upload<RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.upload(tableQueryData));
            const filename = this.rootStore.intlStore.formatMessage('expertiseTask.listTitle');
            await fileDownload(data, `${filename}.xlsx`);
        } catch (error) {
            console.error(error);
        }
    }
}
