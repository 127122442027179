import React from 'react';
import CampaignModel from '../../../models/campaign/CampaignModel';
import { TotObjectHeader } from '../../../components/tot-object/TotObjectHeader';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { PageHeaderTitle } from './PageHeaderTitle';
import { CampaignHeaderLeft } from './CampaignHeaderLeft';

export type CampaignHeaderProps = {
    campaignModel: CampaignModel;
};

export const CampaignHeader = (props: CampaignHeaderProps): JSX.Element => {
    const { campaignModel } = props;
    const { title, identifier, metaInfo, manager } = campaignModel;
    const { created, stateTitle } = metaInfo;
    const { name } = manager;

    return (
        <TotObjectHeader>
            <PageHeader
                title={<PageHeaderTitle title={title} />}
                left={
                    <CampaignHeaderLeft
                        identifier={identifier}
                        created={created}
                        stateTitle={stateTitle}
                        managerName={name}
                    />
                }
            />
        </TotObjectHeader>
    );
};
