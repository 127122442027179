import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AuthorizationCheckQuery } from '../store/AuthorizationStore';
import { fromPromise } from 'mobx-utils';
import { useStore } from '../hooks';

export type CheckResult = { [key: string]: boolean };
type RenderChildren = (result: CheckResult) => JSX.Element;

export type AuthorizationCheckAllProps = {
    or: boolean;
    queries: { [key: string]: AuthorizationCheckQuery };
    pendingElement?: JSX.Element;
    errorElement?: JSX.Element;
    children?: RenderChildren | JSX.Element;
};

export const AuthorizationCheckAll = observer(
    (props: AuthorizationCheckAllProps): JSX.Element => {
        const { queries, children, pendingElement, errorElement, or } = props;
        const { authorizationStore } = useStore();
        const [isAllAllowed, setIsAllAllowed] = useState<Promise<boolean[]>>(Promise.resolve([]));

        const checkIsAllAllowed = useCallback(() => {
            return authorizationStore.checkAll(Object.values(queries));
        }, []); // queries

        useEffect(() => {
            setIsAllAllowed(checkIsAllAllowed());
        }, [checkIsAllAllowed]);

        const renderChildrenOr = useCallback(
            (result: boolean[]): JSX.Element => {
                const allowed = result.some((_) => _);
                if (allowed) {
                    const checkResult = getCheckResult(result);
                    if (typeof children === 'function') {
                        const render = children as RenderChildren;
                        return render(checkResult);
                    }
                    return children as JSX.Element;
                }
                return <React.Fragment />;
            },
            [children],
        );

        const renderChildrenAll = useCallback(
            (result: boolean[]): JSX.Element => {
                if (typeof children === 'function') {
                    const checkResult = getCheckResult(result);
                    const render = children as RenderChildren;
                    return render(checkResult);
                } else {
                    throw new Error(
                        'AuthorizationCheckAll без установленной опции "or" в качестве children допускает только render-функцию `(result: boolean[]) => ReactNode`',
                    );
                }
            },
            [children],
        );

        const getCheckResult = (result: boolean[]): CheckResult => {
            const checkKeys = Object.keys(queries);
            const checkResult: CheckResult = {};
            checkKeys.forEach((key: string, index: number) => {
                checkResult[key] = result[index];
            });
            return checkResult;
        };

        return fromPromise(isAllAllowed).case({
            pending: () => pendingElement || <React.Fragment />,
            fulfilled: or ? renderChildrenOr : renderChildrenAll,
            rejected: () => errorElement || <React.Fragment />,
        });
    },
);
