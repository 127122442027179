import React, { ChangeEvent, FormEvent, ReactNode } from 'react';
import { Box, Button, CircularProgress, Dialog, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
import { action, computed, observable } from 'mobx';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Autocomplete } from '@material-ui/lab';
import { IdTitle } from '../../../models/IdTitle';
import { ExpertiseStore } from '../../../store/ExpertiseStore';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import { RootStore } from '../../../store/RootStore';

type OuterProps = {
    open: boolean;
    curatorUserId: string;
    onCancel: () => void;
    onSubmit(curatorUserId: string): void;
    isLoading: boolean;
};
type StoreProps = {
    expertiseStore: ExpertiseStore;
};
type InnerProps = OuterProps & StoreProps & { intl: IntlFormatters };

class ExpertiseEditCurator extends React.Component<InnerProps, {}> {
    @observable curatorUserId?: string;
    @observable validationStarted = false;
    @observable curatorOptions: IdTitle[] = [];
    @observable curatorOptionsLoading = false;
    @observable curatorOptionsLoaded = false;

    constructor(props: InnerProps) {
        super(props);
        this.curatorUserId = props.curatorUserId;
    }

    loadCuratorOptions = (): void => {
        if (!this.curatorOptionsLoaded && !this.curatorOptionsLoading) {
            this.curatorOptionsLoading = true;
            this.props.expertiseStore
                .loadCuratorSelectOptions()
                .then(
                    action((options: IdTitle[]) => {
                        this.curatorOptions = options;
                        this.curatorOptionsLoaded = true;
                    }),
                )
                .finally(() => {
                    this.curatorOptionsLoading = false;
                });
        }
    };

    @action.bound
    onChangeCurator = (event: ChangeEvent<{}>, value: IdTitle | null): void => {
        this.enableValidation();
        this.curatorUserId = value?.id;
    };

    enableValidation = (): void => {
        this.validationStarted = true;
    };

    @computed
    get errorCurator(): string {
        if (this.validationStarted && !this.curatorUserId) {
            const { intl } = this.props;
            return intl.formatMessage({ id: 'validation.required' });
        }
        return '';
    }

    onSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        this.enableValidation();
        if (this.curatorUserId) {
            const { onSubmit } = this.props;
            onSubmit(this.curatorUserId);
        }
    };

    renderInput = (
        label: string,
        required: boolean,
        errorText?: string,
    ): ((params: AutocompleteRenderInputParams) => ReactNode) => {
        return (params: AutocompleteRenderInputParams): ReactNode => (
            <TextField
                {...params}
                required={required}
                label={label}
                variant="outlined"
                error={!!errorText}
                helperText={errorText}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {this.curatorOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        );
    };

    render(): JSX.Element {
        const { onCancel, isLoading, intl, open } = this.props;
        return (
            <Dialog maxWidth="xs" fullWidth open={open} scroll="body">
                <Box pt={4} pr={4}>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <Box pl={12} pr={12} pb={12}>
                    <Grid container justify="center">
                        <Grid item>
                            <Typography variant="h5">
                                <Box fontWeight="fontWeightBold">
                                    <FormattedMessage id="expertise.changeCurator" />
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box pt={8}>
                        <form noValidate onSubmit={this.onSubmit}>
                            <Grid container spacing={6} direction="column" justify="center">
                                <Grid item>
                                    <Autocomplete
                                        onChange={this.onChangeCurator}
                                        onOpen={this.loadCuratorOptions}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => option.title}
                                        options={this.curatorOptions.slice()}
                                        loading={this.curatorOptionsLoading}
                                        renderInput={this.renderInput(
                                            intl.formatMessage({ id: 'expertise.fields.curator' }),
                                            true,
                                            this.errorCurator,
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={isLoading}
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        type="submit"
                                    >
                                        <FormattedMessage id="common.save" />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={onCancel} fullWidth size="large" variant="contained">
                                        <FormattedMessage id="common.cancel" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Dialog>
        );
    }
}

export default compose<InnerProps, OuterProps>(
    injectIntl,
    inject<RootStore, {}, StoreProps, {}>(({ expertiseStore }) => {
        return {
            expertiseStore,
        };
    }),
    observer,
)(ExpertiseEditCurator);
