import { TableModel, RowsData, TableQueryData } from '../list/TableModel';
import { action, observable } from 'mobx';
import { IdTitle } from '../IdTitle';
import { UserStore } from '../../store/UserStore';
import { LikeFilter } from '../list/filter/LikeFilter';

export type UserStatusCode = 'blocked' | 'active' | 'inactive';

export interface UserRowDTO {
    login: IdTitle;
    status: UserStatusCode;
    name: string;
    roles: string;
}

export type UserFilter = {
    name: LikeFilter;
    login: LikeFilter;
};

export class UserListModel extends TableModel<UserRowDTO, UserFilter, {}> {
    @observable userStore: UserStore;

    constructor(userStore: UserStore) {
        super(
            {
                name: new LikeFilter(),
                login: new LikeFilter(),
            },
            {},
        );
        this.userStore = userStore;
        this.pageSize = 10;
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<UserRowDTO>> {
        return this.userStore.loadList(queryData);
    }

    @action.bound
    blockUser(id: string): (message?: string) => Promise<void> {
        return (message?: string): Promise<void> => this.userStore.blockUser(id, message).then(this.reloadData);
    }

    @action.bound
    unblockUser(id: string): () => Promise<void> {
        return () => this.userStore.unblockUser(id).then(this.reloadData);
    }

    @action.bound
    deleteUser(id: string): () => Promise<void> {
        return () => this.userStore.deleteUser(id).then(this.reloadData);
    }
}
