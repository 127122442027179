import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import { observer } from 'mobx-react';
import { BottomControlPanel } from '../../../components/BottomControlPanel';
import ControlPanelLeft from './ControlPanelLeft';
import ControlPanelRight from './ControlPanelRight';
import { useStore } from '../../../hooks';

export type TemplateOfExpertiseControlPanelProps = {
    templateOfExpertiseTitle: string;
    deleteTemplateOfExpertise: () => Promise<void>;
    onSubmit: () => void;
    onLifeCycleTransition(transitionId: string, taskId: string, validate?: boolean): Promise<void>;
};

type RouteParams = {
    id: string;
};

export const TemplateOfExpertiseControlPanel = observer(
    (props: TemplateOfExpertiseControlPanelProps): JSX.Element => {
        const { templateOfExpertiseTitle, deleteTemplateOfExpertise, onSubmit, onLifeCycleTransition } = props;
        const { templatesOfExpertiseStore } = useStore();
        const history = useHistory();
        const { id } = useParams<RouteParams>();

        const goToReadPage = (): void => {
            history.push(generatePath(clientRoute.templateOfExpertise, { id }));
        };

        return (
            <BottomControlPanel
                left={
                    <ControlPanelLeft
                        id={id}
                        title={templateOfExpertiseTitle}
                        deleteTemplateOfExpertise={deleteTemplateOfExpertise}
                    />
                }
                right={
                    <ControlPanelRight
                        onSubmit={onSubmit}
                        id={id}
                        goToReadPage={goToReadPage}
                        getTransitionButtons={templatesOfExpertiseStore.getTransitionButtons}
                        onLifeCycleTransition={onLifeCycleTransition}
                    />
                }
            />
        );
    },
);
