import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Tab, useTheme } from '@material-ui/core';
import { generatePath, NavLink, Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import clientRoute from '../../../../clientRoute';
import { FormApi } from '@platform/formiojs-react';
import { PanelHorizontal } from './PanelHorizontal';
import { ExpertiseTaskViewModel } from '../../../../models/expertise-task/ExpertiseTaskViewModel';
import { ExpertiseTaskView } from './ExpertiseTaskView';
import { RoutedTabs, RouteSetting } from '../../../../components/tabs/RoutedTabs';
import { ExpertiseTaskSubject } from './ExpertiseTaskSubject';
import { ExpertiseTaskReport } from './ExpertiseTaskReport';
import {
    ExpertiseTaskViewPageContainer,
    TaskViewContainer,
    TaskViewReportContainer,
    TaskViewTabsContainer,
    TaskViewTabsContentContainer,
} from './ExpertiseTaskViewPage.styled';
import { ExpertiseTaskViewHeader } from './ExpertiseTaskViewHeader';
import { AuthorizationCheck } from '../../../../components/AuthorizationCheck';
import { entities, permissions } from '../../../../authSchemeConfig';
import { observer } from 'mobx-react';
import { useStore } from '../../../../hooks/useStore';
import { useReload } from '../../../../hooks/useReload';
import { SwitchViewState } from './SwitchExpertiseTaskView';
import { di } from 'react-magnetic-di';
import { useFeature } from 'feature-toggle-jsx';
import { ExpertiseTaskSubjectWithoutFiles } from './ExpertiseTaskSubjectWithoutFiles';

const queryString = require('query-string');

export type ExpertiseTaskRouteParams = {
    id: string;
    backUrl: string;
};

export const getAdditionalTaskViewRoutesPagesInj = (reloadKey: number): JSX.Element => <React.Fragment />;

export const getAdditionalTaskViewTabsInj = (allowed: boolean, id: string): JSX.Element[] => [];

export const getAdditionalTaskViewTabSettingsInj = (): RouteSetting[] => [];

export const defaultTabsSettings: RouteSetting[] = [
    { tab: 'task', path: clientRoute.expertiseTask, exact: true },
    {
        tab: 'subject',
        path: clientRoute.expertiseTaskSubject,
        exact: true,
    },
];

export const ExpertiseTaskViewPage = observer(
    (): JSX.Element => {
        const [getAdditionalTaskViewRoutesPages] = di([getAdditionalTaskViewRoutesPagesInj], ExpertiseTaskViewPage);
        const [getAdditionalTaskViewTabs] = di([getAdditionalTaskViewTabsInj], ExpertiseTaskViewPage);
        const [getAdditionalTaskViewTabSettings] = di([getAdditionalTaskViewTabSettingsInj], ExpertiseTaskViewPage);

        const [withSubjectFilesTab] = useFeature('withSubjectFilesTab');

        const theme = useTheme();
        const { reloadKey, reloadIncrement } = useReload();

        const { id } = useParams<ExpertiseTaskRouteParams>();
        const location = useLocation<ExpertiseTaskRouteParams>();

        const [formApi, setFormApi] = useState<FormApi>();
        const [expertiseTaskViewModel] = useState<ExpertiseTaskViewModel>(new ExpertiseTaskViewModel(id));
        const [headerCollapsed, setHeaderCollapsed] = useState<boolean>(false);
        const [switchViewState, setSwitchViewState] = useState<SwitchViewState>({ left: true, right: true });
        const { expertiseTaskStore, intlStore } = useStore();
        let backUrl = '';
        const queries = queryString.parse(location.search);
        backUrl = queries.backUrl || generatePath(clientRoute.expertiseTasks);

        const { taskModel } = expertiseTaskViewModel;

        const reloadTaskModel = (): void => {
            expertiseTaskStore.loadTaskView(id, backUrl).then(expertiseTaskViewModel.load);
            reloadIncrement();
        };

        const onFormReady = useCallback(
            (form: FormApi): void => {
                setFormApi(form);
            },
            [setFormApi],
        );

        const getTabIndicatorStyle = (): React.CSSProperties => {
            return theme ? { backgroundColor: theme.palette.secondary.dark } : {};
        };

        const onToggleHeader = (): void => {
            setHeaderCollapsed(!headerCollapsed);
        };

        const onChangeSwitchViewState = (event: React.ChangeEvent<HTMLInputElement>): void => {
            setSwitchViewState({ ...switchViewState, [event.target.name]: event.target.checked });
        };

        useEffect(() => {
            expertiseTaskStore.loadTaskView(id, backUrl).then(expertiseTaskViewModel.load);
        }, [expertiseTaskStore, expertiseTaskViewModel.load, id, intlStore.locale, backUrl]);

        const [formIsChanged, setFormIsChanged] = useState(false);

        const isTaskFullWidth = switchViewState.left && !switchViewState.right;
        const isReportFullWidth = switchViewState.right && !switchViewState.left;

        return (
            <ExpertiseTaskViewPageContainer
                container
                direction="column"
                wrap="nowrap"
                fullScreen={headerCollapsed}
                overflow="hidden"
            >
                <ExpertiseTaskViewHeader
                    taskModel={taskModel}
                    formApi={formApi}
                    backUrl={backUrl}
                    onChangeSwitchViewState={onChangeSwitchViewState}
                    switchViewState={switchViewState}
                    onToggleHeader={onToggleHeader}
                    headerCollapsed={headerCollapsed}
                    reloadKey={reloadKey}
                    reloadTaskModel={reloadTaskModel}
                    setFormIsChanged={(): void => setFormIsChanged(false)}
                />
                <TaskViewContainer overflow="hidden" boxHeight="100%">
                    <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        style={{ height: '100%', position: 'relative', overflow: 'hidden' }}
                    >
                        <PanelHorizontal
                            fullWidth={isTaskFullWidth}
                            isOtherFullWidth={isReportFullWidth}
                            items={[
                                <TaskViewTabsContainer item key={'tabs'}>
                                    <AuthorizationCheck
                                        entityCode={entities.ExpertiseTask}
                                        permCode={permissions.ExpertiseTask.ViewSubject}
                                        entityId={id}
                                    >
                                        {(allowed): JSX.Element => {
                                            const additionalSetting: RouteSetting[] = getAdditionalTaskViewTabSettings();
                                            const settings: RouteSetting[] = withSubjectFilesTab
                                                ? defaultTabsSettings.concat(
                                                      [
                                                          {
                                                              tab: 'file',
                                                              path: clientRoute.expertiseTaskFiles,
                                                              exact: true,
                                                          },
                                                      ],
                                                      additionalSetting,
                                                  )
                                                : defaultTabsSettings.concat(additionalSetting);

                                            const tabs: JSX.Element[] = [
                                                <Tab
                                                    key="task"
                                                    value="task"
                                                    label={<FormattedMessage id="expertiseTask.tabs.task" />}
                                                    component={NavLink}
                                                    to={generatePath(clientRoute.expertiseTask, { id })}
                                                />,
                                                allowed ? (
                                                    <Tab
                                                        key="subject"
                                                        value="subject"
                                                        label={<FormattedMessage id="expertiseTask.tabs.subject" />}
                                                        component={NavLink}
                                                        to={generatePath(clientRoute.expertiseTaskSubject, { id })}
                                                    />
                                                ) : (
                                                    <React.Fragment key="subject" />
                                                ),
                                                withSubjectFilesTab && allowed ? (
                                                    <Tab
                                                        key="file"
                                                        value="file"
                                                        label={<FormattedMessage id="expertiseTask.tabs.files" />}
                                                        component={NavLink}
                                                        to={generatePath(clientRoute.expertiseTaskFiles, { id })}
                                                    />
                                                ) : (
                                                    <React.Fragment key="file" />
                                                ),
                                                ...getAdditionalTaskViewTabs(allowed, id),
                                            ];

                                            return (
                                                <RoutedTabs
                                                    tabsProps={{
                                                        indicatorColor: 'secondary',
                                                        textColor: 'secondary',
                                                        TabIndicatorProps: { style: getTabIndicatorStyle() },
                                                    }}
                                                    settings={settings}
                                                >
                                                    {tabs}
                                                </RoutedTabs>
                                            );
                                        }}
                                    </AuthorizationCheck>
                                </TaskViewTabsContainer>,
                                <TaskViewTabsContentContainer item key={'tabs-content'}>
                                    <Switch>
                                        <Route path={clientRoute.expertiseTask} exact>
                                            <ExpertiseTaskView key={reloadKey} taskViewModel={expertiseTaskViewModel} />
                                        </Route>
                                        <AuthorizationCheck
                                            entityCode={entities.ExpertiseTask}
                                            permCode={permissions.ExpertiseTask.ViewSubject}
                                            entityId={id}
                                        >
                                            {(allowed): JSX.Element =>
                                                allowed ? (
                                                    withSubjectFilesTab ? (
                                                        <React.Fragment>
                                                            <Route path={clientRoute.expertiseTaskSubject} exact>
                                                                <ExpertiseTaskSubjectWithoutFiles key={reloadKey} />
                                                            </Route>
                                                            <Route path={clientRoute.expertiseTaskFiles} exact>
                                                                <ExpertiseTaskSubject
                                                                    key={reloadKey}
                                                                    isOnlyFiles={true}
                                                                />
                                                            </Route>
                                                        </React.Fragment>
                                                    ) : (
                                                        <Route path={clientRoute.expertiseTaskSubject} exact>
                                                            <ExpertiseTaskSubject key={reloadKey} />
                                                        </Route>
                                                    )
                                                ) : (
                                                    <Redirect to={generatePath(clientRoute.expertiseTask, { id })} />
                                                )
                                            }
                                        </AuthorizationCheck>
                                        {getAdditionalTaskViewRoutesPages(reloadKey)}
                                    </Switch>
                                </TaskViewTabsContentContainer>,
                            ]}
                            leftSide={true}
                        />

                        <PanelHorizontal
                            items={[
                                <TaskViewReportContainer key={reloadKey} item style={{ padding: 0 }}>
                                    <ExpertiseTaskReport
                                        setFormIsChanged={setFormIsChanged}
                                        formIsChanged={formIsChanged}
                                        onFormReady={onFormReady}
                                    />
                                </TaskViewReportContainer>,
                            ]}
                            leftSide={false}
                            fullWidth={isReportFullWidth}
                            isOtherFullWidth={isTaskFullWidth}
                        />
                    </Grid>
                </TaskViewContainer>
            </ExpertiseTaskViewPageContainer>
        );
    },
);
