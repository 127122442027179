import { action, observable } from 'mobx';
import { ExpertiseTaskDTO } from '../../store/ExpertiseTaskStore';
import { ExpertiseTaskModel } from './ExpertiseTaskModel';
import { IdTitle } from '../IdTitle';
import { DateUtils } from '../../utils/DateUtils';

export type ExpertiseTaskViewDTO = {
    taskInfo: ExpertiseTaskDTO;
    reportsInfo?: ReportsRowDTO[];
};

export type ReportsRowDTO = {
    report: IdTitle;
    deadline?: string; //date
    completed?: string; //date
};

export type ReportsRow = {
    report: IdTitle;
    deadline?: Date;
    completed?: Date;
};

export class ExpertiseTaskViewModel {
    @observable id: string;
    @observable taskModel: ExpertiseTaskModel;
    @observable reportsTable?: ReportsRow[];

    constructor(id: string) {
        this.id = id;
        this.taskModel = new ExpertiseTaskModel(id);
    }

    @action.bound
    load(dto: ExpertiseTaskViewDTO): void {
        const { taskInfo, reportsInfo } = dto;
        this.taskModel.load(taskInfo);
        reportsInfo && (this.reportsTable = reportsInfo.map(this.mapReportsRowDTO));
    }

    mapReportsRowDTO(dto: ReportsRowDTO): ReportsRow {
        const deadline = new Date(dto.deadline || '');
        const completed = new Date(dto.completed || '');

        const dateDTO = {
            ...(DateUtils.isValidDate(deadline) && { deadline }),
            ...(DateUtils.isValidDate(completed) && { completed }),
        };

        return {
            report: dto.report,
            ...dateDTO,
        };
    }
}
