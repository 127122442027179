import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { observer } from 'mobx-react';
import { ReactComponent as QuestionIcon } from '../../resources/images/icons/question-circle.svg';
import { FormattedMessage, useIntl } from 'react-intl';

export const InstructionsButton = observer(
    (): JSX.Element => {
        const intl = useIntl();
        return (
            <Tooltip title={<FormattedMessage id="common.downloadInstructions" />}>
                <div>
                    <IconButton
                        color="primary"
                        component="a"
                        download={intl.formatMessage({ id: 'common.instructionFileName' })}
                        href={intl.formatMessage({ id: 'common.instructionSrc' })}
                    >
                        <QuestionIcon />
                    </IconButton>
                </div>
            </Tooltip>
        );
    },
);
