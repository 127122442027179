import React from 'react';
import { Grid, Typography, Tooltip, Button } from '@material-ui/core';
import { getOrElse } from '../../../utils/getOrElse';
import { FormattedMessage } from 'react-intl';
import { Route } from 'react-router-dom';
import { IconButtonLarge } from '../../../components/buttons/IconButtonLarge';
import { AuthorizationCheck } from '../../../components/AuthorizationCheck';
import clientRoute from '../../../clientRoute';
import { UserEditDialog } from '../UserEditDialog';
import { ReactComponent as EditIcon } from '../../../resources/images/icons/edit.svg';
import { UserPersonModel } from '../../../models/users/UserPersonModel';
import { permissions, entities } from '../../../authSchemeConfig';

interface Props {
    userPersonModel: UserPersonModel;
    setModalIsOpen: () => void;
    isModalOpen: boolean;
    setModalIsClosed: () => void;
    loginAsUser: () => void;
    login: UserPersonModel['login'];
}

const PageHeaderTitle = ({
    userPersonModel,
    setModalIsOpen,
    isModalOpen,
    setModalIsClosed,
    loginAsUser,
    login,
}: Props): JSX.Element => (
    <Grid item container justify="space-between">
        <Grid item>
            <Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
                <Grid item>
                    <Typography variant="h1">{getOrElse(login, login, '-')}</Typography>
                </Grid>
                <Route path={clientRoute.user} exact>
                    <AuthorizationCheck
                        entityCode={entities.User}
                        entityId={userPersonModel.id}
                        permCode={permissions.User.Update}
                    >
                        <Grid item>
                            <Tooltip title={<FormattedMessage id="users.editUser" />}>
                                <div>
                                    <IconButtonLarge onClick={setModalIsOpen}>
                                        <EditIcon />
                                    </IconButtonLarge>
                                </div>
                            </Tooltip>
                            <UserEditDialog
                                userPersonModel={userPersonModel}
                                isOpen={isModalOpen}
                                close={setModalIsClosed}
                            />
                        </Grid>
                    </AuthorizationCheck>
                </Route>
            </Grid>
        </Grid>
        <Route path={clientRoute.user} exact>
            <Grid item>
                <AuthorizationCheck
                    entityCode={entities.User}
                    permCode={permissions.User.LoginAs}
                    entityId={userPersonModel.id}
                >
                    <Button onClick={loginAsUser} variant="contained" color="primary">
                        <FormattedMessage id="users.loginAsUser" />
                    </Button>
                </AuthorizationCheck>
            </Grid>
        </Route>
    </Grid>
);

export default PageHeaderTitle;
