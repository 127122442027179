import { action, observable } from 'mobx';
import { FormModel } from '@platform/formiojs-react';
import { TemplatesOfExpertiseDTO } from '../../store/TemplatesOfExpertiseStore';
import { MetaInfoModel } from '../MetaInfo';

class TemplatesOfExpertiseModel {
    @observable id: string;
    @observable title = '';
    @observable formInfo = '';
    @observable formModel: FormModel;
    @observable metaInfo: MetaInfoModel = new MetaInfoModel();

    constructor(id: string) {
        this.id = id;
        this.formModel = new FormModel(id);
    }

    @action.bound
    load(dto: TemplatesOfExpertiseDTO): void {
        this.title = dto.title;
        this.formModel.load(dto.formInfo);
        this.metaInfo.load(dto.metaInfo);
    }

    @action.bound
    loadCard(dto: TemplatesOfExpertiseDTO): void {
        this.title = dto.title;
        this.metaInfo.load(dto.metaInfo);
    }
}

export default TemplatesOfExpertiseModel;
