const headerLinks: Record<string, Record<string, string> | string> = {
    campaigns: 'Кампании',
    categories: 'Категории',
    subjects: 'Объекты',
    templatesOfExpertise: 'Шаблоны экспертиз',
    expertiseTasks: 'Мои задания',
    expertiseTasksForCurator: 'Задания',
    expertiseList: 'Экспертизы',
    users: 'Пользователи',
    pfTemplates: 'Шаблоны ПФ',
};

export default headerLinks;
