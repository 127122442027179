const headerLinks: Record<string, Record<string, string> | string> = {
    campaigns: 'Campaigns',
    categories: 'Categories',
    subjects: 'Objects',
    templatesOfExpertise: 'Templates of expertise',
    expertiseTasks: 'My tasks',
    expertiseTasksForCurator: 'Tasks',
    expertiseList: 'Expertise',
    users: 'Users',
    pfTemplates: 'Templates of PF',
};

export default headerLinks;
