import React, { useMemo } from 'react';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { ReactComponent as PhoneIcon } from '../resources/images/icons/phone-alt.svg';
import { ReactComponent as MailIcon } from '../resources/images/icons/envelope.svg';
import { observer } from 'mobx-react';
import { useFeature } from 'feature-toggle-jsx';
import { useStore } from '../hooks/useStore';
import getEmailFromText from '../utils/getEmailFromText';

const FooterWrapper = withTheme(
    styled.footer`
        position: relative;
        padding: ${({ theme }) => `${theme.spacing(3.75)}px 0`};
        background-color: ${({ theme }) => theme?.variables.palette.appBar.mainContrast};
        bottom: 0;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: ${({ theme }) => theme?.variables.palette.appBar.mainContrast};
        }
    `,
);

export const FooterButton = withTheme(
    styled(Button)`
        .MuiButton-label {
            align-items: center;
        }

        .MuiTypography-root {
            font-weight: bold;
        }
    `,
);

export const FooterTypography = withTheme(
    styled(Typography)`
        color: ${({ theme }) => theme?.variables.palette.footer.textMain};
        text-transform: none;
        font-size: 15px;
        line-height: 1.47;
        letter-spacing: 0.3px;
        color: ${({ theme }) => theme?.variables.palette.mainLight};
    `,
);

export const Footer = observer(
    (): JSX.Element => {
        const { env, intlStore } = useStore();
        const { totTel, totMail = '' } = env;
        const hrefTel = (totTel || '').replace(/\s/g, '');

        const email = getEmailFromText(totMail);
        const [phoneNumber] = useFeature('phoneNumber');

        const copyright = useMemo((): string | undefined => {
            return intlStore.locale === 'en' ? env.totCopyrightEn : env.totCopyrightRu;
        }, [intlStore.locale, env]);

        return (
            <FooterWrapper>
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="center" justify="space-between" spacing={3}>
                        <Grid item>
                            <FooterTypography component="small" color="primary">
                                {copyright}
                            </FooterTypography>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" alignItems="center" justify="flex-end">
                                {phoneNumber && (
                                    <Grid item>
                                        <Box pr={3}>
                                            <FooterButton
                                                component="a"
                                                href={`tel:${hrefTel}`}
                                                startIcon={<PhoneIcon />}
                                                variant="text"
                                                color="secondary"
                                            >
                                                {totTel}
                                            </FooterButton>
                                        </Box>
                                    </Grid>
                                )}
                                {email && (
                                    <Grid item>
                                        <FooterButton
                                            component="a"
                                            href={`mailto:${email}`}
                                            startIcon={<MailIcon />}
                                            variant="text"
                                            color="secondary"
                                        >
                                            {totMail}
                                        </FooterButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </FooterWrapper>
        );
    },
);
