import { observer } from 'mobx-react';
import { Grid, Tooltip } from '@material-ui/core';
import React from 'react';
import { entities } from '../../../../authSchemeConfig';
import { FormattedMessage } from 'react-intl';
import { IconButtonLarge } from '../../../../components/buttons/IconButtonLarge';
import { ReactComponent as EditIcon } from '../../../../resources/images/icons/edit.svg';
import { AuthorizationCheck } from '../../../../components/AuthorizationCheck';

export type ExpertiseTaskAuthEditFieldProps = {
    toggleIsOpenDialog?: () => void;
    messageId: string;
    permCode: string;
    entityId?: string;
};

export const ExpertiseTaskAuthEditField = observer(
    (props: ExpertiseTaskAuthEditFieldProps): JSX.Element => {
        const { toggleIsOpenDialog, messageId, entityId, permCode } = props;

        return (
            <React.Fragment>
                {toggleIsOpenDialog && (
                    <AuthorizationCheck entityCode={entities.ExpertiseTask} permCode={permCode} entityId={entityId}>
                        <Grid item>
                            <Tooltip title={<FormattedMessage id={messageId} />}>
                                <div>
                                    <IconButtonLarge onClick={toggleIsOpenDialog}>
                                        <EditIcon />
                                    </IconButtonLarge>
                                </div>
                            </Tooltip>
                        </Grid>
                    </AuthorizationCheck>
                )}
            </React.Fragment>
        );
    },
);
