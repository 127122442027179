import { UserNameModel, UserPersonDTO } from './person/UserNameModel';
import { action, observable } from 'mobx';
import { DateUtils } from '../utils/DateUtils';
import { IdTitle } from './IdTitle';

export interface MetaInfoDTO {
    author: UserPersonDTO;
    created: string; //date
    modified?: string; //date
    stateTitle?: string;
    processInfo?: IdTitle;
    formInfos: string[];
}

export class MetaInfoModel {
    @observable author: UserNameModel;
    @observable created?: Date;
    @observable modified?: Date;
    @observable stateTitle?: string;
    @observable processInfo?: IdTitle;
    @observable formInfos: string[] = [];

    constructor() {
        this.author = new UserNameModel();
    }

    @action.bound
    load(dto: MetaInfoDTO): MetaInfoModel {
        this.author.load(dto.author);

        const created = new Date(dto.created || '');
        if (DateUtils.isValidDate(created)) {
            this.created = created;
        }

        const modified = new Date(dto.modified || '');
        if (DateUtils.isValidDate(modified)) {
            this.modified = modified;
        }
        this.stateTitle = dto.stateTitle;
        this.processInfo = dto.processInfo;
        this.formInfos = dto.formInfos;
        return this;
    }
}
