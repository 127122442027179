import React from 'react';
import { Grid, Link } from '@material-ui/core';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { RowData } from '../../../../components/PageHeader/RowData';
import { generatePath, NavLink } from 'react-router-dom';
import { getOrElse } from '../../../../utils/getOrElse';
import { ExpertiseModel } from '../../../../models/expertise/ExpertiseModel';
import clientRoute from '../../../../clientRoute';

export type ExpertiseHeaderLeftProps = {
    identifier: ExpertiseModel['identifier'];
    stateTitle: ExpertiseModel['metaInfo']['stateTitle'];
    created: ExpertiseModel['metaInfo']['created'];
    template?: ExpertiseModel['template'];
    subjectOfExpertise?: ExpertiseModel['subject'];
};

export const ExpertiseHeaderLeft = (props: ExpertiseHeaderLeftProps): JSX.Element => {
    const { identifier, stateTitle, created, template, subjectOfExpertise } = props;

    const templateValue = template?.title || '-';
    const templateWrapper =
        template &&
        ((children: JSX.Element): JSX.Element => {
            return (
                <Link
                    component={NavLink}
                    underline="none"
                    to={generatePath(clientRoute.templateOfExpertise, {
                        id: template.id,
                    })}
                    color="textPrimary"
                >
                    {children}
                </Link>
            );
        });

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item container spacing={7} direction="row">
                <Grid item>
                    <RowData
                        label={<FormattedMessage id="expertise.fields.identifier" />}
                        value={getOrElse(identifier)}
                    />
                </Grid>
                <Grid item>
                    <RowData label={<FormattedMessage id="expertise.fields.state" />} value={getOrElse(stateTitle)} />
                </Grid>
            </Grid>
            <Grid item container spacing={7} direction="row" wrap="nowrap">
                <Grid item>
                    <RowData
                        label={<FormattedMessage id="expertise.fields.created" />}
                        value={getOrElse(created, <FormattedDate value={created} />)}
                    />
                </Grid>
                <Grid item>
                    <RowData
                        label={<FormattedMessage id="expertise.fields.template" />}
                        value={templateValue}
                        valueWrapper={templateWrapper}
                    />
                </Grid>
                <Grid item>
                    <RowData
                        label={<FormattedMessage id="expertise.fields.expertiseSubject" />}
                        value={
                            subjectOfExpertise ? (
                                <Link
                                    component={NavLink}
                                    underline="none"
                                    to={generatePath(clientRoute.subject, {
                                        id: subjectOfExpertise.id,
                                    })}
                                    color="textPrimary"
                                >
                                    {subjectOfExpertise.number}
                                </Link>
                            ) : (
                                '-'
                            )
                        }
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
