import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const TransitionButton = withTheme(
    styled(Button)`
        .MuiButton-label {
            max-width: 250px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: inline;
        }
    `,
);
