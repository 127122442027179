import React from 'react';
import { ExpansionPanel, Typography, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { TotObjectPanelDetails, TotObjectPanelSummary } from '../../components/tot-object/TotObjectServiceInfo';
import { TotObjectPanelLabel } from '../../components/tot-object/TotObjectPanel';
import { StyledButton, StyledTypography } from './StyledCampaignComponents';
import { NavLink } from 'react-router-dom';
import { CampaignSubjects } from '../../store/CampaignStore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createLinkWithQueries } from '../../utils/createLinkWithQueries';
import { CampaignSubjectsLinks } from '../../models/campaign/CampaignLinksModel';

export type CampaignSubjectsPanelProps = {
    subjects: CampaignSubjects;
    links: CampaignSubjectsLinks;
};

export const CampaignSubjectsPanel = (props: CampaignSubjectsPanelProps): JSX.Element => {
    const { subjects, links } = props;
    const { active, withExpertise, withoutExpertise, total } = links;

    return (
        <ExpansionPanel defaultExpanded>
            <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">
                    <FormattedMessage id="campaign.subjects.title" />
                </Typography>
            </TotObjectPanelSummary>
            <TotObjectPanelDetails>
                <Grid container direction="column">
                    <Grid item container direction="row">
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.subjects.total" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledButton
                                        component={NavLink}
                                        to={createLinkWithQueries(total.urn, total.queries)}
                                        variant="text"
                                    >
                                        {subjects.total}
                                    </StyledButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.subjects.active" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(active.urn, active.queries)}
                                            variant="text"
                                        >
                                            {subjects.active}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.subjects.withExpertise" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(withExpertise.urn, withExpertise.queries)}
                                            variant="text"
                                        >
                                            {subjects.withExpertise}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.subjects.withoutExpertise" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(withoutExpertise.urn, withoutExpertise.queries)}
                                            variant="text"
                                        >
                                            {subjects.withoutExpertise}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectPanelDetails>
        </ExpansionPanel>
    );
};
