import React, { PropsWithChildren } from 'react';
import { Box, Container, Dialog, Grid, IconButton, Paper } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Close } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useFeature } from 'feature-toggle-jsx';
import { TotBackground } from '../../../TotBackground';

const queryString = require('query-string');

export type LoginWrapperProps = PropsWithChildren<{}>;

export const LoginWrapper = observer((props: LoginWrapperProps) => {
    const { children } = props;
    const [startPage] = useFeature('startPage');

    return startPage ? (
        <TotBackground>
            <Dialog maxWidth="xs" fullWidth open={true} scroll="body">
                <Box pl={12} pr={12} pb={12}>
                    <Box pt={4} mr={-8}>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <IconButton className="t-close-login" component={RouterLink} to="/">
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                    {children}
                </Box>
            </Dialog>
        </TotBackground>
    ) : (
        <TotBackground withBackdrop={true}>
            <Grid container item direction="column" alignItems="center" justify="center">
                <Container maxWidth="xs">
                    <Paper elevation={24}>
                        <Box p={12}>{children}</Box>
                    </Paper>
                </Container>
            </Grid>
        </TotBackground>
    );
});
