import React from 'react';
import { ExpansionPanel, Typography, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { TotObjectPanelDetails, TotObjectPanelSummary } from '../../components/tot-object/TotObjectServiceInfo';
import { TotObjectPanelLabel } from '../../components/tot-object/TotObjectPanel';
import { StyledButton, StyledTypography } from './StyledCampaignComponents';
import { NavLink } from 'react-router-dom';
import { CampaignExpertise } from '../../store/CampaignStore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createLinkWithQueries } from '../../utils/createLinkWithQueries';
import { CampaignExpertiseLinks } from '../../models/campaign/CampaignLinksModel';

export type CampaignExpertisesPanelProps = {
    expertises: CampaignExpertise;
    links: CampaignExpertiseLinks;
};

export const CampaignExpertisesPanel = (props: CampaignExpertisesPanelProps): JSX.Element => {
    const { expertises, links } = props;
    const { total, active, done, canceled } = links;

    return (
        <ExpansionPanel defaultExpanded>
            <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">
                    <FormattedMessage id="campaign.expertises.title" />
                </Typography>
            </TotObjectPanelSummary>
            <TotObjectPanelDetails>
                <Grid container direction="column">
                    <Grid item container direction="row">
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.expertises.total" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(total.urn, total.queries)}
                                            variant="text"
                                        >
                                            {expertises.total}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.expertises.active" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(active.urn, active.queries)}
                                            variant="text"
                                        >
                                            {expertises.active}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.expertises.done" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(done.urn, done.queries)}
                                            variant="text"
                                        >
                                            {expertises.done}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.expertises.canceled" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(canceled.urn, canceled.queries)}
                                            variant="text"
                                        >
                                            {expertises.canceled}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectPanelDetails>
        </ExpansionPanel>
    );
};
