import { RootStoreProps } from '../types/stores/rootStore';
import { createBrowserHistory } from 'history';

const projectName = process.env.REACT_APP_THEME_NAME;

const env: RootStoreProps['env'] = {
    apiUrl: process.env.REACT_APP_API_URL,
    totCopyrightRu: process.env.REACT_APP_TOT_COPYRIGHT_RU,
    totCopyrightEn: process.env.REACT_APP_TOT_COPYRIGHT_EN,
    totTel: process.env.REACT_APP_TOT_TEL,
    totMail: process.env.REACT_APP_TOT_MAIL,
    appThemeName: process.env.REACT_APP_THEME_NAME,
    formServiceUrl: process.env.REACT_APP_FORM_UI_SERVICE_URL,
    lifeCycleServiceUrl: process.env.REACT_APP_LIFECYCLE_SERVICE_URL,
    termsOfServiceLinkRu: process.env.REACT_APP_TERMS_OF_SERVICE_RU,
    termsOfServiceLinkEn: process.env.REACT_APP_TERMS_OF_SERVICE_EN,
    yaMetrikaId: Number(process.env.REACT_APP_METRIKA_ID),
};

let features: RootStoreProps['features'];
let ru = {};
let en = {};
let themeOverrides: RootStoreProps['themeOverrides'];
if (projectName) {
    features = require(`./features/${projectName}`).default;
    try {
        ru = require(`./resources/locales/${projectName}/ru`).default;
        en = require(`./resources/locales/${projectName}/en`).default;
    } catch (e) {}
    themeOverrides = require(`./themes/${projectName}/${projectName}`).default;
}

const locales: RootStoreProps['locales'] = { ru, en };

const history = createBrowserHistory();

export { env, features, locales, themeOverrides, history };
