export default {
    startPage: false, // отображение стартовой страницы отдельно от логина
    startPageDefaultText: false,
    startPageWithLogin: true, // совмещение стартовой и логина
    // если выставить и startPage, и startPageWithLogin в false - будет отображаться стандартная форма-диалог логина
    logo: {
        isEnabled: true,
        locales: {
            ru: {
                src: require(`../resources/logo.svg`),
                alt: 'TOT EXP logo',
            },
            en: {
                src: require(`../resources/logo.svg`),
                alt: 'TOT EXP logo',
            },
        },
        style: {},
        withText: true,
        mdLogo: false,
    },
    customBackground: false,
    phoneNumber: true,
    instructions: false,
    esiaLogin: false,

    yaMetrika: true,

    userAdding: true,
    userAssignPassword: true,

    withSubjectFilesTab: false,
};
