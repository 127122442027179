import { TotObjectPanelDetails, TotObjectPanelSummary } from '../../../components/tot-object/TotObjectServiceInfo';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanel, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React, { PropsWithChildren } from 'react';

export type ExpertiseTaskServiceInfoWrapperProps = PropsWithChildren<{
    withExpansionPanel: boolean;
}>;

export const ExpertiseTaskServiceInfoWrapper = (props: ExpertiseTaskServiceInfoWrapperProps): JSX.Element => {
    const { withExpansionPanel, children } = props;
    return withExpansionPanel ? (
        <ExpansionPanel defaultExpanded elevation={0}>
            <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">
                    <FormattedMessage id="common.serviceInfo" />
                </Typography>
            </TotObjectPanelSummary>
            <TotObjectPanelDetails>{children}</TotObjectPanelDetails>
        </ExpansionPanel>
    ) : (
        <React.Fragment>{children}</React.Fragment>
    );
};
