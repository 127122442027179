import React, { ReactNode } from 'react';
import { StickyWrapper } from './sticky/StickyWrapper';
import { TotObjectActionsBar } from './tot-object/TotObjectActionsBar';
import { Container, Grid } from '@material-ui/core';

export type BottomControlPanelProps = {
    left?: ReactNode;
    right: ReactNode;
};

export const BottomControlPanel = (props: BottomControlPanelProps): JSX.Element => {
    const { left, right } = props;

    return (
        <StickyWrapper mode="bottom">
            <TotObjectActionsBar>
                <Container maxWidth="lg">
                    <Grid alignItems="center" justify="space-between" container style={{ height: '65px' }}>
                        <Grid item>{left}</Grid>
                        <Grid item>{right}</Grid>
                    </Grid>
                </Container>
            </TotObjectActionsBar>
        </StickyWrapper>
    );
};
