import React from 'react';
import { Route, Switch } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { UserListPage } from './user-list/UserListPage';
import { UserPage } from './UserPage';

class UserPages extends React.Component<{}, {}> {
    render(): JSX.Element {
        return (
            <Switch>
                <Route path={clientRoute.user}>
                    <UserPage />
                </Route>
                <Route path={clientRoute.users}>
                    <UserListPage />
                </Route>
            </Switch>
        );
    }
}

export default UserPages;
