import React from 'react';
import { Grid, Link } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { RowData } from '../../../../components/PageHeader/RowData';
import { NavLink, generatePath } from 'react-router-dom';
import clientRoute from '../../../../clientRoute';
import { SubjectModel } from '../../../../models/subject/SubjectModel';

export type SubjectHeaderRightProps = {
    campaign: SubjectModel['campaign'];
    expertise: SubjectModel['expertise'];
};

export const SubjectHeaderRight = (props: SubjectHeaderRightProps): JSX.Element => {
    const { campaign, expertise } = props;

    const campaignValue = campaign?.title || '-';
    const campaignWrapper =
        campaign &&
        ((children: JSX.Element): JSX.Element => {
            return (
                <Link
                    component={NavLink}
                    underline="none"
                    to={generatePath(clientRoute.campaign, {
                        id: campaign.id,
                    })}
                    color="textPrimary"
                >
                    {children}
                </Link>
            );
        });

    const expertiseValue = expertise?.title || '-';
    const expertiseWrapper =
        expertise &&
        ((children: JSX.Element): JSX.Element => {
            return (
                <Link
                    component={NavLink}
                    underline="none"
                    to={generatePath(clientRoute.expertise, {
                        id: expertise.id,
                    })}
                    color="textPrimary"
                >
                    {children}
                </Link>
            );
        });

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Grid item>
                    <RowData
                        label={<FormattedMessage id="subject.fields.campaign" />}
                        value={campaignValue}
                        valueWrapper={campaignWrapper}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <Grid item>
                    <RowData
                        label={<FormattedMessage id="subject.fields.expertise" />}
                        value={expertiseValue}
                        valueWrapper={expertiseWrapper}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
