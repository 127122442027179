import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SubjectListPage as SubjectListPageInj } from './subject-list/SubjectListPage';
import clientRoute from '../../clientRoute';
import { SubjectPage as SubjectPageInj } from './subject-page/SubjectPage';
import { di } from 'react-magnetic-di';
import { observer } from 'mobx-react';

export const SubjectPages = observer(
    (): JSX.Element => {
        const [SubjectPage] = di([SubjectPageInj], SubjectPages);
        const [SubjectListPage] = di([SubjectListPageInj], SubjectPages);
        return (
            <Switch>
                <Route path={clientRoute.subject}>
                    <SubjectPage />
                </Route>
                <Route>
                    <SubjectListPage />
                </Route>
            </Switch>
        );
    },
);
