import React, { useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    LinearProgress,
    Link,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@material-ui/core';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { TotTablePagination } from '../../components/TotTablePagination';
import clientRoute from '../../clientRoute';
import { ExpertiseTaskListModel, ExpertiseTaskRow } from '../../models/expertise-task/ExpertiseTaskListModel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as ClearFilter } from '../../resources/images/icons/clear-filter.svg';
import ExpertiseTaskListFilterPanel from './ExpertiseTaskListFilterPanel';
import { ExportButton } from '../../components/buttons/ExportButton';
import { ExpertiseTasksRow } from '../../models/expertise-task/ExpertiseTasksListByExpertiseModel';
import { useStore } from '../../hooks/useStore';

export const getTaskRoute = (task: ExpertiseTaskRow | ExpertiseTasksRow): string => {
    const { id } = task;
    return generatePath(clientRoute.expertiseTask, { id });
};

export const ExpertiseTaskListPage = observer(
    (): JSX.Element => {
        const { api, history, expertiseTaskStore } = useStore();
        const intl = useIntl();
        const tableModel = useMemo(() => new ExpertiseTaskListModel(api, history), [api, history]);

        useEffect(() => {
            return tableModel.dispose;
        }, [tableModel.dispose]);

        const renderBody = (): JSX.Element[] => {
            return tableModel.rows.map((t) => {
                const { id } = t;
                return (
                    <TableRow key={id} hover>
                        <TableCell>
                            <Link
                                component={NavLink}
                                underline="none"
                                to={generatePath(clientRoute.expertiseTask, { id })}
                            >
                                {t.identifier}
                            </Link>
                        </TableCell>
                        <TableCell>{t.taskType.title}</TableCell>
                        <TableCell>{getExpertCell(t)}</TableCell>
                        <TableCell>
                            <Link
                                component={NavLink}
                                underline="none"
                                to={generatePath(clientRoute.expertise, { id: t.expertise.id })}
                            >
                                {t.expertise.title}
                            </Link>
                        </TableCell>
                        <TableCell>{t.curator?.name || ''}</TableCell>
                        <TableCell>{t.created && <FormattedDate value={t.created} />}</TableCell>
                        <TableCell>{t.deadline && <FormattedDate value={t.deadline} />}</TableCell>
                        <TableCell>{t.state}</TableCell>
                    </TableRow>
                );
            });
        };

        const getExpertCell = (row: ExpertiseTaskRow): React.ReactNode => {
            return row.expert ? (
                row.expert.name
            ) : row.expertCandidate ? (
                <FormattedMessage
                    id="expertiseTask.expertCandidate"
                    values={{
                        expert: row.expertCandidate.name,
                    }}
                />
            ) : (
                ''
            );
        };

        const { sorting } = tableModel;
        const filename = `${intl.formatMessage({ id: 'expertiseTask.listTitle' })}.xlsx`;

        return (
            <Container maxWidth="lg">
                <Box pt={5} pb={5}>
                    <Grid container direction="column" spacing={10}>
                        <Grid item container direction="row" justify="space-between">
                            <Grid item>
                                <Typography variant="h1">
                                    <FormattedMessage id="expertiseTask.listTitle" />
                                </Typography>
                            </Grid>

                            <Grid item xs={6} container spacing={2} justify="flex-end">
                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        startIcon={
                                            <SvgIcon>
                                                <ClearFilter />
                                            </SvgIcon>
                                        }
                                        onClick={tableModel.clearFilters}
                                    >
                                        <FormattedMessage id="common.resetFilters" />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <ExportButton
                                        queryData={tableModel.queryData}
                                        loadRegisterList={expertiseTaskStore.exportListXls}
                                        filename={filename}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <ExpertiseTaskListFilterPanel model={tableModel} />
                        </Grid>
                        <Grid item>
                            <TableContainer component={Paper}>
                                {tableModel.isLoading && <LinearProgress />}
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '16%' }}>
                                                <TableSortLabel
                                                    active={sorting.identifier.isActive}
                                                    direction={sorting.identifier.direction}
                                                    onClick={tableModel.changeSorting(sorting.identifier)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <Typography>
                                                        <FormattedMessage id="expertiseTask.fields.number" />
                                                    </Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell style={{ width: '12%' }}>
                                                <Typography>
                                                    <FormattedMessage id="expertiseTask.fields.taskType" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: '12%' }}>
                                                <Typography>
                                                    <FormattedMessage id="expertiseTask.fields.expert" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: '12%' }}>
                                                <TableSortLabel
                                                    active={sorting.expertise.isActive}
                                                    direction={sorting.expertise.direction}
                                                    onClick={tableModel.changeSorting(sorting.expertise)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <Typography>
                                                        <FormattedMessage id="expertiseTask.fields.expertiseNumber" />
                                                    </Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell style={{ width: '12%' }}>
                                                <Typography>
                                                    <FormattedMessage id="expertiseTask.fields.curator" />
                                                </Typography>
                                            </TableCell>

                                            <TableCell style={{ width: '12%' }}>
                                                <TableSortLabel
                                                    active={sorting.created.isActive}
                                                    direction={sorting.created.direction}
                                                    onClick={tableModel.changeSorting(sorting.created)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <Typography>
                                                        <FormattedMessage id="expertiseTask.fields.created" />
                                                    </Typography>
                                                </TableSortLabel>
                                            </TableCell>

                                            <TableCell style={{ width: '12%' }}>
                                                <TableSortLabel
                                                    active={sorting.deadline.isActive}
                                                    direction={sorting.deadline.direction}
                                                    onClick={tableModel.changeSorting(sorting.deadline)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <Typography>
                                                        <FormattedMessage id="expertiseTask.fields.deadline" />
                                                    </Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell style={{ width: '12%' }}>
                                                <Typography>
                                                    <FormattedMessage id="expertiseTask.fields.state" />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{renderBody()}</TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TotTablePagination
                                                count={tableModel.rowsCount}
                                                page={tableModel.pageNumber}
                                                onChangePage={tableModel.onChangePage}
                                                onChangeRowsPerPage={tableModel.onChangePageSize}
                                                rowsPerPage={tableModel.pageSize}
                                                rowsPerPageOptions={tableModel.pageSizeOptions}
                                                labelRowsPerPage={intl.formatMessage({ id: 'common.rowsPerPage' })}
                                                labelDisplayedRows={(p): string =>
                                                    intl.formatMessage(
                                                        { id: 'expertiseTask.registryPagingInfo' },
                                                        { to: p.to, from: p.from, count: p.count },
                                                    )
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        );
    },
);
