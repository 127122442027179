import React from 'react';
import { Container, Grid } from '@material-ui/core';

interface Props {
    title?: React.ReactNode;
    left: React.ReactNode;
    right?: React.ReactNode;
}

export const PageHeader = (props: Props) => {
    const { title, left, right } = props;

    return (
        <Container maxWidth="lg">
            <Grid container direction="column" spacing={3}>
                <Grid item container direction="row" spacing={5} alignItems="center">
                    {title}
                </Grid>
                {right && left ? (
                    <Grid item container direction="row" justify="space-between" alignItems="center">
                        <Grid item>{left}</Grid>
                        <Grid item>{right}</Grid>
                    </Grid>
                ) : (
                    <Grid item container spacing={7}>
                        {left}
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};
