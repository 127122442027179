import React, { ReactNode, useEffect, useState } from 'react';
import { Button, Grid, MenuItem, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { ExpertiseTaskModel } from '../../../../models/expertise-task/ExpertiseTaskModel';
import { entities, permissions } from '../../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../../components/AuthorizationCheck';
import { TotObjectTransitions } from '../../../../components/tot-object/TotObjectTransitions';
import { generatePath, NavLink, useRouteMatch } from 'react-router-dom';
import clientRoute from '../../../../clientRoute';
import { SelectionOfExpertDialog } from '../../SelectionOfExpertDialog';
import SaveButton from './SaveButton';
import { GridNoOverflow } from '../../../../components/styled/GridNoOverflow';
import { MenuButton } from '../../../../components/buttons/MenuButton';
import { ActionsButton } from '../../../../components/buttons/ActionsButton';
import { DeleteActionMenuItem } from '../../../../components/DeleteActionMenuItem';
import { AuthorizationCheckQuery } from '../../../../store/AuthorizationStore';
import { TaskViewControlPanelContainer } from './ExpertiseTaskViewPage.styled';
import { useStore } from '../../../../hooks/useStore';
import { useModal } from '../../../../hooks/useModal';

export type ExpertiseTaskViewControlPanelProps = {
    expertiseTaskModel: ExpertiseTaskModel;
    deleteExpertiseTask: () => Promise<void>;
    onSubmit: () => Promise<void>;
    onLifeCycleTransition: (transitionId: string, taskId: string, validate?: boolean) => Promise<void>;
    updateTaskViewPage: () => void;
    saveProgress: boolean;
    status: 'success' | 'default' | 'failed';
};

export const ExpertiseTaskViewControlPanel = observer(
    (props: ExpertiseTaskViewControlPanelProps): JSX.Element => {
        const match = useRouteMatch();
        const { expertiseTaskStore, authorizationStore } = useStore();
        const {
            updateTaskViewPage,
            onSubmit,
            onLifeCycleTransition,
            saveProgress,
            status,
            deleteExpertiseTask,
        } = props;
        const { id, activeInviteId, identifier } = props.expertiseTaskModel;

        const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();

        const [canEdit, setCanEdit] = useState<boolean>(false);
        const [canDelete, setCanDelete] = useState<boolean>(false);

        const deleteCheck: AuthorizationCheckQuery = {
            entityCode: entities.ExpertiseTask,
            permCode: permissions.ExpertiseTask.Delete,
            entityId: id,
        };
        const editCheck: AuthorizationCheckQuery = {
            entityCode: entities.ExpertiseTask,
            permCode: permissions.ExpertiseTask.Edit,
            entityId: id,
        };

        useEffect(() => {
            const checkAllAuth = async (): Promise<void> => {
                try {
                    const [canEditResult, canDeleteResult] = await authorizationStore.checkAll([
                        editCheck,
                        deleteCheck,
                    ]);
                    setCanEdit(canEditResult);
                    setCanDelete(canDeleteResult);
                } catch (error) {
                    console.error(error.message);
                }
            };

            checkAllAuth();
        }, [authorizationStore, deleteCheck, editCheck]);

        const onExpertSelection = (): void => {
            updateTaskViewPage();
        };

        const renderMenuActionItems = (): (() => ReactNode[]) => {
            const backUrl = match.url;
            const nodes: ReactNode[] = [];
            if (canDelete) {
                nodes.push(
                    <DeleteActionMenuItem
                        id="delete"
                        key="delete"
                        wrappedComponentProps={{ tabIndex: 0 }}
                        title={<FormattedMessage id="common.confirmDeletion" />}
                        message={
                            <FormattedMessage
                                id="expertiseTask.confirmDeletionInfoText"
                                values={{
                                    number: identifier,
                                }}
                            />
                        }
                        onConfirm={deleteExpertiseTask}
                    />,
                );
            }
            if (canEdit) {
                nodes.push(
                    <MenuItem
                        dense
                        button={true}
                        tabIndex={1}
                        component={NavLink}
                        key="edit"
                        to={`${generatePath(clientRoute.expertiseTaskEdit, {
                            id,
                        })}?backUrl=${backUrl}`}
                    >
                        <FormattedMessage id="expertiseTask.actions.edit" />
                    </MenuItem>,
                );
            }
            return (): ReactNode[] => nodes;
        };

        const renderMenuActionsButton = (): ReactNode => {
            if (canEdit || canDelete) {
                return (
                    <Grid item>
                        <MenuButton
                            placement="bottom"
                            disablePortal={false}
                            renderButton={ActionsButton}
                            renderMenuItems={renderMenuActionItems()}
                        />
                    </Grid>
                );
            } else {
                return null;
            }
        };

        const taskInviteLifeCycleTransition = async (transitionId: string, objectId: string): Promise<void> => {
            await expertiseTaskStore.taskInviteLifeCycleTransition(transitionId, objectId);
            updateTaskViewPage();
        };

        const lifeCycleTransition = async (
            transitionId: string,
            objectId: string,
            validate?: boolean,
        ): Promise<void> => {
            await onLifeCycleTransition(transitionId, objectId, validate);
            updateTaskViewPage();
        };

        return (
            <TaskViewControlPanelContainer spacing={3} wrap="nowrap">
                {renderMenuActionsButton()}
                <AuthorizationCheck
                    entityCode={entities.ExpertiseTask}
                    entityId={id}
                    permCode={permissions.ExpertiseTask.SetExpert}
                >
                    <GridNoOverflow>
                        <Button variant="contained" color="secondary" onClick={setModalIsOpen}>
                            <Typography variant="inherit" noWrap>
                                <FormattedMessage id="expertiseTask.selectExpertButton" />
                            </Typography>
                        </Button>
                        {isModalOpen && (
                            <SelectionOfExpertDialog onClose={setModalIsClosed} onSubmit={onExpertSelection} />
                        )}
                    </GridNoOverflow>
                </AuthorizationCheck>
                {activeInviteId && (
                    <TotObjectTransitions
                        objectId={activeInviteId}
                        updateObjectPage={updateTaskViewPage}
                        getTransitions={expertiseTaskStore.getTaskInviteLifeCycleTransitions}
                        lifeCycleTransition={(transitionId: string, objectId: string): Promise<void> =>
                            taskInviteLifeCycleTransition(transitionId, objectId)
                        }
                        noOverflow
                    />
                )}

                <TotObjectTransitions
                    objectId={id}
                    updateObjectPage={updateTaskViewPage}
                    getTransitions={expertiseTaskStore.getLifeCycleTransitions}
                    lifeCycleTransition={(transitionId: string, objectId: string, validate?: boolean): Promise<void> =>
                        lifeCycleTransition(transitionId, objectId, validate)
                    }
                    noOverflow
                />

                <AuthorizationCheck
                    entityCode={entities.ExpertiseTask}
                    entityId={id}
                    permCode={permissions.ExpertiseTask.EditConclusion}
                >
                    <GridNoOverflow>
                        <SaveButton status={status} onSubmit={onSubmit} saveProgress={saveProgress} noOverflow />
                    </GridNoOverflow>
                </AuthorizationCheck>
            </TaskViewControlPanelContainer>
        );
    },
);
