import { computed, observable } from 'mobx';
import { LikeFilterValue } from './filter/LikeFilter';
import { InSetFilterValue } from './filter/InSetFilter';
import { DateRangeFilterValue, DateRangeJsonFilterValue } from './filter/DateRangeFilter';
import { CheckFilterValue } from './filter/CheckFilter';

export type FilterValue = LikeFilterValue | InSetFilterValue | CheckFilterValue | DateRangeFilterValue;
export type JsonFilterValue = string | string[] | boolean | DateRangeJsonFilterValue;

export abstract class Filter<T> {
    @observable value: T;

    protected constructor(defaultValue: T) {
        this.value = defaultValue;
    }

    abstract checkActive(value: T): boolean;

    abstract clear(): void;

    abstract get asJson(): JsonFilterValue;

    @computed
    get isActive(): boolean {
        return this.checkActive(this.value);
    }
}
