import React, { useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    LinearProgress,
    Link,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { TotTablePagination } from '../../../components/TotTablePagination';
import clientRoute from '../../../clientRoute';
import { UserListModel, UserStatusCode } from '../../../models/users/UserListModel';
import UserListFilterPanel from './UserListFilterPanel';
import { ErrorDialog } from '../../../components/ErrorDialog';
import { AxiosError } from 'axios';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { AuthorizationCheck } from '../../../components/AuthorizationCheck';
import { permissionsConfig } from '../../../authSchemeConfig';
import { useFeature } from 'feature-toggle-jsx';
import { UserDeleteMenuItemWithConfirm } from './user-list-menu-items/UserDeleteMenuItemWithConfirm';
import { useStore } from '../../../hooks/useStore';
import { useError } from '../../../hooks/useError';
import { useModal } from '../../../hooks/useModal';
import { MenuButton } from '../../../components/buttons/MenuButton';
import { ActionsButton } from '../../../components/buttons/ActionsButton';
import { UserAssignPasswordDialog } from './user-list-menu-items/UserAssignPasswordDialog';
import { UserAddDialog } from './user-add/UserAddDialog';
import { UserInfoMessageValues } from './UserInfoMessageValues';
import { UserBlockUnblock } from './user-block-unblock/UserBlockUnblock';

enum StatusTextId {
    active = 'users.status.active',
    blocked = 'users.status.blocked',
    inactive = 'users.status.inactive',
}

export const UserListPage = observer(
    (): JSX.Element => {
        const [userAdding] = useFeature('userAdding');
        const [userAssignPassword] = useFeature('userAssignPassword');
        const { userStore } = useStore();
        const { isError, errorText, setErrorIsClosed, setErrorIsOpen } = useError();
        const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();
        const intl = useIntl();
        const tableModel = useMemo(() => new UserListModel(userStore), [userStore]);

        useEffect(() => {
            return tableModel.dispose;
        }, [tableModel.dispose]);

        const renderActionItems = (hideMenu: () => void) => (
            userId: string,
            status: UserStatusCode,
            userInfoMessageValues: UserInfoMessageValues,
        ): (JSX.Element | null)[] => {
            const onActivateClick = (): void => {
                hideMenu();
                tableModel.unblockUser(userId)();
            };
            const onDeleteClick = (): Promise<void> => {
                hideMenu();
                return deleteUser(userId)();
            };
            return [
                status === 'inactive' ? (
                    <MenuItem key="activate-user" dense button={true} tabIndex={0} onClick={onActivateClick}>
                        <FormattedMessage id="users.actions.activate" />
                    </MenuItem>
                ) : null,
                userAssignPassword ? (
                    <AuthorizationCheck key="edit-user-password" {...permissionsConfig.accountManagement}>
                        <UserAssignPasswordDialog
                            id={userId}
                            tabIndex={1}
                            userInfoMessageValues={userInfoMessageValues}
                            hideMenu={hideMenu}
                        />
                    </AuthorizationCheck>
                ) : null,
                <UserDeleteMenuItemWithConfirm
                    id="delete-user"
                    key="delete-user"
                    title={<FormattedMessage id="users.deleteUser" />}
                    message={<FormattedMessage id="users.deleteUserConfirmText" values={userInfoMessageValues} />}
                    onConfirm={onDeleteClick}
                />,
            ];
        };

        const renderBody = (): JSX.Element[] => {
            return tableModel.rows.map((user) => {
                const { status } = user;
                const { id, title: login } = user.login;
                const userInfoMessageValues = { login, name: user.name };
                return (
                    <TableRow key={id} hover>
                        <TableCell>
                            <Link component={NavLink} underline="none" to={generatePath(clientRoute.user, { id })}>
                                {login}
                            </Link>
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id={StatusTextId[status]} />
                        </TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.roles}</TableCell>
                        <AuthorizationCheck {...permissionsConfig.updateUser(id)}>
                            {(allowed): JSX.Element => (
                                <React.Fragment>
                                    <TableCell>
                                        <UserBlockUnblock
                                            user={user}
                                            allowed={allowed}
                                            userInfoMessageValues={userInfoMessageValues}
                                            tableModel={tableModel}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {allowed && (
                                            <MenuButton
                                                disablePortal={true}
                                                renderButton={ActionsButton}
                                                renderMenuItems={(hideMenu) =>
                                                    renderActionItems(hideMenu)(id, status, userInfoMessageValues)
                                                }
                                            />
                                        )}
                                    </TableCell>
                                </React.Fragment>
                            )}
                        </AuthorizationCheck>
                    </TableRow>
                );
            });
        };

        const deleteUser = (id: string): (() => Promise<void>) => {
            return () => tableModel.deleteUser(id)().catch(handleErrorDeletion);
        };

        const handleErrorDeletion = (error: AxiosError): void => {
            const errorText = ErrorMessage(error);
            setErrorIsOpen(errorText);
        };

        return (
            <Container maxWidth="lg">
                {isModalOpen && (
                    <UserAddDialog
                        open={isModalOpen}
                        setModalIsClosed={setModalIsClosed}
                        createUser={userStore.createUser}
                        userRoleList={userStore.roleListWithoutAdministration}
                    />
                )}
                <Box pt={5} pb={5}>
                    <Grid container spacing={10} direction="column">
                        <Grid item container direction="row" justify="space-between">
                            <Grid item>
                                <Typography variant="h1">
                                    <FormattedMessage id="users.listTitle" />
                                </Typography>
                            </Grid>
                            {userAdding && (
                                <AuthorizationCheck {...permissionsConfig.accountManagement}>
                                    <Grid item>
                                        <Button variant="contained" onClick={setModalIsOpen} color="primary">
                                            <FormattedMessage id="users.addUser" />
                                        </Button>
                                    </Grid>
                                </AuthorizationCheck>
                            )}
                        </Grid>
                        <Grid item>
                            <UserListFilterPanel model={tableModel} />
                        </Grid>
                        <Grid item>
                            <TableContainer component={Paper}>
                                {tableModel.isLoading && <LinearProgress />}
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '23%' }}>
                                                <Typography>
                                                    <FormattedMessage id="users.fields.login" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: '23%' }}>
                                                <Typography>
                                                    <FormattedMessage id="users.fields.status" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: '23%' }}>
                                                <Typography>
                                                    <FormattedMessage id="users.fields.name" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: '23%' }}>
                                                <Typography>
                                                    <FormattedMessage id="users.fields.roles" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell />
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{renderBody()}</TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TotTablePagination
                                                count={tableModel.rowsCount}
                                                page={tableModel.pageNumber}
                                                onChangePage={tableModel.onChangePage}
                                                onChangeRowsPerPage={tableModel.onChangePageSize}
                                                rowsPerPage={tableModel.pageSize}
                                                rowsPerPageOptions={tableModel.pageSizeOptions}
                                                labelRowsPerPage={intl.formatMessage({ id: 'common.rowsPerPage' })}
                                                labelDisplayedRows={(p): string =>
                                                    intl.formatMessage(
                                                        { id: 'users.registryPagingInfo' },
                                                        { to: p.to, from: p.from, count: p.count },
                                                    )
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
                <ErrorDialog message={errorText} open={isError} onClose={setErrorIsClosed} />
            </Container>
        );
    },
);
