import React from 'react';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import clientRoute from '../../../../clientRoute';
import { AuthorizationCheck } from '../../../../components/AuthorizationCheck';
import { entities, permissions } from '../../../../authSchemeConfig';
import { ExpertiseCreateDialog } from '../../../expertise/ExpertiseCreateDialog';
import { TotObjectTransitions } from '../../../../components/tot-object/TotObjectTransitions';
import { SubjectPageRouteParams } from '../SubjectPage';
import { SubjectModel } from '../../../../models/subject/SubjectModel';
import { SubjectStore } from '../../../../store/SubjectStore';
import styled from 'styled-components';
import { AxiosPromise } from 'axios';
import { FilesDownload } from '../../../../components/files/FilesDownload';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    onSubmit: () => void;
    setModalIsOpen: () => void;
    id: SubjectPageRouteParams['id'];
    isModalOpen: boolean;
    subjectModel: SubjectModel;
    setModalIsClosed: () => void;
    goToSubjectReadPage: () => void;
    subjectStore: SubjectStore;
    onLifeCycleTransition: (transitionId: string, requestId: string, validate?: boolean | undefined) => Promise<void>;
}

const ButtonNoWrap = styled(Button)`
    white-space: nowrap;
`;

const ControlPanelRight = (props: Props): JSX.Element => {
    const {
        onSubmit,
        id,
        setModalIsOpen,
        isModalOpen,
        subjectModel,
        setModalIsClosed,
        goToSubjectReadPage,
        subjectStore,
        onLifeCycleTransition,
    } = props;

    const { intlStore } = useStore();
    const filename = `${intlStore.formatMessage('subject.allFiles', { title: subjectModel.identifier })}.zip`;

    const downloadAllFiles = (): AxiosPromise<Blob> => {
        return subjectStore.downloadSubjectFiles(subjectModel.id);
    };

    return (
        <Grid item container alignItems="baseline" spacing={3} wrap="nowrap">
            <Switch>
                <Route path={clientRoute.subjectCreate} exact>
                    <Grid item>
                        <Button variant="contained" component={NavLink} to={generatePath(clientRoute.subjects)}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={onSubmit}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route path={clientRoute.subjectEdit} exact>
                    <Grid item>
                        <Button variant="contained" component={NavLink} to={generatePath(clientRoute.subject, { id })}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={onSubmit}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route path={clientRoute.subject} exact>
                    <AuthorizationCheck
                        entityCode={entities.ExpertiseSubject}
                        entityId={id}
                        permCode={permissions.ExpertiseSubject.DownloadCommonFile}
                    >
                        <Grid item>
                            <FilesDownload
                                onDownloadClick={downloadAllFiles}
                                filename={filename}
                                buttonText={<FormattedMessage id="subject.downloadAllFiles" />}
                            />
                        </Grid>
                    </AuthorizationCheck>

                    <AuthorizationCheck
                        entityCode={entities.ExpertiseSubject}
                        permCode={permissions.ExpertiseSubject.CreateExpertise}
                        entityId={id}
                    >
                        <Grid item>
                            <ButtonNoWrap variant="contained" onClick={setModalIsOpen}>
                                <FormattedMessage id="expertise.createExpertise" />
                            </ButtonNoWrap>
                            {isModalOpen && (
                                <ExpertiseCreateDialog
                                    subject={{
                                        id,
                                        title: `${subjectModel.identifier} ${subjectModel.title}`,
                                    }}
                                    onClose={setModalIsClosed}
                                />
                            )}
                        </Grid>
                    </AuthorizationCheck>

                    <TotObjectTransitions
                        objectId={id}
                        updateObjectPage={goToSubjectReadPage}
                        getTransitions={subjectStore.getLifeCycleTransitions}
                        lifeCycleTransition={onLifeCycleTransition}
                    />
                </Route>
            </Switch>
        </Grid>
    );
};

export default ControlPanelRight;
