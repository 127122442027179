import { action, IObservableArray, observable } from 'mobx';
import PlanEntryModel from './PlanEntryModel';
import { ExpertisePlanStore, PlanEntryDTO } from '../../store/ExpertisePlanStore';
import { CodeTitle } from '../CodeTitle';
import { FullSubmission } from '@platform/formiojs-react';

export default class PlanEntryListModel {
    ownerId: string;
    @observable store: ExpertisePlanStore;
    @observable entries: PlanEntryDTO[] = [];
    @observable taskFormSelectData: IObservableArray<CodeTitle> = observable.array();
    @observable isAlertOpen = false;

    constructor(ownerId: string, store: ExpertisePlanStore) {
        this.ownerId = ownerId;
        this.store = store;
        this.loadPlan();
        this.store.expertiseTaskFormsSelectData().then((data) => this.taskFormSelectData.replace(data));
    }

    @action.bound
    setAlertIsOpen(): void {
        this.isAlertOpen = true;
    }

    @action.bound
    setAlertIsClosed(): void {
        this.isAlertOpen = false;
    }

    @action.bound
    loadPlan(): void {
        this.store.loadPlanEntryList(this.ownerId).then((entries) => (this.entries = entries));
    }

    @action.bound
    buildNewEntry(): PlanEntryModel {
        return new PlanEntryModel(this.taskFormSelectData, this.store.expertiseTaskFormByCode, this.store.intl);
    }

    @action.bound
    newEntry(): PlanEntryModel {
        const model = this.buildNewEntry();
        this.store.createPlanEntry(this.ownerId).then(model.loadCreated);
        return model;
    }

    @action.bound
    getEntry(id: string): PlanEntryModel {
        const model = this.buildNewEntry();
        this.store.loadPlanEntry(id).then(model.load);
        return model;
    }

    @action.bound
    async saveEntry(entry: PlanEntryModel, submission: FullSubmission, taskSubmission?: FullSubmission): Promise<void> {
        await this.store.updatePlanEntry(entry.id, submission, entry.taskFormCode, taskSubmission);
        await this.loadPlan();
        this.setAlertIsOpen();
    }

    @action.bound
    deleteEntry(id: string): () => Promise<void> {
        return async () => {
            await this.store.deletePlanEntry(id);
            await this.loadPlan();
            this.setAlertIsOpen();
        };
    }
}
