import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import clientRoute from './clientRoute';
import { TotAppBar } from './components/app-bar/TotAppBar';
import { AppContentContainer } from './components/AppContentContainer';
import { StartPage } from './pages/start/StartPage';
import RecoveryPasswordPage from './pages/authentication/RecoveryPasswordPage';
import { RegistrationPage } from './pages/authentication/RegistrationPage';
import AuthenticationInfoPage from './pages/authentication/AuthenticationInfoPage';
import RegistrationConfirmPage from './pages/authentication/RegistrationConfirmPage';
import { NewPasswordPage } from './pages/authentication/NewPasswordPage';
import { CampaignPages } from './pages/campaigns/CampaignPages';
import { Footer } from './components/Footer';
import EsiaPages from './pages/authentication/esia/EsiaPages';
import { ErrorPage } from './pages/error-page/ErrorPage';
import { SubjectPages } from './pages/subject/SubjectPages';
import TemplatesOfExpertisePages from './pages/templates-of-expertise/TemplatesOfExpertisePages';
import { ExpertiseTasksPages as ExpertiseTasksPagesInj } from './pages/task/ExpertiseTasksPages';
import { ExpertisePages } from './pages/expertise/ExpertisePages';
import UserPages from './pages/users/UserPages';
import CategoriesOfSubjectsPages from './pages/categories-subjects/CategoriesOfSubjectsPages';
import { PfTemplateListPage } from './pages/pf-template/PfTemplateListPage';
import { ExpertiseTaskViewPage } from './pages/task/expertise-task-page/view/ExpertiseTaskViewPage';
import { AppContentScreenSizeContainer } from './components/AppContentScreenSizeContainer';
import { ExpertiseTaskListForCuratorPage } from './pages/task/ExpertiseTaskListForCuratorPage';
import { LoginPage } from './pages/authentication/login/LoginPage';
import { useFeature } from 'feature-toggle-jsx';
import HomePage from './pages/home/HomePage';
import { Authentication } from './components/Authentication';
import { useStore } from './hooks/useStore';
import { di } from 'react-magnetic-di';
import { FormattedMessage } from 'react-intl';

export const LeadExpertExpertiseTaskViewPageInj = (): JSX.Element => <React.Fragment />;

export const getExpertiseTaskViewRoutesInj = (withSubjectFilesTab: boolean): string[] => {
    const routes = [clientRoute.expertiseTask, clientRoute.expertiseTaskSubject];
    if (withSubjectFilesTab) {
        routes.push(clientRoute.expertiseTaskFiles);
    }
    return routes;
};

export const ExpertiseTaskViewRoutesPagesInj = (withSubjectFilesTab: boolean): JSX.Element | JSX.Element[] => {
    const taskViewRoutes = getExpertiseTaskViewRoutesInj(withSubjectFilesTab);
    return (
        <Route path={taskViewRoutes} key={taskViewRoutes[0]} exact>
            <ExpertiseTaskViewPage />
        </Route>
    );
};

export const getLeadExpertExpertiseTaskRoutesInj = (): string[] => [];

export const App = (): JSX.Element => {
    const { personStore } = useStore();
    const [startPage] = useFeature('startPage');
    const [startPageWithLogin] = useFeature('startPageWithLogin');
    const [withSubjectFilesTab] = useFeature('withSubjectFilesTab');

    const [ExpertiseTasksPages] = di([ExpertiseTasksPagesInj], App);
    const [getExpertiseTaskViewRoutes] = di([getExpertiseTaskViewRoutesInj], App);
    const [ExpertiseTaskViewRoutesPages] = di([ExpertiseTaskViewRoutesPagesInj], App);

    const taskViewRoutes = getExpertiseTaskViewRoutes(withSubjectFilesTab);

    return (
        <Switch>
            <Route path={taskViewRoutes} key={taskViewRoutes[0]} exact>
                <AppContentScreenSizeContainer>
                    <TotAppBar />
                    <Authentication>{ExpertiseTaskViewRoutesPages(withSubjectFilesTab)}</Authentication>
                </AppContentScreenSizeContainer>
            </Route>
            {startPageWithLogin && (
                <Route path={clientRoute.login} exact>
                    <AppContentContainer>
                        <HomePage />
                    </AppContentContainer>
                </Route>
            )}
            <Route path={[clientRoute.notFound, clientRoute.notAllowed]}>
                <TotAppBar withInstructions={false} />
                <AppContentContainer>
                    <Switch>
                        <Route path={clientRoute.notFound} exact>
                            <ErrorPage errorCode="404" errorText={<FormattedMessage id="notFoundPage.notExist" />} />
                        </Route>
                        <Route path={clientRoute.notAllowed} exact>
                            <ErrorPage errorCode="403" errorText={<FormattedMessage id="common.actionNotAllowed" />} />
                        </Route>
                    </Switch>
                </AppContentContainer>
                <Footer />
            </Route>
            <Route>
                <TotAppBar />
                <AppContentContainer>
                    <Switch>
                        <Route path={clientRoute.esia}>
                            <EsiaPages />
                        </Route>
                        <Route path={clientRoute.newPassword}>
                            <NewPasswordPage />
                        </Route>
                        <Route path={clientRoute.recoveryPassword}>
                            <RecoveryPasswordPage />
                        </Route>
                        <Route path={clientRoute.authenticationInfo}>
                            <AuthenticationInfoPage />
                        </Route>
                        <Route path={clientRoute.registrationConfirm}>
                            <RegistrationConfirmPage />
                        </Route>
                        <Route path={clientRoute.registration}>
                            <RegistrationPage />
                        </Route>
                        {!startPage && (
                            <Route path={clientRoute.root} exact>
                                <Authentication>
                                    <Redirect to={personStore.redirectPath} />
                                </Authentication>
                            </Route>
                        )}
                        <Route path={[clientRoute.root, clientRoute.login]} exact>
                            {startPage && <StartPage />}
                            {!startPageWithLogin && (
                                <Route path={clientRoute.login} exact>
                                    <LoginPage />
                                </Route>
                            )}
                        </Route>
                        <Authentication>
                            <Switch>
                                <Route path={clientRoute.campaigns}>
                                    <CampaignPages />
                                </Route>
                                <Route path={clientRoute.subjects}>
                                    <SubjectPages />
                                </Route>
                                <Route path={clientRoute.categories}>
                                    <CategoriesOfSubjectsPages />
                                </Route>
                                <Route path={clientRoute.templatesOfExpertise}>
                                    <TemplatesOfExpertisePages />
                                </Route>
                                <Route path={clientRoute.expertiseTasksForCurator} exact>
                                    <ExpertiseTaskListForCuratorPage />
                                </Route>
                                <Route path={clientRoute.expertiseTasks}>
                                    <ExpertiseTasksPages />
                                </Route>
                                <Route path={clientRoute.expertiseList}>
                                    <ExpertisePages />
                                </Route>
                                <Route path={clientRoute.users}>
                                    <UserPages />
                                </Route>
                                <Route path={clientRoute.pfTemplateList}>
                                    <PfTemplateListPage />
                                </Route>
                                <Route>
                                    <Redirect to={clientRoute.notFound} />
                                </Route>
                            </Switch>
                        </Authentication>
                        <Route>
                            <Redirect to={clientRoute.notFound} />
                        </Route>
                    </Switch>
                </AppContentContainer>
                <Footer />
            </Route>
        </Switch>
    );
};
