import React from 'react';
import { ExpansionPanel, Grid, Link, Typography } from '@material-ui/core';
import { SubjectModel } from '../../../models/subject/SubjectModel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { TotObjectPanelLabel, TotObjectPanelValue } from '../../../components/tot-object/TotObjectPanel';
import { getOrElse } from '../../../utils/getOrElse';
import { TotObjectPanelDetails, TotObjectPanelSummary } from '../../../components/tot-object/TotObjectServiceInfo';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks';

export type SubjectServiceInfoProps = {
    subjectModel: SubjectModel;
};

export const SubjectServiceInfo = observer(
    (props: SubjectServiceInfoProps): JSX.Element => {
        const { subjectModel } = props;
        const { services } = useStore();
        return (
            <ExpansionPanel defaultExpanded elevation={0}>
                <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h3">
                        <FormattedMessage id="subject.blocks.serviceInfo" />
                    </Typography>
                </TotObjectPanelSummary>
                <TotObjectPanelDetails>
                    <Grid container direction="column" spacing={6}>
                        <Grid item container direction="row">
                            <Grid item xs={8}>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="subject.fields.author" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>{subjectModel.metaInfo.author.name}</TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="subject.fields.lastModified" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>
                                            {getOrElse(
                                                subjectModel.metaInfo.modified,
                                                <FormattedDate value={subjectModel.metaInfo.modified} />,
                                            )}
                                        </TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row">
                            <Grid item>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="subject.fields.category" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>
                                            {getOrElse(subjectModel.categoryTitle)}
                                        </TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row">
                            <Grid item>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="subject.fields.lifecycle" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>
                                            {getOrElse(
                                                subjectModel.metaInfo.processInfo,
                                                <Link
                                                    target="_blank"
                                                    href={services.lifecycleProcessLink(
                                                        subjectModel.metaInfo.processInfo?.id,
                                                    )}
                                                >
                                                    {subjectModel.metaInfo.processInfo?.title}
                                                </Link>,
                                            )}
                                        </TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row">
                            <Grid item>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="subject.fields.form" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>
                                            {getOrElse(
                                                subjectModel.metaInfo.formInfos,
                                                subjectModel.metaInfo.formInfos.map((item) => {
                                                    return (
                                                        <Link
                                                            key={item}
                                                            target="_blank"
                                                            href={services.formVersionLinks(item)}
                                                        >
                                                            {item}
                                                        </Link>
                                                    );
                                                }),
                                            )}
                                        </TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </TotObjectPanelDetails>
            </ExpansionPanel>
        );
    },
);
