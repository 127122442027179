import React from 'react';
import { Grid } from '@material-ui/core';
import { TotObjectTransitions } from '../../../../components/tot-object/TotObjectTransitions';
import { ExpertiseStore } from '../../../../store/ExpertiseStore';

interface Props {
    expertiseId: string;
    reloadModel: () => void;
    getLifeCycleTransitions: ExpertiseStore['getLifeCycleTransitions'];
    onLifeCycleTransition: (transitionId: string, taskId: string) => Promise<void>;
}

const ControlPanelRight = ({
    expertiseId,
    reloadModel,
    getLifeCycleTransitions,
    onLifeCycleTransition,
}: Props): JSX.Element => (
    <Grid item container spacing={3} wrap="nowrap">
        <TotObjectTransitions
            objectId={expertiseId}
            updateObjectPage={reloadModel}
            getTransitions={getLifeCycleTransitions}
            lifeCycleTransition={onLifeCycleTransition}
        />
    </Grid>
);

export default ControlPanelRight;
