import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { ExpertiseTaskModel } from '../../../../models/expertise-task/ExpertiseTaskModel';
import { TaskViewHeaderPropertiesContainer } from './ExpertiseTaskViewPage.styled';
import { ExpertiseTaskHeaderDescription } from '../ExpertiseTaskHeaderDescription/ExpertiseTaskHeaderDescription';
import styled from 'styled-components';

export type ExpertiseTaskViewHeaderDescriptionProps = {
    expertiseTaskModel: ExpertiseTaskModel;
    collapsed: boolean;
    toggleIsOpen: () => void;
    toggleIsOpenStartDateDialog: () => void;
    toggleIsOpenDueDateDialog: () => void;
};

export const ExpertiseTaskViewHeaderDescriptionGridStyled = styled(({ collapsed, ...props }) => <Grid {...props} />)`
    overflow: hidden;
    transition: max-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    max-height: ${({ collapsed }): string => (collapsed ? '0vh' : '50vh')};
`;

export const ExpertiseTaskViewHeaderDescription = (props: ExpertiseTaskViewHeaderDescriptionProps): JSX.Element => {
    const {
        expertiseTaskModel,
        collapsed,
        toggleIsOpen,
        toggleIsOpenStartDateDialog,
        toggleIsOpenDueDateDialog,
    } = props;
    return (
        <ExpertiseTaskViewHeaderDescriptionGridStyled item collapsed={collapsed}>
            <TaskViewHeaderPropertiesContainer>
                <Container maxWidth="lg">
                    <ExpertiseTaskHeaderDescription
                        toggleIsOpen={toggleIsOpen}
                        toggleIsOpenStartDateDialog={toggleIsOpenStartDateDialog}
                        toggleIsOpenDueDateDialog={toggleIsOpenDueDateDialog}
                        expertiseTaskModel={expertiseTaskModel}
                    />
                </Container>
            </TaskViewHeaderPropertiesContainer>
        </ExpertiseTaskViewHeaderDescriptionGridStyled>
    );
};
