import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import TemplatesOfExpertiseModel from '../../../models/templates-of-expertise/TemplatesOfExpertiseModel';

interface Props {
    title: TemplatesOfExpertiseModel['title'];
}

const PageHeaderTitle = ({ title }: Props): JSX.Element => (
    <Grid item>
        <Typography variant="h1">{title}</Typography>
    </Grid>
);

export default PageHeaderTitle;
