import React from 'react';
import { AxiosError } from 'axios';
import { Transition } from '../../models/tot-object/TotObjectTransitions';
import { LifeCycleTransitionConfirmationDialog } from './LifeCycleTransitionConfirmationDialog';
import { ExpertiseTaskInviteSentToAcceptDialog } from './invite/ExpertiseTaskInviteSentToAcceptDialog';
import { ExpertiseTaskInviteToRejectDialog } from './invite/ExpertiseTaskInviteToRejectDialog';
import { RejectReasonDialog } from './RejectReasonDialog';

export type TransitionDialogProps = {
    objectId: string;
    isModalOpen: boolean;
    setModalIsClosed: () => void;
    transition: Transition;
    lifeCycleTransition: () => Promise<void>;
    handleSuccessTransition: () => void;
    handleErrorTransition: (error: AxiosError) => void;
};

export const TransitionDialog = (props: TransitionDialogProps): JSX.Element => {
    const { dialogComponentCode } = props.transition.params;
    switch (dialogComponentCode) {
        //<editor-fold desk="ExpertiseTaskInvite">
        case 'ExpertiseTaskInviteSentToAccept':
            return <ExpertiseTaskInviteSentToAcceptDialog {...props} />;
        case 'ExpertiseTaskInviteToReject':
            return <ExpertiseTaskInviteToRejectDialog {...props} />;
        case 'TaskCheckToTaskRework':
            return <RejectReasonDialog {...props} />;
        //</editor-fold>
        default:
            return <LifeCycleTransitionConfirmationDialog {...props} />;
    }
};
