import { action, IObservableArray, observable } from 'mobx';
import downloadFile from 'js-file-download';
import { RootStore } from './RootStore';
import { Api } from './Api';
import FileDTO from '../models/FileDTO';
import apiConfigs from '../apiConfigs';
import PfTemplateModel from '../models/PfTemplateModel';
import { handleAxiosErrorByResponseStatus } from '../utils/errorHandleUtils';
import clientRoute from '../clientRoute';

export interface PfTemplateDTO {
    id: string;
    title: string;
    created: string; // date
    fileDTO: FileDTO;
}

export class PfTemplateStore {
    @observable rootStore: RootStore;
    @observable protected api: Api;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @action.bound
    create(model: PfTemplateModel): Promise<string> {
        return this.api.client(apiConfigs.createPfTemplate(model.formData)).then((r) => r.data);
    }

    @action.bound
    templateList(): PfTemplateDTO[] {
        const list: IObservableArray<PfTemplateDTO> = observable.array();
        this.api
            .client(apiConfigs.pfTemplates)
            .then((res) => res.data)
            .then(({ rows, count }) => list.replace(rows))
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.rootStore.history.replace(clientRoute.notAllowed),
                }),
            );
        return list;
    }

    @action.bound
    downloadTemplateFile(fileDTO: FileDTO): void {
        this.api
            .client(apiConfigs.downloadPfTemplateFile(fileDTO.id))
            .then((r) => r.data)
            .then((f) => downloadFile(f, fileDTO.filename, fileDTO.mimeType));
    }

    @action.bound
    update(model: PfTemplateModel, templateId?: string): Promise<string> {
        return this.api.client(apiConfigs.updatePfTemplate(model.formData, templateId)).then((r) => r.data);
    }
}
