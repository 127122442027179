import PersonModel, { PersonDTO } from './PersonModel';
import { action, computed, observable } from 'mobx';
import { IdTitle } from '../IdTitle';

export interface UserPersonDTO {
    userId: string;
    person: PersonDTO;
}

export class UserNameModel {
    @observable userId = '';
    @observable name = '';
    @observable fullName = '';

    constructor() {
        return this;
    }

    @action.bound
    load(dto: UserPersonDTO) {
        this.userId = dto.userId;
        const person: PersonModel = new PersonModel().load(dto.person);
        this.name = person.lastNameWithInitials;
        this.fullName = `${person.lastName} ${person.firstName}${person.middleName ? ` ${person.middleName}` : ''}`;
        return this;
    }

    @action.bound
    clear() {
        this.userId = '';
        this.name = '';
        this.fullName = '';
        return this;
    }

    @computed
    get asIdTitle(): IdTitle {
        return {
            id: this.userId,
            title: this.name,
        };
    }
}
