import React, { ReactNode } from 'react';
import {
    Button,
    Grid,
    Link,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { MenuButton } from '../../../components/buttons/MenuButton';
import { generatePath, NavLink, useRouteMatch } from 'react-router-dom';
import { AuthorizationCheck } from '../../../components/AuthorizationCheck';
import { DeleteActionMenuItem } from '../../../components/DeleteActionMenuItem';
import {
    ExpertiseTasksListByExpertiseModel,
    ExpertiseTasksRow,
} from '../../../models/expertise-task/ExpertiseTasksListByExpertiseModel';
import clientRoute from '../../../clientRoute';
import { entities, permissions } from '../../../authSchemeConfig';
import CreateTaskDialog from '../../task/CreateTaskDialog';
import { ErrorDialog } from '../../../components/ErrorDialog';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { getTaskRoute as getTaskRouteInj } from '../../task/ExpertiseTaskListPage';
import { useStore } from '../../../hooks/useStore';
import { useError } from '../../../hooks/useError';
import { useLoading } from '../../../hooks/useLoading';
import { useModal } from '../../../hooks/useModal';
import { di } from 'react-magnetic-di';
import { ActionsButton } from '../../../components/buttons/ActionsButton';

export type ExpertiseTasksTableProps = {
    model: ExpertiseTasksListByExpertiseModel;
};

export const ExpertiseTasksTable = observer(
    (props: ExpertiseTasksTableProps): JSX.Element => {
        const { model } = props;

        const [getTaskRoute] = di([getTaskRouteInj], ExpertiseTasksTable);

        const { expertiseTaskStore } = useStore();
        const { isError, errorText, setErrorIsClosed, setErrorIsOpen } = useError();
        const { isLoading, enableLoading, disableLoading } = useLoading();
        const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();
        const match = useRouteMatch();

        const createExpertiseTask = (planEntryId: string): void => {
            enableLoading();
            expertiseTaskStore
                .createExpertiseTask(planEntryId)
                .then(() => {
                    model.loadExpertiseTasksList();
                })
                .catch((error) => {
                    const errorText = ErrorMessage(error);
                    setErrorIsOpen(errorText);
                })
                .finally(() => {
                    setModalIsClosed();
                    disableLoading();
                });
        };

        const renderActionItems = (task: ExpertiseTasksRow): (() => ReactNode[]) => {
            const backUrl = match.url;
            const taskId = task.id;
            return (): ReactNode[] => [
                <AuthorizationCheck
                    entityCode={entities.ExpertiseTask}
                    entityId={taskId}
                    permCode={permissions.ExpertiseTask.Edit}
                    key="edit"
                >
                    <MenuItem
                        dense
                        tabIndex={0}
                        button={true}
                        component={NavLink}
                        to={`${generatePath(clientRoute.expertiseTaskEdit, { id: taskId })}?backUrl=${backUrl}`}
                    >
                        <FormattedMessage id="common.edit" />
                    </MenuItem>
                </AuthorizationCheck>,
                <AuthorizationCheck
                    entityCode={entities.ExpertiseTask}
                    entityId={taskId}
                    permCode={permissions.ExpertiseTask.Delete}
                    key="delete"
                >
                    <DeleteActionMenuItem
                        id="delete"
                        wrappedComponentProps={{ tabIndex: 1 }}
                        title={<FormattedMessage id="common.confirmDeletion" />}
                        message={<FormattedMessage id="expertiseTask.listConfirmDeletionInfoText" />}
                        onConfirm={model.deleteTask(taskId)}
                    />
                </AuthorizationCheck>,
            ];
        };

        const renderTableBody = (tasks: ExpertiseTasksRow[]) => {
            return tasks.map((task: ExpertiseTasksRow) => {
                const taskRoute = getTaskRoute(task);
                return (
                    <TableRow key={task.id} hover>
                        <TableCell>
                            <Link component={NavLink} underline="none" to={taskRoute}>
                                {task.identifier}
                            </Link>
                        </TableCell>
                        <TableCell>
                            {task.planEntry.title}{' '}
                            {task.viewPoint && (
                                <Typography>
                                    <FormattedMessage id="templatesOfExpertise.plan.fields.viewPoint" />
                                    <span>: {task.viewPoint}</span>
                                </Typography>
                            )}
                        </TableCell>
                        <TableCell>{getExpertCell(task)}</TableCell>
                        <TableCell>
                            {(task.acceptance && <FormattedDate value={task.acceptance} />) || (
                                <Typography>
                                    {task.acceptanceDays}
                                    <FormattedMessage id="expertiseTask.expertiseTasksTable.daysPlan" />
                                </Typography>
                            )}
                        </TableCell>
                        <TableCell>
                            {(task.deadline && <FormattedDate value={task.deadline} />) || (
                                <Typography>
                                    {task.planProcessingDays}
                                    <FormattedMessage id="expertiseTask.expertiseTasksTable.daysPlan" />
                                </Typography>
                            )}
                        </TableCell>
                        <TableCell>{task.completed && <FormattedDate value={task.completed} />}</TableCell>
                        <TableCell>{task.state}</TableCell>
                        <TableCell>{renderActions(task)}</TableCell>
                    </TableRow>
                );
            });
        };

        const renderActions = (task: ExpertiseTasksRow): JSX.Element => {
            return (
                <MenuButton
                    disablePortal={true}
                    renderButton={ActionsButton}
                    renderMenuItems={renderActionItems(task)}
                />
            );
        };

        const getExpertCell = (row: ExpertiseTasksRow): React.ReactNode => {
            return row.expertName ? (
                row.expertName
            ) : row.expertCandidateName ? (
                <FormattedMessage
                    id="expertiseTask.expertCandidate"
                    values={{
                        expert: row.expertCandidateName,
                    }}
                />
            ) : (
                ''
            );
        };

        return (
            <React.Fragment>
                {isModalOpen && (
                    <CreateTaskDialog
                        onCancel={setModalIsClosed}
                        onSubmit={createExpertiseTask}
                        isLoading={isLoading}
                    />
                )}
                <ErrorDialog message={errorText} open={isError} onClose={setErrorIsClosed} />
                <Grid container direction="column" spacing={5}>
                    <Grid item container direction="row" justify="space-between">
                        <Grid item>
                            <Typography variant="h3">
                                <FormattedMessage id="expertiseTask.listTitle" />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <AuthorizationCheck
                                entityCode={entities.Expertise}
                                permCode={permissions.Expertise.CreateTask}
                                entityId={model.id}
                            >
                                <Button color="primary" variant="contained" onClick={setModalIsOpen}>
                                    <FormattedMessage id="expertiseTask.create" />
                                </Button>
                            </AuthorizationCheck>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.number" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.planEntry" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.expert" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.responseTime" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.deadline" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.actualDateOfTheExecution" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.stateTitle" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderTableBody(model.expertiseTasks)}</TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    },
);
