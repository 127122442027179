import { TableQueryData } from './../models/list/TableModel';
import { action, computed, observable } from 'mobx';
import { RootStore } from './RootStore';
import { Api } from './Api';
import { IdTitle } from '../models/IdTitle';
import apiConfigs from '../apiConfigs';
import { handleAxiosErrorByResponseStatus } from '../utils/errorHandleUtils';
import clientRoute from '../clientRoute';
import { History } from 'history';
import { SubjectModel, SubjectDTO } from '../models/subject/SubjectModel';
import { TransitionsDTO } from '../models/tot-object/TotObjectTransitions';
import { FullSubmission } from '@platform/formiojs-react';
import { AxiosPromise } from 'axios';
import { di } from 'react-magnetic-di';

export interface NewSubjectDTO {
    categoryId: string;
    campaignId?: string;
    identifier: string;
}

export class SubjectStore {
    @observable private rootStore: RootStore;
    @observable protected api: Api;
    @observable showCommonValidation: boolean = false;
    @observable showHiddenValidation: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;

        this.getSubjectModel = this.getSubjectModel.bind(this);
        this.loadSubject = this.loadSubject.bind(this);
        this.lifeCycleTransition = this.lifeCycleTransition.bind(this);
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    exportListXls(queryData: TableQueryData): AxiosPromise<Blob> {
        return this.api.client(apiConfigs.subjectListXls(queryData));
    }

    @action.bound
    downloadSubjectFiles(subjectId: string): AxiosPromise<Blob> {
        return this.api.client(apiConfigs.downloadSubjectFiles(subjectId));
    }

    getSubjectModel(id: string): SubjectModel {
        const model = new SubjectModel(id);
        this.loadSubject(id).then(model.load);
        return observable(model);
    }

    loadSubject(id: string): Promise<SubjectDTO> {
        return this.api
            .client(apiConfigs.subject(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    loadSubjectsSelectList(): Promise<IdTitle[]> {
        return this.api.client(apiConfigs.subjectsSelectList).then((r) => r.data);
    }

    @action.bound
    getSubjectNumber(campaignId?: string): Promise<string> {
        return this.api.client(apiConfigs.subjectNumber(campaignId)).then((r) => r.data);
    }

    @action.bound
    saveForms(subjectId: string, commonSubmission: FullSubmission, hiddenSubmission?: FullSubmission): Promise<void> {
        return this.api
            .client(apiConfigs.saveSubjectForms(subjectId, commonSubmission, hiddenSubmission))
            .then((r) => r.data);
    }

    lifeCycleTransition(transitionId: string, subjectId: string, updateReloadKey: () => void): Promise<void> {
        return this.api.client(apiConfigs.subjectLifeCycleTransition(transitionId, subjectId)).then((r) => {
            updateReloadKey();
            return r.data;
        });
    }

    @action.bound
    getLifeCycleTransitions(subjectId: string): Promise<TransitionsDTO> {
        return this.api.client(apiConfigs.getSubjectLifeCycleTransitions(subjectId)).then((r) => r.data);
    }

    @action.bound
    loadCampaignSelectOptions(): Promise<IdTitle[]> {
        return this.api
            .client(apiConfigs.campaignSelectOptions)
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    loadCategorySelectOptions(): Promise<IdTitle[]> {
        return this.api
            .client(apiConfigs.categorySelectOptions)
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    createSubject(dto: NewSubjectDTO): Promise<string> {
        return this.api
            .client(apiConfigs.createSubject(dto))
            .then((r) => r.data.id)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    deleteSubject(id: string): Promise<void> {
        return this.api
            .client(apiConfigs.deleteSubject(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }
}

export const getSubjectStore = (): any => {
    const [_SubjectStore] = di([SubjectStore], getSubjectStore);
    return _SubjectStore;
};
