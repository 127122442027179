import React from 'react';
import { Box, Typography, Grid, withTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { TotObjectPanelLabel, TotObjectPanelValue } from '../../components/tot-object/TotObjectPanel';
import styled from 'styled-components';
import { getOrElse } from '../../utils/getOrElse';

const StyledBox = withTheme(
    styled(Box)`
        background: ${({ theme }): string => theme.variables.palette.accentBackgroundGradient};
        color: ${({ theme }) => theme.variables.palette.accentContrastBackgroundGradient};
        min-width: 75px;
        box-sizing: border-box;
        text-align: center;
    `,
);

type OuterProps = {
    index: number;
    taskTypeTitle: string;
    taskMin: number;
    taskMax: number;
    acceptanceDays: number;
    processingDays: number;
};

class PlanEntryExpertise extends React.Component<OuterProps, {}> {
    render(): JSX.Element {
        const { index, taskTypeTitle, taskMin, taskMax, acceptanceDays, processingDays } = this.props;
        return (
            <React.Fragment>
                <Box pt={12}>
                    <Grid container alignItems="baseline">
                        <Grid item>
                            <StyledBox pt={2.5} pb={2.5}>
                                <Typography variant="body2" className="t-new-expertise-title">
                                    <Box component="span" fontWeight="fontWeightBold">
                                        {index}
                                    </Box>
                                </Typography>
                            </StyledBox>
                        </Grid>
                        <Grid item>
                            <Box pl={12}>
                                <Typography variant="body2" className="t-new-expertise-title">
                                    <Box component="span" fontWeight="fontWeightBold">
                                        {taskTypeTitle || '-'}
                                    </Box>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box pt={4} pl={30.75}>
                    <Grid container alignItems="baseline">
                        <Grid item>
                            <Box pr={12}>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="expertise.plan.fields.numberOfConclusions" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>
                                            {getOrElse(
                                                taskMin !== undefined &&
                                                    taskMax !== undefined &&
                                                    taskMin !== null &&
                                                    taskMax !== null,
                                                <FormattedMessage
                                                    id="expertise.plan.fields.numberOfConclusionsText"
                                                    values={{ min: taskMin, max: taskMax }}
                                                />,
                                                '-',
                                            )}
                                        </TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box pr={12}>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="expertise.plan.fields.responseTimeForAJob" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>
                                            {getOrElse(
                                                acceptanceDays !== undefined && acceptanceDays !== null,
                                                <FormattedMessage
                                                    id="expertise.plan.fields.responseTimeForAJobText"
                                                    values={{ count: acceptanceDays }}
                                                />,
                                                '-',
                                            )}
                                        </TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box pr={12}>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="expertise.plan.fields.deadline" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>
                                            {getOrElse(
                                                processingDays !== undefined && processingDays !== null,
                                                <FormattedMessage
                                                    id="expertise.plan.fields.deadlineText"
                                                    values={{ count: processingDays }}
                                                />,
                                                '-',
                                            )}
                                        </TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </React.Fragment>
        );
    }
}

export default PlanEntryExpertise;
