import React, { useCallback } from 'react';
import { TransitionsDTO } from '../../../models/tot-object/TotObjectTransitions';
import { observer } from 'mobx-react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import { BottomControlPanel } from '../../../components/BottomControlPanel';
import { ControlPanelRight } from './ControlPanelRight';
import { ControlPanelLeft } from './ControlPanelLeft';
import { useModal } from '../../../hooks/useModal';
import { useStore } from '../../../hooks/useStore';
import { useReload } from '../../../hooks/useReload';

export type CampaignControlPanelRouteParams = {
    id: string;
};

export type CampaignControlPanelProps = {
    campaignTitle: string;
    campaignIdentifier: string;
    deleteCampaign: () => Promise<void>;
    onSubmit: () => void;
    getCampaignTransitions: (campaignId: string) => Promise<TransitionsDTO>;
    lifeCycleTransition(transitionId: string, campaignId: string): Promise<void>;
};

export const CampaignControlPanel = observer(
    (props: CampaignControlPanelProps): JSX.Element => {
        const { campaignTitle, campaignIdentifier, deleteCampaign, lifeCycleTransition, onSubmit } = props;

        const { setModalIsOpen, isModalOpen, setModalIsClosed } = useModal();
        const { id } = useParams<CampaignControlPanelRouteParams>();
        const { reloadKey, reloadIncrement } = useReload();
        const { campaignStore } = useStore();
        const history = useHistory();

        const goToReadPage = useCallback((): void => {
            history.push(generatePath(clientRoute.campaign, { id }));
        }, [history, generatePath]);

        return (
            <BottomControlPanel
                left={<ControlPanelLeft id={id} deleteCampaign={deleteCampaign} title={campaignTitle} />}
                right={
                    <ControlPanelRight
                        id={id}
                        reloadKey={reloadKey}
                        setModalIsOpen={setModalIsOpen}
                        isModalOpen={isModalOpen}
                        campaignTitle={campaignTitle}
                        campaignIdentifier={campaignIdentifier}
                        setModalIsClosed={setModalIsClosed}
                        goToReadPage={goToReadPage}
                        campaignStore={campaignStore}
                        lifeCycleTransition={lifeCycleTransition}
                        onSubmit={onSubmit}
                    />
                }
            />
        );
    },
);
