class ClientRoute {
    root = '/';
    notFound = '/404';
    notAllowed = '/403';

    campaigns = '/campaigns';
    campaign = '/campaigns/:id';
    campaignEdit = '/campaigns/:id/edit';
    campaignCreate = '/campaigns/:id/create';

    categories = '/categories';
    category = '/categories/:id';
    categoryEdit = '/categories/:id/edit';
    categoryCreate = '/categories/:id/create';

    subjects = '/subjects';
    subject = '/subjects/:id';
    subjectCreate = '/subjects/:id/create';
    subjectEdit = '/subjects/:id/edit';

    templatesOfExpertise = '/templates';
    templateOfExpertise = '/templates/:id';
    templateOfExpertiseEdit = '/templates/:id/edit';
    templateOfExpertiseCreate = '/templates/:id/create';

    templateOfExpertisePlanEntryCreate = '/templates/:id/plan-entry/create';
    templateOfExpertisePlanEntryEdit = '/templates/:id/plan-entry/:entryId/edit';

    expertiseTasksForCurator = '/tasks-for-curator';
    expertiseTasks = '/tasks';
    expertiseTask = '/tasks/:id';
    expertiseTaskSubject = '/tasks/:id/subject';
    expertiseTaskFiles = '/tasks/:id/files';
    expertiseTaskEdit = '/tasks/:id/edit';

    expertiseList = '/expertise';
    expertise = '/expertise/:id';

    expertisePlanEntryCreate = '/expertise/:id/plan-entry/create';
    expertisePlanEntryEdit = '/expertise/:id/plan-entry/:entryId/edit';

    users = '/users';
    user = '/users/:id';
    userEdit = '/users/:id/edit';

    pfTemplateList = '/pf-templates';
    pfTemplateCreate = '/pf-templates/create';
    pfTemplateEdit = '/pf-templates/:id/edit';

    login = '/login';
    recoveryPassword = '/recovery';
    newPassword = '/recovery/:token';
    registration = '/registration';
    authenticationInfo = '/info';
    registrationInfoSuccess = '/info/registration/success';
    registrationInfoExpired = '/info/registration/expired';
    registrationInfoNotFound = '/info/registration/404';
    recoveryPasswordInfoSuccess = '/info/recovery-password/success';
    registrationConfirm = '/registration/confirm/:token';

    esia = '/esia';
    esiaGetAuthUrl = '/esia/auth-url';
    esiaLogin = '/esia/login';

    dataUsePolicy = '/consents/data-use-policy';
}

export default new ClientRoute();
