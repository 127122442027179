import React from 'react';
import { Box, createStyles, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { useStore } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { FooterButton } from '../../components/Footer';
import { InstructionsButton } from '../../components/app-bar/InstructionsButton';
import { LanguageButton } from '../../components/app-bar/LanguageButton';
import { useFeature } from 'feature-toggle-jsx';
import { LogoConfig, LogoImg } from '../../components/app-bar/LogoImg';

const useStyles = makeStyles(() =>
    createStyles({
        mainContainer: {
            height: '100%',
        },
        email: {
            paddingLeft: '1px',
            paddingRight: '1px',
        },
    }),
);

const LeftSide = observer(
    (): JSX.Element => {
        const { env } = useStore();
        const classes = useStyles();
        const { totMail } = env;
        const [logo, logoConfig] = useFeature('logo');
        const [instructions] = useFeature('instructions');

        return (
            <Grid container direction="column" justify="space-between" wrap="nowrap" className={classes.mainContainer}>
                <Grid item>
                    <Link component={RouterLink} to={clientRoute.root} underline="none">
                        <Grid container direction="row" alignItems="center">
                            <Grid container direction="row" alignItems="center">
                                <LogoImg logoConfig={logoConfig as LogoConfig} />
                            </Grid>
                        </Grid>
                    </Link>
                </Grid>
                <Grid item>
                    <Typography variant="h1" component="p" gutterBottom>
                        <Box fontWeight={700} component="span">
                            <FormattedMessage id="startPage.productName" />
                        </Box>
                    </Typography>
                    <Typography variant="h1" component="p">
                        <Box fontSize="16px" fontWeight={500} component="span">
                            <FormattedMessage id="startPage.productDescription" />
                        </Box>
                    </Typography>
                </Grid>
                <Grid container item alignItems="center">
                    <Grid item xs={6}>
                        <FooterButton
                            component="a"
                            className={classes.email}
                            href={`mailto:${totMail}`}
                            variant="text"
                            color="secondary"
                        >
                            {totMail}
                        </FooterButton>
                    </Grid>
                    <Grid container item direction="row" justify="flex-end" spacing={3} xs={6}>
                        {instructions && (
                            <Grid item>
                                <InstructionsButton />
                            </Grid>
                        )}
                        <Grid item>
                            <LanguageButton />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    },
);

export default LeftSide;
