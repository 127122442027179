import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Button, Checkbox, Dialog, FormControlLabel, Portal, Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { TransitionDialogProps } from '../TransitionDialog';

export const ExpertiseTaskInviteSentToAcceptDialog = observer(
    (props: TransitionDialogProps): JSX.Element => {
        const {
            setModalIsClosed,
            lifeCycleTransition,
            handleErrorTransition,
            handleSuccessTransition,
            isModalOpen,
        } = props;
        const [agree, setAgree] = useState<boolean>(false);

        const handleCancel = (): void => {
            setAgree(false);
            setModalIsClosed();
        };

        const handleConfirm = async (): Promise<void> => {
            lifeCycleTransition().then(handleSuccessTransition).catch(handleErrorTransition).finally(setModalIsClosed);
        };

        return (
            <Portal>
                <Dialog fullWidth={true} maxWidth="sm" aria-labelledby="confirmation-dialog-title" open={isModalOpen}>
                    <DialogTitle id="confirmation-dialog-title">
                        <FormattedMessage id="common.lifeCycleConfirmTitle" />
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                            <FormattedMessage id="expertiseTask.invites.agreement" />
                        </Typography>
                        <FormControlLabel
                            value="top"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={agree}
                                    onChange={(event, checked): void => setAgree(checked)}
                                />
                            }
                            label={<FormattedMessage id="expertiseTask.invites.agree" />}
                            labelPlacement="end"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} color="primary">
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button onClick={handleConfirm} disabled={!agree} color="secondary" variant="contained">
                            <FormattedMessage id={'expertiseTask.invites.accept'} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Portal>
        );
    },
);
