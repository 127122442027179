import React from 'react';
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { AccessTime, Block, CheckCircleOutline } from '@material-ui/icons';
import { TotBackground } from '../../TotBackground';
import { Route, Switch } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { FormattedMessage } from 'react-intl';

class AuthenticationInfoPage extends React.Component<{}, {}> {
    render(): JSX.Element {
        return (
            <TotBackground withBackdrop={true}>
                <Grid container item direction="column" alignItems="center" justify="center">
                    <Container className="t-authentication-info-page" maxWidth="xs">
                        <Paper elevation={24}>
                            <Box p={12} textAlign="center">
                                <Switch>
                                    <Route path={clientRoute.registrationInfoSuccess}>
                                        <CheckCircleOutline fontSize="large" color="action" />
                                        <Typography>
                                            <FormattedMessage id="authentication.confirmingEmailSent" />
                                        </Typography>
                                    </Route>
                                    <Route path={clientRoute.registrationInfoExpired}>
                                        <AccessTime fontSize="large" color="action" />
                                        <Typography>
                                            <FormattedMessage id="authentication.confirmingEmailExpired" />
                                        </Typography>
                                    </Route>
                                    <Route path={clientRoute.registrationInfoNotFound}>
                                        <Block fontSize="large" color="action" />
                                        <Typography>
                                            <FormattedMessage id="authentication.confirmingEmailNotFound" />
                                        </Typography>
                                    </Route>
                                    <Route path={clientRoute.recoveryPasswordInfoSuccess}>
                                        <CheckCircleOutline fontSize="large" color="action" />
                                        <Typography>
                                            <FormattedMessage id="authentication.recoveryLinkSent" />
                                        </Typography>
                                    </Route>
                                </Switch>
                            </Box>
                        </Paper>
                    </Container>
                </Grid>
            </TotBackground>
        );
    }
}

export default AuthenticationInfoPage;
