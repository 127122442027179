import React from 'react';
import {
    Box,
    Button,
    Checkbox,
    createStyles,
    FormControlLabel,
    Grid,
    Link,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, Redirect, useLocation } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { observer } from 'mobx-react';
import { EmailField } from '../../components/fields/EmailField';
import { PasswordField } from '../../components/fields/PasswordField';
import { useStore } from '../../hooks/useStore';

const queryString = require('query-string');

const useStyles = makeStyles(() =>
    createStyles({
        mainContainer: {
            height: '100%',
        },
    }),
);

const RightSide = observer(
    (): JSX.Element => {
        const classes = useStyles();
        const rootStore = useStore();
        const { loginModel } = rootStore.authenticationStore;
        const { personStore } = rootStore;
        const { authStatus } = rootStore.api;
        const location = useLocation();
        const queries = queryString.parse(location.search);
        const backUrl = queries.backUrl;

        return (
            <Grid container justify="center" alignItems="center" className={classes.mainContainer}>
                <Grid item xs={8}>
                    <Grid container justify="center">
                        <Grid item>
                            <Typography variant="h5" className="t-login-title">
                                <Box fontWeight="fontWeightBold">
                                    <FormattedMessage id="authentication.loginTitle" />
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box pt={8}>
                        <form noValidate onSubmit={loginModel.login}>
                            <Grid item container spacing={6} direction="column">
                                {loginModel.loginFailedMessage && (
                                    <Grid item>
                                        <Typography className="t-login-failed-message" color="error">
                                            {loginModel.loginFailedMessage}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item>
                                    <EmailField model={loginModel} />
                                </Grid>
                                <Grid item>
                                    <PasswordField model={loginModel} newPassword={false} />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        value="top"
                                        control={
                                            <Checkbox
                                                className="t-remember-me"
                                                color="primary"
                                                onChange={(event, checked): boolean =>
                                                    (loginModel.rememberMe = checked)
                                                }
                                            />
                                        }
                                        label={<FormattedMessage id="authentication.rememberMe" />}
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        className="t-login"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        <FormattedMessage id="authentication.login" />
                                    </Button>
                                </Grid>
                                <Grid item container justify="space-between">
                                    <Grid item>
                                        <Link
                                            className="t-forgot-password"
                                            component={RouterLink}
                                            to={clientRoute.recoveryPassword}
                                            underline="none"
                                        >
                                            <FormattedMessage id="authentication.forgotPassword" />
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link
                                            className="t-registration"
                                            component={RouterLink}
                                            to={clientRoute.registration}
                                            underline="none"
                                        >
                                            <FormattedMessage id="authentication.register" />
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                    {authStatus === 'ok' && <Redirect push to={backUrl || personStore.redirectPath} />}
                </Grid>
            </Grid>
        );
    },
);

export default RightSide;
