import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { generatePath, Route, Switch, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { FormApi, FormView, MultiLangFormEdit } from '@platform/formiojs-react';
import { UserPersonModel } from '../../models/users/UserPersonModel';
import { TotObjectMain } from '../../components/tot-object/TotObjectMain';
import clientRoute from '../../clientRoute';
import { UserHeader } from './UserHeader/UserHeader';
import UserControlPanel from './UserControlPanel/UserControlPanel';
import { useStore } from '../../hooks/useStore';
import { AuthorizationCheck } from '../../components/AuthorizationCheck';
import { entities, permissions } from '../../authSchemeConfig';

export type UserPageRouteParams = {
    id: string;
};

export const UserPage = observer(
    (): JSX.Element => {
        const { userStore, personStore, intlStore, history } = useStore();
        const { id } = useParams<UserPageRouteParams>();
        const [userModel] = useState<UserPersonModel>(userStore.getUserPersonModel(id));
        const [formApi, setFormApi] = useState<FormApi>();

        const onFormReady = (form: FormApi): void => {
            setFormApi(form);
        };

        const savePersonForm = async (): Promise<void> => {
            if (formApi && formApi.validate()) {
                await userStore.saveUser(userModel.id, formApi.getSubmission());
                history.push(generatePath(clientRoute.user, { id }));
            }
        };

        const loginAsUser = (): void => {
            userStore.loginAsUser(userModel.id).then(() => {
                personStore.getInfo().then(() => {
                    history.push(generatePath(personStore.redirectPath));
                });
            });
        };

        const onNotAllowed = () => {
            history.push(clientRoute.notAllowed);
        };

        const { personForm } = userModel;
        const { locale } = intlStore;
        return (
            <Grid container direction="column" wrap="nowrap">
                <Grid item>
                    <UserHeader userPersonModel={userModel} loginAsUser={loginAsUser} />
                </Grid>
                <TotObjectMain>
                    <Grid container spacing={10}>
                        <Grid item container direction="column" spacing={10}>
                            <Grid item style={{ maxWidth: '100%' }}>
                                <Switch>
                                    <Route exact path={clientRoute.userEdit}>
                                        <AuthorizationCheck
                                            entityCode={entities.User}
                                            entityId={userModel.id}
                                            permCode={permissions.User.Update}
                                            onNotAllowed={onNotAllowed}
                                        >
                                            <MultiLangFormEdit
                                                intlStore={intlStore}
                                                form={personForm}
                                                onFormReady={onFormReady}
                                            />
                                        </AuthorizationCheck>
                                    </Route>

                                    <Route exact path={clientRoute.user}>
                                        <AuthorizationCheck
                                            entityCode={entities.User}
                                            entityId={userModel.id}
                                            permCode={permissions.User.View}
                                            onNotAllowed={onNotAllowed}
                                        >
                                            <FormView
                                                locale={locale}
                                                form={personForm}
                                                onFormReady={onFormReady}
                                                multiLang={true}
                                            />
                                        </AuthorizationCheck>
                                    </Route>
                                </Switch>
                            </Grid>
                        </Grid>
                    </Grid>
                </TotObjectMain>
                <Grid item>
                    <UserControlPanel userPersonModel={userModel} saveForm={savePersonForm} />
                </Grid>
            </Grid>
        );
    },
);
