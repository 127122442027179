import React from 'react';
import { Route, Switch } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { CampaignPage } from './CampaignPage';
import { CampaignListPage } from './CampaignListPage';

export const CampaignPages = (): JSX.Element => {
    return (
        <Switch>
            <Route path={[clientRoute.campaign, clientRoute.campaignEdit, clientRoute.campaignCreate]}>
                <CampaignPage />
            </Route>
            <Route>
                <CampaignListPage />
            </Route>
        </Switch>
    );
};
