import React from 'react';
import { observer } from 'mobx-react';
import { BottomControlPanel } from '../../../components/BottomControlPanel';
import { CategoryOfSubjectsControlPanelLeft } from './CategoryOfSubjectsControlPanelLeft';
import { CategoryOfSubjectsControlPanelRight } from './CategoryOfSubjectsControlPanelRight';

export type CategoryOfSubjectsControlPanelProps = {
    categoryOfSubjectsTitle: string;
    categoryOfSubjectsId: string;
    deleteCategoryOfSubjects: () => Promise<void>;
    onSubmit: () => void;
};

export const CategoryOfSubjectsControlPanel = observer(
    (props: CategoryOfSubjectsControlPanelProps): JSX.Element => {
        const { categoryOfSubjectsId, categoryOfSubjectsTitle, deleteCategoryOfSubjects, onSubmit } = props;
        return (
            <BottomControlPanel
                left={
                    <CategoryOfSubjectsControlPanelLeft
                        categoryOfSubjectsTitle={categoryOfSubjectsTitle}
                        deleteCategoryOfSubjects={deleteCategoryOfSubjects}
                        categoryOfSubjectsId={categoryOfSubjectsId}
                    />
                }
                right={
                    <CategoryOfSubjectsControlPanelRight
                        categoryOfSubjectsId={categoryOfSubjectsId}
                        onSubmit={onSubmit}
                    />
                }
            />
        );
    },
);
