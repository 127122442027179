import { BlockUnblockProps } from '../BlockUnblockProps';
import { useModal } from '../../../../hooks/useModal';
import React from 'react';
import { UserBlockDialog } from './UserBlockDialog';
import { UserBlockUnblockButton } from './UserBlockUnblockButton';

export type UserBlockButtonWithReasonProps = BlockUnblockProps & {
    message: JSX.Element;
    onUserBlock: (message?: string) => Promise<void>;
};

export const UserBlockButtonWithReason = (props: UserBlockButtonWithReasonProps): JSX.Element => {
    const { onUserBlock, message, allowed, isBlocked } = props;
    const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();

    return (
        <React.Fragment>
            {isModalOpen && <UserBlockDialog message={message} onClose={setModalIsClosed} onConfirm={onUserBlock} />}
            <UserBlockUnblockButton allowed={allowed} isBlocked={isBlocked} setModalIsOpen={setModalIsOpen} />
        </React.Fragment>
    );
};
