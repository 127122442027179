import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

interface Props {
    goBack: () => void;
    onSubmit: () => void;
}

const ControlPanelRight = ({ goBack, onSubmit }: Props): JSX.Element => (
    <Grid spacing={3} alignItems="center" justify="center" container wrap="nowrap">
        <Grid item>
            <Button variant="contained" onClick={goBack}>
                <FormattedMessage id="common.cancel" />
            </Button>
        </Grid>
        <Grid item>
            <Button variant="contained" onClick={onSubmit}>
                <FormattedMessage id="common.save" />
            </Button>
        </Grid>
    </Grid>
);

export default ControlPanelRight;
