import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { ISidebarItem, ISidebarSubItem } from './Sidebar.types';
import ElementPositionUtils from '../../utils/ElementPositionUtils';
import BootstrapPanelUtils from '../../utils/BootstrapPanelUtils';
import ScrollUtils from '../../utils/ScrollUtils';
import { observer, useLocalStore } from 'mobx-react';
import {
    ErrorButton,
    ErrorButtonContainer,
    SidebarCollapseButton,
    SidebarIconWrapper,
    StyledSidebar,
} from './Sidebar.styled';
import { UpperLink } from '../links/UpperLink';
import { Box, Link, Tooltip, useTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { AngleIconButton } from '../buttons/AngleIconButton';
import { AppTheme } from '../../themes/types/themeTypes';
import SwapHorizSharpIcon from '@material-ui/icons/SwapHorizSharp';
import CompareArrowsSharpIcon from '@material-ui/icons/CompareArrowsSharp';
import { ReactComponent as PuzzleIcon } from '../../resources/images/icons/icon-all-icons-unicons-puzzle-piece-24.svg';
import { useFeature } from 'feature-toggle-jsx';

export type SidebarProps = {
    sidebarItems: ISidebarItem[];
    open: boolean;
    toggleSidebar: () => void;
};

type SidebarLocalStore = {
    rootScrollTop: number;
    spiedEntities: (ISidebarItem | ISidebarSubItem)[];
    isSidebarSticky: boolean;
    openItemId: string;
    activeItem: ISidebarItem;
    activeSubItem: ISidebarSubItem;
    sidebarItems: ISidebarItem[];
    hoveredItemId: string;
};

// todo: копипаст из Воронки, вынести в либу
export const Sidebar = observer(
    (props: SidebarProps): JSX.Element => {
        const { sidebarItems = [], open, toggleSidebar } = props;
        const theme = useTheme();
        const [customSidebarIcon, customSidebarIconConfig] = useFeature('customSidebarIcon');
        const sidebarContainerRef = useRef<HTMLDivElement>();
        const root = document.getElementById('root');

        const localStore = useLocalStore(
            (): SidebarLocalStore => ({
                rootScrollTop: 0,
                spiedEntities: [],
                isSidebarSticky: false,
                openItemId: '',
                activeItem: {} as ISidebarItem,
                activeSubItem: {} as ISidebarSubItem,
                sidebarItems: [],
                hoveredItemId: '',
            }),
        );

        const scrollHandler = (): void => {
            const currentScrollTop = root?.scrollTop;

            if (localStore.rootScrollTop !== currentScrollTop) {
                spyHandler();
                stickyHandler();
            }

            localStore.rootScrollTop = currentScrollTop || 0;
        };

        useEffect(() => {
            scrollHandler();
            root?.addEventListener('scroll', scrollHandler);

            return () => root?.removeEventListener('scroll', scrollHandler);
        }, []);

        useEffect(() => {
            const newSpiedEntities: (ISidebarItem | ISidebarSubItem)[] = [];
            sidebarItems.forEach((item) => {
                if (item.isShown) {
                    if (item.subItems.length > 0) {
                        item.subItems.forEach((subItem) => {
                            if (subItem.isShown) {
                                newSpiedEntities.push(subItem);
                            }
                        });
                    } else {
                        newSpiedEntities.push(item);
                    }
                }
            });
            localStore.spiedEntities = newSpiedEntities;
            localStore.sidebarItems = sidebarItems;

            // для определения активного элемента
            spyHandler();
        }, [sidebarItems]);

        const openPanelAndScrollById = (item: ISidebarItem | ISidebarSubItem) => (): void => {
            const itemId = item.id;
            BootstrapPanelUtils.openPanelById(itemId);
            ScrollUtils.scrollToElementById(itemId);
        };

        const setNewOpenItemId = (id: string) => (): void => {
            const newOpenItemId = localStore.openItemId === id ? '' : id;
            localStore.openItemId = newOpenItemId;
        };

        const setNewHoveredItemId = (id: string) => (): void => {
            const newHoveredItemId = localStore.hoveredItemId === id ? '' : id;
            localStore.hoveredItemId = newHoveredItemId;
        };

        const renderItem = (item: ISidebarItem, itemId: string, subItems: ISidebarSubItem[]): JSX.Element | null => {
            if (item.isShown && localStore.activeItem) {
                const onMouseEvent = setNewHoveredItemId(itemId);
                const active = localStore.activeItem.id === itemId;
                const hasSubItems = subItems.length > 0;
                const open = localStore.openItemId === itemId && hasSubItems;
                const hovered = localStore.hoveredItemId === itemId;
                return (
                    <li key={itemId} className={classNames('sidebar-item', { 'has-subitems': hasSubItems }, { open })}>
                        {item.hasError && (
                            <Tooltip
                                title={
                                    <FormattedMessage id="sidebar.scrollToError" values={{ count: item.errorsCount }} />
                                }
                            >
                                <ErrorButtonContainer>
                                    <ErrorButton size="small" color="secondary" onClick={openPanelAndScrollById(item)}>
                                        {item.errorsCount && item.errorsCount}
                                    </ErrorButton>
                                </ErrorButtonContainer>
                            </Tooltip>
                        )}
                        <Box fontWeight="bold">
                            {(active || hovered) && (
                                <SidebarIconWrapper>
                                    {customSidebarIcon ? (
                                        <img src={customSidebarIconConfig.src} style={{ maxWidth: '36px' }} />
                                    ) : (
                                        <PuzzleIcon />
                                    )}
                                </SidebarIconWrapper>
                            )}
                            <UpperLink
                                underline="none"
                                color="textPrimary"
                                className={classNames('sidebar-link', { active }, { hovered })}
                                onClick={openPanelAndScrollById(item)}
                                onMouseEnter={onMouseEvent}
                                onMouseLeave={onMouseEvent}
                            >
                                {item.title}
                            </UpperLink>
                        </Box>
                        {hasSubItems && (
                            <AngleIconButton isUp={open} isActive={active} onClick={setNewOpenItemId(itemId)} />
                        )}
                    </li>
                );
            }
            return null;
        };

        const renderSubItems = (itemId: string, subItems: ISidebarSubItem[]): JSX.Element[] | JSX.Element | null => {
            const onMouseEvent = setNewHoveredItemId(itemId);
            if (localStore.openItemId === itemId) {
                const subItemsLastIndex = subItems.length - 1;
                return subItems.map((subItem, index) => {
                    const subItemId = subItem.id;
                    const activeSubItemId = localStore.activeSubItem?.id;
                    const active = activeSubItemId === subItemId;
                    const last = index === subItemsLastIndex;
                    const onClickEvent = openPanelAndScrollById(subItem);
                    return (
                        <React.Fragment key={subItemId}>
                            {subItem.isShown && (
                                <li key={subItemId} className={classNames('sidebar-subitem', { last })}>
                                    <div onMouseEnter={onMouseEvent} onMouseLeave={onMouseEvent}>
                                        {subItem.hasError && (
                                            <Tooltip
                                                title={
                                                    <FormattedMessage
                                                        id="sidebar.scrollToError"
                                                        values={{ count: subItem.errorsCount }}
                                                    />
                                                }
                                            >
                                                <ErrorButtonContainer>
                                                    <ErrorButton size="small" color="secondary" onClick={onClickEvent}>
                                                        {subItem.errorsCount && subItem.errorsCount}
                                                    </ErrorButton>
                                                </ErrorButtonContainer>
                                            </Tooltip>
                                        )}
                                        <Link
                                            underline="none"
                                            color="textPrimary"
                                            className={classNames({ active })}
                                            onClick={onClickEvent}
                                        >
                                            {subItem.title}
                                        </Link>
                                    </div>
                                </li>
                            )}
                        </React.Fragment>
                    );
                });
            }
            return null;
        };

        const spyHandler = (): void => {
            const panelHeight = (theme as AppTheme)?.variables.requestPanel.height;
            const spiedElementsLength = localStore.spiedEntities.length;
            for (let i = 0; i < spiedElementsLength; i++) {
                const entity: ISidebarItem | ISidebarSubItem = localStore.spiedEntities[i];
                const element: HTMLElement | null = document.querySelector(`#${entity.id}`);
                if (element && ElementPositionUtils.isPartOfElementInView(element, 0, panelHeight)) {
                    if ((entity as ISidebarSubItem).parentId) {
                        setNewActiveSubItem(entity as ISidebarSubItem);
                    } else {
                        setNewActiveItem(entity as ISidebarItem);
                    }
                    break;
                }
            }
        };

        const stickyHandler = (): void => {
            if (sidebarContainerRef.current) {
                const newStickyStatus: boolean = !ElementPositionUtils.isTopOfElementInView(
                    sidebarContainerRef.current,
                );
                if (localStore.isSidebarSticky !== newStickyStatus) {
                    localStore.isSidebarSticky = newStickyStatus;
                }
            }
        };

        const setNewActiveItem = (item: ISidebarItem): void => {
            localStore.activeItem = item;
            localStore.activeSubItem = findActiveSubItem(item);
            localStore.openItemId = item.id;
        };

        const setNewActiveSubItem = (subItem: ISidebarSubItem): void => {
            const parentId = subItem.parentId;
            const newActiveItem = localStore.sidebarItems.find((item) => item.id === parentId) as ISidebarItem;

            localStore.activeItem = newActiveItem;
            localStore.activeSubItem = subItem;
            localStore.openItemId = parentId;
        };

        const findActiveSubItem = (parentItem: ISidebarItem): ISidebarSubItem => {
            return parentItem.subItems.find((subItem) => subItem.isShown) as ISidebarSubItem;
        };

        return (
            <StyledSidebar item open={open} className="sidebar-container" ref={sidebarContainerRef}>
                <nav
                    className={classNames('sidebar', { sticky: localStore.isSidebarSticky, 'sidebar--closed': !open })}
                >
                    <Tooltip
                        title={
                            <FormattedMessage
                                id={open ? 'campaignRequest.expandWorkArea' : 'campaignRequest.reduceWorkArea'}
                            />
                        }
                    >
                        <SidebarCollapseButton onClick={toggleSidebar}>
                            {open ? <SwapHorizSharpIcon /> : <CompareArrowsSharpIcon />}
                        </SidebarCollapseButton>
                    </Tooltip>
                    <ul className={classNames('sidebar-list', { 'sidebar-list--closed': !open })}>
                        {sidebarItems.map((item) => {
                            const itemId = item.id;
                            const subItems = item.subItems;
                            const Item = renderItem(item, itemId, subItems);
                            const SubItems = renderSubItems(itemId, subItems);
                            return [Item, SubItems];
                        })}
                    </ul>
                </nav>
            </StyledSidebar>
        );
    },
);
