import React from 'react';
import { Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import { RouteProps } from 'react-router';
import { AuthorizationCheck, AuthorizationCheckProps } from './AuthorizationCheck';
import clientRoute from '../clientRoute';
import { useStore } from '../hooks';

export type AuthorizedRouteProps = AuthorizationCheckProps & RouteProps;

export const AuthorizedRoute = observer(
    (props: AuthorizedRouteProps): JSX.Element => {
        const { permCode, entityCode, entityId, pendingElement, onAllowed, errorElement, ...other } = props;
        const routeProps = other as RouteProps;
        const rootStore = useStore();

        const onNotAllowed = (): void => {
            rootStore.history.replace(clientRoute.notAllowed);
        };

        return (
            <AuthorizationCheck
                entityCode={entityCode}
                permCode={permCode}
                entityId={entityId}
                pendingElement={pendingElement}
                onAllowed={onAllowed}
                onNotAllowed={onNotAllowed}
            >
                <Route {...routeProps} />
            </AuthorizationCheck>
        );
    },
);
