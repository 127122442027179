import React, { PropsWithChildren, ReactNode, useState } from 'react';
import { ClickAwayListener, Grow, MenuList, Paper, Popper, PopperPlacementType } from '@material-ui/core';
import { observer } from 'mobx-react';

export type MenuButtonProps = PropsWithChildren<{
    renderButton(onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element;
    renderMenuItems?(hideMenu: () => void): JSX.Element | JSX.Element[] | ReactNode[];
    handleClose?: () => void;
    disablePortal?: boolean;
    placement?: PopperPlacementType;
    reloadKey?: string;
}>;

export const MenuButton = observer(
    (props: MenuButtonProps): JSX.Element => {
        const { handleClose, renderButton, renderMenuItems, children, disablePortal, placement = 'bottom-end' } = props;
        const [buttonRef, setButtonRef] = useState<null | HTMLButtonElement>(null);

        const hideMenu = (): void => {
            setButtonRef(null);
        };

        const handleCloseCallback = (): void => {
            handleClose && handleClose();
            hideMenu();
        };

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
            setButtonRef(event.currentTarget);
        };

        const isMenuOpen = !!buttonRef;

        return (
            <React.Fragment>
                {renderButton(handleClick)}
                <Popper
                    open={isMenuOpen}
                    style={{ zIndex: 1 }}
                    anchorEl={buttonRef}
                    transition
                    disablePortal={!disablePortal}
                    placement={placement}
                    modifiers={{
                        offset: {
                            enabled: true,
                            offset: '0, 8',
                        },
                    }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseCallback}>
                                    <MenuList id="language-menu" role="menu">
                                        {renderMenuItems && renderMenuItems(hideMenu)}
                                        {children}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </React.Fragment>
        );
    },
);
