import React, { useEffect, useMemo } from 'react';
import { Box, Chip, Dialog, FormControl, FormHelperText, Grid, InputLabel, Portal, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { TotDropzoneArea } from '../../components/TotDropzoneArea';
import PfTemplateModel from '../../models/PfTemplateModel';
import clientRoute from '../../clientRoute';
import { PfTemplateDTO } from '../../store/PfTemplateStore';
import { useStore } from '../../hooks';

type RouteParams = { id?: string };

export type PfTemplateCreateDialogProps = {
    pfTemplateList: PfTemplateDTO[];
    pageTitleId: string;
    templateUpdateMethod:
        | ((model: PfTemplateModel, templateId?: string) => Promise<string>)
        | ((model: PfTemplateModel) => Promise<string>);
};

export const PfTemplateCreateDialog = observer((props: PfTemplateCreateDialogProps) => {
    const { templateUpdateMethod, pfTemplateList, pageTitleId } = props;

    const { intlStore } = useStore();
    const intl = useIntl();
    const history = useHistory();
    const { id } = useParams<RouteParams>();

    const pfTemplateModel = useMemo(() => new PfTemplateModel(intlStore), [intlStore]);

    useEffect(() => {
        const templateDto = pfTemplateList.find((dto) => dto.id === id);
        templateDto && pfTemplateModel.load(templateDto);
    }, [id, pfTemplateList.length]);

    const handleSave = (): void => {
        pfTemplateModel.validationStarted = true;
        if (pfTemplateModel.isValid) {
            templateUpdateMethod(pfTemplateModel, id).then(handleClose);
        }
    };

    const handleClose = (): void => {
        history.push(generatePath(clientRoute.pfTemplateList));
    };

    const { file, title, errorFile, errorTitle } = pfTemplateModel;
    return (
        <Portal>
            <Dialog fullWidth={true} maxWidth="sm" open={true} scroll="body">
                <DialogTitle>
                    <FormattedMessage id={pageTitleId} />
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={6} direction="column">
                        <Grid item>
                            <TextField
                                variant="outlined"
                                required
                                error={!!errorTitle}
                                helperText={errorTitle}
                                label={<FormattedMessage id="pfTemplate.fields.title" />}
                                value={title}
                                onChange={(e): string => (pfTemplateModel.title = e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth required error={!!errorFile} variant="outlined">
                                <InputLabel shrink={true}>
                                    {<FormattedMessage id="pfTemplate.fields.file" />}
                                </InputLabel>
                                <TotDropzoneArea
                                    showAlerts={false}
                                    useChipsForPreview={true}
                                    showPreviewsInDropzone={false}
                                    filesLimit={1}
                                    acceptedFiles={file.acceptedFiles}
                                    dropzoneText={intl.formatMessage({ id: 'common.dropzoneText' })}
                                    onDrop={file.onChangeFile}
                                    onChange={file.onChangeFile}
                                />
                                {file.fileName && (
                                    <Box pt={1}>
                                        <Chip label={file.fileName} onDelete={file.onDeleteFile} />
                                    </Box>
                                )}
                                <FormHelperText>{errorFile}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave} color="primary" variant="contained">
                        <FormattedMessage id="common.save" />
                    </Button>
                    <Button onClick={handleClose} variant="text">
                        <FormattedMessage id="common.cancel" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    );
});
