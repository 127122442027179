import fonts from './fonts';
import { AppFonts, AppIcons, AppPalette, ThemeOverrides } from '../../../themes/types/themeTypes';

type ColorName =
    | 'blackLight'
    | 'black'
    | 'white'
    | 'yellow'
    | 'yellowDark'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'green'
    | 'red'
    | 'greyTable';

const totXColors: Record<ColorName, string> = {
    blackLight: '#1c1d1f',
    black: '#000000',
    white: '#ffffff',
    yellow: '#ffff33',
    yellowDark: '#f5c722',
    greyMain: '#63666a',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    greyWhite: '#f1f1f1',
    greyLighter: '#fafafa',
    greyMiddleLight: '#ccd3db',
    green: '#03cea4',
    red: '#fb4d3d',
    greyTable: '#ececec',
};

const totXFonts: AppFonts = {
    ...fonts,
    mainFontAttrs: {
        fontSize: '13px',
        lineHeight: 1.69,
        letterSpacing: '0.2px',
        fontWeight: 400,
    },
};

const totXIcons: AppIcons = {
    fontFamily: fonts.iconsFont,
    arrowIcon: {
        fontSize: 11,
        content: '"\\e90f"',
        width: 12,
        height: 7,
    },
    doneIcon: {
        fontSize: 14,
        content: '"\\e906"',
        width: 14,
        height: 11,
    },
    calendarIcon: {
        fontSize: 20,
        content: '"\\e905"',
        width: 18,
        height: 20,
    },
};

const accentBackgroundGradient = `linear-gradient(-30deg, ${totXColors.yellow} 0%, ${totXColors.yellowDark} 100%)`;

export const totXPalette: AppPalette = {
    main: totXColors.greyMain,
    textMain: totXColors.greyMain,
    textDark: totXColors.black,
    mainMiddleLight: totXColors.greyMiddleLight,
    mainLight: totXColors.greyLight,
    mainDark: totXColors.greyDark,
    mainContrast: totXColors.white,
    mainContrastDarker: totXColors.greyLighter,
    accent: totXColors.yellow,
    accentDark: totXColors.yellowDark,
    accentContrast: totXColors.black,
    accentBackgroundGradient: accentBackgroundGradient,
    accentContrastBackgroundGradient: totXColors.black,
    hover: totXColors.yellow,
    hoverInLists: totXColors.greyWhite,
    green: totXColors.green,
    red: totXColors.red,
    table: {
        row: {
            even: totXColors.white,
            odd: totXColors.greyLighter,
            checked: totXColors.greyTable,
        },
    },
    card: {
        header: {
            main: '#252629',
        },
    },
    appBar: {
        mainContrast: totXColors.blackLight,
    },
    tooltip: {
        backgroundColor: totXColors.blackLight,
    },
    buttonPrimary: {
        color: totXColors.greyMain,
        hoverColor: totXColors.black,
    },
    iconButton: {
        color: totXColors.greyMain,
        hoverColor: totXColors.white,
        hoverBackgroundColor: totXColors.greyMain,
    },
    footer: {
        textMain: totXColors.greyMain,
    },
    panel: {
        markerBackgroundColor: accentBackgroundGradient,
        markerColor: totXColors.black,
    },
    campaignRequest: {
        icon: totXColors.black,
    },
    startLink: {
        color: totXColors.white,
        hoverColor: totXColors.yellow,
    },
};

export default {
    fonts: totXFonts,
    palette: totXPalette,
    icons: totXIcons,
} as ThemeOverrides;
