import EntityCreatedDTO from '../models/EntityCreatedDTO';
import { FormDTO, FullSubmission } from '@platform/formiojs-react';
import { Api } from './Api';
import { action } from 'mobx';
import { CodeTitle } from '../models/CodeTitle';
import apiConfigs from '../apiConfigs';
import { IntlStore } from './IntlStore';

export interface PlanEntryDTO {
    id: string;
    taskType: string;
    viewPoint?: string;
    tasksMin: number;
    tasksMax: number;
    acceptanceDays: number;
    processingDays: number;
    processTitle: string;
    taskFormCode: string;
    reportFormCode: string;
    formInfo?: FormDTO;
    taskFormInfo?: FormDTO;
}

export abstract class ExpertisePlanStore {
    abstract api: Api;
    abstract intl: IntlStore;

    abstract loadPlanEntryList(ownerId: string): Promise<PlanEntryDTO[]>;
    abstract createPlanEntry(ownerId: string): Promise<EntityCreatedDTO>;
    abstract loadPlanEntry(entryId: string): Promise<PlanEntryDTO>;
    abstract deletePlanEntry(entryId: string): Promise<void>;
    abstract updatePlanEntry(
        entryId: string,
        submission: FullSubmission,
        taskFormCode?: string,
        taskSubmission?: FullSubmission,
    ): Promise<void>;

    @action.bound
    expertiseTaskFormsSelectData(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.expertiseTaskFormsSelectData).then((r) => r.data);
    }

    @action.bound
    expertiseTaskFormByCode(code: string): Promise<FormDTO> {
        return this.api.client(apiConfigs.expertiseTaskFormByCode(code)).then((r) => r.data);
    }
}
