import { action, observable } from 'mobx';
import { CampaignDTO } from '../../store/CampaignStore';
import { UserNameModel } from '../person/UserNameModel';
import { MetaInfoModel } from '../MetaInfo';
import { FormModel } from '@platform/formiojs-react';

export default class CampaignModel {
    @observable id = '';
    @observable title = '';
    @observable identifier = '';
    @observable formModel: FormModel;
    @observable manager: UserNameModel = new UserNameModel();
    @observable metaInfo: MetaInfoModel = new MetaInfoModel();

    constructor(id: string) {
        this.id = id;
        this.formModel = new FormModel(id);
    }

    @action.bound
    load(dto: CampaignDTO): void {
        const formInfo = dto.formInfo;
        this.id = dto.id;
        this.title = dto.title;
        this.identifier = dto.identifier;
        this.formModel.load(formInfo);
        this.manager.load(dto.manager);
        this.metaInfo.load(dto.metaInfo);
    }

    @action.bound
    loadCard(dto: CampaignDTO): void {
        this.id = dto.id;
        this.title = dto.title;
        this.identifier = dto.identifier;
        this.manager.load(dto.manager);
        this.metaInfo.load(dto.metaInfo);
    }
}
