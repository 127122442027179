import React, { ReactNode, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    LinearProgress,
    Link,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@material-ui/core';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { NewSubjectDTO } from '../../../store/SubjectStore';
import { TotTablePagination } from '../../../components/TotTablePagination';
import { SubjectListModel as SubjectListModelInj, SubjectRow } from '../../../models/subject/SubjectListModel';
import { MenuButton } from '../../../components/buttons/MenuButton';
import { CreateSubjectDialog } from './CreateSubjectDialog';
import { generatePath, NavLink } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import { DeleteActionMenuItem } from '../../../components/DeleteActionMenuItem';
import { CreateExpertiseButton } from './CreateExpertiseButton';
import { AuthorizationCheck } from '../../../components/AuthorizationCheck';
import { entities, permissions, permissionsConfig } from '../../../authSchemeConfig';
import { SubjectListFilterPanel as SubjectListFilterPanelInj } from './SubjectListFilterPanel';
import { ReactComponent as ClearFilter } from '../../../resources/images/icons/clear-filter.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExportButton } from '../../../components/buttons/ExportButton';
import { AuthorizationCheckAll } from '../../../components/AuthorizationCheckAll';
import { ActionsButton } from '../../../components/buttons/ActionsButton';
import { useStore } from '../../../hooks/useStore';
import { useLoading } from '../../../hooks/useLoading';
import { useModal } from '../../../hooks/useModal';
import { di } from 'react-magnetic-di';

export const renderSubjectListHeadInj = (tableModel: SubjectListModelInj): JSX.Element => {
    const { sorting } = tableModel;
    return (
        <TableRow>
            <TableCell>
                <TableSortLabel
                    active={sorting.identifier.isActive}
                    direction={sorting.identifier.direction}
                    onClick={tableModel.changeSorting(sorting.identifier)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="subject.fields.identifier" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={sorting.title.isActive}
                    direction={sorting.title.direction}
                    onClick={tableModel.changeSorting(sorting.title)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="subject.fields.title" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={sorting.created.isActive}
                    direction={sorting.created.direction}
                    onClick={tableModel.changeSorting(sorting.created)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="subject.fields.created" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <Typography>
                    <FormattedMessage id="subject.fields.state" />
                </Typography>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={sorting.campaign.isActive}
                    direction={sorting.campaign.direction}
                    onClick={tableModel.changeSorting(sorting.campaign)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="subject.fields.campaign" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={sorting.category.isActive}
                    direction={sorting.category.direction}
                    onClick={tableModel.changeSorting(sorting.category)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="subject.fields.category" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={sorting.expertise.isActive}
                    direction={sorting.expertise.direction}
                    onClick={tableModel.changeSorting(sorting.expertise)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="subject.fields.expertise" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell />
        </TableRow>
    );
};

export const renderSubjectListBodyInj = (
    tableModel: SubjectListModelInj,
    renderActions: (subjectRow: SubjectRow) => ReactNode,
): JSX.Element[] => {
    return tableModel.rows.map((c) => (
        <TableRow key={c.id} hover>
            <TableCell>
                <Link component={NavLink} underline="none" to={generatePath(clientRoute.subject, { id: c.id })}>
                    {c.identifier || <FormattedMessage id="common.withoutNumber" />}
                </Link>
            </TableCell>
            <TableCell>{c.title}</TableCell>
            <TableCell>{c.created && <FormattedDate value={c.created} />}</TableCell>
            <TableCell>{c.state}</TableCell>
            <TableCell>
                {c.campaign && (
                    <Link
                        component={NavLink}
                        underline="none"
                        to={generatePath(clientRoute.campaign, {
                            id: c.campaign.id,
                        })}
                    >
                        {c.campaign.title}
                    </Link>
                )}
            </TableCell>
            <TableCell>
                {c.category && (
                    <Link
                        component={NavLink}
                        underline="none"
                        to={generatePath(clientRoute.category, {
                            id: c.category.id,
                        })}
                    >
                        {c.category.title}
                    </Link>
                )}
            </TableCell>
            <TableCell>
                {c.expertise && (
                    <Link
                        component={NavLink}
                        underline="none"
                        to={generatePath(clientRoute.expertise, {
                            id: c.expertise.id,
                        })}
                    >
                        {c.expertise.title}
                    </Link>
                )}
            </TableCell>
            <TableCell>{renderActions(c)}</TableCell>
        </TableRow>
    ));
};

export const SubjectListPage = observer(
    (): JSX.Element => {
        const [SubjectListModel] = di([SubjectListModelInj], SubjectListPage);
        const [SubjectListFilterPanel] = di([SubjectListFilterPanelInj], SubjectListPage);
        const [renderSubjectListHead] = di([renderSubjectListHeadInj], SubjectListPage);
        const [renderSubjectListBody] = di([renderSubjectListBodyInj], SubjectListPage);

        const rootStore = useStore();
        const { subjectStore, history } = rootStore;
        const tableModel = useMemo(() => new SubjectListModel(rootStore), [history]);

        useEffect(() => {
            return tableModel.dispose;
        }, [tableModel.dispose]);

        const { isLoading, enableLoading, disableLoading } = useLoading();
        const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();

        const intl = useIntl();

        const createSubject = (dto: NewSubjectDTO): Promise<void> => {
            enableLoading();
            return subjectStore.createSubject(dto).then((id) => {
                setModalIsClosed();
                history.push(generatePath(clientRoute.subjectCreate, { id }));
            });
        };

        const deleteSubject = (subjectRow: SubjectRow): (() => Promise<void>) => {
            return () => {
                return subjectStore.deleteSubject(subjectRow.id).then(tableModel.reloadData);
            };
        };

        const renderActionItems = (subjectRow: SubjectRow): (() => ReactNode[]) => {
            const { id } = subjectRow;
            return () => [
                <AuthorizationCheck
                    key="create-expertise"
                    {...permissionsConfig.createExpertiseForExpertiseSubject(id)}
                >
                    <CreateExpertiseButton
                        tabIndex={0}
                        subject={{ id, title: `${subjectRow.identifier} ${subjectRow.title}` }}
                    />
                </AuthorizationCheck>,
                <AuthorizationCheck key="delete-subject" {...permissionsConfig.deleteExpertiseSubject(id)}>
                    <DeleteActionMenuItem
                        id="delete"
                        wrappedComponentProps={{ tabIndex: 0 }}
                        title={<FormattedMessage id="common.confirmDeletion" />}
                        message={
                            <FormattedMessage
                                id="subject.confirmDeletionInfoText"
                                values={{ title: subjectRow.identifier }}
                            />
                        }
                        onConfirm={deleteSubject(subjectRow)}
                    />
                </AuthorizationCheck>,
            ];
        };

        const renderActions = (subjectRow: SubjectRow): ReactNode => {
            return (
                <AuthorizationCheckAll
                    or
                    queries={{
                        createExpertiseForExpertiseSubject: permissionsConfig.createExpertiseForExpertiseSubject(
                            subjectRow.id,
                        ),
                        deleteExpertiseSubject: permissionsConfig.deleteExpertiseSubject(subjectRow.id),
                    }}
                >
                    <MenuButton
                        disablePortal={true}
                        renderButton={ActionsButton}
                        renderMenuItems={renderActionItems(subjectRow)}
                    />
                </AuthorizationCheckAll>
            );
        };

        const filename = `${intl.formatMessage({ id: 'subject.subjectListTitle' })}.xlsx`;

        return (
            <React.Fragment>
                {isModalOpen && (
                    <CreateSubjectDialog
                        isLoading={isLoading}
                        onCancel={setModalIsClosed}
                        onSubmit={createSubject}
                        disableLoading={disableLoading}
                    />
                )}
                <Container maxWidth="lg">
                    <Box pt={5} pb={5}>
                        <Grid container direction="column" spacing={10}>
                            <Grid item container direction="row" justify="space-between">
                                <Grid item>
                                    <Typography variant="h1">
                                        <FormattedMessage id="subject.subjectListTitle" />
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} container spacing={2} justify="flex-end">
                                    <Grid item>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            startIcon={
                                                <SvgIcon>
                                                    <ClearFilter />
                                                </SvgIcon>
                                            }
                                            onClick={tableModel.clearFilters}
                                        >
                                            <FormattedMessage id="common.resetFilters" />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <ExportButton
                                            queryData={tableModel.queryData}
                                            loadRegisterList={subjectStore.exportListXls}
                                            filename={filename}
                                        />
                                    </Grid>
                                    <AuthorizationCheck
                                        entityCode={entities.System}
                                        permCode={permissions.System.AddExpertiseSubject}
                                    >
                                        <Grid item>
                                            <Button color="primary" variant="contained" onClick={setModalIsOpen}>
                                                <FormattedMessage id="subject.createSubject" />
                                            </Button>
                                        </Grid>
                                    </AuthorizationCheck>
                                </Grid>
                            </Grid>
                            <Grid item xs>
                                <SubjectListFilterPanel model={tableModel} />
                            </Grid>
                            <Grid item>
                                <TableContainer component={Paper}>
                                    {tableModel.isLoading && <LinearProgress />}
                                    <Table>
                                        <TableHead>{renderSubjectListHead(tableModel)}</TableHead>
                                        <TableBody>{renderSubjectListBody(tableModel, renderActions)}</TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TotTablePagination
                                                    count={tableModel.rowsCount}
                                                    page={tableModel.pageNumber}
                                                    onChangePage={tableModel.onChangePage}
                                                    onChangeRowsPerPage={tableModel.onChangePageSize}
                                                    rowsPerPage={tableModel.pageSize}
                                                    rowsPerPageOptions={tableModel.pageSizeOptions}
                                                    labelRowsPerPage={intl.formatMessage({ id: 'common.rowsPerPage' })}
                                                    labelDisplayedRows={(p): string =>
                                                        intl.formatMessage(
                                                            { id: 'subject.registryPagingInfo' },
                                                            { to: p.to, from: p.from, count: p.count },
                                                        )
                                                    }
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </React.Fragment>
        );
    },
);
