import { ExpertiseByCampaignDTO, ExpertiseCreateDTO } from './store/ExpertiseStore';
import { AxiosRequestConfig } from 'axios';
import { LoginDTO } from './models/authentication/LoginModel';
import { NewPasswordDTO } from './models/authentication/NewPasswordModel';
import { EmailDTO } from './models/authentication/RecoveryPasswordModel';
import { ConfirmEmailDTO } from './models/authentication/RegistrationConfirmModel';
import { RegistrationDTO } from './models/authentication/RegistrationModel';
import { TableQueryData } from './models/list/TableModel';
import { AuthorizationCheckQuery } from './store/AuthorizationStore';
import { NewSubjectDTO } from './store/SubjectStore';
import { FullSubmission } from '@platform/formiojs-react';
import { UserForCreate, UserUpdateBody } from './store/UserStore';
import { Preset, TTableQueryData, TTableRow } from '@platform/ttable';
import { ExpertiseTaskCuratorRegistryRow } from './store/ExpertiseTaskStore';

class ApiConfigs {
    login: (data: LoginDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/login',
            method: 'PUT',
            data,
        };
    };

    esiaAuthUrl: AxiosRequestConfig = {
        url: '/authUrl/esia',
        method: 'GET',
    };

    esiaLogin: (search: string) => AxiosRequestConfig = (search) => {
        return {
            url: `/login/esia${search}`,
            method: 'PUT',
        };
    };

    logout: AxiosRequestConfig = {
        url: '/logout',
        method: 'PUT',
    };

    resetPassword: (data: NewPasswordDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/reset-password',
            method: 'PUT',
            data,
        };
    };

    forgotPassword: (data: EmailDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/forgot-password',
            method: 'PUT',
            data,
        };
    };

    confirmEmail: (data: ConfirmEmailDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/confirm-email',
            method: 'PUT',
            data,
        };
    };

    register: (data: RegistrationDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/register',
            method: 'PUT',
            data,
        };
    };

    authorizationCheck: (queries: Readonly<AuthorizationCheckQuery[]>) => AxiosRequestConfig = (queries) => {
        return {
            url: '/check',
            method: 'PUT',
            data: queries,
        };
    };

    person: AxiosRequestConfig = {
        url: '/person',
        method: 'GET',
    };

    setLang: (lang: string) => AxiosRequestConfig = (lang) => {
        return {
            url: `/person?lang=${lang}`,
            method: 'PUT',
        };
    };

    createCampaign: AxiosRequestConfig = {
        url: '/campaign',
        method: 'POST',
    };

    campaigns: (data: TableQueryData) => AxiosRequestConfig = (data) => {
        return {
            url: '/campaigns',
            method: 'PUT',
            data,
        };
    };

    campaign: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/campaign/${id}`,
            method: 'GET',
        };
    };

    editCampaign: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/campaign/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    deleteCampaign: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/campaign/${id}`,
            method: 'DELETE',
        };
    };

    campaignSelectOptions: AxiosRequestConfig = {
        url: `select/campaigns`,
        method: 'GET',
    };

    campaignLifeCycleTransitions: (campaignId: string) => AxiosRequestConfig = (campaignId) => {
        return {
            url: `/campaign/${campaignId}/transitions`,
            method: 'GET',
        };
    };

    campaignLifeCycleTransition: (transitionId: string, campaignId: string) => AxiosRequestConfig = (
        transitionId,
        campaignId,
    ) => {
        return {
            url: `/campaign/${campaignId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    campaignStatistic: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `campaign/${id}/statistic`,
            method: 'GET',
        };
    };

    campaignLinks: (campaignId: string) => AxiosRequestConfig = (campaignId) => {
        return {
            url: `/campaign/${campaignId}/statistic/links`,
            method: 'GET',
        };
    };

    categoriesOfSubjectsListData: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/categories',
            method: 'PUT',
            data: queryData,
        };
    };

    createCategoryOfSubjects: AxiosRequestConfig = {
        url: '/category',
        method: 'POST',
    };

    deleteCategoryOfSubjects: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/category/${id}`,
            method: 'DELETE',
        };
    };

    loadCategoryOfSubjectsDTO: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/category/${id}`,
            method: 'GET',
        };
    };

    saveChangeCategoryOfSubjects: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/category/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    categorySelectOptions: AxiosRequestConfig = {
        url: `select/categories`,
        method: 'GET',
    };

    subjects: (data: TableQueryData) => AxiosRequestConfig = (data) => {
        return {
            url: '/subjects',
            method: 'PUT',
            data,
        };
    };

    createSubject: (data: NewSubjectDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/subject',
            method: 'POST',
            data,
        };
    };

    deleteSubject: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/subject/${id}`,
            method: 'DELETE',
        };
    };

    subject: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/subject/${id}`,
            method: 'GET',
        };
    };

    saveSubjectForms: (
        subjectId: string,
        commonSubmission: FullSubmission,
        hiddenSubmission?: FullSubmission,
    ) => AxiosRequestConfig = (subjectId, commonSubmission, hiddenSubmission) => {
        return {
            url: `/subject/${subjectId}`,
            method: 'PUT',
            data: {
                commonFormSubmission: commonSubmission,
                hiddenFormSubmission: hiddenSubmission,
            },
        };
    };

    subjectLifeCycleTransition: (transitionId: string, subjectId: string) => AxiosRequestConfig = (
        transitionId,
        subjectId,
    ) => {
        return {
            url: `/subject/${subjectId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    downloadSubjectFiles: (subjectId: string) => AxiosRequestConfig = (subjectId) => {
        return {
            url: `/subject/${subjectId}/files`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    getSubjectLifeCycleTransitions: (subjectId: string) => AxiosRequestConfig = (subjectId) => {
        return {
            url: `/subject/${subjectId}/transitions`,
            method: 'GET',
        };
    };

    templatesOfExpertiseListData: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/templates',
            method: 'PUT',
            data: queryData,
        };
    };

    createTemplate: AxiosRequestConfig = {
        url: `/template`,
        method: 'POST',
    };

    deleteTemplate: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/template/${id}`,
            method: 'DELETE',
        };
    };

    loadTemplateDTO: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/template/${id}`,
            method: 'GET',
        };
    };

    saveTemplate: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/template/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    loadTemplatePlanEntries: (templateId: string) => AxiosRequestConfig = (templateId) => {
        return {
            url: `/template/${templateId}/plan-entries`,
            method: 'PUT',
        };
    };

    createTemplatePlanEntry: (templateId: string) => AxiosRequestConfig = (templateId) => {
        return {
            url: `/template/${templateId}/plan-entry`,
            method: 'POST',
        };
    };

    updateTemplatePlanEntry: (
        id: string,
        submission: FullSubmission,
        taskFormCode?: string,
        taskSubmission?: FullSubmission,
    ) => AxiosRequestConfig = (id, submission, taskFormCode, taskSubmission) => {
        return {
            url: `/template/plan-entry/${id}`,
            method: 'PUT',
            data: {
                submission,
                taskFormCode,
                taskSubmission,
            },
        };
    };

    loadTemplatePlanEntry: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/template/plan-entry/${id}`,
            method: 'GET',
        };
    };

    deleteTemplatePlanEntry: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/template/plan-entry/${id}`,
            method: 'DELETE',
        };
    };

    expertiseTaskListData: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/expertise-tasks',
            method: 'PUT',
            data: queryData,
        };
    };

    createExpertiseTask: (planEntryId: string) => AxiosRequestConfig = (planEntryId) => {
        return {
            url: `/plan-entry/${planEntryId}/expertise-task`,
            method: 'POST',
        };
    };

    expertisePlanEntriesSelectOptions: (expertiseId: string) => AxiosRequestConfig = (expertiseId) => {
        return {
            url: `/select/expertise/${expertiseId}/plan-entries`,
            method: 'GET',
        };
    };

    expertiseListData: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/expertises',
            method: 'PUT',
            data: queryData,
        };
    };

    loadExpertiseFiltersData: AxiosRequestConfig = {
        url: '/expertises/filters',
        method: 'GET',
    };

    loadExpertise: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/expertise/${id}`,
            method: 'GET',
        };
    };

    deleteExpertise: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/expertise/${id}`,
            method: 'DELETE',
        };
    };

    expertiseLifeCycleTransition: (transitionId: string, expertiseId: string) => AxiosRequestConfig = (
        transitionId,
        expertiseId,
    ) => {
        return {
            url: `/expertise/${expertiseId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    getExpertiseLifeCycleTransitions: (expertiseId: string) => AxiosRequestConfig = (expertiseId) => {
        return {
            url: `/expertise/${expertiseId}/transitions`,
            method: 'GET',
        };
    };

    expertiseListXls: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/expertisesXls',
            method: 'PUT',
            responseType: 'blob',
            data: queryData,
        };
    };

    loadExpertisePlanEntries: (expertiseId: string) => AxiosRequestConfig = (expertiseId) => {
        return {
            url: `/expertise/${expertiseId}/plan-entries`,
            method: 'PUT',
        };
    };

    createExpertisePlanEntry: (expertiseId: string) => AxiosRequestConfig = (expertiseId) => {
        return {
            url: `/expertise/${expertiseId}/plan-entry`,
            method: 'POST',
        };
    };

    updateExpertisePlanEntry: (
        id: string,
        submission: FullSubmission,
        taskFormCode?: string,
        taskSubmission?: FullSubmission,
    ) => AxiosRequestConfig = (id, submission, taskFormCode, taskSubmission) => {
        return {
            url: `/plan-entry/${id}`,
            method: 'PUT',
            data: {
                submission,
                taskFormCode,
                taskSubmission,
            },
        };
    };

    loadExpertisePlanEntry: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/plan-entry/${id}`,
            method: 'GET',
        };
    };

    deleteExpertisePlanEntry: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/plan-entry/${id}`,
            method: 'DELETE',
        };
    };

    subjectsSelectList: AxiosRequestConfig = {
        url: '/select/expertise/subjects',
        method: 'GET',
    };

    subjectNumber: (campaignId?: string) => AxiosRequestConfig = (campaignId) => {
        return {
            url: '/subject/identifier',
            method: 'GET',
            params: {
                campaign: campaignId,
            },
        };
    };

    templatesSelectList: AxiosRequestConfig = {
        url: '/select/templates',
        method: 'GET',
    };

    lifeCyclesExpertiseList: AxiosRequestConfig = {
        url: '/lifecycles/expertise',
        method: 'GET',
    };

    templateDTO: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/template/${id}/details`,
            method: 'GET',
        };
    };

    createExpertise: (data: ExpertiseCreateDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/expertise',
            method: 'POST',
            data,
        };
    };

    createExpertiseByCampaign: (data: ExpertiseByCampaignDTO) => AxiosRequestConfig = (data) => {
        return {
            url: `/expertise`,
            method: 'POST',
            params: {
                campaign: data.campaignId,
                template: data.templateId,
            },
            data,
        };
    };

    expertiseTasksListByExpertise: (expertiseId: string) => AxiosRequestConfig = (expertiseId) => {
        return {
            url: `/expertise/${expertiseId}/expertise-tasks`,
            method: 'PUT',
            data: {
                filter: {},
                sort: [],
            },
        };
    };

    editExpertiseCurator: (expertiseId: string, curatorUserId: string) => AxiosRequestConfig = (
        expertiseId,
        curatorUserId,
    ) => {
        return {
            url: `/expertise/${expertiseId}/curator`,
            method: 'PUT',
            params: {
                userId: curatorUserId,
            },
        };
    };

    expertiseCuratorSelectOptions: AxiosRequestConfig = {
        url: '/select/users',
        method: 'GET',
        params: {
            role: 'Curator',
        },
    };

    deleteExpertiseTask: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/expertise-task/${id}`,
            method: 'DELETE',
        };
    };

    loadExpertiseTaskView: (taskId: string) => AxiosRequestConfig = (taskId) => {
        return {
            url: `/expertise-task/${taskId}/view`,
            method: 'GET',
        };
    };

    loadExpertiseTaskSubject: (taskId: string) => AxiosRequestConfig = (taskId) => {
        return {
            url: `/expertise-task/${taskId}/subject`,
            method: 'GET',
        };
    };

    loadExpertiseTaskTransitions: (taskId: string) => AxiosRequestConfig = (taskId) => {
        return {
            url: `/expertise-task/${taskId}/transitions`,
            method: 'GET',
        };
    };

    expertiseTaskLifeCycleTransition: (transitionId: string, taskId: string) => AxiosRequestConfig = (
        transitionId,
        taskId,
    ) => {
        return {
            url: `/expertise-task/${taskId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    loadTaskReportForm: (taskId: string) => AxiosRequestConfig = (taskId) => {
        return {
            url: `/expertise-task/${taskId}/report/form`,
        };
    };

    updateTaskReportForm: (taskId: string, submission: FullSubmission, expertiseInfo?: object) => AxiosRequestConfig = (
        taskId,
        submission,
        expertiseInfo,
    ) => {
        return {
            url: `/expertise-task/${taskId}/report`,
            method: 'PUT',
            data: {
                submission,
                expertiseInfo,
            },
        };
    };

    loadExpertiseTaskDTO: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/expertise-task/${id}`,
            method: 'GET',
        };
    };

    saveExpertiseTask: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/expertise-task/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    expertList: (data: TableQueryData, id: string) => AxiosRequestConfig = (data, id) => {
        return {
            url: `/expertise-task/${id}/experts`,
            method: 'PUT',
            data,
        };
    };

    // Получение данных для селекта пока не готово, поставил заглушку url: '/catalogItems/:code_catalog'
    scienceFieldsFilterData: AxiosRequestConfig = {
        url: '/catalogItems/CatalogCode1',
        method: 'GET',
    };

    setExpertOnTask: (taskId: string, expertId: string) => AxiosRequestConfig = (taskId, expertId) => {
        return {
            url: `/expertise-task/${taskId}/expert?expertId=${expertId}`,
            method: 'PUT',
        };
    };

    subjectListXls: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/subjectsXls',
            method: 'PUT',
            responseType: 'blob',
            data: queryData,
        };
    };

    loadStateFilterData: AxiosRequestConfig = {
        url: '/subjects/filters',
        method: 'GET',
    };

    expertiseTaskFormsSelectData: AxiosRequestConfig = {
        url: `/select/forms/search`,
        method: 'GET',
        params: {
            code: 'ExpertiseTask_',
        },
    };

    expertiseTaskFormByCode: (code: string) => AxiosRequestConfig = (code) => {
        return {
            url: `/expertise-task/form`,
            method: 'GET',
            params: {
                code,
            },
        };
    };

    loadTaskInviteTransitions: (inviteId: string) => AxiosRequestConfig = (inviteId) => {
        return {
            url: `/invite/${inviteId}/transitions`,
            method: 'GET',
        };
    };

    taskInviteLifeCycleTransition: (transitionId: string, inviteId: string) => AxiosRequestConfig = (
        transitionId,
        inviteId,
    ) => {
        return {
            url: `/invite/${inviteId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    rejectTaskInvite: (transitionId: string, inviteId: string, reason: string) => AxiosRequestConfig = (
        transitionId,
        inviteId,
        reason,
    ) => {
        return {
            url: `/invite/${inviteId}/reject`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
            data: {
                reason,
            },
        };
    };

    expertiseTaskPrintForms: (expertiseTaskId: string) => AxiosRequestConfig = (expertiseTaskId) => {
        return {
            url: `/expertise-task/${expertiseTaskId}/pf`,
            method: 'GET',
        };
    };

    createExpertiseTaskPrintForm: (expertiseTaskId: string, pfCode: string) => AxiosRequestConfig = (
        expertiseTaskId,
        pfCode,
    ) => {
        return {
            method: 'POST',
            url: `/expertise-task/${expertiseTaskId}/pf`,
            params: {
                pfCode: pfCode,
            },
        };
    };

    updateExpertiseTaskPrintForm: (taskPrintFormId: string) => AxiosRequestConfig = (taskPrintFormId) => {
        return {
            method: 'PUT',
            url: `/pf/${taskPrintFormId}`,
        };
    };

    downloadPrintFormFile: (pfId: string) => AxiosRequestConfig = (pfId) => {
        return {
            url: `/pf/${pfId}`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    userList: (data: TableQueryData) => AxiosRequestConfig = (data) => {
        return {
            url: '/administration/users',
            method: 'PUT',
            data,
        };
    };

    loadUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}`,
            method: 'GET',
        };
    };

    saveUser: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/administration/user/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    userRoleList: AxiosRequestConfig = {
        url: `roles`,
        method: 'GET',
    };

    saveUserHead: (id: string, body: UserUpdateBody) => AxiosRequestConfig = (id, body) => {
        return {
            url: `/administration/user/${id}/update`,
            method: 'PUT',
            data: body,
        };
    };

    blockUser: (id: string, message?: string) => AxiosRequestConfig = (id, message) => {
        return {
            url: `/administration/user/${id}/block`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(message),
        };
    };

    unblockUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}/unblock`,
            method: 'PUT',
        };
    };

    deleteUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}`,
            method: 'DELETE',
        };
    };

    loginAsUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/login-as/${id}`,
            method: 'PUT',
        };
    };

    assignUserPassword: (id: string, password: string) => AxiosRequestConfig = (id, password) => {
        return {
            url: `/administration/user/${id}/password`,
            method: 'PUT',
            data: { password },
        };
    };

    createUser: (user: UserForCreate) => AxiosRequestConfig = (user) => {
        return {
            url: '/administration/register',
            method: 'POST',
            data: user,
        };
    };

    getRoleListWithoutAdministration: AxiosRequestConfig = {
        url: '/administration/roles',
        method: 'GET',
    };

    loadExpertiseTasksFilterData: AxiosRequestConfig = {
        url: '/expertise-tasks/filters',
        method: 'GET',
    };

    loadExpertFilterData: AxiosRequestConfig = {
        url: '/select/experts',
        method: 'GET',
    };

    loadManagerFilterData: AxiosRequestConfig = {
        url: '/select/curators',
        method: 'GET',
    };

    loadExpertiseTasksXls: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/expertise-tasksXls',
            method: 'PUT',
            responseType: 'blob',
            data: queryData,
        };
    };

    getTemplateOfExpertiseLifeCycleTransitions: (templateId: string) => AxiosRequestConfig = (templateId) => {
        return {
            url: `/template/${templateId}/transitions`,
            method: 'GET',
        };
    };

    templateOfExpertiseLifeCycleTransition: (transitionId: string, templateId: string) => AxiosRequestConfig = (
        transitionId,
        templateId,
    ) => {
        return {
            url: `/template/${templateId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    sendToRework: (transitionId: string, taskId: string, message: string) => AxiosRequestConfig = (
        transitionId: string,
        taskId,
        message,
    ) => {
        return {
            url: `/expertise-task/${taskId}/rework`,
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(message),
            params: {
                transition: transitionId,
            },
        };
    };

    catalogItems: (catalogCode: string, lang: string) => AxiosRequestConfig = (catalogCode, lang) => {
        return {
            url: `/catalogItems`,
            method: 'GET',
            params: {
                catalogCode,
                lang,
            },
        };
    };

    pfTemplates: AxiosRequestConfig = {
        url: '/pf-templates',
        method: 'GET',
    };

    createPfTemplate: (formData: FormData) => AxiosRequestConfig = (formData) => {
        return {
            url: `/pf-template`,
            method: 'POST',
            data: formData,
        };
    };

    updatePfTemplate: (formData: FormData, templateId?: string) => AxiosRequestConfig = (formData, templateId) => {
        return {
            url: `/pf-template/update`,
            method: 'PUT',
            params: {
                id: templateId,
            },
            data: formData,
        };
    };

    downloadPfTemplateFile: (pfTemplateId: string) => AxiosRequestConfig = (pfTemplateId) => {
        return {
            url: `/pf-template/${pfTemplateId}/file`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    changeExpertiseTaskCurator: (expertiseTaskId: string, curatorUserId: string) => AxiosRequestConfig = (
        expertiseTaskId,
        curatorUserId,
    ) => {
        return {
            url: `/expertise-task/${expertiseTaskId}/curator?userId=${curatorUserId}`,
            method: 'PUT',
        };
    };

    academicDegreeCatalogList: (lang?: string) => AxiosRequestConfig = (lang) => {
        return {
            method: 'GET',
            url: '/catalogItems/filters',
            params: {
                catalogCode: 'AcademicDegree',
                ...(lang && { lang }),
            },
        };
    };

    changeStartedDate: (expertiseTaskId: string, started: string) => AxiosRequestConfig = (
        expertiseTaskId,
        started,
    ) => {
        return {
            method: 'PUT',
            url: `/expertise-task/${expertiseTaskId}/started`,
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(started),
        };
    };

    changeDueDate: (expertiseTaskId: string, deadline: string) => AxiosRequestConfig = (expertiseTaskId, deadline) => {
        return {
            method: 'PUT',
            url: `/expertise-task/${expertiseTaskId}/deadline`,
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(deadline),
        };
    };

    registryTasks: (
        registryCode: string,
        queryData: TTableQueryData<ExpertiseTaskCuratorRegistryRow>,
    ) => AxiosRequestConfig = (registryCode, queryData) => {
        return {
            url: '/registry/tasks',
            method: 'PUT',
            params: {
                code: registryCode,
            },
            data: queryData,
        };
    };

    registryTasksColumns: (registryCode: string) => AxiosRequestConfig = (registryCode) => {
        return {
            url: '/registry/tasks/columns',
            method: 'GET',
            params: {
                code: registryCode,
            },
        };
    };

    //<editor-fold desc="Пресеты">
    listPreset: (registryCode: string) => AxiosRequestConfig = (registryCode) => {
        return {
            url: `presets`,
            method: 'GET',
        };
    };

    savePreset: <RowType extends TTableRow>(preset: Preset<RowType>) => AxiosRequestConfig = (preset) => {
        return {
            url: `preset`,
            method: `PUT`,
            data: preset,
        };
    };

    deletePreset: (presetId: string) => AxiosRequestConfig = (presetId) => {
        return {
            url: `preset/${presetId}`,
            method: 'DELETE',
        };
    };

    saveListPresets = <RowType extends TTableRow>(presets: Preset<RowType>[]): AxiosRequestConfig => ({
        url: '/presets',
        method: 'PUT',
        data: presets,
    });

    getLastPreset = (registryCode: string): AxiosRequestConfig => ({
        url: '/lastPreset',
        method: 'GET',
        params: {
            registryCode,
        },
    });

    saveLastPreset = (registryCode: string, presetId: string): AxiosRequestConfig => ({
        url: '/lastPreset',
        method: 'PUT',
        params: {
            registryCode,
            presetId,
        },
    });
    //<editor-fold/>

    loadModulesList = (): AxiosRequestConfig => ({
        url: '/getRoutes',
        method: 'GET',
    });

    upload: <RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>) => AxiosRequestConfig = (
        tableQueryData,
    ) => ({
        url: '/registry/tasks/export',
        method: 'PUT',
        data: tableQueryData,
        responseType: 'arraybuffer',
    });
}

export default new ApiConfigs();
