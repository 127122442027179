import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { Switch, Route, NavLink, generatePath } from 'react-router-dom';
import { AuthorizationCheck } from '../../../components/AuthorizationCheck';
import { TotObjectDeleteButton } from '../../../components/tot-object/TotObjectActionsBar';
import { FormattedMessage } from 'react-intl';
import { IconButtonLarge } from '../../../components/buttons/IconButtonLarge';
import clientRoute from '../../../clientRoute';
import { entities, permissions } from '../../../authSchemeConfig';
import { ReactComponent as EditIcon } from '../../../resources/images/icons/edit.svg';

interface Props {
    id: string;
    title: string;
    deleteTemplateOfExpertise: () => Promise<void>;
}

const ControlPanelLeft = ({ id, title, deleteTemplateOfExpertise }: Props): JSX.Element => (
    <Grid spacing={3} alignItems="center" justify="center" container>
        <Switch>
            <Route exact path={clientRoute.templateOfExpertise}>
                <AuthorizationCheck
                    entityCode={entities.TemplateExpertise}
                    entityId={id}
                    permCode={permissions.TemplateExpertise.Delete}
                >
                    <Grid item>
                        <TotObjectDeleteButton
                            id="delete-category"
                            title={<FormattedMessage id="common.confirmDeletion" />}
                            message={
                                <FormattedMessage
                                    id="templatesOfExpertise.confirmDeletionInfoText"
                                    values={{ title }}
                                />
                            }
                            onConfirm={deleteTemplateOfExpertise}
                            wrappedComponentProps={{
                                tooltipTitleId: 'templatesOfExpertise.actions.delete',
                            }}
                        />
                    </Grid>
                </AuthorizationCheck>
                <AuthorizationCheck
                    entityCode={entities.TemplateExpertise}
                    entityId={id}
                    permCode={permissions.TemplateExpertise.Edit}
                >
                    <Grid item>
                        <Tooltip title={<FormattedMessage id="templatesOfExpertise.actions.edit" />}>
                            <div>
                                <IconButtonLarge
                                    component={NavLink}
                                    to={generatePath(clientRoute.templateOfExpertiseEdit, {
                                        id,
                                    })}
                                >
                                    <EditIcon />
                                </IconButtonLarge>
                            </div>
                        </Tooltip>
                    </Grid>
                </AuthorizationCheck>
            </Route>
        </Switch>
    </Grid>
);

export default ControlPanelLeft;
