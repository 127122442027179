import React from 'react';
import { Grid } from '@material-ui/core';
import { RowData } from '../../../../components/PageHeader/RowData';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { getOrElse } from '../../../../utils/getOrElse';
import { SubjectModel } from '../../../../models/subject/SubjectModel';

export type SubjectHeaderLeftProps = {
    identifier: SubjectModel['identifier'];
    stateTitle: SubjectModel['metaInfo']['stateTitle'];
    created: SubjectModel['metaInfo']['created'];
};

export const SubjectHeaderLeft = (props: SubjectHeaderLeftProps): JSX.Element => {
    const { identifier, stateTitle, created } = props;
    return (
        <Grid container spacing={2}>
            <Grid item container spacing={7}>
                <Grid item>
                    <RowData
                        label={<FormattedMessage id="subject.fields.identifier" />}
                        value={identifier || <FormattedMessage id="common.withoutNumber" />}
                    />
                </Grid>
                <Grid item>
                    <RowData label={<FormattedMessage id="subject.fields.state" />} value={stateTitle} />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item>
                    <RowData
                        label={<FormattedMessage id="subject.fields.created" />}
                        value={getOrElse(created, <FormattedDate value={created} />)}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
