const templatesOfExpertise: Record<string, string | Record<string, string | Record<string, string>>> = {
    listTitle: 'Шаблоны экспертиз',
    create: 'Создать шаблон',
    new: 'Новый шаблон',
    fields: {
        title: 'Наименование',
        created: 'Дата создания',
        state: 'Состояние',
        author: 'Автор',
        lastModified: 'Последнее изменение',
        lifeCycle: 'Жизненный цикл',
        form: 'Форма',
    },
    plan: {
        listTitle: 'План экспертизы',
        createEntry: 'Создать запись плана',
        entryTitle: 'Запись плана',
        confirmDeletionInfoText: 'Вы действительно хотите удалить запись №{index}?',
        fields: {
            number: '№',
            viewPoint: 'Точка зрения экспертизы',
            planCountTasks: 'Плановое количество заданий',
            acceptanceDays: 'Срок ответа',
            processingDays: 'Срок выполнения',
            reportForm: 'Анкета заключения',
            taskForm: 'Форма задания',
            processTitle: 'ЖЦ задания',
        },
    },
    actions: {
        delete: 'Удалить шаблон',
        edit: 'Редактировать шаблон',
    },
    blocks: {
        serviceInfo: 'Служебная информация',
    },
    registryPagingInfo:
        'Всего {count, number} шаблон{count, plural, one {} few {а} other {ов}}. ' +
        'Отображены c {from, number} по {to, number}',
    confirmDeletionInfoText: 'Вы действительно хотите удалить шаблон "{title}"?',
};

export default templatesOfExpertise;
