import React from 'react';
import { observer } from 'mobx-react';
import { TotObjectHeader } from '../../../components/tot-object/TotObjectHeader';
import { UserPersonModel } from '../../../models/users/UserPersonModel';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import PageHeaderTitle from './PageHeaderTitle';
import PageHeaderLeft from './PageHeaderLeft';
import { useModal } from '../../../hooks';

export type UserHeaderProps = {
    userPersonModel: UserPersonModel;
    loginAsUser: () => void;
};

export const UserHeader = observer(
    (props: UserHeaderProps): JSX.Element => {
        const { userPersonModel, loginAsUser } = props;
        const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();

        return (
            <TotObjectHeader>
                <PageHeader
                    title={
                        <PageHeaderTitle
                            userPersonModel={userPersonModel}
                            login={userPersonModel.login}
                            setModalIsOpen={setModalIsOpen}
                            isModalOpen={isModalOpen}
                            setModalIsClosed={setModalIsClosed}
                            loginAsUser={loginAsUser}
                        />
                    }
                    left={<PageHeaderLeft userPersonModel={userPersonModel} />}
                />
            </TotObjectHeader>
        );
    },
);
