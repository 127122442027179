import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, withTheme, Tooltip, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { compose } from 'recompose';
import { ProgressButton } from '../../../../components/buttons/ProgressButton';

const SuccessButton = withTheme(styled(Button)`
    background-image: none;
    background-color: ${({ theme }): string => theme.variables.palette.green} !important;
    color: ${({ theme }): string => theme.variables.palette.mainContrast} !important;
`);

const FailedButton = withTheme(styled(Button)`
    background-image: none;
    background-color: ${({ theme }): string => theme.variables.palette.red} !important;
    color: ${({ theme }): string => theme.variables.palette.mainContrast} !important;
`);

type OuterProps = {
    status: 'success' | 'default' | 'failed';
    onSubmit: () => void;
    saveProgress: boolean;
    noOverflow?: boolean;
};

type InnerProps = OuterProps;

const SaveButton = (props: InnerProps): JSX.Element => {
    const { status, onSubmit, saveProgress, noOverflow } = props;

    const buttonTextEllipsis = noOverflow ? (
        <Typography variant="inherit" noWrap>
            <FormattedMessage id="common.save" />
        </Typography>
    ) : (
        <FormattedMessage id="common.save" />
    );

    switch (status) {
        case 'success':
            return (
                <SuccessButton variant="contained" disabled={true}>
                    {buttonTextEllipsis}
                </SuccessButton>
            );
        case 'failed':
            return (
                <Tooltip open={true} title={<FormattedMessage id="common.errorText" />}>
                    <FailedButton variant="contained" disabled={true}>
                        {buttonTextEllipsis}
                    </FailedButton>
                </Tooltip>
            );
        default:
            return (
                <ProgressButton
                    onClick={onSubmit}
                    buttonText={buttonTextEllipsis}
                    isInProgress={saveProgress}
                    buttonProps={{ color: 'primary', variant: 'contained' }}
                />
            );
    }
};

export default compose<InnerProps, OuterProps>()(SaveButton);
