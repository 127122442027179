import React, { ReactNode, useCallback } from 'react';
import { createStyles, makeStyles, Portal } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useYup } from '../../../../hooks/useYup';

const useStyles = makeStyles(() =>
    createStyles({
        formWrapper: {
            '&:first-child': {
                paddingTop: '10px',
            },
        },
    }),
);

export type UserBlockDialogProps = {
    onClose: () => void;
    onConfirm: (message?: string) => Promise<void>;
    message: ReactNode;
};

type FormikValues = {
    message: string;
};

const initialValues: FormikValues = {
    message: '',
};

export const UserBlockDialog = (props: UserBlockDialogProps): JSX.Element => {
    const { onClose, onConfirm, message } = props;
    const classes = useStyles();
    const intl = useIntl();
    const { Yup } = useYup();

    const schema = Yup.object().shape({
        message: Yup.string().required(),
    });

    const handleConfirm = useCallback(
        (formData: FormikValues) => {
            onConfirm(formData.message).finally(onClose);
        },
        [onConfirm],
    );

    return (
        <Portal>
            <Dialog fullWidth={true} maxWidth="xs" open={true}>
                <DialogTitle>
                    <FormattedMessage id="users.blockReason" />
                </DialogTitle>
                <DialogContent>{message}</DialogContent>
                <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleConfirm}>
                    <Form>
                        <DialogContent className={classes.formWrapper}>
                            <Field
                                component={TextField}
                                name={'message'}
                                label={intl.formatMessage({ id: 'users.blockReason' }) + '*'}
                                variant="outlined"
                                size="small"
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={onClose} color="primary">
                                <FormattedMessage id="common.cancel" />
                            </Button>
                            <Button type="submit" color="secondary" variant="contained">
                                <FormattedMessage id="users.actions.block" />
                            </Button>
                        </DialogActions>
                    </Form>
                </Formik>
            </Dialog>
        </Portal>
    );
};
