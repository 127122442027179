import React, { ChangeEvent, FormEvent, ReactNode, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { Autocomplete } from '@material-ui/lab';
import { IdTitle } from '../../../models/IdTitle';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import { useStore } from '../../../hooks';

export type ExpertiseTaskEditCuratorProps = {
    open: boolean;
    onCancel: () => void;
    onSubmit(curatorUserId: string): void;
    isLoading: boolean;
};

export const ExpertiseTaskEditCurator = observer(
    (props: ExpertiseTaskEditCuratorProps): JSX.Element => {
        const { onCancel, isLoading, open } = props;
        const { expertiseStore } = useStore();
        const intl = useIntl();

        const [curatorUserId, setCuratorUserId] = useState<string | undefined>('');
        const [validationStarted, setValidationStarted] = useState<boolean>(false);
        const [curatorOptions, setCuratorOptions] = useState<IdTitle[]>([]);
        const [curatorOptionsLoading, setCuratorOptionsLoading] = useState<boolean>(false);
        const [curatorOptionsLoaded, setCuratorOptionsLoaded] = useState<boolean>(false);

        const loadCuratorOptions = (): void => {
            if (!curatorOptionsLoaded && !curatorOptionsLoading) {
                setCuratorOptionsLoading(true);
                expertiseStore
                    .loadCuratorSelectOptions()
                    .then(
                        action((options: IdTitle[]) => {
                            setCuratorOptions(options);
                            setCuratorOptionsLoaded(true);
                        }),
                    )
                    .finally(() => {
                        setCuratorOptionsLoading(false);
                    });
            }
        };

        const enableValidation = (): void => {
            setValidationStarted(true);
        };

        const onChangeCurator = (event: ChangeEvent<{}>, value: IdTitle | null): void => {
            enableValidation();
            setCuratorUserId(value?.id);
        };

        const errorCurator = (): string => {
            if (validationStarted && !curatorUserId) {
                return intl.formatMessage({ id: 'validation.required' });
            }
            return '';
        };

        const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
            event.preventDefault();
            enableValidation();
            if (curatorUserId) {
                const { onSubmit } = props;
                onSubmit(curatorUserId);
            }
        };

        const renderInput = (
            label: string,
            required: boolean,
            errorText: string,
        ): ((params: AutocompleteRenderInputParams) => ReactNode) => {
            return (params: AutocompleteRenderInputParams): ReactNode => (
                <TextField
                    {...params}
                    required={required}
                    label={label}
                    variant="outlined"
                    error={!!errorText}
                    helperText={errorText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {curatorOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            );
        };

        return (
            <Dialog maxWidth="xs" fullWidth open={open} scroll="body">
                <Box pt={4} pr={4}>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <Box pl={12} pr={12} pb={12}>
                    <Grid container justify="center">
                        <Grid item>
                            <Typography variant="h5">
                                <Box fontWeight="fontWeightBold">
                                    <FormattedMessage id="expertise.changeCurator" />
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box pt={8}>
                        <form noValidate onSubmit={onSubmit}>
                            <Grid container spacing={6} direction="column" justify="center">
                                <Grid item>
                                    <Autocomplete
                                        onChange={onChangeCurator}
                                        onOpen={loadCuratorOptions}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => option.title}
                                        options={curatorOptions.slice()}
                                        loading={curatorOptionsLoading}
                                        renderInput={renderInput(
                                            intl.formatMessage({ id: 'expertise.fields.curator' }),
                                            true,
                                            errorCurator(),
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={isLoading}
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        type="submit"
                                    >
                                        <FormattedMessage id="common.save" />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={onCancel} fullWidth size="large" variant="contained">
                                        <FormattedMessage id="common.cancel" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Dialog>
        );
    },
);
