import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import CampaignModel from '../../../models/campaign/CampaignModel';

export type PageHeaderTitleProps = {
    title: CampaignModel['title'];
};

export const PageHeaderTitle = (props: PageHeaderTitleProps): JSX.Element => {
    const { title } = props;

    return (
        <Grid item>
            <Typography variant="h1">{title}</Typography>
        </Grid>
    );
};
