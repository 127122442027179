import React from 'react';
import { AppBar, Box, Container, Grid, Link, useMediaQuery } from '@material-ui/core';
import { Link as RouterLink, Route, Switch } from 'react-router-dom';
import { LanguageButton } from './LanguageButton';
import clientRoute from '../../clientRoute';
import StartPageHeaderLinks from '../../pages/start/StartPageHeaderLinks';
import { AppHeaderLinks } from './AppHeaderLinks';
import { observer, useLocalStore } from 'mobx-react';
import { AppTheme } from '../../themes/types/themeTypes';
import { useStore } from '../../hooks/useStore';
import { ModularNavigationWidget } from '@platform/modular-navigation-widget';
import { useFeature } from 'feature-toggle-jsx';
import { InstructionsButton } from './InstructionsButton';
import { LogoConfig, LogoImg } from './LogoImg';

export type TotAppBarProps = {
    withInstructions?: boolean;
};

export const TotAppBar = observer(
    (props: TotAppBarProps): JSX.Element => {
        const { withInstructions = true } = props;
        const { intlStore, modulesStore, personStore } = useStore();
        const { loadModulesList } = modulesStore;

        const [logo, logoConfig] = useFeature('logo');
        const [startPage] = useFeature('startPage');
        const [instructions] = useFeature('instructions');

        const store = useLocalStore(() => ({
            isMenuOpen: false,
            setMenuIsClosed(): void {
                this.isMenuOpen = false;
            },
            handlerBurgerButton(): void {
                this.isMenuOpen = !this.isMenuOpen;
            },
        }));

        const isSmSize = useMediaQuery((theme: AppTheme) => theme.breakpoints.down('sm'));
        const isMdSize =
            (useMediaQuery((theme: AppTheme) => theme.breakpoints.down('md')) && !isSmSize) ||
            (logoConfig.mdLogo && !isSmSize);

        return (
            <AppBar color="default" position="static" elevation={0}>
                <Container maxWidth="lg">
                    <Box pt={3.75} pb={3.75}>
                        <Grid container direction="row" alignItems="flex-start" justify="space-between">
                            <Grid item>
                                <Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
                                    <Grid item>
                                        <Link component={RouterLink} to={clientRoute.root} underline="none">
                                            <Grid container direction="row" alignItems="center">
                                                <LogoImg logoConfig={logoConfig as LogoConfig} />
                                            </Grid>
                                        </Link>
                                    </Grid>
                                    {personStore.user.id && (
                                        <Grid item key={personStore.user.id}>
                                            <ModularNavigationWidget
                                                loadModulesList={loadModulesList}
                                                locale={intlStore.locale}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item style={{ flexGrow: 1 }}>
                                <Box ml={1} mr={1} pt={3.75}>
                                    <Switch>
                                        <Route exact path={[clientRoute.root, clientRoute.login]}>
                                            {startPage && <StartPageHeaderLinks />}
                                        </Route>
                                        <Route>
                                            <AppHeaderLinks
                                                isSmSize={isSmSize}
                                                isMdSize={isMdSize}
                                                isOpenMenu={store.isMenuOpen}
                                                handlerBurgerButton={store.handlerBurgerButton}
                                                closeBurgerMenu={store.setMenuIsClosed}
                                            />
                                        </Route>
                                    </Switch>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justify="flex-end" spacing={3}>
                                    {instructions && withInstructions && (
                                        <Grid item>
                                            <Box pt={3.75}>
                                                <InstructionsButton />
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Box pt={3.75}>
                                            <LanguageButton />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </AppBar>
        );
    },
);
