import React from 'react';
import { observer } from 'mobx-react';
import { TotObjectHeader } from '../../../components/tot-object/TotObjectHeader';
import TemplatesOfExpertiseModel from '../../../models/templates-of-expertise/TemplatesOfExpertiseModel';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import PageHeaderTitle from './PageHeadeTitle';
import PageHeaderLeft from './PageHeaderLeft';

type TemplatesOfExpertiseHeaderProps = {
    templatesOfExpertiseModel: TemplatesOfExpertiseModel;
};

type InnerProps = TemplatesOfExpertiseHeaderProps;

@observer
class TemplatesOfExpertiseHeader extends React.Component<InnerProps, {}> {
    render(): JSX.Element {
        const { templatesOfExpertiseModel } = this.props;
        const { title, metaInfo } = templatesOfExpertiseModel;
        const { created, stateTitle } = metaInfo;
        return (
            <TotObjectHeader>
                <PageHeader
                    title={<PageHeaderTitle title={title} />}
                    left={<PageHeaderLeft created={created} stateTitle={stateTitle} />}
                />
            </TotObjectHeader>
        );
    }
}

export default TemplatesOfExpertiseHeader;
