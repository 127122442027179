import { RootStore } from './RootStore';
import { action, observable } from 'mobx';
import Components from 'formiojs/components/Components';
import AllComponents from 'formiojs/components';
import FormioExt from '@platform/formiojs-ext/dist';
import Widgets from 'formiojs/widgets';

type Formio = {
    fetch<T>(url: string, options?: object): Promise<T>;
    // ... other
};

export class FormioStore {
    @observable private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        const apiUrl = rootStore.env.apiUrl;

        Components.setComponents(AllComponents);
        Components.setComponents(FormioExt.ExtComponents);
        Components.setComponents(FormioExt.ExpertiseComponents);
        Components.addComponent('select', FormioExt.ExtSelect({ baseUrl: apiUrl, catalogsUrl: apiUrl }));
        Components.addComponent(
            'expertiseScoreSelect',
            FormioExt.ScoreSelect({ baseUrl: apiUrl, catalogsUrl: apiUrl }),
        );
        Components.addComponent('categoryFile', FormioExt.CategoryFile({ baseUrl: apiUrl, catalogsUrl: apiUrl }));
        Components.addComponent('file', FormioExt.StyledFile({ baseUrl: apiUrl }));
        Widgets.calendar = FormioExt.RuCalendarWidget;

        this.hackFormioFetch();
    }

    @action.bound
    hackFormioFetch(): void {
        const globalWithFormio = (global as unknown) as { Formio: Formio };
        const formio = globalWithFormio.Formio;
        if (formio && formio.fetch) {
            const formioFetch = formio.fetch;

            globalWithFormio.Formio.fetch = <T>(url: string, options?: object): Promise<T> => {
                let fetchOptions = { credentials: 'include' };
                if (!!options) {
                    fetchOptions = Object.assign(fetchOptions, options);
                }
                return formioFetch(url, fetchOptions);
            };
        }
    }
}
