import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { getOrElse } from '../../../../utils/getOrElse';
import { IdTitleNumber } from '../../../../models/IdTitleNumber';

interface Props {
    title?: IdTitleNumber['title'];
}

const PageHeaderTitle = ({ title }: Props): JSX.Element => (
    <Grid item>
        <Typography variant="h1">
            {getOrElse(title, <FormattedMessage id="expertise.expertiseTitle" values={{ title: title }} />)}
        </Typography>
    </Grid>
);

export default PageHeaderTitle;
