import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { Switch, Route, generatePath, NavLink } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import { FormattedMessage } from 'react-intl';
import { UserPersonModel } from '../../../models/users/UserPersonModel';
import { AuthorizationCheck } from '../../../components/AuthorizationCheck';
import { entities, permissions } from '../../../authSchemeConfig';

interface Props {
    id: UserPersonModel['id'];
    saveForm: () => Promise<void>;
}

const ControlPanelRight = ({ id, saveForm }: Props): JSX.Element => (
    <Grid spacing={3} alignItems="center" justify="center" container wrap="nowrap">
        <Switch>
            <AuthorizationCheck entityCode={entities.User} entityId={id} permCode={permissions.User.UpdateForm}>
                <Route exact path={clientRoute.userEdit}>
                    <Grid item>
                        <Button variant="contained" component={NavLink} to={generatePath(clientRoute.user, { id })}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={saveForm}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
            </AuthorizationCheck>
        </Switch>
    </Grid>
);

export default ControlPanelRight;
