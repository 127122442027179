import React, { ReactNode, useState } from 'react';
import { ProgressButton } from '../buttons/ProgressButton';
import { AxiosError, AxiosPromise } from 'axios';
import { ErrorMessage } from '../ErrorMessage';
import downloadFile from 'js-file-download';
import { ErrorDialog } from '../ErrorDialog';
import { ButtonTypeMap } from '@material-ui/core/Button/Button';

export type FilesDownloadProps = {
    onDownloadClick(): AxiosPromise<Blob>;
    buttonText: ReactNode;
    filename: string;
    mimeType?: string;
    buttonProps?: ButtonTypeMap<{}>['props'];
};

export const FilesDownload = (props: FilesDownloadProps): JSX.Element => {
    const {
        onDownloadClick,
        filename,
        buttonText,
        mimeType = 'application/zip',
        buttonProps = {
            color: 'secondary',
            variant: 'contained',
        },
    } = props;
    const [downloadFilesInProgress, setDownloadFilesInProgress] = useState<boolean>(false);
    const [errorDownloadFiles, setErrorDownloadFiles] = useState<string | ReactNode>('');

    const downloadFiles = (): void => {
        setDownloadFilesInProgress(true);
        onDownloadClick()
            .then((r) => r.data)
            .then((f) => downloadFile(f, filename, mimeType))
            .catch((error: AxiosError) => {
                if (error.request.responseType === 'blob' && error.response?.data instanceof Blob) {
                    const reader = new FileReader();

                    reader.onload = () => {
                        error.response && (error.response.data = reader.result);
                        const errorText = ErrorMessage(error);
                        setErrorDownloadFiles(errorText);
                    };

                    reader.readAsText(error.response.data);
                } else {
                    const errorText = ErrorMessage(error);
                    setErrorDownloadFiles(errorText);
                }
            })
            .finally(() => setDownloadFilesInProgress(false));
    };

    return (
        <React.Fragment>
            <ProgressButton
                onClick={downloadFiles}
                buttonText={buttonText}
                isInProgress={downloadFilesInProgress}
                buttonProps={buttonProps}
            />
            <ErrorDialog
                message={errorDownloadFiles}
                open={!!errorDownloadFiles}
                onClose={() => setErrorDownloadFiles('')}
            />
        </React.Fragment>
    );
};
