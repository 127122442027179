const templatesOfExpertise: Record<string, string | Record<string, string | Record<string, string>>> = {
    listTitle: 'Templates of expertise',
    create: 'Create template',
    new: 'New template',
    fields: {
        title: 'Title',
        created: 'Created',
        state: 'State',
        author: 'Author',
        lastModified: 'Last modified',
        lifeCycle: 'Lifecycle',
        form: 'Form',
    },
    plan: {
        listTitle: 'Expertise plan',
        createEntry: 'Create plan entry',
        entryTitle: 'Plan entry',
        confirmDeletionInfoText: 'Are you sure you want to delete entry №{index}?',
        fields: {
            number: '№',
            viewPoint: 'Point of view',
            planCountTasks: 'Planned number of tasks',
            acceptanceDays: 'Answer time',
            processingDays: 'Period of execution',
            reportForm: 'Report form',
            taskForm: 'Task form',
            processTitle: 'Lifecycle',
        },
    },
    actions: {
        delete: 'Delete template',
        edit: 'Edit template',
    },
    blocks: {
        serviceInfo: 'Service information',
    },
    registryPagingInfo:
        'Total {count, number} template{count, plural, one {} other {s}}. Displayed from {from, number} to {to, number}',
    confirmDeletionInfoText: 'Are you sure you want to delete the template "{title}"?',
};

export default templatesOfExpertise;
