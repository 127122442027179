import createAppThemeOptions from './themes/pages/app/appTheme';
import { AppTheme, AppThemeVariables, LandingTheme, ThemeOverrides } from './themes/types/themeTypes';
import { createMuiTheme, ThemeOptions } from '@material-ui/core';
import { ruRU } from '@material-ui/core/locale';
import { RootStore } from './store/RootStore';
import createLandingThemeOptions from './themes/pages/landing/landingTheme';

/* eslint import/no-webpack-loader-syntax: off */
// const sassVars = require('sass-extract-loader?{"plugins":["sass-extract-js"]}!./themes/pages/app/sass/vars.scss');
// скопированы из воронки, чуть отличаются от тех, что в файле vars.scss
import { sassVars } from './themes/pages/app/sass/sassVars';

export const createAppTheme = (
    store: RootStore,
    createOptions: (overrides: ThemeOverrides) => ThemeOptions = createAppThemeOptions,
): AppTheme => {
    const themeOptions = createOptions(store.themeOverrides);
    const themeVariables = themeOptions.variables as AppThemeVariables;
    return createMuiTheme(
        {
            ...themeOptions,
            variables: {
                ...themeVariables,
                sass: {
                    ...sassVars,
                    ...themeVariables.sass,
                },
            },
        },
        ruRU,
    );
};

export type LandingThemeCreator = (appTheme: AppTheme) => LandingTheme;

export const createLandingThemeCreator = (store: RootStore): LandingThemeCreator => {
    const landingOptions = createLandingThemeOptions(store.themeOverrides);
    return (appTheme: AppTheme): LandingTheme => {
        return createMuiTheme(
            {
                ...appTheme,
                ...landingOptions,
            },
            ruRU,
        );
    };
};
