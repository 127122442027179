import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { AuthorizationCheck } from '../../../components/AuthorizationCheck';
import { FormattedMessage } from 'react-intl';
import { TotObjectTransitions } from '../../../components/tot-object/TotObjectTransitions';
import { ExpertiseCreateDialog } from '../../expertise/ExpertiseCreateDialog';
import { entities, permissions } from '../../../authSchemeConfig';
import { CampaignPageRouteParams } from '../CampaignPage';
import { CampaignStore } from '../../../store/CampaignStore';
import clientRoute from '../../../clientRoute';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';

export type ControlPanelRightProps = {
    id: CampaignPageRouteParams['id'];
    reloadKey: number;
    setModalIsOpen: () => void;
    isModalOpen: boolean;
    campaignTitle: string;
    campaignIdentifier: string;
    setModalIsClosed: () => void;
    goToReadPage: () => void;
    campaignStore: CampaignStore;
    onSubmit: () => void;
    lifeCycleTransition: (transitionId: string, campaignId: string) => Promise<void>;
};

export const ControlPanelRight = (props: ControlPanelRightProps): JSX.Element => {
    const {
        id,
        reloadKey,
        setModalIsOpen,
        isModalOpen,
        campaignTitle,
        campaignIdentifier,
        setModalIsClosed,
        goToReadPage,
        campaignStore,
        lifeCycleTransition,
        onSubmit,
    } = props;

    return (
        <Grid container>
            <Switch>
                <Route path={clientRoute.campaignCreate} exact>
                    <Grid item>
                        <Button variant="contained" component={NavLink} to={generatePath(clientRoute.campaigns)}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={onSubmit}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route path={clientRoute.campaignEdit} exact>
                    <Grid item>
                        <Button variant="contained" component={NavLink} to={generatePath(clientRoute.campaign, { id })}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={onSubmit}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route path={clientRoute.campaign} exact>
                    <Grid item>
                        <Grid container direction="row" spacing={3} wrap="nowrap">
                            <AuthorizationCheck
                                key={reloadKey}
                                entityCode={entities.ExpertiseCampaign}
                                entityId={id}
                                permCode={permissions.ExpertiseCampaign.AddExpertises}
                            >
                                <Grid item>
                                    <Button variant="contained" onClick={setModalIsOpen}>
                                        <FormattedMessage id="expertise.createExpertises" />
                                    </Button>
                                    {isModalOpen && (
                                        <ExpertiseCreateDialog
                                            byCampaign
                                            campaign={{ id, title: campaignTitle, number: campaignIdentifier }}
                                            onClose={setModalIsClosed}
                                        />
                                    )}
                                </Grid>
                            </AuthorizationCheck>
                            <TotObjectTransitions
                                objectId={id}
                                updateObjectPage={goToReadPage}
                                getTransitions={campaignStore.getLifeCycleTransitions}
                                lifeCycleTransition={lifeCycleTransition}
                            />
                        </Grid>
                    </Grid>
                </Route>
            </Switch>
        </Grid>
    );
};
