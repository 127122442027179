import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { TotObjectHeader } from '../../../../components/tot-object/TotObjectHeader';
import { ExpertiseTaskModel } from '../../../../models/expertise-task/ExpertiseTaskModel';
import { ExpertiseTaskHeaderDescription } from '../ExpertiseTaskHeaderDescription/ExpertiseTaskHeaderDescription';

type ExpertiseTaskEditHeaderProps = {
    expertiseTaskModel: ExpertiseTaskModel;
};
type InnerProps = ExpertiseTaskEditHeaderProps;

@observer
class ExpertiseTaskEditHeader extends React.Component<InnerProps, {}> {
    render(): JSX.Element {
        const { expertiseTaskModel } = this.props;
        return (
            <TotObjectHeader>
                <Container maxWidth="lg">
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <Typography variant="h1">
                                <FormattedMessage
                                    id="expertiseTask.title"
                                    values={{ number: expertiseTaskModel.identifier }}
                                />
                            </Typography>
                        </Grid>
                        <ExpertiseTaskHeaderDescription expertiseTaskModel={expertiseTaskModel} />
                    </Grid>
                </Container>
            </TotObjectHeader>
        );
    }
}

export default ExpertiseTaskEditHeader;
