import React from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Button, Container, Grid, Link, Paper, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { TotBackground } from '../../TotBackground';
import { EmailField } from '../../components/fields/EmailField';
import clientRoute from '../../clientRoute';
import { RootStore } from '../../store/RootStore';
import RecoveryPasswordModel from '../../models/authentication/RecoveryPasswordModel';

type OuterProps = {};
type StoreProps = {
    recoveryPasswordModel: RecoveryPasswordModel;
};
type InnerProps = OuterProps & StoreProps;

class RecoveryPasswordPage extends React.Component<InnerProps, {}> {
    render(): JSX.Element {
        const { recoveryPasswordModel } = this.props;
        return (
            <TotBackground withBackdrop={true}>
                <Grid container item direction="column" alignItems="center" justify="center">
                    <Container className="t-recovery-password-page" maxWidth="xs">
                        <Paper elevation={24}>
                            <Box p={12}>
                                <Grid container justify="center">
                                    <Grid item>
                                        <Typography variant="h5" className="t-recovery-title">
                                            <Box fontWeight="fontWeightBold">
                                                <FormattedMessage id="authentication.recoveryTitle" />
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Box pt={8}>
                                    <form noValidate onSubmit={recoveryPasswordModel.sendToken}>
                                        <Grid container spacing={6} direction="column">
                                            <Grid item>
                                                <EmailField model={recoveryPasswordModel} />
                                            </Grid>
                                            <Grid item>
                                                <Typography className="t-recovery-info">
                                                    <FormattedMessage id="authentication.recoveryInfo" />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className="t-recovery"
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    type="submit"
                                                >
                                                    <FormattedMessage id="authentication.sendToken" />
                                                </Button>
                                            </Grid>
                                            <Grid item container justify="center">
                                                <Link
                                                    className="t-login"
                                                    component={RouterLink}
                                                    to={clientRoute.login}
                                                    underline="none"
                                                >
                                                    <FormattedMessage id="authentication.login" />
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Box>
                            </Box>
                        </Paper>
                        {recoveryPasswordModel.sentSucceed && <Redirect to={clientRoute.recoveryPasswordInfoSuccess} />}
                    </Container>
                </Grid>
            </TotBackground>
        );
    }
}

export default compose<InnerProps, OuterProps>(
    inject<RootStore, {}, StoreProps, {}>((rootStore) => ({
        recoveryPasswordModel: rootStore.authenticationStore.recoveryPasswordModel,
    })),
    observer,
)(RecoveryPasswordPage);
