import React from 'react';
import { SubjectModel } from '../../../../models/subject/SubjectModel';
import { observer } from 'mobx-react';
import { TotObjectHeader } from '../../../../components/tot-object/TotObjectHeader';
import { PageHeader } from '../../../../components/PageHeader/PageHeader';
import PageHeaderTitle from './PageHeaderTitle';
import { SubjectHeaderRight } from './SubjectHeaderRight';
import { SubjectHeaderLeft } from './SubjectHeaderLeft';

export type SubjectHeaderProps = {
    subjectModel: SubjectModel;
};

export const SubjectHeader = observer(
    (props: SubjectHeaderProps): JSX.Element => {
        const { subjectModel } = props;
        const { campaign, expertise, title, identifier, metaInfo } = subjectModel;
        const { stateTitle, created } = metaInfo;
        return (
            <TotObjectHeader>
                <PageHeader
                    title={<PageHeaderTitle title={title} />}
                    left={<SubjectHeaderLeft identifier={identifier} stateTitle={stateTitle} created={created} />}
                    right={<SubjectHeaderRight campaign={campaign} expertise={expertise} />}
                />
            </TotObjectHeader>
        );
    },
);
