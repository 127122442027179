import React from 'react';
import { Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ExpertiseLink } from './ExpertiseLink';
import { CuratorInfo } from '../CuratorInfo';
import { RowData } from '../../../../components/PageHeader/RowData';
import { generatePath, NavLink } from 'react-router-dom';
import { getOrElse } from '../../../../utils/getOrElse';
import clientRoute from '../../../../clientRoute';
import { UserNameModel } from '../../../../models/person/UserNameModel';
import { ExpertiseTaskModel } from '../../../../models/expertise-task/ExpertiseTaskModel';
import { entities, permissions } from '../../../../authSchemeConfig';
import { ExpertiseTaskAuthEditField } from '../edit/ExpertiseTaskAuthEditField';
import { ExpertTooltip } from '../ExpertTooltip';
import { AuthorizationCheck } from '../../../../components';

export type ExpertiseTaskHeaderDescriptionRightProps = {
    expertId: UserNameModel['userId'];
    expert: ExpertiseTaskModel['expert'];
    expertCandidateId: UserNameModel['userId'];
    expertiseTaskModel: ExpertiseTaskModel;
    expertCandidate: ExpertiseTaskModel['expertCandidate'];
    toggleIsOpen?: () => void;
};

export const ExpertiseTaskHeaderDescriptionRight = (props: ExpertiseTaskHeaderDescriptionRightProps): JSX.Element => {
    const { expertId, expert, expertCandidateId, expertiseTaskModel, expertCandidate, toggleIsOpen } = props;
    let expertValue = '-';
    let expertWrapper;
    if (expertId) {
        expertValue = expert.name;
        expertWrapper = (children: {} | null): JSX.Element => {
            return (
                <AuthorizationCheck entityCode={entities.User} permCode={permissions.User.View} entityId={expertId}>
                    {(allowed) =>
                        allowed ? (
                            <ExpertiseLink
                                component={NavLink}
                                to={generatePath(clientRoute.user, {
                                    id: expertId,
                                })}
                                underline="none"
                                color="textPrimary"
                            >
                                {children}
                            </ExpertiseLink>
                        ) : (
                            <React.Fragment>{children}</React.Fragment>
                        )
                    }
                </AuthorizationCheck>
            );
        };
    } else if (expertCandidateId) {
        expertValue = expertCandidate.name;
        expertWrapper = (children: {} | null): JSX.Element => {
            return (
                <AuthorizationCheck
                    entityCode={entities.User}
                    permCode={permissions.User.View}
                    entityId={expertCandidateId}
                >
                    {(allowed) =>
                        allowed ? (
                            <ExpertiseLink
                                component={NavLink}
                                to={generatePath(clientRoute.user, {
                                    id: expertCandidateId,
                                })}
                                underline="none"
                                color="textPrimary"
                            >
                                <FormattedMessage
                                    id="expertiseTask.expertCandidate"
                                    values={{
                                        expert: children,
                                    }}
                                />
                            </ExpertiseLink>
                        ) : (
                            <React.Fragment>{children}</React.Fragment>
                        )
                    }
                </AuthorizationCheck>
            );
        };
    }

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item container direction="row" alignItems="center" spacing={2} wrap="nowrap">
                <Grid item>
                    <RowData
                        label={<FormattedMessage id="expertiseTask.fields.expert" />}
                        value={expertValue}
                        valueWrapper={expertWrapper}
                        valueMaxLength={25}
                    />
                </Grid>
                <Grid item>
                    {(Boolean(expertId) || Boolean(expertCandidateId)) && (
                        <ExpertTooltip fullName={expert.fullName || expertCandidate.fullName} />
                    )}
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
                    <Grid item>
                        <RowData
                            label={<FormattedMessage id="expertiseTask.fields.curator" />}
                            value={getOrElse(expertiseTaskModel.curator.name)}
                            valueMaxLength={25}
                        />
                    </Grid>
                    {expertiseTaskModel.curator.name && (
                        <Grid item>
                            <CuratorInfo
                                name={expertiseTaskModel.curator.fullName}
                                contacts={expertiseTaskModel.curatorContacts}
                            />
                        </Grid>
                    )}
                    <ExpertiseTaskAuthEditField
                        toggleIsOpenDialog={toggleIsOpen}
                        messageId={'expertise.changeCurator'}
                        permCode={permissions.ExpertiseTask.UpdateTaskCurator}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
