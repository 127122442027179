import { Sort } from '../list/Sort';
import { DateRangeFilter } from '../list/filter/DateRangeFilter';
import { TableModel, RowsData, TableQueryData } from '../list/TableModel';
import { action, observable } from 'mobx';
import { Api } from '../../store/Api';
import apiConfigs from '../../apiConfigs';
import { IdTitle } from '../IdTitle';
import { DateUtils } from '../../utils/DateUtils';
import { History } from 'history';
import { handleAxiosErrorByResponseStatus } from '../../utils/errorHandleUtils';
import clientRoute from '../../clientRoute';
import { UserNameModel, UserPersonDTO } from '../person/UserNameModel';
import { LikeFilter } from '../list/filter/LikeFilter';
import { InSetFilter } from '../list/filter/InSetFilter';
import { CodeTitle } from '../CodeTitle';
import { GroupedIdTitle } from '../GroupedIdTitle';

// dto записи в таблице заданий на экспертизу
export interface ExpertiseTaskRowDTO {
    id: string;
    identifier: string;
    taskType: CodeTitle;
    expert?: UserPersonDTO;
    expertCandidate?: UserPersonDTO;
    expertise: IdTitle;
    curator?: UserPersonDTO;
    state: string;
    deadline: string;
    created: string;
}

export interface ExpertiseTaskRow {
    id: string;
    identifier: string;
    taskType: CodeTitle;
    expert?: UserNameModel;
    expertCandidate?: UserNameModel;
    expertise: IdTitle;
    curator?: UserNameModel;
    state: string;
    deadline?: Date;
    created?: Date;
}

type ExpertiseTaskFilter = {
    identifier: LikeFilter;
    expert: InSetFilter;
    expertise: LikeFilter;
    curator: InSetFilter;
    state: InSetFilter;
    campaigns: InSetFilter;
    taskType: InSetFilter;
    created: DateRangeFilter;
    deadline: DateRangeFilter;
};

type ExpertiseTaskSort = {
    identifier: Sort;
    expertise: Sort;
    created: Sort;
    deadline: Sort;
};

export class ExpertiseTaskListModel extends TableModel<ExpertiseTaskRow, ExpertiseTaskFilter, ExpertiseTaskSort> {
    @observable protected api: Api;
    @observable history: History;
    @observable taskTypeFilterData: IdTitle[];
    @observable stateFilterData: GroupedIdTitle;
    @observable expertFilterData: IdTitle[];
    @observable curatorFilterData: IdTitle[];
    @observable campaignFilterData: IdTitle[];

    person: UserNameModel;

    constructor(api: Api, history: History) {
        const filter: ExpertiseTaskFilter = {
            identifier: new LikeFilter(),
            taskType: new InSetFilter(),
            expert: new InSetFilter(),
            expertise: new LikeFilter(),
            curator: new InSetFilter(),
            created: new DateRangeFilter(),
            deadline: new DateRangeFilter(),
            state: new InSetFilter(),
            campaigns: new InSetFilter(),
        };
        const sort: ExpertiseTaskSort = {
            identifier: new Sort(),
            expertise: new Sort(),
            created: new Sort('desc'),
            deadline: new Sort(),
        };
        super(filter, sort);

        this.api = api;
        this.history = history;

        this.taskTypeFilterData = [];
        this.stateFilterData = {};
        this.expertFilterData = [];
        this.curatorFilterData = [];
        this.campaignFilterData = [];

        this.getFilterData();
        this.getExpertsFilterData();
        this.getCuratorFilterData();

        this.person = new UserNameModel();
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<ExpertiseTaskRow>> {
        return this.api
            .client(apiConfigs.expertiseTaskListData(queryData))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            )
            .then(({ rows, count }) => ({ rows: rows.map(this.mapDtoToRow), count }));
    }

    @action.bound
    getFilterData(): void {
        this.api
            .client(apiConfigs.loadExpertiseTasksFilterData)
            .then((r) => r.data)
            .then(
                action((data) => {
                    this.stateFilterData = data.statesByProcess;
                    this.taskTypeFilterData = data.taskTypes.map((taskType: CodeTitle) => ({
                        id: taskType.code,
                        title: taskType.title,
                    }));
                    this.campaignFilterData = data.campaigns.map((campaign: IdTitle) => ({
                        id: campaign.id,
                        title: campaign.title,
                    }));
                }),
            );
    }

    @action.bound
    getExpertsFilterData(): void {
        this.api
            .client(apiConfigs.loadExpertFilterData)
            .then((r) => r.data)
            .then(
                action((data) => {
                    this.expertFilterData = data.map((expert: CodeTitle) => ({
                        id: expert.code,
                        title: expert.title,
                    }));
                }),
            );
    }

    @action.bound
    getCuratorFilterData(): void {
        this.api
            .client(apiConfigs.loadManagerFilterData)
            .then((r) => r.data)
            .then(
                action((data) => {
                    this.curatorFilterData = data;
                }),
            );
    }

    @action.bound
    mapDtoToRow(dto: ExpertiseTaskRowDTO): ExpertiseTaskRow {
        const deadline = new Date(dto.deadline);
        const created = new Date(dto.created);
        const parsedFields = {
            ...(DateUtils.isValidDate(created) && { created }),
            ...(DateUtils.isValidDate(deadline) && { deadline }),
        };

        const expert = dto.expert && new UserNameModel().load(dto.expert);
        const expertCandidate = dto.expertCandidate && new UserNameModel().load(dto.expertCandidate);
        const curator = dto.curator && new UserNameModel().load(dto.curator);

        return {
            id: dto.id,
            identifier: dto.identifier,
            taskType: dto.taskType,
            state: dto.state,
            expertise: dto.expertise,
            curator,
            expert,
            expertCandidate,
            ...parsedFields,
        };
    }
}
