import { observable, action, reaction, IReactionDisposer } from 'mobx';
import { Api } from '../../store/Api';
import { TableModel, TableQueryData, RowsData } from '../list/TableModel';
import apiConfigs from '../../apiConfigs';
import { LikeFilter } from '../list/filter/LikeFilter';
import { InSetFilter } from '../list/filter/InSetFilter';
import { Sort } from '../list/Sort';
import { ChangeEvent } from 'react';
import { RootStore } from '../../store/RootStore';
import { IdTitle } from '../IdTitle';
import { CodeTitle } from '../CodeTitle';

export interface SelectionOfExpertRow {
    id: string;
    name: string;
    birthDate?: string;
    fullNameEmplOrg?: string;
    academicDegree?: string;
    specialization?: string;
    login?: string;
    tasksInProgress: number;
    completedTasks: number;
    rejectedTasks: number;
    notCompletedTasks: number;
    checked: boolean;
}

export interface SelectionOfExpertRowDTO {
    id: string;
    name: string;
    birthDate?: string;
    fullNameEmplOrg?: string;
    academicDegree?: string;
    specialization?: string;
    login?: string;
    tasksInProgress: number;
    completedTasks: number;
    rejectedTasks: number;
    notCompletedTasks: number;
}

export type SelectionOfExpertFilter = {
    name: LikeFilter;
    academicDegree: InSetFilter;
    specialization: LikeFilter;
    fullNameEmplOrg: LikeFilter;
    birthDate: LikeFilter;
};

export type SelectionOfExpertSorting = {
    name: Sort;
    tasksInProgress: Sort;
    completedTasks: Sort;
    rejectedTasks: Sort;
    notCompletedTasks: Sort;
};

export class SelectionOfExpertListModel extends TableModel<
    SelectionOfExpertRow,
    SelectionOfExpertFilter,
    SelectionOfExpertSorting
> {
    @observable protected api: Api;
    @observable rootStore: RootStore;
    @observable id: string;
    @observable expert: string;
    @observable error: boolean;
    @observable disposer: IReactionDisposer;
    @observable academicDegreeList: IdTitle[];

    constructor(id: string, rootStore: RootStore) {
        const sort = {
            name: new Sort(),
            tasksInProgress: new Sort(),
            completedTasks: new Sort(),
            rejectedTasks: new Sort(),
            notCompletedTasks: new Sort(),
        };
        const filter = {
            name: new LikeFilter(),
            academicDegree: new InSetFilter(),
            specialization: new LikeFilter(),
            fullNameEmplOrg: new LikeFilter(),
            birthDate: new LikeFilter(),
        };
        super(filter, sort);
        this.api = rootStore.api;
        this.rootStore = rootStore;
        this.id = id;
        this.expert = '';
        this.error = false;

        this.disposer = reaction(
            () => this.expert,
            () => this.changeSelectRow(),
        );

        this.academicDegreeList = [];

        this.getFilterData();
    }

    @action.bound
    disposeAll(): void {
        this.dispose();
        this.disposer();
    }

    @action.bound
    closeAlert(): void {
        this.error = false;
    }

    @action.bound
    changeSelectRow(): void {
        const checkedRow = this.rows.find((row) => row.checked);
        if (checkedRow) {
            checkedRow.checked = false;
        }
        const row = this.rows.find((row) => row.id === this.expert);
        if (row) {
            row.checked = true;
        }
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<SelectionOfExpertRow>> {
        return this.api
            .client(apiConfigs.expertList(queryData, this.id))
            .then((r) => r.data)
            .then(({ rows, count }) => ({ rows: rows.map(this.mapDtoToRow), count }));
    }

    @action.bound
    onSubmit(onClose: () => void): () => void {
        return (): void => {
            if (this.expert) {
                this.setExpertOnTask(this.id, this.expert)
                    .then(() => onClose())
                    .catch(() => (this.error = true));
            } else {
                this.error = true;
            }
        };
    }

    @action.bound
    onChecked(e: ChangeEvent<HTMLInputElement>, checked: boolean): void {
        if (checked) {
            this.expert = e.target.value;
            if (this.error) {
                this.error = false;
            }
        }
    }

    @action.bound
    setExpertOnTask(taskId: string, expertId: string): Promise<void> {
        return this.api.client(apiConfigs.setExpertOnTask(taskId, expertId)).then((r) => r.data);
    }

    @action.bound
    getFilterData(): void {
        this.api
            .client(apiConfigs.academicDegreeCatalogList())
            .then((r) => r.data)
            .then(
                action((data) => {
                    this.academicDegreeList = data.map((academicDegree: CodeTitle) => ({
                        id: academicDegree.code,
                        title: academicDegree.title,
                    }));
                }),
            );
    }

    @action.bound
    mapDtoToRow(dto: SelectionOfExpertRowDTO): SelectionOfExpertRow {
        return {
            id: dto.id,
            name: dto.name,
            birthDate: dto.birthDate || '',
            fullNameEmplOrg: dto.fullNameEmplOrg || '',
            academicDegree: dto.academicDegree || '',
            specialization: dto.specialization || '',
            login: dto.login || '',
            tasksInProgress: dto.tasksInProgress,
            completedTasks: dto.completedTasks,
            rejectedTasks: dto.rejectedTasks,
            notCompletedTasks: dto.notCompletedTasks,
            checked: false,
        };
    }
}
