import React from 'react';
import { observer } from 'mobx-react';
import { UserPersonModel } from '../../../models/users/UserPersonModel';
import { BottomControlPanel } from '../../../components/BottomControlPanel';
import ControlPanelLeft from './ControlPanelLeft';
import ControlPanelRight from './ControlPanelRight';

type UserControlPanelProps = {
    userPersonModel: UserPersonModel;
    saveForm: () => Promise<void>;
};

class UserControlPanel extends React.Component<UserControlPanelProps, {}> {
    render(): JSX.Element {
        const { id } = this.props.userPersonModel;
        return (
            <BottomControlPanel
                left={<ControlPanelLeft id={id} />}
                right={<ControlPanelRight id={id} saveForm={this.props.saveForm} />}
            />
        );
    }
}

export default observer(UserControlPanel);
