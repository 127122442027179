import React, { ReactNode, useEffect, useMemo } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    Dialog,
    DialogContent,
    FormControlLabel,
    Grid,
    LinearProgress,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    useTheme,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as RadioIcon } from '../../resources/images/icons/radio-icon.svg';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { SelectionOfExpertListModel } from '../../models/expertise-task/SelectionOfExpertListModel';
import SelectionOfExpertFilter from './SelectionOfExpertFilter';
import { TotTablePagination } from '../../components/TotTablePagination';
import { EllipsisBox } from './EllipsisBox';
import { Alert } from '@material-ui/lab';
import { checkboxProps } from '../../constants/registryFilterInputs';
import { useStore } from '../../hooks/useStore';

type RouterProps = {
    id: string;
};

export type SelectionOfExpertiseDialogProps = {
    onClose: () => void;
    onSubmit: () => void;
};

const parseDate = (date?: string): Date | undefined => {
    return date ? new Date(date) : undefined;
};

export const SelectionOfExpertDialog = observer(
    (props: SelectionOfExpertiseDialogProps): JSX.Element => {
        const intl = useIntl();
        const { id } = useParams<RouterProps>();
        const rootStore = useStore();
        const tableModel = useMemo(() => new SelectionOfExpertListModel(id, rootStore), [id, rootStore]);
        const theme = useTheme();

        const { sorting } = tableModel;
        const { onClose, onSubmit } = props;
        const lightBorder = theme?.variables.lightFormBorder;

        useEffect(() => {
            return (): void => tableModel.disposeAll();
        }, [tableModel]);

        const renderTableRow = (model: SelectionOfExpertListModel): ReactNode[] => {
            return model.rows.map((o) => {
                return (
                    <TableRow
                        style={o.checked ? { backgroundColor: theme?.variables.palette.table.checked } : {}}
                        key={o.id}
                        hover
                    >
                        <TableCell>{o.name}</TableCell>
                        <TableCell>{parseDate(o.birthDate)?.toLocaleDateString()}</TableCell>
                        <TableCell>{o.fullNameEmplOrg}</TableCell>
                        <TableCell>{o.academicDegree && <EllipsisBox text={o.academicDegree} />}</TableCell>
                        <TableCell>{o.specialization && <EllipsisBox text={o.specialization} />}</TableCell>
                        <TableCell>{o.tasksInProgress}</TableCell>
                        <TableCell style={{ color: theme?.variables.palette.green }}>{o.completedTasks}</TableCell>
                        <TableCell>{o.rejectedTasks}</TableCell>
                        <TableCell style={{ color: theme?.variables.palette.red }}>{o.notCompletedTasks}</TableCell>
                        <TableCell>{o.login}</TableCell>
                        <TableCell>
                            <Radio
                                value={o.id}
                                onChange={model.onChecked}
                                checked={o.checked}
                                color="secondary"
                                checkedIcon={<RadioIcon />}
                                icon={<RadioIcon />}
                            />
                        </TableCell>
                    </TableRow>
                );
            });
        };

        return (
            <Dialog maxWidth="xl" fullWidth open={true}>
                <DialogContent>
                    <Box borderBottom={lightBorder}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Typography variant="h5" className="t-new-expertise-title">
                                    <Box pl={6.5} pt={8} pb={7.25} fontWeight="fontWeightBold">
                                        <FormattedMessage id="expertiseTask.selectionOfExpert.title" />
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Box pl={6} pt={2}>
                                    {/* Чекбокс не стал ни к чему прикручивать, т.к он пока не играет роли */}
                                    <FormControlLabel
                                        control={<Checkbox {...checkboxProps} />}
                                        label={intl.formatMessage({
                                            id: 'expertiseTask.selectionOfExpert.entireRegistry',
                                        })}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box pt={4}>
                        <SelectionOfExpertFilter model={tableModel} />
                    </Box>

                    <Box pl={6} pr={6} pb={4}>
                        <Collapse in={tableModel.error} timeout="auto">
                            <Alert variant="standard" severity="error" onClose={tableModel.closeAlert}>
                                <FormattedMessage
                                    id={
                                        tableModel.expert
                                            ? 'expertiseTask.prohibitedErrorText'
                                            : 'expertiseTask.errorText'
                                    }
                                />
                            </Alert>
                        </Collapse>
                    </Box>

                    <TableContainer>
                        {tableModel.isLoading && <LinearProgress />}
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '10%' }}>
                                        <TableSortLabel
                                            active={sorting.name.isActive}
                                            direction={sorting.name.direction}
                                            onClick={tableModel.changeSorting(sorting.name)}
                                            IconComponent={ExpandMoreIcon}
                                        >
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.selectionOfExpert.fields.name" />
                                            </Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography>
                                            <FormattedMessage id="expertiseTask.selectionOfExpert.fields.birthDate" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography>
                                            <FormattedMessage id="expertiseTask.selectionOfExpert.fields.fullNameEmplOrg" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography>
                                            <FormattedMessage id="expertiseTask.selectionOfExpert.fields.degree" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography>
                                            <FormattedMessage id="expertiseTask.selectionOfExpert.fields.specialization" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '8%' }}>
                                        <TableSortLabel
                                            active={sorting.tasksInProgress.isActive}
                                            direction={sorting.tasksInProgress.direction}
                                            onClick={tableModel.changeSorting(sorting.tasksInProgress)}
                                            IconComponent={ExpandMoreIcon}
                                        >
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.selectionOfExpert.fields.tasksInProgress" />
                                            </Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{ width: '8%' }}>
                                        <TableSortLabel
                                            active={sorting.completedTasks.isActive}
                                            direction={sorting.completedTasks.direction}
                                            onClick={tableModel.changeSorting(sorting.completedTasks)}
                                            IconComponent={ExpandMoreIcon}
                                        >
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.selectionOfExpert.fields.completedTasks" />
                                            </Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{ width: '8%' }}>
                                        <TableSortLabel
                                            active={sorting.rejectedTasks.isActive}
                                            direction={sorting.rejectedTasks.direction}
                                            onClick={tableModel.changeSorting(sorting.rejectedTasks)}
                                            IconComponent={ExpandMoreIcon}
                                        >
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.selectionOfExpert.fields.rejections" />
                                            </Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{ width: '8%' }}>
                                        <TableSortLabel
                                            active={sorting.notCompletedTasks.isActive}
                                            direction={sorting.notCompletedTasks.direction}
                                            onClick={tableModel.changeSorting(sorting.notCompletedTasks)}
                                            IconComponent={ExpandMoreIcon}
                                        >
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.selectionOfExpert.fields.notCompletedTasks" />
                                            </Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography>
                                            <FormattedMessage id="expertiseTask.selectionOfExpert.fields.login" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '8%' }} />
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderTableRow(tableModel)}</TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TotTablePagination
                                        style={{ border: 'none' }}
                                        count={tableModel.rowsCount}
                                        page={tableModel.pageNumber}
                                        onChangePage={tableModel.onChangePage}
                                        onChangeRowsPerPage={tableModel.onChangePageSize}
                                        rowsPerPage={tableModel.pageSize}
                                        rowsPerPageOptions={tableModel.pageSizeOptions}
                                        labelRowsPerPage={intl.formatMessage({ id: 'common.rowsPerPage' })}
                                        labelDisplayedRows={(p): string =>
                                            intl.formatMessage(
                                                { id: 'expertiseTask.selectionOfExpert.registryPagingInfo' },
                                                { to: p.to, from: p.from, count: p.count },
                                            )
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <Box borderTop={lightBorder} pt={6} pb={6} pl={30.75} pr={30.75}>
                        <Grid container spacing={10} justify="center">
                            <Grid style={{ width: '35%' }} item>
                                <Button size="large" fullWidth variant="contained" onClick={onClose}>
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                            </Grid>
                            <Grid style={{ width: '35%' }} item>
                                <Button
                                    size="large"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={tableModel.onSubmit(onSubmit)}
                                >
                                    <FormattedMessage id="expertiseTask.selectionOfExpert.buttonLabel" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    },
);
