import React from 'react';
import { Grid } from '@material-ui/core';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { RowData } from '../../../components/PageHeader/RowData';
import { getOrElse } from '../../../utils/getOrElse';
import TemplatesOfExpertiseModel from '../../../models/templates-of-expertise/TemplatesOfExpertiseModel';

interface Props {
    created: TemplatesOfExpertiseModel['metaInfo']['created'];
    stateTitle: TemplatesOfExpertiseModel['metaInfo']['stateTitle'];
}

const PageHeaderLeft = ({ created, stateTitle }: Props): JSX.Element => (
    <React.Fragment>
        <Grid item>
            <RowData
                label={<FormattedMessage id="templatesOfExpertise.fields.created" />}
                value={getOrElse(created, <FormattedDate value={created} />)}
            />
        </Grid>
        <Grid item>
            <RowData
                label={<FormattedMessage id="templatesOfExpertise.fields.state" />}
                value={getOrElse(stateTitle, stateTitle, '-')}
            />
        </Grid>
    </React.Fragment>
);

export default PageHeaderLeft;
