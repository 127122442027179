import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

export type WithLoadingProps = {
    isLoading: boolean;
    disableLoading: () => void;
    enableLoading: () => void;
};

export function withLoading<P extends object = {}>(
    WrappedComponent: React.ComponentType<P>,
): React.ComponentClass<P & WithLoadingProps, {}> {
    type OuterProps = P & WithLoadingProps;
    type InnerProps = OuterProps;

    class ComponentWithLoading extends React.Component<InnerProps, {}> {
        @observable isLoading = false;

        @action
        disableLoading = (): void => {
            this.isLoading = false;
        };

        @action
        enableLoading = (): void => {
            this.isLoading = true;
        };

        render(): JSX.Element {
            return (
                <WrappedComponent
                    {...this.props}
                    enableLoading={this.enableLoading}
                    disableLoading={this.disableLoading}
                    isLoading={this.isLoading}
                />
            );
        }
    }

    return compose<InnerProps, OuterProps>(observer)(ComponentWithLoading);
}
