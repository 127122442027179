import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { Route, Switch, generatePath, NavLink } from 'react-router-dom';
import { AuthorizationCheck } from '../../../../components/AuthorizationCheck';
import clientRoute from '../../../../clientRoute';
import { entities, permissions } from '../../../../authSchemeConfig';
import { TotObjectDeleteButton } from '../../../../components/tot-object/TotObjectActionsBar';
import { FormattedMessage } from 'react-intl';
import { IconButtonLarge } from '../../../../components/buttons/IconButtonLarge';
import { ReactComponent as EditIcon } from '../../../../resources/images/icons/edit.svg';
import { SubjectPageRouteParams } from '../SubjectPage';
import { SubjectModel } from '../../../../models/subject/SubjectModel';

interface Props {
    id: SubjectPageRouteParams['id'];
    title: SubjectModel['title'];
    deleteSubject: () => Promise<void>;
}

const ControlPanelLeft = ({ id, title, deleteSubject }: Props): JSX.Element => (
    <Grid spacing={3} alignItems="center" justify="center" container>
        <Switch>
            <Route key={clientRoute.subject} path={clientRoute.subject} exact>
                <AuthorizationCheck
                    entityCode={entities.ExpertiseSubject}
                    permCode={permissions.ExpertiseSubject.Delete}
                    entityId={id}
                >
                    <Grid item>
                        <TotObjectDeleteButton
                            id="delete-subject"
                            title={<FormattedMessage id="common.confirmDeletion" />}
                            message={<FormattedMessage id="subject.confirmDeletionInfoText" values={{ title }} />}
                            onConfirm={deleteSubject}
                            wrappedComponentProps={{
                                tooltipTitleId: 'subject.actions.deleteSubject',
                            }}
                        />
                    </Grid>
                </AuthorizationCheck>
                <AuthorizationCheck
                    entityCode={entities.ExpertiseSubject}
                    permCode={permissions.ExpertiseSubject.Edit}
                    entityId={id}
                >
                    <Grid item>
                        <Tooltip title={<FormattedMessage id="subject.actions.editSubject" />}>
                            <div>
                                <IconButtonLarge
                                    component={NavLink}
                                    to={generatePath(clientRoute.subjectEdit, {
                                        id,
                                    })}
                                >
                                    <EditIcon />
                                </IconButtonLarge>
                            </div>
                        </Tooltip>
                    </Grid>
                </AuthorizationCheck>
            </Route>
        </Switch>
    </Grid>
);

export default ControlPanelLeft;
