import React from 'react';
import { Grid, Box, Chip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { RowData } from '../../../components/PageHeader/RowData';
import { UserPersonModel } from '../../../models/users/UserPersonModel';

interface Props {
    userPersonModel: UserPersonModel;
}

const PageHeaderLeft = ({ userPersonModel }: Props): JSX.Element => (
    <React.Fragment>
        <Grid item>
            <RowData
                label={<FormattedMessage id="users.fields.roles" />}
                value={userPersonModel.roles.map((r) => (
                    <Box pr={1} component="span" key={r.code}>
                        <Chip label={r.title} />
                    </Box>
                ))}
            />
            {userPersonModel.blockReason && (
                <RowData
                    label={<FormattedMessage id="users.blockReason" />}
                    value={<Box whiteSpace={'normal'}>{userPersonModel.blockReason}</Box>}
                />
            )}
        </Grid>
    </React.Fragment>
);

export default PageHeaderLeft;
