import React, { useState } from 'react';
import { Box, Popper, Paper, Fade, withTheme } from '@material-ui/core';
import styled from 'styled-components';

const FormTitleTooltipArea = withTheme(styled(Box)`
    background: transparent;
    width: calc(100% - 180px);
    height: 32px;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    padding: ${({ theme }): string => `${theme.spacing(4)}px 0 0 ${theme.spacing(2)}px`};
`);

export type FormTitleInfoProps = {
    title: string;
};

export const FormTitleInfo = (props: FormTitleInfoProps): JSX.Element => {
    const { title } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const setAnchorElCallback = (e: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(e.currentTarget);
    };

    const deleteAnchorEl = (): void => {
        setAnchorEl(null);
    };

    return (
        <FormTitleTooltipArea onPointerEnter={setAnchorElCallback} onPointerLeave={deleteAnchorEl}>
            <Popper
                open={!!title && !!anchorEl}
                anchorEl={anchorEl}
                transition
                style={{ zIndex: 1102, maxWidth: '400px' }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={200}>
                        <Paper>
                            <Box padding={3}>{title}</Box>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </FormTitleTooltipArea>
    );
};
