import withConfirmDialog from '../../../../hocs/withConfirmDialog';
import { BlockUnblockProps } from '../BlockUnblockProps';
import React from 'react';
import { UserBlockUnblockButton } from './UserBlockUnblockButton';

export const UserBlockUnblockButtonWithConfirm = withConfirmDialog<BlockUnblockProps>(
    (props): JSX.Element => (
        <UserBlockUnblockButton
            allowed={props.allowed}
            isBlocked={props.isBlocked}
            setModalIsOpen={props.openConfirmDialog}
        />
    ),
);
