import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { SubjectModel } from '../../../../models/subject/SubjectModel';

interface Props {
    title: SubjectModel['title'];
}

const PageHeaderTitle = ({ title }: Props): JSX.Element => (
    <Grid item>
        <Typography variant="h1">{title}</Typography>
    </Grid>
);

export default PageHeaderTitle;
