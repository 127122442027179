import { action, computed, observable } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { RootStore } from './RootStore';
import LoginModel from '../models/authentication/LoginModel';
import RegistrationModel from '../models/authentication/RegistrationModel';
import RegistrationConfirmModel from '../models/authentication/RegistrationConfirmModel';
import RecoveryPasswordModel from '../models/authentication/RecoveryPasswordModel';
import NewPasswordModel from '../models/authentication/NewPasswordModel';
import { Api } from './Api';
import apiConfigs from '../apiConfigs';
import { AxiosPromise } from 'axios';

export class AuthenticationStore {
    @observable private rootStore: RootStore;
    @observable protected api: Api;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get loginModel(): LoginModel {
        return new LoginModel(this.rootStore.api, this.rootStore.intlStore, this.rootStore.personStore);
    }

    @computed
    get registrationModel(): RegistrationModel {
        return new RegistrationModel(this.rootStore.api, this.rootStore.intlStore);
    }

    @computed
    get registrationConfirmModel(): RegistrationConfirmModel {
        return new RegistrationConfirmModel(this.rootStore.api, this.rootStore.intlStore);
    }

    @computed
    get recoveryPasswordModel(): RecoveryPasswordModel {
        return new RecoveryPasswordModel(this.rootStore.api, this.rootStore.intlStore);
    }

    @computed
    get newPasswordModel(): NewPasswordModel {
        return new NewPasswordModel(this.rootStore.api, this.rootStore.intlStore);
    }

    @action.bound
    esiaGetAuthUrl(): Promise<string> {
        return this.api.client(apiConfigs.esiaAuthUrl).then((r) => r.data);
    }

    @action.bound
    esiaLogin(search: string): Promise<void> {
        return this.api.client(apiConfigs.esiaLogin(search)).then((r) => r.data);
    }

    @asyncAction
    @action.bound
    logout(): AxiosPromise<void> {
        return this.api.client(apiConfigs.logout);
    }
}
