import React from 'react';
import { observer } from 'mobx-react';
import { BottomControlPanel } from '../../../../../components/BottomControlPanel';
import ControlPanelRight from './ControlPanelRight';

export type ExpertiseTaskControlPanelProps = {
    onSubmit: () => void;
    goBack: () => void;
};

export const ExpertiseTaskControlPanel = observer(
    (props: ExpertiseTaskControlPanelProps): JSX.Element => {
        const { onSubmit, goBack } = props;
        return <BottomControlPanel right={<ControlPanelRight goBack={goBack} onSubmit={onSubmit} />} />;
    },
);
