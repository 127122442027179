const expertiseTask: Record<string, string | Record<string, string | Record<string, string>>> = {
    listTitle: 'Задания на экспертизу',
    create: 'Создать задание',
    new: 'Новое задание',
    title: 'Задание на экспертизу № {number}',
    saveWarning: 'Не забудьте нажать кнопку “Сохранить”!',
    fields: {
        number: 'Номер',
        taskType: 'Тип задания',
        expert: 'Эксперт',
        expertiseNumber: 'Номер экспертизы',
        curator: 'Куратор',
        created: 'Дата создания',
        deadline: 'Срок выполнения',
        state: 'Состояние',
        expertisePlanEntry: 'Запись плана экспертизы',
        expertise: 'Экспертиза',
        viewPoint: 'Точка зрения экспертизы',
        campaign: 'Кампания',
        started: 'Дата начала',
        withoutExpert: 'Задания без эксперта',
    },
    editDeadline: 'Изменить срок выполнения',
    editStarted: 'Изменить дату начала',
    curator: {
        name: 'ФИО',
        phone: 'Телефон',
        email: 'Email',
    },
    expertCandidate: '{expert} (подобран)',
    expertiseTasksTable: {
        fields: {
            number: 'Номер задания',
            planEntry: 'Запись плана',
            expert: 'Эксперт',
            deadline: 'Срок выполнения',
            responseTime: 'Срок ответа',
            actualDateOfTheExecution: 'Фактическая дата выполнения',
            stateTitle: 'Состояние',
        },
        daysPlan: 'д (план)',
    },
    selectExpertButton: 'Назначить эксперта',
    selectionOfExpert: {
        title: 'Подбор экспертов',
        entireRegistry: 'Весь реестр',
        buttonLabel: 'Подобрать',
        fields: {
            name: 'ФИО',
            birthDate: 'Дата рождения',
            fullNameEmplOrg: 'Место работы',
            degree: 'Ученая степень',
            specialization: 'Специализация',
            login: 'Логин',
            tasksInProgress: 'Задания в работе',
            completedTasks: 'Завершённые задания',
            rejections: 'Отказы',
            notCompletedTasks: 'Невыполненные задания',
        },
        registryPagingInfo:
            'Всего {count, number} эксперт{count, plural, one {} few {а} other {ов}}. ' +
            'Отображены c {from, number} по {to, number}',
        yearOfBirth: 'Год рождения',
    },
    actions: {
        delete: 'Удалить задание',
        edit: 'Редактировать задание',
        returnForRevision: 'Вернуть на доработку',
    },
    headerActions: {
        collapseHeader: 'Свернуть шапку',
        expandHeader: 'Развернуть шапку',
        toggleDescription: 'Описание',
        collapseDescriptionTooltip: 'Свернуть описание',
        expandDescriptionTooltip: 'Развернуть описание',
    },

    registryPagingInfo:
        'Всего {count, number} задани{count, plural, one {е} few {я} other {й}}. ' +
        'Отображены c {from, number} по {to, number}',
    listConfirmDeletionInfoText: 'Вы действительно хотите удалить задание на экспертизу?',
    confirmDeletionInfoText: 'Вы действительно хотите удалить задание на экспертизу № {number}?',
    errorText: 'Для продолжения нужно выбрать эксперта из списка',
    prohibitedErrorText: 'Изменение эксперта запрещено',
    tabs: {
        task: 'Задание',
        subject: 'Объект',
    },
    localConclusions: {
        title: 'Частные заключения',
        fields: {
            report: 'Заключение',
            deadline: 'Срок выполнения',
            completed: 'Факт. дата выполнения',
        },
    },
    invites: {
        agree: 'Согласен с условиями проведения экспертизы',
        agreement:
            'При проведении экспертизы эксперт должен руководствоваться следующими принципами:\n' +
            '• компетентность (соглашаясь на проведение экспертизы эксперт подтверждает, что обладает необходимыми знаниями и опытом для проведения качественной экспертизы в предметной области);\n' +
            '• отсутствие конфликтов интересов и объективность (если эксперт обнаружил, что является аффилированным с исполнителями работ по рецензируемому проекту, то должен незамедлительно уведомить об этом куратора экспертизы);\n' +
            '• конфиденциальность (эксперт должен обеспечивать конфиденциальность любой информации, содержащейся в документах представленных к оценке. Эксперт не вправе разглашать любую информацию, касающуюся оценки заявок третьим лицам);\n' +
            '• независимость (эксперт не может обсуждать процесс проведения экспертизы с любыми третьими лицами (включая исполнителей проектов), кроме кураторов экспертизы).\n' +
            '\n' +
            'Конфликт интересов, в частности, возникает в случаях, если эксперт:\n' +
            '• принимал участие в подготовке заявки на участие в конкурсе, представленной для проведения экспертизы;\n' +
            '• состоял в трудовых отношениях, привлекался к выполнению работ, оказанию услуг участником конкурса, заявка на участие в конкурсе которого представлена для проведения экспертизы;\n' +
            '• может получить непосредственную выгоду в случае, если заявка на участие в конкурсе будет одобрена к финансированию;\n' +
            '• является членом семьи, близким родственником лица, состоящего в трудовых отношениях с участником конкурса, заявка на участие в конкурсе которого представлена для проведения экспертизы;\n' +
            '• находится под влиянием иных обстоятельств, препятствующих объективной оценке заявки на участие в конкурсе, представленной для проведения экспертизы.',
        accept: 'Принять задание',
        reject: 'Отклонить задание',
        rejectReason: 'Причина отклонения',
        otherReason: 'Другое',
    },
    printFormsTable: {
        title: 'Печатные формы документов',
        updateAction: 'Обновить',
        createAction: 'Сгенерировать',
        conclusion: 'Экспертное заключение',
        generationError: 'При генерации печатной формы произошла ошибка',
        noPrintForms: 'Печатные формы отсутствуют',
    },
    switchView: {
        task: 'Задание',
        report: 'Анкета',
    },
    changeStartDate: 'Изменение даты начала',
    changeOfDueDate: 'Изменение срока выполнения',
};

export default expertiseTask;
