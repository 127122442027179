import React, { ReactNode, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    LinearProgress,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { TotTablePagination } from '../../components/TotTablePagination';
import clientRoute from '../../clientRoute';
import TemplatesOfExpertiseListModel, {
    TemplatesOfExpertiseRowDTO,
} from '../../models/templates-of-expertise/TemplatesOfExpertiseListModel';
import { MenuButton } from '../../components/buttons/MenuButton';
import { AuthorizationCheck } from '../../components/AuthorizationCheck';
import { entities, permissions } from '../../authSchemeConfig';
import { DeleteActionMenuItem } from '../../components/DeleteActionMenuItem';
import { ActionsButton } from '../../components/buttons/ActionsButton';
import { useStore } from '../../hooks/useStore';
import { useLoading } from '../../hooks/useLoading';

export const TemplatesOfExpertiseListPage = observer(
    (): JSX.Element => {
        const { api, history, templatesOfExpertiseStore } = useStore();
        const intl = useIntl();
        const { isLoading, enableLoading, disableLoading } = useLoading();
        const tableModel = useMemo(() => new TemplatesOfExpertiseListModel(api, history), [api, history]);

        useEffect(() => {
            return tableModel.dispose;
        }, [tableModel.dispose]);

        const createTemplate = async (): Promise<void> => {
            try {
                enableLoading();
                const id = await templatesOfExpertiseStore.create();
                history.push(generatePath(clientRoute.templateOfExpertiseCreate, { id }));
            } catch (error) {
                disableLoading();
            }
        };

        const deleteTemplate = (templateRow: TemplatesOfExpertiseRowDTO): (() => Promise<void>) => {
            return (): Promise<void> => {
                return templatesOfExpertiseStore.delete(templateRow.id).then(tableModel.reloadData);
            };
        };

        const renderActionItems = (templateRow: TemplatesOfExpertiseRowDTO): (() => ReactNode[]) => {
            return (): ReactNode[] => [
                <DeleteActionMenuItem
                    id="delete"
                    key="delete"
                    wrappedComponentProps={{ tabIndex: 1 }}
                    title={<FormattedMessage id="common.confirmDeletion" />}
                    message={
                        <FormattedMessage
                            id="templatesOfExpertise.confirmDeletionInfoText"
                            values={{ title: templateRow.title }}
                        />
                    }
                    onConfirm={deleteTemplate(templateRow)}
                />,
            ];
        };

        const renderActions = (templateRow: TemplatesOfExpertiseRowDTO): JSX.Element => {
            return (
                <MenuButton
                    disablePortal={true}
                    renderButton={ActionsButton}
                    renderMenuItems={renderActionItems(templateRow)}
                />
            );
        };

        const renderBody = (): JSX.Element[] => {
            return tableModel.rows.map((t) => {
                const { id } = t;
                return (
                    <TableRow key={id} hover>
                        <TableCell>
                            <Link
                                component={NavLink}
                                underline="none"
                                to={generatePath(clientRoute.templateOfExpertise, { id })}
                            >
                                {t.title}
                            </Link>
                        </TableCell>
                        <TableCell>
                            <FormattedDate value={t.created} />
                        </TableCell>
                        <TableCell>{t.state}</TableCell>
                        <TableCell>
                            <AuthorizationCheck
                                entityCode={entities.TemplateExpertise}
                                entityId={t.id}
                                permCode={permissions.TemplateExpertise.Delete}
                            >
                                {renderActions(t)}
                            </AuthorizationCheck>
                        </TableCell>
                    </TableRow>
                );
            });
        };

        return (
            <Container maxWidth="lg">
                <Box pt={5} pb={5}>
                    <Grid container direction="column">
                        <Box pb={10}>
                            <Grid item container direction="row" justify="space-between">
                                <Grid item>
                                    <Typography variant="h1">
                                        <FormattedMessage id="templatesOfExpertise.listTitle" />
                                    </Typography>
                                </Grid>
                                <AuthorizationCheck
                                    entityCode={entities.System}
                                    permCode={permissions.System.AddTemplateExpertise}
                                >
                                    <Grid item>
                                        <Button
                                            disabled={isLoading}
                                            color="primary"
                                            variant="contained"
                                            onClick={createTemplate}
                                        >
                                            <FormattedMessage id="templatesOfExpertise.create" />
                                        </Button>
                                    </Grid>
                                </AuthorizationCheck>
                            </Grid>
                        </Box>
                        <Grid item>
                            <TableContainer component={Paper}>
                                {tableModel.isLoading && <LinearProgress />}
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '34%' }}>
                                                <Typography>
                                                    <FormattedMessage id="templatesOfExpertise.fields.title" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: '33%' }}>
                                                <Typography>
                                                    <FormattedMessage id="templatesOfExpertise.fields.created" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: '33%' }}>
                                                <Typography>
                                                    <FormattedMessage id="templatesOfExpertise.fields.state" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{renderBody()}</TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TotTablePagination
                                                count={tableModel.rowsCount}
                                                page={tableModel.pageNumber}
                                                onChangePage={tableModel.onChangePage}
                                                onChangeRowsPerPage={tableModel.onChangePageSize}
                                                rowsPerPage={tableModel.pageSize}
                                                rowsPerPageOptions={tableModel.pageSizeOptions}
                                                labelRowsPerPage={intl.formatMessage({ id: 'common.rowsPerPage' })}
                                                labelDisplayedRows={(p): string =>
                                                    intl.formatMessage(
                                                        { id: 'templatesOfExpertise.registryPagingInfo' },
                                                        { to: p.to, from: p.from, count: p.count },
                                                    )
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        );
    },
);
