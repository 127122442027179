import React from 'react';
import { observer } from 'mobx-react';
import { entities, permissions } from '../../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../../components/AuthorizationCheck';
import { FormView } from '@platform/formiojs-react';
import { ExpertiseTaskServiceInfo } from '../ExpertiseTaskServiceInfo';
import { ExpertiseTaskViewModel, ReportsRow } from '../../../../models/expertise-task/ExpertiseTaskViewModel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Box,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { ExpertiseTaskPrintForms as ExpertiseTaskPrintFormsInj } from './print-forms/ExpertiseTaskPrintForms';
import { useStore } from '../../../../hooks';
import { di } from 'react-magnetic-di';

export type ExpertiseTaskViewProps = {
    taskViewModel: ExpertiseTaskViewModel;
};

export const ExpertiseTaskView = observer(
    (props: ExpertiseTaskViewProps): JSX.Element => {
        const [ExpertiseTaskPrintForms] = di([ExpertiseTaskPrintFormsInj], ExpertiseTaskView);
        const { taskModel, reportsTable } = props.taskViewModel;
        const { intlStore } = useStore();
        const { locale } = intlStore;

        const renderReportsBody = (reportsTable: ReportsRow[]): JSX.Element[] => {
            return reportsTable.map((report) => {
                const { deadline, completed } = report;
                return (
                    <TableRow key={report.report.id} hover>
                        <TableCell>
                            <Link underline="none">{report.report.title}</Link>
                        </TableCell>
                        <TableCell>{deadline && <FormattedDate value={deadline} />}</TableCell>
                        <TableCell>{completed && <FormattedDate value={completed} />}</TableCell>
                    </TableRow>
                );
            });
        };

        return (
            <React.Fragment>
                <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
                    <ExpertiseTaskServiceInfo metaInfoModel={taskModel.metaInfo} />
                </AuthorizationCheck>
                <FormView locale={locale} form={taskModel.formModel} multiLang={true} />
                {reportsTable && (
                    <ExpansionPanel defaultExpanded elevation={0}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h3">
                                <FormattedMessage id="expertiseTask.localConclusions.title" />
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ padding: 0 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '33.3%' }}>
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.localConclusions.fields.report" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: '33.3%' }}>
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.localConclusions.fields.deadline" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: '33.3%' }}>
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.localConclusions.fields.completed" />
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderReportsBody(reportsTable)}</TableBody>
                            </Table>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
                <Box mt={5} mb={5}>
                    <ExpertiseTaskPrintForms />
                </Box>
            </React.Fragment>
        );
    },
);
