import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { FormView } from '@platform/formiojs-react';
import { ExpertiseTaskSubjectModel } from '../../../../models/expertise-task/ExpertiseTaskSubjectModel';
import { ExpertiseTaskRouteParams } from './ExpertiseTaskViewPage';
import { AuthorizationCheck } from '../../../../components/AuthorizationCheck';
import { entities, permissions } from '../../../../authSchemeConfig';
import { FormattedMessage } from 'react-intl';
import { Box, Grid } from '@material-ui/core';
import { FilesDownload } from '../../../../components/files/FilesDownload';
import { useStore } from '../../../../hooks';

export type ExpertiseTaskSubjectProps = {
    taskId?: string;
    buttonsSpacing?: boolean;
    isOnlyFiles?: boolean;
};

export const ExpertiseTaskSubject = observer(
    (props: ExpertiseTaskSubjectProps): JSX.Element => {
        const { taskId, buttonsSpacing, isOnlyFiles } = props;
        const { expertiseTaskStore, subjectStore, intlStore } = useStore();
        const { locale } = intlStore;
        const { id } = useParams<ExpertiseTaskRouteParams>();
        const subjectModelId = taskId || id;
        const [subjectModel] = useState<ExpertiseTaskSubjectModel>(new ExpertiseTaskSubjectModel(subjectModelId));
        const { hiddenFormModel, subjectId, subjectIdentifier, commonFormModel } = subjectModel;
        const filename = `${intlStore.formatMessage('subject.allFiles', { title: subjectIdentifier })}.zip`;

        useEffect(() => {
            if (isOnlyFiles) {
                expertiseTaskStore.loadTaskSubjectView(id, true).then(subjectModel.load);
            } else {
                expertiseTaskStore.loadTaskSubject(id).then(subjectModel.load);
            }
        }, [expertiseTaskStore, subjectModel.load, id, isOnlyFiles]);

        const downloadAllFiles = useCallback(() => {
            const { subjectId } = subjectModel;
            return subjectStore.downloadSubjectFiles(subjectId);
        }, [subjectModel, subjectStore.downloadSubjectFiles]);

        return (
            <React.Fragment>
                {subjectId && (
                    <AuthorizationCheck
                        entityCode={entities.ExpertiseSubject}
                        entityId={subjectId}
                        permCode={permissions.ExpertiseSubject.DownloadCommonFile}
                    >
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Box mb={2} {...(buttonsSpacing && { mt: 4, mr: 4 })}>
                                    <FilesDownload
                                        onDownloadClick={downloadAllFiles}
                                        filename={filename}
                                        buttonText={<FormattedMessage id="subject.downloadAllFiles" />}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </AuthorizationCheck>
                )}
                <FormView locale={locale} form={commonFormModel} multiLang={true} />
                {hiddenFormModel && <FormView locale={locale} form={hiddenFormModel} multiLang={true} />}
            </React.Fragment>
        );
    },
);
