import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ExpertiseTaskListPage as ExpertiseTaskListPageInj } from './ExpertiseTaskListPage';
import { ExpertiseTaskEditPage as ExpertiseTaskEditPageInj } from './expertise-task-page/edit/ExpertiseTaskEditPage';
import clientRoute from '../../clientRoute';
import { di } from 'react-magnetic-di';
import { observer } from 'mobx-react';

export const ExpertiseTasksPages = observer(
    (): JSX.Element => {
        const [ExpertiseTaskEditPage] = di([ExpertiseTaskEditPageInj], ExpertiseTasksPages);
        const [ExpertiseTaskListPage] = di([ExpertiseTaskListPageInj], ExpertiseTasksPages);
        return (
            <Switch>
                <Route path={clientRoute.expertiseTaskEdit} key={clientRoute.expertiseTaskEdit} exact>
                    <ExpertiseTaskEditPage />
                </Route>
                <Route>
                    <ExpertiseTaskListPage />
                </Route>
            </Switch>
        );
    },
);
