import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import clientRoute from '../../../../clientRoute';
import { SubjectPageRouteParams } from '../SubjectPage';
import { observer } from 'mobx-react';
import { SubjectModel } from '../../../../models/subject/SubjectModel';
import { BottomControlPanel } from '../../../../components/BottomControlPanel';
import ControlPanelLeft from './ControlPanelLeft';
import ControlPanelRight from './ControlPanelRight';
import { useModal, useStore } from '../../../../hooks';

export type SubjectControlPanelProps = {
    subjectModel: SubjectModel;
    onSubmit: () => void;
    onLifeCycleTransition(transitionId: string, requestId: string, validate?: boolean): Promise<void>;
};

export const SubjectControlPanel = observer(
    (props: SubjectControlPanelProps): JSX.Element => {
        const { subjectModel, onSubmit, onLifeCycleTransition } = props;
        const { subjectStore } = useStore();
        const history = useHistory();
        const { id } = useParams<SubjectPageRouteParams>();
        const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();

        const deleteSubject = (): Promise<void> => {
            return subjectStore.deleteSubject(subjectModel.id).then(() => {
                history.push(generatePath(clientRoute.subjects));
            });
        };

        const goToSubjectReadPage = (): void => {
            history.push(generatePath(clientRoute.subject, { id }));
        };

        return (
            <BottomControlPanel
                left={<ControlPanelLeft id={id} title={subjectModel.title} deleteSubject={deleteSubject} />}
                right={
                    <ControlPanelRight
                        onSubmit={onSubmit}
                        setModalIsOpen={setModalIsOpen}
                        id={id}
                        isModalOpen={isModalOpen}
                        subjectModel={subjectModel}
                        setModalIsClosed={setModalIsClosed}
                        goToSubjectReadPage={goToSubjectReadPage}
                        subjectStore={subjectStore}
                        onLifeCycleTransition={onLifeCycleTransition}
                    />
                }
            />
        );
    },
);
