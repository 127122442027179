import React from 'react';
import withConfirmDialog from '../../../../hocs/withConfirmDialog';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export const UserDeleteMenuItemWithConfirm = withConfirmDialog(
    (props): JSX.Element => (
        <MenuItem key="delete-user" dense button={true} tabIndex={2} onClick={props.openConfirmDialog}>
            <FormattedMessage id="common.delete" />
        </MenuItem>
    ),
);
