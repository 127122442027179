import React from 'react';
import withConfirmDialog from '../hocs/withConfirmDialog';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export const DeleteActionMenuItem = withConfirmDialog<{ tabIndex: number }>((props) => (
    <MenuItem dense button={true} tabIndex={props.tabIndex} onClick={props.openConfirmDialog}>
        <FormattedMessage id="common.delete" />
    </MenuItem>
));
