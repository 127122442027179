import React, { useState } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormApi } from '@platform/formiojs-react';
import { ExpertiseTaskViewControlPanel } from './ExpertiseTaskViewControlPanel';
import {
    ExpertiseTaskHeaderActionsDelimiter,
    TaskViewHeaderActionsContainer,
    TaskViewHeaderContainer,
} from './ExpertiseTaskViewPage.styled';
import ToggleHeaderButton from './ToggleHeaderButton';
import ToggleDescriptionButton from './ToggleDescriptionButton';
import { ExpertiseTaskViewHeaderDescription } from './ExpertiseTaskViewHeaderDescription';
import { SwitchExpertiseTaskView, SwitchViewState } from './SwitchExpertiseTaskView';
import { ExpertiseTaskModel } from '../../../../models/expertise-task/ExpertiseTaskModel';
import { ExpertiseTaskEditCurator } from '../ExpertiseTaskEditCurator';
import { ExpertiseTaskEditDate } from '../ExpertiseTaskEditDate';
import { useStore } from '../../../../hooks/useStore';
import { useLoading } from '../../../../hooks/useLoading';

export type ExpertiseTaskViewHeader = {
    taskModel: ExpertiseTaskModel;
    formApi?: FormApi;
    backUrl: string;
    onToggleHeader(): void;
    headerCollapsed: boolean;
    reloadKey: number;
    reloadTaskModel(): void;
    onChangeSwitchViewState?(event: React.ChangeEvent<HTMLInputElement>): void;
    switchViewState?: SwitchViewState;
    setFormIsChanged?: () => void;
    withPaddingRight?: boolean;
};

type routeMatch = {
    params: {
        id: string;
    };
};

export const ExpertiseTaskViewHeader = observer(
    (props: ExpertiseTaskViewHeader): JSX.Element => {
        const intl = useIntl();

        const { expertiseTaskStore } = useStore();

        const { isLoading, enableLoading, disableLoading } = useLoading();

        const [isLoadingSaved, setIsLoadingSaved] = useState<boolean>(false);
        const [status, setStatus] = useState<'success' | 'default' | 'failed'>('default');
        const [descriptionCollapsed, setDescriptionCollapsed] = useState<boolean>(true);
        const [isOpen, setIsOpen] = useState<boolean>(false);
        const [isOpenStartDateDialog, setIsOpenStartDateDialog] = useState<boolean>(false);
        const [isOpenDueDateDialog, setIsOpenDueDateDialog] = useState<boolean>(false);

        const {
            formApi,
            backUrl,
            reloadKey,
            onChangeSwitchViewState,
            switchViewState,
            onToggleHeader,
            headerCollapsed,
            taskModel,
            reloadTaskModel,
            setFormIsChanged,
            withPaddingRight,
        } = props;

        const history = useHistory();
        const match: routeMatch = useRouteMatch();
        const { id } = match.params;

        const toggleIsOpen = (): void => {
            setIsOpen(!isOpen);
        };
        const toggleIsOpenStartDateDialog = (): void => {
            setIsOpenStartDateDialog(!isOpenStartDateDialog);
        };
        const toggleIsOpenDueDateDialog = (): void => {
            setIsOpenDueDateDialog(!isOpenDueDateDialog);
        };

        const changeExpertiseTaskCurator = (curatorUserId: string): void => {
            enableLoading();
            expertiseTaskStore
                .changeCurator(id, curatorUserId)
                .then(() => {
                    reloadTaskModel();
                })
                .finally(() => {
                    disableLoading();
                });
            toggleIsOpen();
        };

        const changeStartedDate = (started: Date): void => {
            enableLoading();
            expertiseTaskStore
                .changeStartedDate(id, started)
                .then(() => {
                    reloadTaskModel();
                })
                .finally(() => {
                    disableLoading();
                });
            toggleIsOpenStartDateDialog();
        };

        const changeDueDate = (deadline: Date): void => {
            enableLoading();
            expertiseTaskStore
                .changeDueDate(id, deadline)
                .then(() => {
                    reloadTaskModel();
                })
                .finally(() => {
                    disableLoading();
                });
            toggleIsOpenDueDateDialog();
        };

        const onSubmit = (needValidate: boolean): Promise<void> => {
            if (formApi) {
                if (needValidate && !formApi.validate()) {
                    return Promise.resolve();
                }
                setIsLoadingSaved(true);
                return expertiseTaskStore
                    .updateTaskReportForm(id, formApi.getSubmission(), formApi.getExpertiseInfo())
                    .then(() => {
                        setStatus('success');
                        setFormIsChanged && setFormIsChanged();
                    })
                    .catch(() => {
                        setStatus('failed');
                    })
                    .finally(() => {
                        setIsLoadingSaved(false);
                        setTimeout(() => setStatus('default'), 3000);
                    });
            }
            return Promise.resolve();
        };

        const onValidateSubmit = (): Promise<void> => {
            return onSubmit(true);
        };

        const onNoValidateSubmit = (): Promise<void> => {
            return onSubmit(false);
        };

        const deleteExpertiseTask = (): Promise<void> => {
            return expertiseTaskStore.delete(id).then(() => {
                history.push(backUrl);
            });
        };

        const validateForm = (onSuccess?: () => Promise<void>): Promise<void> => {
            const isFormValid = (formApi && formApi.validate()) || false;
            if (isFormValid) {
                return onSuccess ? onSuccess() : Promise.resolve();
            }
            return Promise.reject(['validation error']);
        };

        const onLifeCycleTransition = async (
            transitionId: string,
            taskId: string,
            validate?: boolean,
        ): Promise<void> => {
            const { lifeCycleTransition } = expertiseTaskStore;
            const onSuccess = (): Promise<void> => lifeCycleTransition(transitionId, taskId, backUrl);
            await onValidateSubmit();
            if (validate) {
                return validateForm(onSuccess);
            } else {
                return onSuccess();
            }
        };

        const onToggleDescription = (): void => {
            setDescriptionCollapsed(!descriptionCollapsed);
        };

        return (
            <React.Fragment>
                <ExpertiseTaskEditCurator
                    open={isOpen}
                    onCancel={toggleIsOpen}
                    onSubmit={changeExpertiseTaskCurator}
                    isLoading={isLoading}
                />
                {isOpenStartDateDialog && (
                    <ExpertiseTaskEditDate
                        open={isOpenStartDateDialog}
                        onCancel={toggleIsOpenStartDateDialog}
                        onSubmit={changeStartedDate}
                        currentDate={new Date(taskModel.started ? taskModel.started : Date.now())}
                        label={intl.formatMessage({ id: 'expertiseTask.fields.started' }) + '*'}
                        title={<FormattedMessage id="expertiseTask.changeStartDate" />}
                        maxValue={taskModel.deadline ? new Date(taskModel.deadline) : undefined}
                    />
                )}
                {isOpenDueDateDialog && (
                    <ExpertiseTaskEditDate
                        open={isOpenDueDateDialog}
                        onCancel={toggleIsOpenDueDateDialog}
                        onSubmit={changeDueDate}
                        currentDate={new Date(taskModel.deadline ? taskModel.deadline : Date.now())}
                        label={intl.formatMessage({ id: 'expertiseTask.fields.deadline' }) + '*'}
                        title={<FormattedMessage id="expertiseTask.changeOfDueDate" />}
                        minValue={taskModel.started ? new Date(taskModel.started) : undefined}
                    />
                )}
                <TaskViewHeaderContainer withPaddingRight={withPaddingRight} item>
                    <Container maxWidth="lg">
                        <Grid container spacing={3} direction="column" style={{ padding: '8px 0' }}>
                            <Grid item>
                                <Typography variant="h1">
                                    <FormattedMessage
                                        id="expertiseTask.title"
                                        values={{ number: taskModel.identifier }}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                    <TaskViewHeaderActionsContainer item>
                        <Container maxWidth="lg">
                            <Grid container item direction="row" wrap="nowrap">
                                <Grid item style={{ flex: 1, padding: '6px 0' }}>
                                    <ToggleDescriptionButton
                                        onClick={onToggleDescription}
                                        collapsed={descriptionCollapsed}
                                    />
                                </Grid>
                                <Grid item>
                                    <ExpertiseTaskViewControlPanel
                                        key={reloadKey}
                                        expertiseTaskModel={taskModel}
                                        saveProgress={isLoadingSaved}
                                        status={status}
                                        deleteExpertiseTask={deleteExpertiseTask}
                                        onSubmit={onNoValidateSubmit}
                                        onLifeCycleTransition={onLifeCycleTransition}
                                        updateTaskViewPage={reloadTaskModel}
                                    />
                                </Grid>
                                {switchViewState && onChangeSwitchViewState && (
                                    <React.Fragment>
                                        <ExpertiseTaskHeaderActionsDelimiter item />
                                        <Grid item>
                                            <SwitchExpertiseTaskView
                                                viewState={switchViewState}
                                                labels={{
                                                    left: <FormattedMessage id="expertiseTask.switchView.task" />,
                                                    right: <FormattedMessage id="expertiseTask.switchView.report" />,
                                                }}
                                                handleChange={onChangeSwitchViewState}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )}
                                <ExpertiseTaskHeaderActionsDelimiter
                                    item
                                    style={{ marginLeft: '16px', marginRight: '16px' }}
                                />
                                <Grid item style={{ padding: '6px 0' }}>
                                    <ToggleHeaderButton onClick={onToggleHeader} collapsed={headerCollapsed} />
                                </Grid>
                            </Grid>
                        </Container>
                    </TaskViewHeaderActionsContainer>
                    <ExpertiseTaskViewHeaderDescription
                        key={reloadKey}
                        toggleIsOpen={toggleIsOpen}
                        toggleIsOpenStartDateDialog={toggleIsOpenStartDateDialog}
                        toggleIsOpenDueDateDialog={toggleIsOpenDueDateDialog}
                        expertiseTaskModel={taskModel}
                        collapsed={descriptionCollapsed}
                    />
                </TaskViewHeaderContainer>
            </React.Fragment>
        );
    },
);
