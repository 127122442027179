import React, { ReactNode } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, CircularProgress, Paper, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { observable } from 'mobx';
import { fromPromise } from 'mobx-utils';
import { AuthenticationStore } from '../../../store/AuthenticationStore';
import { RootStore } from '../../../store/RootStore';

type OuterProps = {};
type StoreProps = {
    authenticationStore: AuthenticationStore;
};
type InnerProps = OuterProps & StoreProps;

class EsiaGetAuthUrlPage extends React.Component<InnerProps, {}> {
    @observable getAuthUrl = this.props.authenticationStore.esiaGetAuthUrl();

    redirectToAuthUrl = (authUrl: string): null => {
        window.location.href = authUrl;
        return null;
    };

    render(): ReactNode {
        return fromPromise(this.getAuthUrl).case({
            pending: () => <CircularProgress />,
            fulfilled: this.redirectToAuthUrl,
            rejected: () => (
                <Paper elevation={24}>
                    <Box p={12}>
                        <Typography variant="h5">
                            <Box fontWeight="fontWeightBold">
                                <FormattedMessage id="authentication.error" />
                            </Box>
                        </Typography>
                    </Box>
                </Paper>
            ),
        });
    }
}

export default compose<InnerProps, OuterProps>(
    inject<RootStore, {}, StoreProps, {}>((rootStore) => ({
        authenticationStore: rootStore.authenticationStore,
    })),
    observer,
)(EsiaGetAuthUrlPage);
