import React from 'react';
import { Container, createStyles, Grid, makeStyles } from '@material-ui/core';
import LeftSide from './LeftSide';
import RightSide from './RightSide';

const useStyles = makeStyles((theme) =>
    createStyles({
        mainContainer: {
            width: '100%',
            height: '100%',
            padding: `${theme.spacing(3.75)}px 0`,
            boxSizing: 'border-box',
            background: `linear-gradient(90deg, ${theme.variables.palette.appBar.mainContrast} 50%, transparent 50%)`,
        },
        fullHeight: {
            height: '100%',
        },
        leftSide: {
            color: theme.variables.palette.mainContrast,
        },
    }),
);

const HomePage = (): JSX.Element => {
    const classes = useStyles();

    return (
        <div className={classes.mainContainer}>
            <Container maxWidth="lg" className={classes.fullHeight}>
                <Grid container item spacing={10} className={classes.fullHeight}>
                    <Grid item xs={6} className={classes.leftSide}>
                        <LeftSide />
                    </Grid>
                    <Grid item xs={6}>
                        <RightSide />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default HomePage;
