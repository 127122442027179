import { action, observable } from 'mobx';
import { History } from 'history';
import { IntlStore, Locales } from './IntlStore';
import { AuthenticationStore } from './AuthenticationStore';
import { Api } from './Api';
import { PersonStore } from './PersonStore';
import { FormioStore } from './FormioStore';
import { CampaignStore } from './CampaignStore';
import { FormioSidebarStore } from '@platform/formiojs-react';
import { AuthorizationStore } from './AuthorizationStore';
import { SubjectStore } from './SubjectStore';
import { CategoriesOfSubjectsStore } from './CategoriesOfSubjectsStore';
import { TemplatesOfExpertiseStore } from './TemplatesOfExpertiseStore';
import { ExpertiseStore } from './ExpertiseStore';
import { ExpertiseTaskStore } from './ExpertiseTaskStore';
import { UserStore } from './UserStore';
import { CatalogStore } from './CatalogStore';
import { PfTemplateStore } from './PfTemplateStore';
import { PresetStore } from './PresetStore';
import { ModulesStore } from './ModulesStore';
import { AppTheme, ThemeOverrides } from '../themes/types/themeTypes';
import { RootStoreProps } from '../types/stores/rootStore';
import ru from '../resources/locales/ru';
import en from '../resources/locales/en';

type Env = {
    apiUrl?: string;
    totCopyrightRu?: string;
    totCopyrightEn?: string;
    totTel?: string;
    totMail?: string;
    appThemeName?: string;
    formServiceUrl?: string;
    lifeCycleServiceUrl?: string;
    termsOfServiceLinkRu?: string;
    termsOfServiceLinkEn?: string;
    yaMetrikaId?: number;
};

export type Services = {
    formVersionLinks: (formVersionId: string) => string;
    lifecycleProcessLink: (processId?: string) => string;
};

export class RootStore {
    @observable api: Api;
    @observable authenticationStore: AuthenticationStore;
    @observable authorizationStore: AuthorizationStore;
    @observable intlStore: IntlStore;
    @observable history: History;
    @observable personStore: PersonStore;
    @observable formioStore: FormioStore;
    @observable campaignStore: CampaignStore;
    @observable formioSidebarStore: FormioSidebarStore;
    @observable subjectStore: SubjectStore;
    @observable categoriesOfSubjectsStore: CategoriesOfSubjectsStore;
    @observable templatesOfExpertiseStore: TemplatesOfExpertiseStore;
    @observable expertiseStore: ExpertiseStore;
    @observable expertiseTaskStore: ExpertiseTaskStore;
    @observable userStore: UserStore;
    @observable catalogStore: CatalogStore;
    @observable pfTemplateStore: PfTemplateStore;
    @observable presetStore: PresetStore;
    @observable modulesStore: ModulesStore;
    @observable features: RootStoreProps['features'] = {};
    @observable env: Env = {} as Env;
    @observable themeOverrides: ThemeOverrides = {} as ThemeOverrides;
    @observable appTheme?: AppTheme;

    services: Services = {
        formVersionLinks: (formVersionId: string) => {
            return `${this.env.formServiceUrl}/form-version/${formVersionId}`;
        },
        lifecycleProcessLink: (processId?: string) => {
            return `${this.env.lifeCycleServiceUrl}/process/schema-state/list-by-process/${processId}`;
        },
    };

    constructor(props: RootStoreProps) {
        this.setObservables(props);
        this.api = new Api(this);
        this.authenticationStore = new AuthenticationStore(this);
        this.authorizationStore = new AuthorizationStore(this);

        const { locales, history } = props;
        const messages: Locales = {
            ru: { ...ru, ...locales.ru },
            en: { ...en, ...locales.en },
        };
        this.intlStore = new IntlStore(this, messages);
        this.history = history;

        this.personStore = new PersonStore(this);
        this.formioStore = new FormioStore(this);
        this.campaignStore = new CampaignStore(this);
        this.formioSidebarStore = new FormioSidebarStore();
        this.subjectStore = new SubjectStore(this);
        this.categoriesOfSubjectsStore = new CategoriesOfSubjectsStore(this);
        this.templatesOfExpertiseStore = new TemplatesOfExpertiseStore(this);
        this.expertiseStore = new ExpertiseStore(this);
        this.expertiseTaskStore = new ExpertiseTaskStore(this);
        this.userStore = new UserStore(this);
        this.catalogStore = new CatalogStore(this);
        this.pfTemplateStore = new PfTemplateStore(this);
        this.presetStore = new PresetStore(this);
        this.modulesStore = new ModulesStore(this);
    }

    @action.bound
    setObservables(props: RootStoreProps) {
        const { env, features, themeOverrides } = props;
        this.env = env;
        this.features = features;
        this.themeOverrides = themeOverrides;
    }

    @action.bound
    setAppTheme(appTheme: AppTheme) {
        this.appTheme = appTheme;
    }
}
