import { ExpertiseTaskStore } from '../../store/ExpertiseTaskStore';
import { observable, action } from 'mobx';
import { IdTitle } from '../IdTitle';
import { UserNameModel, UserPersonDTO } from '../person/UserNameModel';
import { DateUtils } from '../../utils/DateUtils';
import { CodeTitle } from '../CodeTitle';

export interface ExpertiseTasksRow {
    id: string;
    identifier: string;
    planEntry: IdTitle;
    viewPoint?: string;
    expertName: string;
    expertCandidateName: string;
    acceptance?: Date;
    acceptanceDays: number;
    deadline?: Date;
    planProcessingDays: number;
    completed?: Date;
    state: string;
    taskType?: CodeTitle;
}

// dto записи в блоке заданий на экспертизу на странице экспертизы
export interface ExpertiseTasksRowDTO {
    id: string;
    identifier: string;
    planEntry: IdTitle;
    viewPoint?: string;
    expert?: UserPersonDTO;
    expertCandidate?: UserPersonDTO;
    acceptance?: string;
    acceptanceDays: number;
    deadline?: string;
    planProcessingDays: number;
    completed?: string;
    state: string;
    taskType?: CodeTitle;
}

export class ExpertiseTasksListByExpertiseModel {
    @observable id: string;
    @observable store: ExpertiseTaskStore;
    @observable expertiseTasks: ExpertiseTasksRow[] = [];

    constructor(id: string, store: ExpertiseTaskStore) {
        this.id = id;
        this.store = store;
        this.loadExpertiseTasksList();
    }

    @action.bound
    loadExpertiseTasksList(): void {
        this.store
            .loadExpertiseTasksListByExpertise(this.id)
            .then((tasks) => (this.expertiseTasks = tasks.map(this.mapExpertiseTasksDTO)));
    }

    @action.bound
    deleteTask(id: string): () => Promise<void> {
        return async (): Promise<void> => {
            await this.store.deleteExpertiseTask(id);
            await this.loadExpertiseTasksList();
        };
    }

    @action.bound
    mapExpertiseTasksDTO(dto: ExpertiseTasksRowDTO): ExpertiseTasksRow {
        const acceptance = new Date(dto.acceptance || '');
        const deadline = new Date(dto.deadline || '');
        const completed = new Date(dto.completed || '');
        const expertName = dto.expert ? new UserNameModel().load(dto.expert).name : '';
        const expertCandidateName = dto.expertCandidate ? new UserNameModel().load(dto.expertCandidate).name : '';

        const dateDTO = {
            ...(DateUtils.isValidDate(acceptance) && { acceptance }),
            ...(DateUtils.isValidDate(deadline) && { deadline }),
            ...(DateUtils.isValidDate(completed) && { completed }),
        };

        return {
            id: dto.id,
            identifier: dto.identifier,
            planEntry: dto.planEntry,
            viewPoint: dto.viewPoint,
            acceptanceDays: dto.acceptanceDays,
            planProcessingDays: dto.planProcessingDays,
            state: dto.state,
            taskType: dto.taskType,
            expertName,
            expertCandidateName,
            ...dateDTO,
        };
    }
}
