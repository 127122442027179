import React from 'react';
import { Grid, ThemeProvider } from '@material-ui/core';
import StartAuthHeaderLink from './StartAuthHeaderLink';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { RootStore } from '../../store/RootStore';
import { createLandingThemeCreator, LandingThemeCreator } from '../../createThemes';

type StoreProps = {
    rootStore: RootStore;
};

type InnerProps = StoreProps;

class StartPageHeaderLinks extends React.Component<InnerProps, {}> {
    landingThemeCreator: LandingThemeCreator;

    constructor(props: InnerProps) {
        super(props);
        this.landingThemeCreator = createLandingThemeCreator(props.rootStore);
    }

    render(): JSX.Element {
        return (
            <ThemeProvider theme={this.landingThemeCreator}>
                <Grid container direction="row" alignItems="center" justify="flex-end">
                    <StartAuthHeaderLink />
                </Grid>
            </ThemeProvider>
        );
    }
}

export default compose<InnerProps, {}>(
    inject<RootStore, {}, StoreProps, {}>((rootStore) => ({
        rootStore,
    })),
    observer,
)(StartPageHeaderLinks);
