export default {
    startPage: false,
    startPageDefaultText: false,
    startPageWithLogin: false,
    logo: {
        isEnabled: true,
        locales: {
            ru: {
                src: require(`../resources/images/tszd-logo.svg`),
                alt: 'Растим детей',
            },
            en: {
                src: require(`../resources/images/tszd-logo.svg`),
                alt: 'Растим детей',
            },
        },
        style: { minHeight: 70 },
        withText: false,
        mdLogo: true,
    },
    customBackground: {
        isEnabled: true,
        src: require(`../resources/images/tszd-background.jpg`),
    },
    phoneNumber: false,
    instructions: false,
    esiaLogin: false,

    yaMetrika: true,

    userAdding: false,
    userAssignPassword: false,

    withSubjectFilesTab: false,
};
