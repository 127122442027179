import { computed, observable } from 'mobx';
import { TotLocale } from '../../store/IntlStore';

export interface PersonDTO {
    uuid: string;
    lastName: string;
    firstName: string;
    middleName?: string;
    lang: TotLocale;
}

export default class PersonModel {
    uuid = '';
    @observable lastName = '';
    @observable firstName = '';
    @observable middleName?: string;

    load(dto: PersonDTO): PersonModel {
        Object.assign(this, dto);
        return this;
    }

    @computed
    get lastNameWithInitials(): string {
        const { firstName, lastName, middleName } = this;
        return `${lastName} ${firstName ? firstName[0] + '. ' : ''}${middleName ? middleName[0] + '.' : ''}`;
    }
}
