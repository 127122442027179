import React, { ReactNode } from 'react';
import { Box, Divider, IconButton, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react';
import { MenuButton } from '../buttons/MenuButton';
import { ReactComponent as UserIcon } from '../../resources/images/icons/user-plus.svg';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { AuthorizationCheck } from '../AuthorizationCheck';
import { entities, permissions } from '../../authSchemeConfig';
import clientRoute from '../../clientRoute';
import { useStore } from '../../hooks/useStore';

export const UserButton = observer(
    (): JSX.Element => {
        const { authenticationStore, personStore, api } = useStore();
        const history = useHistory();

        const handleLogout = (hideMenu: () => void) => async (): Promise<void> => {
            await authenticationStore.logout().then(personStore.clearPerson).catch(hideMenu);
            await api.authVerify();
            await personStore.getInfo();
        };

        const renderMenuItems = (withProfile: boolean) => (hideMenu: () => void): ReactNode[] => {
            const onProfileClick = (): void => {
                hideMenu();
                history.push(
                    generatePath(clientRoute.user, {
                        id: personStore.user.id,
                    }),
                );
            };

            return [
                <Box mb={2} key={'person'}>
                    <MenuItem dense button={false} tabIndex={-1}>
                        <Box pb={2} fontWeight="fontWeightBold">
                            {personStore.person.lastNameWithInitials}
                        </Box>
                    </MenuItem>
                    {withProfile && (
                        <MenuItem dense tabIndex={0} onClick={onProfileClick}>
                            <FormattedMessage id="users.profile" />
                        </MenuItem>
                    )}
                </Box>,
                <Box key={'divider'} mb={2}>
                    <Divider />
                </Box>,
                <MenuItem dense key={'logout'} button={true} onClick={handleLogout(hideMenu)} tabIndex={0}>
                    <FormattedMessage id="authentication.logout" />
                </MenuItem>,
            ];
        };

        const renderButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
            return (
                <IconButton color="primary" onClick={onClick}>
                    <UserIcon />
                </IconButton>
            );
        };

        return (
            <React.Fragment>
                {personStore.user.id && (
                    <AuthorizationCheck
                        entityCode={entities.User}
                        permCode={permissions.User.View}
                        entityId={personStore.user.id}
                    >
                        {(allowed) => (
                            <MenuButton
                                renderButton={renderButton}
                                renderMenuItems={renderMenuItems(allowed)}
                                reloadKey={personStore.person.lastNameWithInitials}
                            />
                        )}
                    </AuthorizationCheck>
                )}
            </React.Fragment>
        );
    },
);
