import React from 'react';
import { Button, Container, Dialog } from '@material-ui/core';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PlanEntryModel from '../../models/expertise-plan/PlanEntryModel';
import PlanEntryListModel from '../../models/expertise-plan/PlanEntryListModel';
import { Form, FormApi, MultiLangFormEdit } from '@platform/formiojs-react';
import { IReactionDisposer, observable, reaction } from 'mobx';
import { generatePath, RouteComponentProps, withRouter } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { renderAutoCompleteInput } from '../../components/renderAutoCompleteInput';
import { withLoading, WithLoadingProps } from '../../hocs/withLoading';
import { CodeTitle } from '../../models/CodeTitle';
import { RootStore } from '../../store/RootStore';
import { IntlStore } from '../../store/IntlStore';

type OuterProps = {
    getEntryModel: () => PlanEntryModel;
    listModel: PlanEntryListModel;
    ownerPagePath: string;
};

type RouteParams = {
    id: string;
};

type StoreProps = {
    intlStore: IntlStore;
};

type InnerProps = OuterProps &
    RouteComponentProps<RouteParams> & { intl: IntlFormatters } & WithLoadingProps &
    StoreProps;

class PlanEntryFormDialog extends React.Component<InnerProps, {}> {
    @observable formApi?: FormApi;
    @observable taskFormApi?: FormApi;
    @observable entryModel: PlanEntryModel = this.props.getEntryModel();
    @observable formSelectDefaultValue?: CodeTitle;
    @observable disposer: IReactionDisposer;

    constructor(props: InnerProps) {
        super(props);
        this.disposer = reaction(
            () => {
                return { id: this.entryModel.id, taskFormCode: this.entryModel.taskFormCode };
            },
            ({ taskFormCode }, reaction) => {
                this.formSelectDefaultValue = this.getFormSelectDefaultValue();
                reaction.dispose();
            },
        );
    }

    componentWillUnmount(): void {
        this.disposer();
    }

    onFormReady = (form: FormApi): void => {
        this.formApi = form;
    };

    onTaskFormReady = (form: FormApi): void => {
        this.taskFormApi = form;
    };

    handleSave = async (): Promise<void> => {
        const { listModel, history, match, ownerPagePath, enableLoading, disableLoading } = this.props;
        const { id } = match.params;
        const { isValid } = this.entryModel;
        this.entryModel.validationStarted = true;
        if (this.formApi && this.formApi.validate() && isValid && this.taskFormApi?.validate()) {
            try {
                enableLoading();
                await listModel.saveEntry(
                    this.entryModel,
                    this.formApi.getSubmission(),
                    this.taskFormApi?.getSubmission(),
                );
                history.push(generatePath(ownerPagePath, { id }));
            } catch (error) {
                disableLoading();
            }
        }
    };

    handleCancel = (): void => {
        const { history, match, ownerPagePath } = this.props;
        const { id } = match.params;
        history.push(generatePath(ownerPagePath, { id }));
    };

    getFormSelectDefaultValue(): CodeTitle | undefined {
        const { taskFormCode } = this.entryModel;
        if (taskFormCode) {
            const value = this.entryModel.taskFormSelectData.find((val: CodeTitle) => val.code === taskFormCode);
            if (value) {
                return value;
            }
        }
        return;
    }

    render(): JSX.Element {
        const { formModel, taskFormModel } = this.entryModel;
        const { intl, isLoading, intlStore } = this.props;
        const { locale } = intlStore;
        return (
            <Dialog maxWidth="md" fullScreen open={true} scroll="paper">
                <DialogTitle>
                    <Container maxWidth="lg">
                        <FormattedMessage id="templatesOfExpertise.plan.entryTitle" />
                    </Container>
                </DialogTitle>
                <DialogContent dividers>
                    <Container maxWidth="lg">
                        <Form locale={locale} onFormReady={this.onFormReady} form={formModel} />
                        {this.formApi && (
                            <Autocomplete
                                defaultValue={this.formSelectDefaultValue}
                                onChange={this.entryModel.onChangeSelect}
                                getOptionSelected={(option, value) => option.code === value.code}
                                getOptionLabel={(option) => option.title}
                                options={this.entryModel.taskFormSelectData.slice()}
                                renderInput={renderAutoCompleteInput(
                                    intl.formatMessage({
                                        id: 'templatesOfExpertise.plan.fields.taskForm',
                                    }),
                                    true,
                                    this.entryModel.errorTaskForm,
                                )}
                            />
                        )}
                        {this.entryModel.taskFormCode && taskFormModel && (
                            <MultiLangFormEdit
                                intlStore={intlStore}
                                onFormReady={this.onTaskFormReady}
                                form={taskFormModel}
                            />
                        )}
                    </Container>
                </DialogContent>
                <Container maxWidth="lg">
                    <DialogActions>
                        <Button size="large" fullWidth variant="contained" onClick={this.handleCancel}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            disabled={isLoading}
                            size="large"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.handleSave}
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Container>
            </Dialog>
        );
    }
}

export default compose<InnerProps, OuterProps>(
    injectIntl,
    withRouter,
    withLoading,
    inject<RootStore, {}, StoreProps, {}>((rootStore) => ({
        intlStore: rootStore.intlStore,
    })),
    observer,
)(PlanEntryFormDialog);
