import { action, IObservableArray, observable } from 'mobx';
import { UserDTO } from '../../store/UserStore';
import { CodeTitle } from '../CodeTitle';
import { FormModel } from '@platform/formiojs-react';

export class UserPersonModel {
    @observable id: string;
    @observable login = '';
    @observable lang = '';
    @observable roles: IObservableArray<CodeTitle> = observable.array();
    @observable personForm: FormModel;
    @observable blockReason?: string;

    constructor(id: string) {
        this.id = id;
        this.personForm = new FormModel(this.id);
    }

    @action.bound
    load(dto: UserDTO): UserPersonModel {
        this.login = dto.login;
        this.lang = dto.lang;
        this.roles.replace(dto.roles);
        this.personForm.load(dto.formInfo);
        this.blockReason = dto.blockInfo ? dto.blockInfo.blockReason : '';
        return this;
    }
}
