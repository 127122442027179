import fonts from './fonts';
import { AppFonts, AppIcons, AppPalette, ThemeOverrides } from '../../../themes/types/themeTypes';

type ColorName =
    | 'black'
    | 'white'
    | 'yellow'
    | 'yellowDark'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'brightGreen'
    | 'green'
    | 'lightGreen'
    | 'darkGreen'
    | 'red'
    | 'greyTable';

const tszdColors: Record<ColorName, string> = {
    black: '#000000',
    white: '#ffffff',
    yellow: '#ffff33',
    yellowDark: '#f5c722',
    greyMain: '#63666a',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    greyWhite: '#f1f1f1',
    greyLighter: '#fafafa',
    greyMiddleLight: '#ccd3db',
    lightGreen: '#f2fbf8',
    green: '#03cea4',
    brightGreen: '#09bca5',
    darkGreen: '#098071',
    red: '#fb4d3d',
    greyTable: '#ececec',
};

const tszdFonts: AppFonts = {
    ...fonts,
    mainFontAttrs: {
        fontSize: '13px',
        lineHeight: 1.69,
        letterSpacing: '0.2px',
        fontWeight: 400,
    },
};

const tszdIcons: AppIcons = {
    fontFamily: fonts.iconsFont,
    arrowIcon: {
        fontSize: 11,
        content: '"\\e90f"',
        width: 12,
        height: 7,
    },
    doneIcon: {
        fontSize: 14,
        content: '"\\e906"',
        width: 14,
        height: 11,
    },
    calendarIcon: {
        fontSize: 20,
        content: '"\\e905"',
        width: 18,
        height: 20,
    },
};

const accentBackgroundGradient = `linear-gradient(-30deg, ${tszdColors.darkGreen} 0%, ${tszdColors.darkGreen} 100%)`;

export const tszdPalette: AppPalette = {
    main: tszdColors.brightGreen,
    textMain: tszdColors.greyMain,
    textDark: tszdColors.black,
    mainMiddleLight: tszdColors.greyMiddleLight,
    mainLight: tszdColors.greyLight,
    mainDark: tszdColors.greyDark,
    mainContrast: tszdColors.white,
    mainContrastDarker: tszdColors.greyLighter,
    accent: tszdColors.brightGreen,
    accentDark: tszdColors.brightGreen,
    accentContrast: tszdColors.black,
    accentBackgroundGradient: accentBackgroundGradient,
    accentContrastBackgroundGradient: tszdColors.white,
    hover: tszdColors.brightGreen,
    hoverInLists: tszdColors.greyWhite,
    green: tszdColors.green,
    red: tszdColors.red,
    table: {
        row: {
            even: tszdColors.white,
            odd: tszdColors.greyLighter,
            checked: tszdColors.greyTable,
        },
    },
    card: {
        header: {
            main: '#252629',
        },
    },
    appBar: {
        mainContrast: tszdColors.lightGreen,
    },
    tooltip: {
        backgroundColor: tszdColors.black,
    },
    buttonPrimary: {
        color: tszdColors.black,
        hoverColor: tszdColors.brightGreen,
    },
    iconButton: {
        color: tszdColors.brightGreen,
        hoverColor: tszdColors.white,
        hoverBackgroundColor: tszdColors.brightGreen,
    },
    footer: {
        textMain: tszdColors.greyMain,
    },
    panel: {
        markerBackgroundColor: accentBackgroundGradient,
        markerColor: tszdColors.white,
    },
    campaignRequest: {
        icon: tszdColors.black,
    },
    startLink: {
        color: tszdColors.greyLight,
        hoverColor: tszdColors.white,
    },
};

export default {
    fonts: tszdFonts,
    palette: tszdPalette,
    icons: tszdIcons,
} as ThemeOverrides;
