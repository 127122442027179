import React, { useEffect, useState } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PrintFormFileDTO } from '../../../../../models/expertise-task/ExpertiseTaskPrintFormModel';
import { TableToolbar } from './ExpertiseTaskPrintForms.styled';
import ExpertiseTaskPrintFormsTable from './ExpertiseTaskPrintFormsTable';
import ExpertiseTaskPrintFormsModel, {
    ExpertiseTaskPrintFormsDTO,
} from '../../../../../models/expertise-task/ExpertiseTaskPrintFormsModel';
import { useStore } from '../../../../../hooks';

type RouteParams = {
    id: string;
};

export const ExpertiseTaskPrintForms = observer(
    (): JSX.Element => {
        const [printForms] = useState<ExpertiseTaskPrintFormsModel>(new ExpertiseTaskPrintFormsModel());
        const { expertiseTaskStore } = useStore();
        const { id: expertiseTaskId } = useParams<RouteParams>();

        useEffect(() => {
            expertiseTaskStore.loadPrintForms(expertiseTaskId).then((printFormsDTO: ExpertiseTaskPrintFormsDTO) => {
                printForms.load(printFormsDTO);
            });
        }, [expertiseTaskId]);

        const downloadPf = (file: PrintFormFileDTO) => (): void => {
            expertiseTaskStore.downloadPrintFormFile(file);
        };

        return (
            <Paper elevation={1}>
                <TableToolbar>
                    <Typography variant="h3">
                        <FormattedMessage id="expertiseTask.printFormsTable.title" />
                    </Typography>
                </TableToolbar>
                <ExpertiseTaskPrintFormsTable downloadPf={downloadPf} printForms={printForms} />
            </Paper>
        );
    },
);
