import { useFeature } from 'feature-toggle-jsx';
import React, { useMemo } from 'react';
import { useStore } from './useStore';
import { defaultLocale } from '../store/IntlStore';

export type LogoProps = {
    alt: string;
    src: string;
};

export const useLogo = (): LogoProps => {
    const [logo, logoConfig] = useFeature('logo');
    const { intlStore } = useStore();
    const { locale } = intlStore;

    const logoProps = useMemo((): LogoProps => {
        const { alt, src } = logoConfig.locales[locale] || logoConfig.locales[defaultLocale];
        return { alt, src };
    }, [logoConfig, locale]);

    return logoProps;
};
