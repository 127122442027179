import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { Switch, Route, NavLink, generatePath } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { IconButtonLarge } from '../../../components/buttons/IconButtonLarge';
import clientRoute from '../../../clientRoute';
import { ReactComponent as EditIcon } from '../../../resources/images/icons/edit.svg';
import { UserPersonModel } from '../../../models/users/UserPersonModel';
import { AuthorizationCheck } from '../../../components/AuthorizationCheck';
import { entities, permissions } from '../../../authSchemeConfig';

interface Props {
    id: UserPersonModel['id'];
}

const ControlPanelLeft = ({ id }: Props): JSX.Element => (
    <Grid spacing={3} alignItems="center" justify="center" container>
        <Switch>
            <Route exact path={clientRoute.user}>
                <Grid item>
                    <AuthorizationCheck entityCode={entities.User} entityId={id} permCode={permissions.User.UpdateForm}>
                        <Tooltip title={<FormattedMessage id="common.edit" />}>
                            <div>
                                <IconButtonLarge component={NavLink} to={generatePath(clientRoute.userEdit, { id })}>
                                    <EditIcon />
                                </IconButtonLarge>
                            </div>
                        </Tooltip>
                    </AuthorizationCheck>
                </Grid>
            </Route>
        </Switch>
    </Grid>
);

export default ControlPanelLeft;
