const users: Record<string, string | Record<string, string | Record<string, string>>> = {
    listTitle: 'Пользователи',
    fields: {
        login: 'Логин',
        status: 'Статус',
        name: 'ФИО',
        roles: 'Роли',
        lang: 'Англоязычный пользователь',
    },
    actions: {
        block: 'Заблокировать',
        unblock: 'Разблокировать',
        activate: 'Активировать',
        passwordAssignment: 'Назначить пароль',
    },
    status: {
        blocked: 'Заблокирован',
        active: 'Активный',
        inactive: 'Неактивный',
    },
    loginAsUser: 'Авторизоваться под пользователем',
    editUser: 'Редактировать пользователя',
    registryPagingInfo:
        'Всего {count, number} пользовател{count, plural, one {ь} few {я} other {ей}}. ' +
        'Отображены c {from, number} по {to, number}',

    confirmBlock: 'Подтвердите блокировку пользователя',
    confirmBlockInfoText: 'Вы действительно хотите заблокировать пользователя {name} ({login})?',
    confirmBlockWithReasonInfoText: 'Пользователь {name} ({login}) будет заблокирован',
    blockReason: 'Причина блокировки',

    confirmUnblock: 'Подтвердите разблокировку пользователя',
    confirmUnblockInfoText: 'Вы действительно хотите разблокировать пользователя {name} ({login})?',

    deleteUser: 'Подтвердите удаление пользователя',
    deleteUserConfirmText: 'Вы действительно хотите удалить пользователя {name} ({login})?',
    notAllowed: 'Невозможно произвести данное действие над своей учетной записью',

    editPasswordDialogTitle: 'Назначение пароля',
    editPasswordDialogInfo: 'Введите новый пароль для пользователя {name} ({login})',

    addUser: 'Добавить пользователя',
    register: 'Зарегистрировать',

    profile: 'Профиль пользователя',
};

export default users;
