import { action, computed, observable } from 'mobx';
import { ChangeEvent } from 'react';
import { RootStore } from '../../store/RootStore';
import { IntlStore } from '../../store/IntlStore';
import { Api } from '../../store/Api';
import PasswordModel from '../authentication/fields/PasswordModel';

export class AssignUserPasswordModel {
    @observable protected api: Api;
    @observable intl: IntlStore;
    @observable newPassword = '';
    @observable repeatPassword = '';
    @observable validationStartedNewPassword = false;
    @observable validationStartedRepeatPassword = false;
    @observable requestStatus?: 'failed' | 'success';
    @observable failedMessage = '';

    constructor(rootStore: RootStore) {
        this.api = rootStore.api;
        this.intl = rootStore.intlStore;
    }

    @action
    disableValidationStartedNewPassword = () => {
        this.validationStartedNewPassword = false;
    };

    @action
    enableValidationStartedNewPassword = () => {
        this.validationStartedNewPassword = true;
    };

    @action
    disableValidationStartedRepeatPassword = () => {
        this.validationStartedRepeatPassword = false;
    };

    @action
    enableValidationStartedRepeatPassword = () => {
        this.validationStartedRepeatPassword = true;
    };

    @action.bound
    onPasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.requestStatus = undefined;
        this.enableValidationStartedNewPassword();
        this.newPassword = event.target.value;
    };

    @action.bound
    onRepeatPasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.requestStatus = undefined;
        this.enableValidationStartedRepeatPassword();
        this.repeatPassword = event.target.value;
    };

    validationPassword = (passwordField: string, validateStrted: boolean): string => {
        const minLength = 8;
        if (validateStrted && !passwordField) {
            return this.intl.formatMessage('validation.required');
        } else if (validateStrted && passwordField.length < minLength) {
            return this.intl.formatMessage('validation.minLength', { length: minLength });
        }
        return '';
    };

    @computed
    get errorPassword(): string {
        return this.validationPassword(this.newPassword, this.validationStartedNewPassword);
    }

    @computed
    get errorRepeatPassword(): string {
        if (this.validationStartedRepeatPassword && this.newPassword !== this.repeatPassword) {
            return this.intl.formatMessage('authentication.passwordsMustMatch');
        }
        return this.validationPassword(this.repeatPassword, this.validationStartedRepeatPassword);
    }

    @computed
    get repeatPasswordModel(): PasswordModel {
        return {
            errorPassword: this.errorRepeatPassword,
            onPasswordChange: this.onRepeatPasswordChange,
        };
    }

    @computed
    get newPasswordModel(): PasswordModel {
        return {
            errorPassword: this.errorPassword,
            onPasswordChange: this.onPasswordChange,
        };
    }

    @computed
    get isAllowedSubmit(): boolean {
        return !!this.newPassword && !!this.repeatPassword && this.newPassword === this.repeatPassword;
    }
}
