import React from 'react';
import { Field, Form, Formik } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from 'formik-material-ui';
import { createStyles, makeStyles, Portal } from '@material-ui/core';
import { Transition } from '../../models/tot-object/TotObjectTransitions';
import { useYup } from '../../hooks/useYup';
import { useStore } from '../../hooks/useStore';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() =>
    createStyles({
        formWrapper: {
            '&:first-child': {
                paddingTop: '10px',
            },
        },
    }),
);

export type RejectReasonDialogProps = {
    transition: Transition;
    objectId: string;
    isModalOpen: boolean;
    setModalIsClosed: () => void;
    lifeCycleTransition: () => Promise<void>;
    handleSuccessTransition: () => void;
    handleErrorTransition: (error: AxiosError) => void;
};

type FormikValues = {
    message: string;
};

const initialValues: FormikValues = {
    message: '',
};

export const RejectReasonDialog = observer(
    (props: RejectReasonDialogProps): JSX.Element => {
        const {
            objectId,
            transition,
            isModalOpen,
            setModalIsClosed,
            handleSuccessTransition,
            handleErrorTransition,
        } = props;
        const { expertiseTaskStore } = useStore();
        const { sendToRework } = expertiseTaskStore;
        const classes = useStyles();
        const intl = useIntl();
        const { Yup } = useYup();

        const schema = Yup.object().shape({
            message: Yup.string().required(),
        });

        const handleCancel = (): void => {
            setModalIsClosed();
        };

        const handleConfirm = (values: FormikValues): void => {
            sendToRework(transition.id, objectId, values.message)
                .then(handleSuccessTransition)
                .catch(handleErrorTransition)
                .finally(setModalIsClosed);
        };

        return (
            <Portal>
                <Dialog fullWidth={true} maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={isModalOpen}>
                    <DialogTitle id="confirmation-dialog-title">
                        <FormattedMessage id="expertiseTask.invites.rejectReason" />
                    </DialogTitle>
                    <DialogContent>
                        <FormattedMessage
                            id="common.lifeCycleConfirmText"
                            values={{ toState: transition.toStateTitle }}
                        />
                    </DialogContent>
                    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleConfirm}>
                        <Form>
                            <DialogContent className={classes.formWrapper}>
                                <Field
                                    component={TextField}
                                    name={'message'}
                                    label={intl.formatMessage({ id: 'expertiseTask.invites.rejectReason' }) + '*'}
                                    variant="outlined"
                                    size="small"
                                    multiline
                                    rows={3}
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus color="primary" onClick={handleCancel}>
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                                <Button type="submit" color="secondary" variant="contained">
                                    <FormattedMessage id="expertiseTask.actions.returnForRevision" />
                                </Button>
                            </DialogActions>
                        </Form>
                    </Formik>
                </Dialog>
            </Portal>
        );
    },
);
