import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Container, Grid, Link, Paper, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { TotBackground } from '../../TotBackground';
import { Link as RouterLink, Redirect, useParams } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import NewPasswordModel from '../../models/authentication/NewPasswordModel';
import { PasswordField } from '../../components/fields/PasswordField';
import { useStore } from '../../hooks';

type RouteParams = {
    token: string | undefined;
};

export const NewPasswordPage = observer(
    (): JSX.Element => {
        const { token } = useParams<RouteParams>();
        const { authenticationStore } = useStore();

        const [newPasswordModel] = useState<NewPasswordModel>(() => {
            const newModel = authenticationStore.newPasswordModel;
            newModel.token = token;
            return newModel;
        });

        return (
            <TotBackground withBackdrop={true}>
                <Grid container item direction="column" alignItems="center" justify="center">
                    <Container className="t-new-password-page" maxWidth="xs">
                        <Paper elevation={24}>
                            <Box p={12}>
                                <Grid container justify="center">
                                    <Grid item>
                                        <Typography variant="h5" className="t-new-password-title">
                                            <Box fontWeight="fontWeightBold">
                                                <FormattedMessage id="authentication.newPasswordTitle" />
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Box pt={8}>
                                    <form noValidate onSubmit={newPasswordModel.updatePassword}>
                                        <Grid container spacing={6} direction="column">
                                            {newPasswordModel.failedMessage && (
                                                <Grid item>
                                                    <Typography className="t-failed-message" color="error">
                                                        {newPasswordModel.failedMessage}
                                                    </Typography>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <PasswordField
                                                    label={<FormattedMessage id="authentication.newPassword" />}
                                                    model={newPasswordModel.newPasswordModel}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <PasswordField
                                                    label={<FormattedMessage id="authentication.repeatPassword" />}
                                                    model={newPasswordModel.repeatPasswordModel}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className="t-confirm"
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    type="submit"
                                                >
                                                    <FormattedMessage id="authentication.confirm" />
                                                </Button>
                                            </Grid>

                                            <Grid item container justify="center">
                                                <Link className="t-login" component={RouterLink} to={clientRoute.login}>
                                                    <Typography variant="caption">
                                                        <FormattedMessage id="authentication.login" />
                                                    </Typography>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Box>
                            </Box>
                        </Paper>
                        {newPasswordModel.changedSucceed && <Redirect to={clientRoute.campaigns} />}
                    </Container>
                </Grid>
            </TotBackground>
        );
    },
);
