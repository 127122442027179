import { TableModel, RowsData, TableQueryData } from '../list/TableModel';
import { action, computed, observable } from 'mobx';
import { Api } from '../../store/Api';
import apiConfigs from '../../apiConfigs';
import { handleAxiosErrorByResponseStatus } from '../../utils/errorHandleUtils';
import clientRoute from '../../clientRoute';
import { RootStore } from '../../store/RootStore';
import { History } from 'history';

export interface CampaignRowDTO {
    id: string;
    identifier: string;
    title: string;
    created: string; //date
    state: string;
}

export default class CampaignListModel extends TableModel<CampaignRowDTO, {}, {}> {
    @observable protected api: Api;
    @observable rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super({}, {});
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<CampaignRowDTO>> {
        return this.api
            .client(apiConfigs.campaigns(queryData))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }
}
