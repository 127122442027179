import { action, observable } from 'mobx';
import { RootStore } from './RootStore';
import { Api } from './Api';
import apiConfigs from '../apiConfigs';
import { Preset, TTableRow } from '@platform/ttable';
import { di } from 'react-magnetic-di';

export class PresetStore {
    @observable private rootStore: RootStore;
    @observable protected api: Api;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @action.bound
    listPreset<RowType extends TTableRow>(registryCode: string): Promise<Preset<RowType>[]> {
        return this.api.client(apiConfigs.listPreset(registryCode)).then((_) => _.data);
    }

    @action.bound
    savePreset<RowType extends TTableRow>(preset: Preset<RowType>): Promise<Preset<RowType>> {
        return this.api.client(apiConfigs.savePreset(preset)).then((_) => _.data);
    }

    @action.bound
    deletePreset(presetId: string): Promise<void> {
        return this.api.client(apiConfigs.deletePreset(presetId)).then((_) => _.data);
    }

    @action.bound
    async saveListPresets<RowType extends TTableRow>(presets: Preset<RowType>[]): Promise<void> {
        try {
            await this.api.client(apiConfigs.saveListPresets(presets));
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    getLastPresetId(registryCode: string): Promise<string | null> {
        return this.api.client(apiConfigs.getLastPreset(registryCode)).then((res) => res.data?.presetId || null);
    }

    @action.bound
    async setLastPreset(preset: Preset<TTableRow>): Promise<void> {
        const { id, registryCode } = preset;
        if (id) {
            this.api.client(apiConfigs.saveLastPreset(registryCode, id));
        }
    }
}

export const getPresetStore = (): any => {
    const [_PresetStore] = di([PresetStore], getPresetStore);
    return _PresetStore;
};
