import fonts from './fonts';
import { AppFonts, AppIcons, AppPalette, AppPaletteInkk, ThemeOverrides } from '../../../themes/types/themeTypes';

type ColorName =
    | 'blackLight'
    | 'black'
    | 'white'
    | 'blue'
    | 'blueDark'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'green'
    | 'red'
    | 'greyTable'
    | 'blueBright'
    | 'blueMiddle'
    | 'blueLight'
    | 'blueLighter'
    | 'blueDarkest'
    | 'blueWhite';

const inkColors: Record<ColorName, string> = {
    blackLight: '#1c1d1f',
    black: '#000000',
    white: '#ffffff',
    blue: '#1B6195',
    blueBright: '#0075eb',
    blueMiddle: '#D6E3EC',
    blueLight: '#5C8EB3',
    blueDark: '#124265',
    blueDarkest: '#1f2326',
    blueLighter: '#AEC7D9',
    blueWhite: '#E7EFF4',
    greyMain: '#63666a',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    greyWhite: '#f1f1f1',
    greyLighter: '#fafafa',
    greyMiddleLight: '#ccd3db',
    green: '#03cea4',
    red: '#fb4d3d',
    greyTable: '#ececec',
};

const inkFonts: AppFonts = {
    ...fonts,
    mainFontAttrs: {
        fontSize: '13px',
        lineHeight: 1.69,
        letterSpacing: '0.2px',
        fontWeight: 400,
    },
};

const inkIcons: AppIcons = {
    fontFamily: fonts.iconsFont,
    arrowIcon: {
        fontSize: 11,
        content: '"\\e90f"',
        width: 12,
        height: 7,
    },
    doneIcon: {
        fontSize: 14,
        content: '"\\e906"',
        width: 14,
        height: 11,
    },
    calendarIcon: {
        fontSize: 20,
        content: '"\\e905"',
        width: 18,
        height: 20,
    },
};

const accentBackgroundGradient = `linear-gradient(-30deg, ${inkColors.blue} 0%, ${inkColors.blueDark} 100%)`;

export const inkPalette: AppPaletteInkk = {
    main: inkColors.greyMain,
    mainMiddleLight: inkColors.greyMiddleLight,
    mainLight: inkColors.greyLight,
    mainDark: inkColors.greyDark,
    mainContrast: inkColors.white,
    mainContrastDarker: inkColors.greyLighter,
    accent: inkColors.blue,
    accentDark: inkColors.blueDark,
    accentContrast: inkColors.black,
    accentBackgroundGradient: `linear-gradient(-30deg, ${inkColors.blue} 0%, ${inkColors.blueDark} 100%)`,
    accentContrastBackgroundGradient: inkColors.white,
    hover: inkColors.blue,
    hoverInLists: inkColors.greyWhite,
    green: inkColors.green,
    red: inkColors.red,
    mainBlue: inkColors.blue,
    mainBlueBright: inkColors.blueBright,
    mainBlueMiddleLight: inkColors.blueMiddle,
    mainBlueLight: inkColors.blueLight,
    mainBlueDark: inkColors.blueDark,
    mainBlueDarkest: inkColors.blueDarkest,
    mainBlueLighter: inkColors.blueLighter,
    mainBlueWhite: inkColors.blueWhite,
    table: {
        row: {
            even: inkColors.white,
            odd: inkColors.greyLighter,
            checked: inkColors.greyTable,
        },
    },
    card: {
        header: {
            main: '#252629',
        },
    },
    appBar: {
        mainContrast: inkColors.blue,
    },
    tooltip: {
        backgroundColor: inkColors.blackLight,
    },
    iconButton: {
        color: inkColors.greyMain,
        hoverColor: inkColors.white,
        hoverBackgroundColor: inkColors.greyMain,
    },
    buttonPrimary: {
        color: inkColors.greyMain,
        hoverColor: inkColors.black,
    },
    footer: {
        textMain: inkColors.greyMain,
    },
    panel: {
        markerBackgroundColor: accentBackgroundGradient,
        markerColor: inkColors.white,
    },
    campaignRequest: {
        icon: inkColors.black,
    },
};

export default {
    fonts: inkFonts,
    palette: inkPalette,
    icons: inkIcons,
} as ThemeOverrides;
