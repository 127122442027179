import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { generatePath, useParams } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { observer } from 'mobx-react';
import { FormApi } from '@platform/formiojs-react';
import CategoryOfSubjectsHeader from './CategoryOfSubjectsHeader/CategoryOfSubjectsHeader';
import { CategoryOfSubjectsControlPanel } from './CategoryOfSubjectsControlPanel/CategoryOfSubjectsControlPanel';
import { TotObjectMain } from '../../components/tot-object/TotObjectMain';
import { ServiceInfoPanel } from '../../components/ServiceInfoPanel';
import { entities, permissions } from '../../authSchemeConfig';
import { AuthorizationCheck } from '../../components/AuthorizationCheck';
import { TotObjectForm } from '../../components/tot-object/TotObjectForm';
import { useStore } from '../../hooks/useStore';
import { useReload } from '../../hooks/useReload';

type RouteParams = {
    id: string;
};

export const CategoryOfSubjectsPage = observer(
    (): JSX.Element => {
        const { categoriesOfSubjectsStore, history, intlStore } = useStore();
        const { reloadKey, reloadIncrement } = useReload();
        const { id } = useParams<RouteParams>();
        const categoryOfSubjectsModel = useMemo(() => categoriesOfSubjectsStore.getCategoryOfSubjectsModel(id), [id]);

        const [formApi, setFormApi] = useState<FormApi>();

        useEffect(() => updateCard, [intlStore.locale, reloadKey]);

        const updateCard = (): void => {
            categoriesOfSubjectsStore.loadCategoryOfSubjectsDTO(id).then(categoryOfSubjectsModel.loadCard);
        };

        const onFormReady = useCallback(
            (form: FormApi) => {
                setFormApi(form);
            },
            [setFormApi],
        );

        const onSubmit = (): void => {
            if (formApi && formApi.validate()) {
                categoriesOfSubjectsStore.saveChangeCategoryOfSubjects(id, formApi.getSubmission()).then(() => {
                    reloadIncrement();
                    history.push(generatePath(clientRoute.category, { id }));
                });
            }
        };

        const deleteCategoryOfSubjects = (): Promise<void> => {
            return categoriesOfSubjectsStore.deleteCategoryOfSubjects(categoryOfSubjectsModel.id).then(() => {
                history.push(generatePath(clientRoute.categories));
            });
        };

        const onNotAllowed = () => {
            history.replace(clientRoute.notAllowed);
        };

        const setCommonValidationState = (state: boolean) => {
            categoriesOfSubjectsStore.showValidation = state;
        };

        const { formModel } = categoryOfSubjectsModel;
        return (
            <AuthorizationCheck
                entityCode={entities.System}
                permCode={permissions.System.Administration}
                onNotAllowed={onNotAllowed}
            >
                <Grid container direction="column" wrap="nowrap">
                    <Grid item>
                        <CategoryOfSubjectsHeader categoryOfSubjectsModel={categoryOfSubjectsModel} />
                    </Grid>
                    <TotObjectMain>
                        <Grid container spacing={10}>
                            <Grid item container direction="column" spacing={10}>
                                <AuthorizationCheck
                                    entityCode={entities.System}
                                    permCode={permissions.System.Administration}
                                >
                                    <Grid item>
                                        <ServiceInfoPanel metaInfoModel={categoryOfSubjectsModel.metaInfo} />
                                    </Grid>
                                </AuthorizationCheck>
                                <Grid item style={{ maxWidth: '100%' }}>
                                    <TotObjectForm
                                        reloadKey={reloadKey}
                                        formName="category"
                                        model={formModel}
                                        editPath={clientRoute.categoryEdit}
                                        createPath={clientRoute.categoryCreate}
                                        readPath={clientRoute.category}
                                        onFormReady={onFormReady}
                                        showReadonlyValidation={categoriesOfSubjectsStore.showValidation}
                                        hideReadonlyValidation={() => setCommonValidationState(false)}
                                        editAuthProps={{
                                            entityCode: entities.System,
                                            permCode: permissions.System.Administration,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </TotObjectMain>
                    <Grid item>
                        <CategoryOfSubjectsControlPanel
                            categoryOfSubjectsTitle={categoryOfSubjectsModel.title}
                            categoryOfSubjectsId={categoryOfSubjectsModel.id}
                            deleteCategoryOfSubjects={deleteCategoryOfSubjects}
                            onSubmit={onSubmit}
                        />
                    </Grid>
                </Grid>
            </AuthorizationCheck>
        );
    },
);
