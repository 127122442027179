import React, { ReactNode, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    LinearProgress,
    Link,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ExpertiseListModel as ExpertiseListModelInj, ExpertiseRow } from '../../models/expertise/ExpertiseListModel';
import { TotTablePagination } from '../../components/TotTablePagination';
import clientRoute from '../../clientRoute';
import { ExpertiseCreateDialog } from './ExpertiseCreateDialog';
import { DeleteActionMenuItem } from '../../components/DeleteActionMenuItem';
import { MenuButton } from '../../components/buttons/MenuButton';
import { AuthorizationCheck } from '../../components/AuthorizationCheck';
import { entities, permissions } from '../../authSchemeConfig';
import { ExportButton } from '../../components/buttons/ExportButton';
import { ExpertiseListFilterPanel as ExpertiseListFilterPanelInj } from './ExpertiseListFilterPanel';
import { ReactComponent as ClearFilter } from '../../resources/images/icons/clear-filter.svg';
import { useStore } from '../../hooks/useStore';
import { di } from 'react-magnetic-di';
import { useModal } from '../../hooks/useModal';
import { ActionsButton } from '../../components/buttons/ActionsButton';

export const renderExpertiseListHeadInj = (): JSX.Element => {
    return (
        <TableRow>
            <TableCell style={{ width: '12.5%' }}>
                <Typography>
                    <FormattedMessage id="expertise.fields.identifier" />
                </Typography>
            </TableCell>
            <TableCell style={{ width: '12.5%' }}>
                <Typography>
                    <FormattedMessage id="expertise.fields.expertiseSubject" />
                </Typography>
            </TableCell>
            <TableCell style={{ width: '12.5%' }}>
                <Typography>
                    <FormattedMessage id="expertise.fields.expertiseSubjectIdentifier" />
                </Typography>
            </TableCell>
            <TableCell style={{ width: '12.5%' }}>
                <Typography>
                    <FormattedMessage id="expertise.fields.curator" />
                </Typography>
            </TableCell>
            <TableCell style={{ width: '12.5%' }}>
                <Typography>
                    <FormattedMessage id="expertise.fields.campaign" />
                </Typography>
            </TableCell>
            <TableCell style={{ width: '12.5%' }}>
                <Typography>
                    <FormattedMessage id="expertise.fields.deadline" />
                </Typography>
            </TableCell>
            <TableCell style={{ width: '12.5%' }}>
                <Typography>
                    <FormattedMessage id="expertise.fields.state" />
                </Typography>
            </TableCell>
            <TableCell style={{ width: '12.5%' }}>
                <Typography>
                    <FormattedMessage id="expertise.fields.template" />
                </Typography>
            </TableCell>
            <TableCell />
        </TableRow>
    );
};

export const renderExpertiseListBodyInj = (
    tableModel: ExpertiseListModelInj,
    renderActions: (categoryRow: ExpertiseRow) => JSX.Element,
): JSX.Element[] => {
    return tableModel.rows.map((expertise) => {
        const { id, campaign } = expertise;
        return (
            <TableRow key={id} hover>
                <TableCell>
                    <Link component={NavLink} underline="none" to={generatePath(clientRoute.expertise, { id })}>
                        {expertise.identifier}
                    </Link>
                </TableCell>
                <TableCell>
                    <Link
                        component={NavLink}
                        underline="none"
                        to={generatePath(clientRoute.subject, { id: expertise.subject.id })}
                    >
                        {expertise.subject.title}
                    </Link>
                </TableCell>
                <TableCell>
                    <Link
                        component={NavLink}
                        underline="none"
                        to={generatePath(clientRoute.subject, { id: expertise.subject.id })}
                    >
                        {expertise.subject.number}
                    </Link>
                </TableCell>
                <TableCell>{expertise.curator?.name || ''}</TableCell>
                <TableCell>
                    {campaign && (
                        <Link
                            component={NavLink}
                            underline="none"
                            to={generatePath(clientRoute.campaign, {
                                id: campaign.id,
                            })}
                        >
                            {campaign.title}
                        </Link>
                    )}
                </TableCell>
                <TableCell>{expertise.deadline && <FormattedDate value={expertise.deadline} />}</TableCell>
                <TableCell>{expertise.state}</TableCell>
                <TableCell>
                    {expertise.template && (
                        <Link
                            component={NavLink}
                            underline="none"
                            to={generatePath(clientRoute.templateOfExpertise, { id: expertise.template.id })}
                        >
                            {expertise.template?.title}
                        </Link>
                    )}
                </TableCell>
                <TableCell>
                    <AuthorizationCheck
                        entityCode={entities.Expertise}
                        permCode={permissions.Expertise.Delete}
                        entityId={id}
                    >
                        {renderActions(expertise)}
                    </AuthorizationCheck>
                </TableCell>
            </TableRow>
        );
    });
};

export const ExpertiseListPage = observer(
    (): JSX.Element => {
        const [ExpertiseListModel] = di([ExpertiseListModelInj], ExpertiseListPage);
        const [ExpertiseListFilterPanel] = di([ExpertiseListFilterPanelInj], ExpertiseListPage);
        const [renderExpertiseListHead] = di([renderExpertiseListHeadInj], ExpertiseListPage);
        const [renderExpertiseListBody] = di([renderExpertiseListBodyInj], ExpertiseListPage);

        const { api, history, expertiseStore } = useStore();
        const intl = useIntl();
        const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();

        const tableModel = useMemo(() => new ExpertiseListModel(api, history), [api, history]);

        useEffect(() => {
            return tableModel.dispose;
        }, [tableModel.dispose]);

        const deleteExpertise = (expertiseRow: ExpertiseRow): (() => Promise<void>) => {
            return (): Promise<void> => {
                return expertiseStore.deleteExpertise(expertiseRow.id).then(tableModel.reloadData);
            };
        };

        const renderActionItems = (expertiseRow: ExpertiseRow): (() => ReactNode[]) => {
            return (): ReactNode[] => [
                <DeleteActionMenuItem
                    id="delete"
                    key="delete"
                    wrappedComponentProps={{ tabIndex: 0 }}
                    title={<FormattedMessage id="common.confirmDeletion" />}
                    message={
                        <FormattedMessage
                            id="expertise.confirmDeletionInfoText"
                            values={{ title: expertiseRow.identifier }}
                        />
                    }
                    onConfirm={deleteExpertise(expertiseRow)}
                />,
            ];
        };

        const renderActions = (categoryRow: ExpertiseRow): JSX.Element => {
            return (
                <MenuButton
                    disablePortal={true}
                    renderButton={ActionsButton}
                    renderMenuItems={renderActionItems(categoryRow)}
                />
            );
        };

        const filename = `${intl.formatMessage({ id: 'expertise.expertiseListTitle' })}.xlsx`;

        return (
            <React.Fragment>
                {isModalOpen && <ExpertiseCreateDialog onClose={setModalIsClosed} />}
                <Container maxWidth="lg">
                    <Box pt={5} pb={5}>
                        <Grid container direction="column" spacing={10}>
                            <Grid item container direction="row" justify="space-between">
                                <Grid item>
                                    <Typography variant="h1">
                                        <FormattedMessage id="expertise.expertiseListTitle" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} container spacing={2} justify="flex-end">
                                    <Grid item>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            startIcon={
                                                <SvgIcon>
                                                    <ClearFilter />
                                                </SvgIcon>
                                            }
                                            onClick={tableModel.clearFilters}
                                        >
                                            <FormattedMessage id="common.resetFilters" />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <ExportButton
                                            queryData={tableModel.queryData}
                                            loadRegisterList={expertiseStore.exportListXls}
                                            filename={filename}
                                        />
                                    </Grid>
                                    <AuthorizationCheck
                                        entityCode={entities.System}
                                        permCode={permissions.System.AddExpertise}
                                    >
                                        <Grid item>
                                            <Button color="primary" variant="contained" onClick={setModalIsOpen}>
                                                <FormattedMessage id="expertise.createExpertise" />
                                            </Button>
                                        </Grid>
                                    </AuthorizationCheck>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <ExpertiseListFilterPanel model={tableModel} />
                            </Grid>
                            <Grid item>
                                <TableContainer component={Paper}>
                                    {tableModel.isLoading && <LinearProgress />}
                                    <Table>
                                        <TableHead>{renderExpertiseListHead()}</TableHead>
                                        <TableBody>{renderExpertiseListBody(tableModel, renderActions)}</TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TotTablePagination
                                                    count={tableModel.rowsCount}
                                                    page={tableModel.pageNumber}
                                                    onChangePage={tableModel.onChangePage}
                                                    onChangeRowsPerPage={tableModel.onChangePageSize}
                                                    rowsPerPage={tableModel.pageSize}
                                                    rowsPerPageOptions={tableModel.pageSizeOptions}
                                                    labelRowsPerPage={intl.formatMessage({ id: 'common.rowsPerPage' })}
                                                    labelDisplayedRows={(p): string =>
                                                        intl.formatMessage(
                                                            { id: 'expertise.pagingInfo' },
                                                            { to: p.to, from: p.from, count: p.count },
                                                        )
                                                    }
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </React.Fragment>
        );
    },
);
