import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ExpertiseListPage as ExpertiseListPageInj } from './ExpertiseListPage';
import { ExpertisePage as ExpertisePageInj } from './expertise-page/ExpertisePage';
import clientRoute from '../../clientRoute';
import { di } from 'react-magnetic-di';
import { observer } from 'mobx-react';

export const ExpertisePages = observer(
    (): JSX.Element => {
        const [ExpertiseListPage] = di([ExpertiseListPageInj], ExpertisePages);
        const [ExpertisePage] = di([ExpertisePageInj], ExpertisePages);
        return (
            <Switch>
                <Route path={clientRoute.expertise}>
                    <ExpertisePage />
                </Route>
                <Route>
                    <ExpertiseListPage />
                </Route>
            </Switch>
        );
    },
);
