import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as GetApp } from '../../resources/images/icons/export.svg';
import { TableQueryData } from '../../models/list/TableModel';
import { FilesDownload } from '../files/FilesDownload';
import { ButtonTypeMap } from '@material-ui/core/Button/Button';
import { AxiosPromise } from 'axios';

export type ExportButtonProps = {
    queryData: TableQueryData;
    loadRegisterList(queryData: TableQueryData): AxiosPromise<Blob>;
    filename: string;
    mimeType?: string;
    buttonProps?: ButtonTypeMap<{}>['props'];
};

export const ExportButton = (props: ExportButtonProps): JSX.Element => {
    const {
        queryData,
        loadRegisterList,
        filename,
        mimeType = 'application/vnd.ms-excel',
        buttonProps = { variant: 'text', color: 'primary', startIcon: <GetApp /> },
    } = props;
    const exportListXls = (): AxiosPromise<Blob> => {
        return loadRegisterList(queryData);
    };

    return (
        <FilesDownload
            onDownloadClick={exportListXls}
            filename={filename}
            mimeType={mimeType}
            buttonText={<FormattedMessage id="common.export" />}
            buttonProps={buttonProps}
        />
    );
};
