import React, { useMemo } from 'react';
import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Preset, TTable, TTableRow } from '@platform/ttable';
import { useStore } from '../../hooks/useStore';
import { ExpertiseTaskCuratorRegistryRow } from '../../store/ExpertiseTaskStore';
import { generatePath, NavLink } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { Row } from 'react-table';
import { observer } from 'mobx-react';
import { AuthorizationCheck } from '../../components/AuthorizationCheck';
import { entities, permissions } from '../../authSchemeConfig';

type ExpertiseTaskCellRendererProps = {
    row: Row<ExpertiseTaskCuratorRegistryRow>;
};

export const ExpertiseTaskListForCuratorPage = observer(
    (): JSX.Element => {
        const { expertiseTaskStore, presetStore, catalogStore, intlStore, userStore } = useStore();

        const cellFormatters = useMemo(
            () => ({
                number: (p: ExpertiseTaskCellRendererProps) => (
                    <Link
                        component={NavLink}
                        underline="always"
                        to={generatePath(clientRoute.expertiseTask, { id: p.row.original.id })}
                    >
                        {p.row.original.number}
                    </Link>
                ),
                'subject.title': (p: ExpertiseTaskCellRendererProps) => (
                    <Link
                        component={NavLink}
                        underline="always"
                        to={generatePath(clientRoute.subject, { id: p.row.original.subject.id })}
                    >
                        {p.row.original.subject.title}
                    </Link>
                ),
                'expertise.title': (p: ExpertiseTaskCellRendererProps) => (
                    <Link
                        component={NavLink}
                        underline="always"
                        to={generatePath(clientRoute.expertise, { id: p.row.original.expertise.id })}
                    >
                        {p.row.original.expertise.title}
                    </Link>
                ),
                'curator.fio': (p: ExpertiseTaskCellRendererProps) => (
                    <Link
                        component={NavLink}
                        underline="always"
                        to={generatePath(clientRoute.user, { id: p.row.original.curator.id })}
                    >
                        {p.row.original.curator.fio}
                    </Link>
                ),
                'expert.fio': (p: ExpertiseTaskCellRendererProps) => {
                    const expertId = p.row.original.expert.id;
                    return expertId ? (
                        <Link
                            component={NavLink}
                            underline="always"
                            to={generatePath(clientRoute.user, { id: expertId })}
                        >
                            {p.row.original.expert.fio}
                        </Link>
                    ) : null;
                },
            }),
            [],
        );

        const saveListPresets = <RowType extends TTableRow>(settings: Preset<RowType>[]): Promise<void> =>
            presetStore.saveListPresets(settings);

        return (
            <Container maxWidth="lg">
                <Box pt={5} pb={5}>
                    <Grid container direction="column" spacing={10}>
                        <Grid item container direction="row" justify="space-between">
                            <Grid item>
                                <Typography variant="h1">
                                    <FormattedMessage id="expertiseTask.listTitle" />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <AuthorizationCheck
                                entityCode={entities.System}
                                permCode={permissions.System.Administration}
                            >
                                {(allowed: boolean): JSX.Element => (
                                    <TTable<ExpertiseTaskCuratorRegistryRow>
                                        registryCode="mexp-tasks"
                                        fetchData={expertiseTaskStore.registry}
                                        fetchColumns={expertiseTaskStore.registryColumns}
                                        presetsSetting={presetStore}
                                        fetchCatalog={catalogStore.fetchCatalog}
                                        fetchSelectDataByUrl={catalogStore.fetchSelectDataByUrl}
                                        lang={intlStore.locale}
                                        cellFormatters={cellFormatters}
                                        saveListPresets={saveListPresets}
                                        fetchUserRoleList={userStore.userRoleList}
                                        upload={expertiseTaskStore.upload}
                                        isAdmin={allowed}
                                    />
                                )}
                            </AuthorizationCheck>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        );
    },
);
