import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { generatePath, useParams } from 'react-router-dom';
import { Grid, Snackbar } from '@material-ui/core';
import clientRoute from '../../../clientRoute';
import { TotObjectMain } from '../../../components/tot-object/TotObjectMain';
import { ExpertiseModel } from '../../../models/expertise/ExpertiseModel';
import { ServiceInfoPanel } from '../../../components/ServiceInfoPanel';
import ExpertiseHeader from './ExpertiseHeader/ExpertiseHeader';
import ExpertiseControlPanel from './ExpertiseControlPanel/ExpertiseControlPanel';
import ExpertisePlan from '../../templates-of-expertise/ExpertisePlan';
import { entities, permissions } from '../../../authSchemeConfig';
import { ExpertiseTasksTable } from './ExpertiseTasksTable';
import { AuthorizationCheck } from '../../../components/AuthorizationCheck';
import { ExpertiseTasksListByExpertiseModel } from '../../../models/expertise-task/ExpertiseTasksListByExpertiseModel';
import PlanEntryListModel from '../../../models/expertise-plan/PlanEntryListModel';
import { Alert } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { useStore } from '../../../hooks/useStore';
import { useReload } from '../../../hooks/useReload';

export type ExpertisePageRouteParams = {
    id: string;
};

export const ExpertisePage = observer(
    (): JSX.Element => {
        const { expertiseStore, expertiseTaskStore, intlStore, history } = useStore();

        const { id } = useParams<ExpertisePageRouteParams>();

        const [expertiseModel, setExpertiseModel] = useState<ExpertiseModel>(expertiseStore.getExpertiseModel(id));
        const [planEntryListModel, setPlanEntryListModel] = useState<PlanEntryListModel>(
            new PlanEntryListModel(id, expertiseStore),
        );
        const [expertiseTasksListByExpertiseModel, setExpertiseTasksListByExpertiseModel] = useState<
            ExpertiseTasksListByExpertiseModel
        >(new ExpertiseTasksListByExpertiseModel(id, expertiseTaskStore));

        const { reloadKey, reloadIncrement } = useReload();

        useEffect(() => {
            expertiseTasksListByExpertiseModel.loadExpertiseTasksList();
        }, [planEntryListModel.entries]);

        useEffect(() => {
            updateModel();
        }, [intlStore.locale]);

        const reloadModel = (): void => {
            setExpertiseModel(expertiseStore.getExpertiseModel(id));
            reloadIncrement();
        };

        const updateModel = (): void => {
            expertiseStore.loadExpertise(id).then(expertiseModel.load);
        };

        const deleteExpertise = (): Promise<void> => {
            return expertiseStore.deleteExpertise(expertiseModel.id).then(() => {
                history.push(generatePath(clientRoute.expertiseList));
            });
        };

        const onLifeCycleTransition = (transitionId: string, taskId: string): Promise<void> => {
            return expertiseStore.lifeCycleTransition(transitionId, taskId);
        };

        return (
            <Grid container direction="column" wrap="nowrap">
                <Snackbar
                    open={planEntryListModel.isAlertOpen}
                    autoHideDuration={6000}
                    onClose={planEntryListModel.setAlertIsClosed}
                >
                    <Alert severity="success">
                        <FormattedMessage id="expertise.updatedTasks" />
                    </Alert>
                </Snackbar>
                <Grid item>
                    <ExpertiseHeader expertiseModel={expertiseModel} reloadModel={reloadModel} />
                </Grid>
                <TotObjectMain>
                    <Grid container spacing={10}>
                        <Grid item container direction="column" spacing={10}>
                            <AuthorizationCheck
                                entityCode={entities.System}
                                permCode={permissions.System.Administration}
                            >
                                <Grid item>
                                    <ServiceInfoPanel
                                        metaInfoModel={expertiseModel.metaInfo}
                                        withForm={false}
                                        elevation={1}
                                    />
                                </Grid>
                            </AuthorizationCheck>
                            <Grid item>
                                <ExpertisePlan
                                    key={reloadKey}
                                    createPath={clientRoute.expertisePlanEntryCreate}
                                    editPath={clientRoute.expertisePlanEntryEdit}
                                    ownerPagePath={clientRoute.expertise}
                                    ownerEntityCode={entities.Expertise}
                                    editPermission={permissions.Expertise.EditPlan}
                                    model={planEntryListModel}
                                />
                            </Grid>
                            <Grid item>
                                <ExpertiseTasksTable key={reloadKey} model={expertiseTasksListByExpertiseModel} />
                            </Grid>
                        </Grid>
                    </Grid>
                </TotObjectMain>
                <Grid item>
                    <ExpertiseControlPanel
                        key={reloadKey}
                        expertiseId={expertiseModel.id}
                        expertiseTitle={expertiseModel.identifier}
                        deleteExpertise={deleteExpertise}
                        reloadModel={reloadModel}
                        expertiseStore={expertiseStore}
                        onLifeCycleTransition={onLifeCycleTransition}
                    />
                </Grid>
            </Grid>
        );
    },
);
