import React from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import { ReactComponent as DotMenu } from '../../resources/images/icons/dot-menu.svg';

export const ActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
    return (
        <IconButton onClick={onClick}>
            <SvgIcon>
                <DotMenu />
            </SvgIcon>
        </IconButton>
    );
};
