import React, { ChangeEvent, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Dialog, Grid, Portal, TextField } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { TransitionDialogProps } from '../TransitionDialog';
import { CodeTitle } from '../../../models/CodeTitle';
import { CatalogCodes, CatalogItemCodes } from '../../../store/CatalogStore';
import { renderAutoCompleteInput } from '../../renderAutoCompleteInput';
import { Autocomplete } from '@material-ui/lab';
import { useStore } from '../../../hooks';

export const ExpertiseTaskInviteToRejectDialog = observer(
    (props: TransitionDialogProps): JSX.Element => {
        const {
            transition,
            objectId,
            handleErrorTransition,
            handleSuccessTransition,
            setModalIsClosed,
            isModalOpen,
        } = props;
        const { expertiseTaskStore, catalogStore } = useStore();
        const intl = useIntl();
        const reasonsCatalog = useMemo(() => catalogStore.catalogItems(CatalogCodes.ReasonsOfRejectInvite), []);
        const [reason, setReason] = useState<CodeTitle | null>(null);
        const [otherReason, setOtherReason] = useState<string>('');
        const [validationStarted, setValidationStarted] = useState<boolean>(false);

        const isOtherReason = useMemo<boolean>((): boolean => {
            return !!reason && reason.code === CatalogItemCodes.ReasonsOfRejectInvite.Other;
        }, [reason]);

        const reasonText = useMemo<string>((): string => {
            if (reason && !isOtherReason) {
                return reason.title;
            }
            return otherReason;
        }, [reason, isOtherReason, otherReason]);

        const isValid = useMemo<boolean>((): boolean => !!reasonText, [reasonText]);

        const handleConfirm = async (): Promise<void> => {
            setValidationStarted(true);
            if (isValid) {
                expertiseTaskStore
                    .rejectTaskInvite(transition.id, objectId, reasonText)
                    .then(handleSuccessTransition)
                    .catch(handleErrorTransition)
                    .finally(setModalIsClosed);
            }
        };

        const onChangeReason = (event: ChangeEvent<{}>, value: CodeTitle | null): void => {
            setReason(value);
            setOtherReason('');
        };

        const onChangeOtherReason = (event: ChangeEvent<HTMLInputElement>): void => {
            setOtherReason(event.target.value);
        };

        const reasonError = useMemo<string>((): string => {
            if (validationStarted && !reason) {
                return intl.formatMessage({ id: 'validation.required' });
            }
            return '';
        }, [validationStarted, reason, intl]);

        const otherReasonError = useMemo<string>((): string => {
            if (validationStarted && isOtherReason && !otherReason) {
                return intl.formatMessage({ id: 'validation.required' });
            }
            return '';
        }, [validationStarted, isOtherReason, otherReason]);

        return (
            <Portal>
                <Dialog fullWidth={true} maxWidth="sm" aria-labelledby="confirmation-dialog-title" open={isModalOpen}>
                    <DialogTitle id="confirmation-dialog-title">
                        <FormattedMessage id="common.lifeCycleConfirmTitle" />
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container direction="column" spacing={5}>
                            <Grid item>
                                <Autocomplete
                                    onChange={onChangeReason}
                                    getOptionSelected={(option, value) => option.code === value.code}
                                    getOptionLabel={(option) => option.title}
                                    options={reasonsCatalog.slice()}
                                    value={reason}
                                    renderInput={renderAutoCompleteInput(
                                        intl.formatMessage({
                                            id: 'expertiseTask.invites.rejectReason',
                                        }),
                                        true,
                                        reasonError,
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                {isOtherReason && (
                                    <TextField
                                        label={<FormattedMessage id="expertiseTask.invites.otherReason" />}
                                        variant="outlined"
                                        multiline
                                        fullWidth
                                        rows={3}
                                        required
                                        onChange={onChangeOtherReason}
                                        value={otherReason}
                                        error={!!otherReasonError}
                                        helperText={otherReasonError}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={setModalIsClosed} color="primary">
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button onClick={handleConfirm} color="secondary" variant="contained">
                            <FormattedMessage id={'expertiseTask.invites.reject'} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Portal>
        );
    },
);
