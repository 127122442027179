import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { RootStore } from '../../store/RootStore';
import { Api } from '../../store/Api';
import { Link as RouterLink } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { Box, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { PersonStore } from '../../store/PersonStore';
import { HeaderLink } from './HeaderLink';

type StoreProps = {
    api: Api;
    personStore: PersonStore;
};

type OtherProps = {};

type InnerProps = StoreProps & OtherProps;

class StartAuthHeaderLink extends React.Component<InnerProps, {}> {
    render(): JSX.Element {
        const { api, personStore } = this.props;
        switch (api.authStatus) {
            case 'ok':
                return (
                    <Grid item>
                        <Box pt={2.25}>
                            <HeaderLink
                                component={RouterLink}
                                to={personStore.redirectPath}
                                color="secondary"
                                underline="none"
                            >
                                {personStore.person.lastNameWithInitials}
                            </HeaderLink>
                        </Box>
                    </Grid>
                );
            default:
                return (
                    <Grid item>
                        <Box pt={0.4}>
                            <HeaderLink
                                component={RouterLink}
                                to={clientRoute.login}
                                color="secondary"
                                underline="none"
                            >
                                <Grid container item alignItems="center">
                                    <FormattedMessage id="startPage.headerLinks.login" />
                                    <Box ml={1} style={{ lineHeight: 1 }}>
                                        <ExitToAppIcon />
                                    </Box>
                                </Grid>
                            </HeaderLink>
                        </Box>
                    </Grid>
                );
        }
    }
}

export default compose<InnerProps, OtherProps>(
    inject<RootStore, {}, StoreProps, {}>((rootStore) => ({
        api: rootStore.api,
        personStore: rootStore.personStore,
    })),
    observer,
)(StartAuthHeaderLink);
