import { TransitionsDTO } from './../models/tot-object/TotObjectTransitions';
import { action, computed, observable } from 'mobx';
import { RootStore } from './RootStore';
import { Api } from './Api';
import apiConfigs from '../apiConfigs';
import { History } from 'history';
import { AxiosError } from 'axios';
import { FormDTO, FullSubmission } from '@platform/formiojs-react';
import TemplatesOfExpertiseModel from '../models/templates-of-expertise/TemplatesOfExpertiseModel';
import EntityCreatedDTO from '../models/EntityCreatedDTO';
import { MetaInfoDTO } from '../models/MetaInfo';
import { IdTitle } from '../models/IdTitle';
import { ExpertisePlanStore, PlanEntryDTO } from './ExpertisePlanStore';
import { IntlStore } from './IntlStore';

export interface TemplatesOfExpertiseDTO {
    id: string;
    title: string;
    metaInfo: MetaInfoDTO;
    formInfo: FormDTO;
}

export type PlanEntryDetailsDTO = {
    taskTypeTitle: string;
    viewPointTitle?: string;
    tasksMin: number;
    tasksMax: number;
    processTitle: string;
    reportFormInfo: string;
    taskFormInfo: string;
    acceptanceDays: number;
    processingDays: number;
};

export interface TemplateDetailsDTO {
    id: string;
    process: IdTitle;
    planEntries: Array<PlanEntryDetailsDTO>;
}

export class TemplatesOfExpertiseStore extends ExpertisePlanStore {
    @observable rootStore: RootStore;
    @observable api: Api;
    @observable showValidation: boolean = false;
    @observable intl: IntlStore;

    constructor(rootStore: RootStore) {
        super();
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.intl = rootStore.intlStore;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    getTransitionButtons(templateId: string): Promise<TransitionsDTO> {
        return this.api.client(apiConfigs.getTemplateOfExpertiseLifeCycleTransitions(templateId)).then((r) => r.data);
    }

    @action.bound
    lifeCycleTransition(transitionId: string, templateId: string, updateReloadKey: () => void): Promise<void> {
        return this.api
            .client(apiConfigs.templateOfExpertiseLifeCycleTransition(transitionId, templateId))
            .then((r) => {
                updateReloadKey();
                return r.data;
            });
    }

    @action.bound
    loadTemplateDTO(id: string): Promise<TemplateDetailsDTO> {
        return this.api.client(apiConfigs.templateDTO(id)).then((r) => r.data);
    }

    @action.bound
    loadTemplatesSelectList(): Promise<IdTitle[]> {
        return this.api.client(apiConfigs.templatesSelectList).then((r) => r.data);
    }

    @action.bound
    getTemplatesOfExpertiseModel(id: string): TemplatesOfExpertiseModel {
        const model = new TemplatesOfExpertiseModel(id);
        this.loadTemplateOfExpertiseDTO(id).then(model.load);
        return observable(model);
    }

    @action.bound
    loadTemplateOfExpertiseDTO(id: string): Promise<TemplatesOfExpertiseDTO> {
        return this.api.client(apiConfigs.loadTemplateDTO(id)).then((r) => r.data);
    }

    @action.bound
    saveChangeTemplateOfExpertise(id: string, submission: FullSubmission): Promise<void> {
        return this.api.client(apiConfigs.saveTemplate(id, submission)).then((r) => r.data);
    }

    @action.bound
    create(): Promise<string> {
        return this.api
            .client(apiConfigs.createTemplate)
            .then((r) => r.data.id)
            .catch(({ code, message }: AxiosError) => console.error(`${code} ${message}`));
    }

    @action.bound
    delete(id: string): Promise<void> {
        return this.api
            .client(apiConfigs.deleteTemplate(id))
            .then((r) => r.data)
            .catch(({ code, message }: AxiosError) => console.error(`${code} ${message}`));
    }

    @action.bound
    loadPlanEntryList(templateId: string): Promise<PlanEntryDTO[]> {
        return this.api.client(apiConfigs.loadTemplatePlanEntries(templateId)).then((r) => r.data);
    }

    @action.bound
    createPlanEntry(templateId: string): Promise<EntityCreatedDTO> {
        return this.api.client(apiConfigs.createTemplatePlanEntry(templateId)).then((r) => r.data);
    }

    @action.bound
    updatePlanEntry(
        id: string,
        submission: FullSubmission,
        taskFormCode?: string,
        taskSubmission?: FullSubmission,
    ): Promise<void> {
        return this.api
            .client(apiConfigs.updateTemplatePlanEntry(id, submission, taskFormCode, taskSubmission))
            .then((r) => r.data);
    }

    @action.bound
    loadPlanEntry(id: string): Promise<PlanEntryDTO> {
        return this.api.client(apiConfigs.loadTemplatePlanEntry(id)).then((r) => r.data);
    }

    @action.bound
    deletePlanEntry(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteTemplatePlanEntry(id)).then((r) => r.data);
    }
}
