import React from 'react';
import { Grid } from '@material-ui/core';
import { TotObjectHeaderLabel, TotObjectHeaderValue } from '../tot-object/TotObjectHeader';
import { EllipsisBox } from '../../pages/task/EllipsisBox';

export type RowDataProps = {
    label: JSX.Element;
    value: string | JSX.Element | React.ReactNode;
    valueWrapper?: (children: {} | null) => JSX.Element;
    withEllipsis?: boolean;
    valueMaxLength?: number;
};

export const RowData = (props: RowDataProps): JSX.Element => {
    const { label, value = '', valueMaxLength, withEllipsis = true, valueWrapper } = props;
    const resultValue = withEllipsis ? <EllipsisBox text={value as string} correctLength={valueMaxLength} /> : value;
    return (
        <Grid container alignItems="baseline" spacing={2} style={{ width: 'calc(100% + 10px)' }} wrap="nowrap">
            <Grid item>
                <TotObjectHeaderLabel>{label}</TotObjectHeaderLabel>
            </Grid>
            <Grid item>
                <TotObjectHeaderValue>{valueWrapper ? valueWrapper(resultValue) : resultValue}</TotObjectHeaderValue>
            </Grid>
        </Grid>
    );
};
