import React, { ReactNode } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, CircularProgress, Paper, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { observable } from 'mobx';
import { fromPromise } from 'mobx-utils';
import { AuthenticationStore } from '../../../store/AuthenticationStore';
import { RootStore } from '../../../store/RootStore';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import { PersonStore } from '../../../store/PersonStore';

type OuterProps = {};
type StoreProps = {
    authenticationStore: AuthenticationStore;
    personStore: PersonStore;
};
type InnerProps = OuterProps & StoreProps & RouteComponentProps;

class EsiaLoginPage extends React.Component<InnerProps, {}> {
    @observable login = this.props.authenticationStore
        .esiaLogin(this.props.location.search)
        .then(() => this.props.personStore.getInfo());

    render(): ReactNode {
        return fromPromise(this.login).case({
            pending: () => <CircularProgress />,
            fulfilled: () => <Redirect to={clientRoute.campaigns} />,
            rejected: () => (
                <Paper elevation={24}>
                    <Box p={12}>
                        <Typography variant="h5">
                            <Box fontWeight="fontWeightBold">
                                <FormattedMessage id="authentication.error" />
                            </Box>
                        </Typography>
                    </Box>
                </Paper>
            ),
        });
    }
}

export default compose<InnerProps, OuterProps>(
    withRouter,
    inject<RootStore, {}, StoreProps, {}>((rootStore) => ({
        authenticationStore: rootStore.authenticationStore,
        personStore: rootStore.personStore,
    })),
    observer,
)(EsiaLoginPage);
