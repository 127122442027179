import React, { ChangeEvent, FormEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { NewSubjectDTO } from '../../../store/SubjectStore';
import { IdTitle } from '../../../models/IdTitle';
import { Autocomplete } from '@material-ui/lab';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import { useStore } from '../../../hooks';
import { ErrorMessage } from '../../../components';

export type CreateSubjectDialogProps = {
    onCancel: () => void;
    onSubmit: (dto: NewSubjectDTO) => Promise<void>;
    isLoading: boolean;
    disableLoading: () => void;
};

export const CreateSubjectDialog = observer(
    (props: CreateSubjectDialogProps): JSX.Element => {
        const { onCancel, onSubmit, isLoading, disableLoading } = props;
        const { subjectStore } = useStore();
        const intl = useIntl();

        const [categoryOptions, setCategoryOptions] = useState<IdTitle[]>([]);
        const [categoryOptionsLoading, setCategoryOptionsLoading] = useState<boolean>(true);

        const [campaignOptions, setCampaignOptions] = useState<IdTitle[]>([]);
        const [campaignOptionsLoading, setCampaignOptionsLoading] = useState<boolean>(false);
        const [campaignOptionsLoaded, setCampaignOptionsLoaded] = useState<boolean>(false);

        const [category, setCategory] = useState<string>();
        const [campaign, setCampaign] = useState<string>();
        const [subjectNumber, setSubjectNumber] = useState<string>('');
        const [validationStarted, setValidationStarted] = useState<boolean>();

        const [errorText, setErrorText] = useState<string | ReactNode>();

        useEffect(() => {
            subjectStore
                .loadCategorySelectOptions()
                .then((options) => {
                    setCategoryOptions(options);
                })
                .finally(() => {
                    setCategoryOptionsLoading(false);
                });
        }, []);

        useEffect(() => {
            subjectStore.getSubjectNumber(campaign).then(setSubjectNumber);
        }, [campaign]);

        const onChangeCategory = (event: ChangeEvent<{}>, value: IdTitle | null): void => {
            setCategory(value?.id);
        };

        const enableCategoryValidation = (): void => {
            setValidationStarted(true);
        };

        const onChangeCampaign = (event: ChangeEvent<{}>, value: IdTitle | null): void => {
            setCampaign(value?.id);
        };

        const onNumberChange = (event: ChangeEvent<HTMLInputElement>): void => {
            setSubjectNumber(event.target.value);
        };

        const loadCampaignOptions = (): void => {
            if (!campaignOptionsLoaded) {
                setCampaignOptionsLoading(true);
                subjectStore
                    .loadCampaignSelectOptions()
                    .then((options) => {
                        setCampaignOptions(options);
                        setCampaignOptionsLoaded(true);
                    })
                    .finally(() => {
                        setCampaignOptionsLoading(false);
                    });
            }
        };

        const errorCategory: string = useMemo((): string => {
            if (validationStarted && !category) {
                return intl.formatMessage({ id: 'validation.required' });
            }
            return '';
        }, [validationStarted, category]);

        const onSubmitCallback = (event: FormEvent<HTMLFormElement>): void => {
            event.preventDefault();
            enableCategoryValidation();
            if (category) {
                onSubmit({
                    categoryId: category,
                    campaignId: campaign,
                    identifier: subjectNumber,
                }).catch((error) => {
                    disableLoading();

                    const errorText = ErrorMessage(error);
                    setErrorText(errorText);
                });
            }
        };

        const renderInput = (
            label: string,
            required: boolean,
            errorText?: string,
        ): ((params: AutocompleteRenderInputParams) => ReactNode) => {
            return (params: AutocompleteRenderInputParams): ReactNode => (
                <TextField
                    {...params}
                    required={required}
                    label={label}
                    variant="outlined"
                    error={!!errorText}
                    helperText={errorText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {categoryOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            );
        };

        return (
            <Dialog maxWidth="xs" fullWidth open={true} scroll="body">
                <Box pt={4} pr={4}>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <Box pl={12} pr={12} pb={12}>
                    <Grid container justify="center">
                        <Grid item>
                            <Typography variant="h5">
                                <Box fontWeight="fontWeightBold">
                                    <FormattedMessage id="subject.newSubject" />
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box pt={8}>
                        <form noValidate onSubmit={onSubmitCallback}>
                            <Grid container spacing={6} direction="column" justify="center">
                                <Grid item>
                                    <Autocomplete
                                        onChange={onChangeCategory}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => option.title}
                                        options={categoryOptions.slice()}
                                        loading={categoryOptionsLoading}
                                        renderInput={renderInput(
                                            intl.formatMessage({ id: 'subject.fields.category' }),
                                            true,
                                            errorCategory,
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        onOpen={loadCampaignOptions}
                                        onChange={onChangeCampaign}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => option.title}
                                        options={campaignOptions.slice()}
                                        loading={campaignOptionsLoading}
                                        renderInput={renderInput(
                                            intl.formatMessage({ id: 'subject.fields.campaign' }),
                                            false,
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth={true}
                                        variant="outlined"
                                        label={intl.formatMessage({ id: 'subject.fields.identifier' })}
                                        value={subjectNumber}
                                        onChange={onNumberChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography component="p" color="error" align="center">
                                        {errorText}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={isLoading}
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        type="submit"
                                    >
                                        <FormattedMessage id="subject.createSubject" />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={onCancel} fullWidth size="large" variant="contained">
                                        <FormattedMessage id="common.cancel" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Dialog>
        );
    },
);
