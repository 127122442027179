import React from 'react';
import { Grid } from '@material-ui/core';
import { AuthorizationCheck } from '../../../../components/AuthorizationCheck';
import { entities, permissions } from '../../../../authSchemeConfig';
import { TotObjectDeleteButton } from '../../../../components/tot-object/TotObjectActionsBar';
import { FormattedMessage } from 'react-intl';

interface Props {
    expertiseId: string;
    deleteExpertise: () => Promise<void>;
    title: string;
}

const ControlPanelLeft = ({ expertiseId, deleteExpertise, title }: Props): JSX.Element => (
    <Grid spacing={3} alignItems="center" justify="center" container item>
        <AuthorizationCheck
            entityCode={entities.Expertise}
            entityId={expertiseId}
            permCode={permissions.Expertise.Delete}
        >
            <TotObjectDeleteButton
                id="delete-expertise"
                title={<FormattedMessage id="common.confirmDeletion" />}
                message={<FormattedMessage id="expertise.confirmDeletionInfoText" values={{ title }} />}
                onConfirm={deleteExpertise}
                wrappedComponentProps={{
                    tooltipTitleId: 'expertise.actions.deleteExpertise',
                }}
            />
        </AuthorizationCheck>
    </Grid>
);

export default ControlPanelLeft;
