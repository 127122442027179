import React from 'react';
import { ExpansionPanel, Typography, Grid, Box, withTheme, useTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { TotObjectPanelDetails, TotObjectPanelSummary } from '../../components/tot-object/TotObjectServiceInfo';
import { TotObjectPanelLabel } from '../../components/tot-object/TotObjectPanel';
import { StyledButton, StyledTypography } from './StyledCampaignComponents';
import { NavLink } from 'react-router-dom';
import { CampaignTasks } from '../../store/CampaignStore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import { createLinkWithQueries } from '../../utils/createLinkWithQueries';
import { CampaignTasksLinks } from '../../models/campaign/CampaignLinksModel';

type CampaignTasksPanelProps = ThemedComponentProps & {
    tasks: CampaignTasks;
    links: CampaignTasksLinks;
};

export const CampaignTasksPanel = (props: CampaignTasksPanelProps): JSX.Element => {
    const { tasks, links } = props;
    const { overdue, active, total, done, notCompleted, responseWaiting, withoutExpert } = links;
    const theme = useTheme();

    return (
        <ExpansionPanel defaultExpanded>
            <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">
                    <FormattedMessage id="campaign.tasks.title" />
                </Typography>
            </TotObjectPanelSummary>
            <TotObjectPanelDetails>
                <Grid container direction="column">
                    <Grid item>
                        <Box borderBottom={theme?.variables.lightFormBorder} pb={9}>
                            <Grid item container direction="row">
                                <Grid item xs={3}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <TotObjectPanelLabel>
                                                <FormattedMessage id="campaign.tasks.total" />
                                            </TotObjectPanelLabel>
                                        </Grid>
                                        <Grid item>
                                            <StyledTypography>
                                                <StyledButton
                                                    component={NavLink}
                                                    to={createLinkWithQueries(total.urn, total.queries)}
                                                    variant="text"
                                                >
                                                    {tasks.total}
                                                </StyledButton>
                                            </StyledTypography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <TotObjectPanelLabel>
                                                <FormattedMessage id="campaign.tasks.withoutExpert" />
                                            </TotObjectPanelLabel>
                                        </Grid>
                                        <Grid item>
                                            <StyledTypography>
                                                <StyledButton
                                                    component={NavLink}
                                                    to={createLinkWithQueries(withoutExpert.urn, withoutExpert.queries)}
                                                    variant="text"
                                                >
                                                    {tasks.withoutExpert}
                                                </StyledButton>
                                            </StyledTypography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <TotObjectPanelLabel>
                                                <FormattedMessage id="campaign.tasks.responseWaiting" />
                                            </TotObjectPanelLabel>
                                        </Grid>
                                        <Grid item>
                                            <StyledTypography>
                                                <StyledButton
                                                    component={NavLink}
                                                    to={createLinkWithQueries(
                                                        responseWaiting.urn,
                                                        responseWaiting.queries,
                                                    )}
                                                    variant="text"
                                                >
                                                    {tasks.responseWaiting}
                                                </StyledButton>
                                            </StyledTypography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box pt={9}>
                            <Grid item container direction="row">
                                <Grid item xs={3}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <TotObjectPanelLabel>
                                                <FormattedMessage id="campaign.tasks.active" />
                                            </TotObjectPanelLabel>
                                        </Grid>
                                        <Grid item>
                                            <StyledTypography>
                                                <StyledButton
                                                    component={NavLink}
                                                    to={createLinkWithQueries(active.urn, active.queries)}
                                                    variant="text"
                                                >
                                                    {tasks.active}
                                                </StyledButton>
                                            </StyledTypography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <TotObjectPanelLabel>
                                                <FormattedMessage id="campaign.tasks.overdue" />
                                            </TotObjectPanelLabel>
                                        </Grid>
                                        <Grid item>
                                            <StyledTypography>
                                                <StyledButton
                                                    component={NavLink}
                                                    to={createLinkWithQueries(overdue.urn, overdue.queries)}
                                                    variant="text"
                                                >
                                                    {tasks.overdue}
                                                </StyledButton>
                                            </StyledTypography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <TotObjectPanelLabel>
                                                <FormattedMessage id="campaign.tasks.done" />
                                            </TotObjectPanelLabel>
                                        </Grid>
                                        <Grid item>
                                            <StyledTypography>
                                                <StyledButton
                                                    component={NavLink}
                                                    to={createLinkWithQueries(done.urn, done.queries)}
                                                    variant="text"
                                                >
                                                    {tasks.done}
                                                </StyledButton>
                                            </StyledTypography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <TotObjectPanelLabel>
                                                <FormattedMessage id="campaign.tasks.notCompleted" />
                                            </TotObjectPanelLabel>
                                        </Grid>
                                        <Grid item>
                                            <StyledTypography>
                                                <StyledButton
                                                    component={NavLink}
                                                    to={createLinkWithQueries(notCompleted.urn, notCompleted.queries)}
                                                    variant="text"
                                                >
                                                    {tasks.notCompleted}
                                                </StyledButton>
                                            </StyledTypography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </TotObjectPanelDetails>
        </ExpansionPanel>
    );
};
