import { TableModel, RowsData, TableQueryData } from '../list/TableModel';
import { action, observable } from 'mobx';
import { Api } from '../../store/Api';
import apiConfigs from '../../apiConfigs';
import { UserNameModel, UserPersonDTO } from '../person/UserNameModel';
import { IdTitle } from '../IdTitle';
import { DateUtils } from '../../utils/DateUtils';
import { handleAxiosErrorByResponseStatus } from '../../utils/errorHandleUtils';
import clientRoute from '../../clientRoute';
import { History } from 'history';
import { IdTitleNumber } from '../IdTitleNumber';
import { LikeFilter } from '../list/filter/LikeFilter';
import { InSetFilter } from '../list/filter/InSetFilter';
import { DateRangeFilter } from '../list/filter/DateRangeFilter';
import { GroupedIdTitle } from '../GroupedIdTitle';

export interface ExpertiseRowDTO {
    id: string;
    identifier: string;
    subject: IdTitleNumber;
    curator?: UserPersonDTO;
    deadline?: string; //date
    state: string;
    template?: IdTitle;
    campaign?: IdTitle;
}

export interface ExpertiseRow {
    id: string;
    identifier: string;
    subject: IdTitleNumber;
    curator?: UserNameModel;
    deadline?: Date;
    state: string;
    template?: IdTitle;
    campaign?: IdTitle;
}

type ExpertiseFilter = {
    identifier: LikeFilter;
    subjectTitle: LikeFilter;
    subjectIdentifier: LikeFilter;
    curator: InSetFilter;
    deadline: DateRangeFilter;
    state: InSetFilter;
    template: InSetFilter;
    campaigns: InSetFilter;
};

export class ExpertiseListModel extends TableModel<ExpertiseRow, ExpertiseFilter, {}> {
    @observable protected api: Api;
    @observable history: History;
    @observable stateFilterData: GroupedIdTitle;
    @observable curatorFilterData: IdTitle[];
    @observable templateFilterData: IdTitle[];
    @observable campaignFilterData: IdTitle[];

    person: UserNameModel;

    constructor(api: Api, history: History) {
        const filter: ExpertiseFilter = {
            identifier: new LikeFilter(),
            subjectTitle: new LikeFilter(),
            subjectIdentifier: new LikeFilter(),
            curator: new InSetFilter(),
            deadline: new DateRangeFilter(),
            state: new InSetFilter(),
            template: new InSetFilter(),
            campaigns: new InSetFilter(),
        };
        super(filter, {});

        this.api = api;
        this.history = history;

        this.stateFilterData = {};
        this.curatorFilterData = [];
        this.templateFilterData = [];
        this.campaignFilterData = [];

        this.person = new UserNameModel();
        this.getFilterData();
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<ExpertiseRow>> {
        return this.api
            .client(apiConfigs.expertiseListData(queryData))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            )
            .then(({ rows, count }) => ({ rows: rows.map(this.mapDtoToRow), count }));
    }

    @action.bound
    mapDtoToRow(dto: ExpertiseRowDTO): ExpertiseRow {
        const deadline = new Date(dto.deadline || '');
        const parsedFields = {
            ...(DateUtils.isValidDate(deadline) && { deadline }),
            ...(dto.curator && { curator: new UserNameModel().load(dto.curator) }),
        };

        return {
            id: dto.id,
            identifier: dto.identifier,
            state: dto.state,
            subject: dto.subject,
            template: dto.template,
            campaign: dto.campaign,
            ...parsedFields,
        };
    }

    @action.bound
    getFilterData(): void {
        this.api
            .client(apiConfigs.loadExpertiseFiltersData)
            .then((r) => r.data)
            .then(
                action((data) => {
                    this.stateFilterData = data.statesByProcess;
                    this.curatorFilterData = data.curators.map(
                        (curator: UserPersonDTO) => this.person.load(curator).asIdTitle,
                    );
                    this.templateFilterData = data.templates;
                    this.campaignFilterData = data.campaigns;
                }),
            );
    }
}
