import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { generatePath, Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { CampaignHeader } from './CampaignHeader/CampaignHeader';
import { CampaignControlPanel } from './CampaignControlPanel/CampaignControlPanel';
import clientRoute from '../../clientRoute';
import { TotObjectMain } from '../../components/tot-object/TotObjectMain';
import { ServiceInfoPanel } from '../../components/ServiceInfoPanel';
import { entities, permissions } from '../../authSchemeConfig';
import { AuthorizationCheck } from '../../components/AuthorizationCheck';
import { CampaignSubjectsPanel } from './CampaignSubjectsPanel';
import { CampaignExpertisesPanel } from './CampaignExpertisesPanel';
import { CampaignTasksPanel } from './CampaignTasksPanel';
import { FormApi } from '@platform/formiojs-react';
import { TotObjectForm } from '../../components/tot-object/TotObjectForm';
import { useStore } from '../../hooks/useStore';
import { useReload } from '../../hooks/useReload';
import { ErrorDialog, ErrorMessage } from '../../components';
import { useError } from '../../hooks';

export type CampaignPageRouteParams = {
    id: string;
};

export const CampaignPage = observer(
    (): JSX.Element => {
        const { id } = useParams<CampaignPageRouteParams>();
        const { reloadKey, reloadIncrement } = useReload();
        const history = useHistory();
        const location = useLocation();

        const { campaignStore, intlStore } = useStore();
        const campaignModel = useMemo(() => campaignStore.getCampaignModel(id), [id]);
        const campaignStatisticModel = useMemo(() => campaignStore.getCampaignStatisticModel(id), [id]);
        const campaignLinksModel = useMemo(() => campaignStore.getCampaignLinksModel(id), [id]);
        const [formApi, setFormAPi] = useState<FormApi>();
        const { isError, errorText, setErrorIsClosed, setErrorIsOpen } = useError();

        const { subjects, expertises, tasks } = campaignStatisticModel;
        const { lifeCycleTransition } = campaignStore;

        useEffect(() => updateCard, [intlStore.locale, reloadKey]);

        useEffect(() => {
            if (formApi) {
                formApi.setSubmissionFromStartSubmission();
            }
        }, [location.pathname]);

        const updateCard = (): void => {
            campaignStore.loadCampaign(id).then(campaignModel.loadCard);
        };

        const deleteCampaign = useCallback((): Promise<void> => {
            return campaignStore.deleteCampaign(campaignModel.id).then(() => {
                history.push(generatePath(clientRoute.campaigns));
            });
        }, [campaignStore.deleteCampaign, campaignModel.id]);

        const validateForm = (onSuccess?: () => Promise<void>): Promise<void> => {
            const isFormValid = (formApi && formApi.validate()) || false;
            if (isFormValid) {
                return onSuccess ? onSuccess() : Promise.resolve();
            }
            return Promise.reject(['validation error']);
        };

        const onLifeCycleTransition = useCallback(
            (transitionId: string, campaignId: string, validate?: boolean): Promise<void> => {
                const onSuccess = () => lifeCycleTransition(transitionId, campaignId, reloadIncrement);
                if (validate) {
                    return validateForm(onSuccess);
                } else {
                    return onSuccess();
                }
            },
            [lifeCycleTransition, validateForm, reloadIncrement],
        );

        const onFormReady = useCallback(
            (form: FormApi) => {
                setFormAPi(form);
            },
            [setFormAPi],
        );

        const setCommonValidationState = useCallback(
            (state: boolean) => {
                campaignStore.showValidation = state;
            },
            [campaignStore.showValidation],
        );

        const onSubmit = useCallback((): void => {
            if (formApi && formApi.validate()) {
                campaignStore
                    .editCampaign(id, formApi.getSubmission())
                    .then(() => {
                        formApi.setStartSubmission();
                        reloadIncrement();
                        history.push(generatePath(clientRoute.campaign, { id }));
                    })
                    .catch((error) => {
                        const errorText = ErrorMessage(error);
                        setErrorIsOpen(errorText);
                    });
            }
        }, [formApi, campaignStore.editCampaign, id, history, generatePath]);

        return (
            <React.Fragment>
                <ErrorDialog message={errorText} open={isError} onClose={setErrorIsClosed} />

                <Grid container direction="column" wrap="nowrap">
                    <Grid item>
                        <CampaignHeader campaignModel={campaignModel} />
                    </Grid>
                    <TotObjectMain>
                        <Grid item container direction="column" spacing={6}>
                            <Grid item>
                                <AuthorizationCheck
                                    entityCode={entities.System}
                                    permCode={permissions.System.Administration}
                                >
                                    <ServiceInfoPanel
                                        metaInfoModel={campaignModel.metaInfo}
                                        withForm={false}
                                        elevation={1}
                                    />
                                </AuthorizationCheck>
                            </Grid>

                            <Grid item style={{ maxWidth: '100%' }}>
                                <TotObjectForm
                                    reloadKey={reloadKey}
                                    formName="template"
                                    model={campaignModel.formModel}
                                    editPath={clientRoute.campaignEdit}
                                    createPath={clientRoute.campaignCreate}
                                    readPath={clientRoute.campaign}
                                    onFormReady={onFormReady}
                                    showReadonlyValidation={campaignStore.showValidation}
                                    hideReadonlyValidation={() => setCommonValidationState(false)}
                                    editAuthProps={{
                                        entityCode: entities.ExpertiseCampaign,
                                        permCode: permissions.ExpertiseCampaign.Edit,
                                        entityId: id,
                                    }}
                                />
                            </Grid>

                            <Route path={clientRoute.campaign} exact>
                                <Grid item>
                                    <CampaignSubjectsPanel subjects={subjects} links={campaignLinksModel.subjects} />
                                </Grid>
                                <Grid item>
                                    <CampaignExpertisesPanel
                                        expertises={expertises}
                                        links={campaignLinksModel.expertises}
                                    />
                                </Grid>
                                <Grid item>
                                    <CampaignTasksPanel tasks={tasks} links={campaignLinksModel.tasks} />
                                </Grid>
                            </Route>
                        </Grid>
                    </TotObjectMain>

                    <CampaignControlPanel
                        key={reloadKey}
                        campaignTitle={campaignModel.title}
                        campaignIdentifier={campaignModel.identifier}
                        deleteCampaign={deleteCampaign}
                        getCampaignTransitions={campaignStore.getLifeCycleTransitions}
                        lifeCycleTransition={onLifeCycleTransition}
                        onSubmit={onSubmit}
                    />
                </Grid>
            </React.Fragment>
        );
    },
);
