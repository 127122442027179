import React from 'react';
import { Grid } from '@material-ui/core';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { getOrElse } from '../../../utils/getOrElse';
import { RowData } from '../../../components/PageHeader/RowData';
import CategoryOfSubjectsModel from '../../../models/categories/CategoryOfSubjectsModel';

interface Props {
    created: CategoryOfSubjectsModel['metaInfo']['created'];
    modified: CategoryOfSubjectsModel['metaInfo']['modified'];
}

const PageHeaderLeft = ({ created, modified }: Props): JSX.Element => (
    <React.Fragment>
        <Grid item>
            <RowData
                label={<FormattedMessage id="category.fields.created" />}
                value={getOrElse(created, <FormattedDate value={created} />, '-')}
            />
        </Grid>
        <Grid item>
            <RowData
                label={<FormattedMessage id="category.fields.lastModified" />}
                value={getOrElse(modified, <FormattedDate value={modified} />, '-')}
            />
        </Grid>
    </React.Fragment>
);

export default PageHeaderLeft;
