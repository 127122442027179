import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AxiosError } from 'axios';
import { Grid, Typography } from '@material-ui/core';
import { Transition, TransitionsDTO } from '../../models/tot-object/TotObjectTransitions';
import { ErrorDialog } from '../ErrorDialog';
import { ErrorMessage } from '../ErrorMessage';
import { TransitionButton } from './TransitionButton';
import { TransitionDialog } from '../transition-dialogs/TransitionDialog';
import { GridNoOverflow } from '../styled/GridNoOverflow';
import { useStore } from '../../hooks/useStore';
import { useModal } from '../../hooks/useModal';
import { useError } from '../../hooks/useError';

export type TotObjectTransitionsProps = {
    objectId: string;
    updateObjectPage: () => void;
    getTransitions: (objectId: string) => Promise<TransitionsDTO>;
    lifeCycleTransition: (transitionId: string, objectId: string, validate?: boolean) => Promise<void>;
    noOverflow?: boolean;
};

export const TotObjectTransitions = observer(
    (props: TotObjectTransitionsProps): JSX.Element => {
        const { intlStore } = useStore();
        const { objectId, getTransitions, updateObjectPage, lifeCycleTransition, noOverflow } = props;

        const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();
        const { isError, errorText, setErrorIsClosed, setErrorIsOpen } = useError();

        const [transition, setTransition] = useState<Transition>();
        const [data, setData] = useState<TransitionsDTO>([]);

        const updateTransitions = (): void => {
            getTransitions(objectId).then((data) => setData(data));
        };

        useEffect(() => {
            updateTransitions();
        }, [intlStore.locale]);

        const handleLifeCycleTransition = (): Promise<void> => {
            return lifeCycleTransition(transition?.id || '', objectId, transition?.params.validate || false).finally(
                setModalIsClosed,
            );
        };

        const handleErrorTransition = (error: AxiosError): void => {
            const errorText = ErrorMessage(error);
            setErrorIsOpen(errorText);
        };

        const onClickModalOpen = (transition: Transition): void => {
            setTransition(transition);
            setModalIsOpen();
        };

        const closeModal = (): void => {
            setModalIsClosed();
            setTransition(undefined);
        };

        const renderTransitions = (noOverflow: boolean | undefined): Array<JSX.Element> => {
            return data.map((transition: Transition) => {
                return noOverflow ? (
                    <GridNoOverflow key={transition.id}>
                        <TransitionButton
                            onClick={(): void => onClickModalOpen(transition)}
                            color={transition.params.btnType}
                            size="medium"
                            variant="contained"
                            type="button"
                        >
                            <Typography variant="inherit" noWrap>
                                {transition.title}
                            </Typography>
                        </TransitionButton>
                    </GridNoOverflow>
                ) : (
                    <Grid item key={transition.id}>
                        <TransitionButton
                            onClick={(): void => onClickModalOpen(transition)}
                            color={transition.params.btnType}
                            size="medium"
                            variant="contained"
                            type="button"
                        >
                            <span>{transition.title}</span>
                        </TransitionButton>
                    </Grid>
                );
            });
        };

        return (
            <React.Fragment>
                {transition && (
                    <TransitionDialog
                        objectId={objectId}
                        transition={transition}
                        isModalOpen={isModalOpen}
                        setModalIsClosed={closeModal}
                        lifeCycleTransition={handleLifeCycleTransition}
                        handleSuccessTransition={updateObjectPage}
                        handleErrorTransition={handleErrorTransition}
                    />
                )}
                <ErrorDialog message={errorText} open={isError} onClose={setErrorIsClosed} />
                {data && renderTransitions(noOverflow)}
            </React.Fragment>
        );
    },
);
