import React from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { ExpertiseCreateDialog } from '../../expertise/ExpertiseCreateDialog';
import { IdTitle } from '../../../models/IdTitle';
import { useModal } from '../../../hooks';

export type CreateExpertiseButtonProps = {
    tabIndex: number;
    subject: IdTitle;
};

export const CreateExpertiseButton = observer(
    (props: CreateExpertiseButtonProps): JSX.Element => {
        const { tabIndex, subject } = props;
        const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();
        return (
            <React.Fragment>
                <MenuItem key="create-expertise" dense button={true} tabIndex={tabIndex} onClick={setModalIsOpen}>
                    <FormattedMessage id="expertise.createExpertise" />
                </MenuItem>
                {isModalOpen && (
                    <ExpertiseCreateDialog
                        subject={{ id: subject.id, title: subject.title }}
                        onClose={setModalIsClosed}
                    />
                )}
            </React.Fragment>
        );
    },
);
