const users: Record<string, string | Record<string, string | Record<string, string>>> = {
    listTitle: 'Users',
    fields: {
        login: 'Login',
        status: 'Status',
        name: 'Full name',
        roles: 'Roles',
        lang: 'English speaking user',
    },
    actions: {
        block: 'Block',
        unblock: 'Unblock',
        activate: 'Activate',
        passwordAssignment: 'Assign a password',
    },
    status: {
        blocked: 'Blocked',
        active: 'Active',
        inactive: 'Inactive',
    },
    loginAsUser: 'LoginPage as user',
    editUser: 'Edit user',
    registryPagingInfo:
        'Total {count, number} user{count, plural, one {} other {s}}. ' +
        'Displayed from {from, number} to {to, number}',

    confirmBlock: 'Confirm user block',
    confirmBlockInfoText: 'Are you sure you want to block user {name} ({login})?',
    confirmBlockWithReasonInfoText: 'User {name} ({login}) will be blocked',
    blockReason: 'Reason for blocking',

    confirmUnblock: 'Confirm user unblock',
    confirmUnblockInfoText: 'Are you sure you want to unblock user {name} ({login})?',

    deleteUser: 'Confirm user deletion',
    deleteUserConfirmText: 'Are you sure you want to delete user {name} ({login})?',
    notAllowed: 'It is impossible to perform this action on your account',

    editPasswordDialogTitle: 'Password assignment',
    editPasswordDialogInfo: 'Enter a new password for the user {name} ({login})',

    addUser: 'Add user',
    register: 'Register',

    profile: 'User profile',
};

export default users;
