import React from 'react';
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

export function ClearFilterButton(props: { clear: () => void }): JSX.Element {
    return (
        <IconButton
            onClick={(e): void => {
                e.stopPropagation();
                props.clear();
            }}
        >
            <Clear />
        </IconButton>
    );
}
