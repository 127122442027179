import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { TransitionDialogProps } from './TransitionDialog';
import { ConfirmationDialog } from '../ConfirmationDialog';

export const LifeCycleTransitionConfirmationDialog = observer(
    (props: TransitionDialogProps): JSX.Element => {
        const {
            transition,
            isModalOpen,
            setModalIsClosed,
            lifeCycleTransition,
            handleErrorTransition,
            handleSuccessTransition,
        } = props;
        const handleConfirm = (): void => {
            lifeCycleTransition().then(handleSuccessTransition).catch(handleErrorTransition).finally();
        };

        return (
            <ConfirmationDialog
                id="confirm-transition"
                onCancel={setModalIsClosed}
                onConfirm={handleConfirm}
                message={
                    <FormattedMessage id="common.lifeCycleConfirmText" values={{ toState: transition.toStateTitle }} />
                }
                open={isModalOpen}
                title={<FormattedMessage id="common.lifeCycleConfirmTitle" />}
                keepMounted={true}
                confirmText={transition.params.title}
            />
        );
    },
);
