import React from 'react';
import { Grid, Collapse } from '@material-ui/core';
import { getBurgerLinksElements } from './HeaderLinksMap';

export type BurgerMenuProps = {
    isOpenMenu: boolean;
    isSmSize: boolean;
    isMdSize: boolean;
};

export const BurgerMenu = (props: BurgerMenuProps): JSX.Element => {
    const { isSmSize, isMdSize, isOpenMenu } = props;
    const burgerLinksElements = getBurgerLinksElements();

    return (
        <Collapse in={isOpenMenu} timeout="auto">
            <Grid container direction="row" alignItems="center" justify="center" spacing={3}>
                {isMdSize
                    ? burgerLinksElements.mdSize
                    : isSmSize
                    ? burgerLinksElements.smSize
                    : burgerLinksElements.defaultSize}
            </Grid>
        </Collapse>
    );
};
