import React, { PropsWithChildren } from 'react';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Box, Container, Grid } from '@material-ui/core';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';

type OverflowProp = {
    overflow: string;
    boxHeight?: string;
};

export const TaskViewContainer = withTheme((props: PropsWithChildren<ThemedComponentProps & OverflowProp>) => {
    const { overflow, boxHeight } = props;
    return (
        <Grid item style={{ flexGrow: 1, height: '100%', overflow: overflow }}>
            <Box
                bgcolor={props.theme?.variables.expertiseTaskView.background}
                minHeight="100%"
                height={boxHeight}
                overflow="hidden"
            >
                <Container maxWidth="lg" style={{ height: '100%' }}>
                    {props.children}
                </Container>
            </Box>
        </Grid>
    );
});

export const ExpertiseTaskViewPageContainer = styled(({ fullScreen, overflow, ...props }) => <Grid {...props} />)`
    flex: 1;
    overflow: ${({ overflow }): string => overflow};
    ${({ fullScreen }): string => {
        return fullScreen ? 'height: 100vh; position: absolute; top: 0; z-index: 1101' : '';
    }};
`;

export const TaskViewTabsContainer = withTheme(
    styled(Grid)`
        padding: 0 !important;
        border-bottom: ${({ theme }): string => theme.variables.expertiseTaskView.tabsBorder};
    `,
);

export const TaskViewHeaderContainer = withTheme(
    styled(({ withPaddingRight, ...props }) => <Grid {...props} />)`
        z-index: 3;
        box-shadow: ${({ theme }): string => theme.variables.expertiseTaskView.headerShadow};
        background: ${({ theme }): string => theme.variables.expertiseTaskView.headerBackground};
        ${({ withPaddingRight }): string => {
            return withPaddingRight ? 'padding-right: 15px' : '';
        }};
    `,
);

export const TaskViewHeaderActionsContainer = withTheme(
    styled(Grid)`
        background-color: ${({ theme }): string => theme.variables.expertiseTaskView.headerActionsBackground};
        .MuiGrid-item {
            button.MuiButton-root.MuiButton-contained,
            button.MuiButton-root.MuiButton-text,
            .MuiIconButton-root {
                height: 28px;
            }
            .MuiIconButton-root {
                width: 28px;
            }
        }
    `,
);

export const TaskViewHeaderPropertiesContainer = withTheme(
    styled(Box)`
        color: ${({ theme }) => theme.palette.secondary.contrastText};
        background-color: ${({ theme }) => theme.variables.expertiseTaskView.headerActionsBackground};
        padding: ${({ theme }): string => `${theme.spacing(5)}px 0 ${theme.spacing(5)}px 0`};
    `,
);

export const TaskViewTabsContentContainer = withTheme(
    styled(Grid)`
        padding: ${({ theme }): string => `${theme.spacing(5)}px`};
        overflow-y: auto;
        overflow-x: hidden;
        flex-grow: 1;
    `,
);

export const TaskViewReportContainer = withTheme(
    styled(Grid)`
        flex-grow: 1;
        height: 100%;
        display: flex;
        overflow: hidden;
    `,
);

export const ExpertiseTaskHeaderActionsDelimiter = withTheme(
    styled.div`
        height: 24px;
        min-width: 1px;
        margin: ${({ theme }): string => `${theme.spacing(2)}px ${theme.spacing(8)}px 0 ${theme.spacing(8)}px`};
        background: ${({ theme }) => theme.variables.expertiseTaskView.headerActionsDelimiterColor};
    `,
);

export const TaskViewControlPanelContainer = styled(({ ...props }) => <Grid container {...props} />)`
    margin: 0 !important;
    max-width: 847px;
`;
