import React from 'react';
import { withTheme, Box, Tooltip } from '@material-ui/core';
import styled from 'styled-components';

const StyledBox = withTheme(styled(Box)`
    width: 100%;
    max-height: 55px;
    overflow: hidden;
`);

export type EllipsisBoxProps = {
    text: string;
    correctLength?: number;
};

export const EllipsisBox = (props: EllipsisBoxProps): JSX.Element => {
    const { text, correctLength = 30 } = props;
    if (text.length > correctLength) {
        return (
            <Tooltip title={text}>
                <div>
                    <StyledBox>{text.substring(0, correctLength - 3) + '...'}</StyledBox>
                </div>
            </Tooltip>
        );
    } else {
        return <React.Fragment>{text}</React.Fragment>;
    }
};
