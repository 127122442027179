import React from 'react';
import { Box, CircularProgress, Grid, Paper } from '@material-ui/core';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { RootStore } from '../../store/RootStore';
import RegistrationConfirmModel from '../../models/authentication/RegistrationConfirmModel';
import { TotBackground } from '../../TotBackground';

type OuterProps = {};

type StoreProps = {
    registrationConfirmModel: RegistrationConfirmModel;
};

type RouteParams = {
    token: string | undefined;
};

type InnerProps = OuterProps & RouteComponentProps<RouteParams> & StoreProps;

class RegistrationConfirmPage extends React.Component<InnerProps, {}> {
    constructor(props: InnerProps) {
        super(props);
        const { match, registrationConfirmModel } = props;
        const { token } = match.params;
        registrationConfirmModel.confirmEmail(token);
    }

    renderByStatus = (): JSX.Element | null => {
        const { status } = this.props.registrationConfirmModel;
        switch (status) {
            case 'pending':
                return <CircularProgress />;
            case 'success':
                return <Redirect to={clientRoute.campaigns} />;
            case 'expired':
                return <Redirect to={clientRoute.registrationInfoExpired} />;
            case 'not-found':
                return <Redirect to={clientRoute.registrationInfoNotFound} />;
            default:
                return null;
        }
    };

    render(): JSX.Element {
        return (
            <TotBackground withBackdrop={true}>
                <Grid container item direction="column" alignItems="center" justify="center">
                    <Paper elevation={24}>
                        <Box>{this.renderByStatus()}</Box>
                    </Paper>
                </Grid>
            </TotBackground>
        );
    }
}

export default compose<InnerProps, OuterProps>(
    withRouter,
    inject<RootStore, StoreProps, {}, {}>((rootStore) => ({
        registrationConfirmModel: rootStore.authenticationStore.registrationConfirmModel,
    })),
    observer,
)(RegistrationConfirmPage);
