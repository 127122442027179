import { ExpertiseByCampaignDTO, ExpertiseCreateDTO } from '../../store/ExpertiseStore';
import { IntlShape } from 'react-intl';
import { ChangeEvent, FormEvent } from 'react';
import { observable, action, computed } from 'mobx';
import { IdTitle } from '../IdTitle';
import { PlanEntryDetailsDTO, TemplateDetailsDTO } from '../../store/TemplatesOfExpertiseStore';
import { CodeTitle } from '../CodeTitle';
import { generatePath } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { RootStore } from '../../store/RootStore';

class ExpertiseCreateModel {
    @observable intl: IntlShape;
    @observable rootStore: RootStore;

    @observable subjects: IdTitle[] = [];
    @observable templates: IdTitle[] = [];
    @observable lifeCycles: CodeTitle[] = [];

    @observable templateId = '';
    @observable processId = '';
    @observable processTitle = '';
    @observable planEntries: Array<PlanEntryDetailsDTO> = [];

    @observable subject? = '';
    @observable template? = '';
    @observable lifeCycle? = '';

    @observable templateIsSelected = false;
    @observable validationSubjectsSelectStarted = false;
    @observable validationLifeCyclesSelectStarted = false;
    @observable validationTemplateSelectStarted = false;

    @observable isLoading = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.intl = rootStore.intlStore.intl;
    }

    @computed
    get errorSubject(): string {
        if (this.validationSubjectsSelectStarted && !this.subject) {
            return this.intl.formatMessage({ id: 'validation.required' });
        }
        return '';
    }

    @computed
    get errorTemplate(): string {
        if (this.validationTemplateSelectStarted && !this.template) {
            return this.intl.formatMessage({ id: 'validation.required' });
        }
        return '';
    }

    @computed
    get errorLifeCycle(): string {
        if (this.validationLifeCyclesSelectStarted && !this.lifeCycle) {
            return this.intl.formatMessage({ id: 'validation.required' });
        }
        return '';
    }

    @action.bound
    onSubmitByTemplate = (onClose: () => void): ((event: FormEvent<HTMLFormElement>) => void) => {
        return (event: FormEvent<HTMLFormElement>): void => {
            event.preventDefault();
            this.enableValidationSubject();
            this.enableValidationLifeCycle();
            if (this.templateIsSelected && this.subject && this.template) {
                this.enableLoading();

                const data: ExpertiseCreateDTO = {
                    subjectId: this.subject,
                    templateId: this.template,
                };

                this.rootStore.expertiseStore
                    .createExpertise(data)
                    .then((id) => {
                        this.rootStore.expertiseStore.history.push(generatePath(clientRoute.expertise, { id }));
                    })
                    .finally(() => {
                        onClose();
                    });
            } else if (!this.templateIsSelected && this.subject && this.lifeCycle) {
                this.enableLoading();

                const data: ExpertiseCreateDTO = {
                    subjectId: this.subject,
                    processCode: this.lifeCycle,
                };

                this.rootStore.expertiseStore
                    .createExpertise(data)
                    .then((id) => {
                        this.rootStore.expertiseStore.history.push(generatePath(clientRoute.expertise, { id }));
                    })
                    .finally(() => {
                        onClose();
                    });
            }
        };
    };

    onSubmitByCampaign = (
        campaignId: string,
        onClose: () => void,
        onSubmit?: () => void,
    ): ((event: FormEvent<HTMLFormElement>) => void) => {
        return (event: FormEvent<HTMLFormElement>): void => {
            event.preventDefault();
            this.enableValidationLifeCycle();
            this.enableValidationTemplate();
            if (this.template) {
                this.enableLoading();

                const data: ExpertiseByCampaignDTO = {
                    templateId: this.template,
                    campaignId,
                };
                this.rootStore.expertiseStore
                    .createExpertiseByCampaign(data)
                    .then(() => {
                        onSubmit && onSubmit();
                        this.rootStore.history.push(generatePath(clientRoute.campaign, { id: campaignId }));
                    })
                    .finally(onClose);
            }
        };
    };

    @action.bound
    enableLoading(): void {
        this.isLoading = true;
    }

    @action.bound
    onChangeSubjectSelect = (event: ChangeEvent<{}>, value: IdTitle | null): void => {
        this.enableValidationSubject();
        this.setSubject(value?.id);
    };

    @action.bound
    onChangeTemplateSelect = (event: ChangeEvent<{}>, value: IdTitle | null): void => {
        if (value) {
            this.setTemplate(value?.id);
            this.rootStore.templatesOfExpertiseStore
                .loadTemplateDTO(value.id)
                .then(this.loadPlanEntryDetails)
                .finally(() => this.enableTemplateIsSelected());
        } else {
            this.setTemplate();
            this.disableTemplateIsSelected();
        }
    };

    @action.bound
    onChangeLifeCycleSelect = (event: ChangeEvent<{}>, value: CodeTitle | null): void => {
        this.enableValidationLifeCycle();
        this.setLifeCycle(value?.code);
    };

    @action.bound
    setSubject(subject: string | undefined = ''): void {
        this.subject = subject;
    }

    @action.bound
    setTemplate(template: string | undefined = ''): void {
        this.template = template;
    }

    @action.bound
    setLifeCycle(lifeCycle: string | undefined = ''): void {
        this.lifeCycle = lifeCycle;
    }

    @action.bound
    loadPlanEntryDetails(dto: TemplateDetailsDTO): void {
        this.templateId = dto.id;
        this.processId = dto.process.title;
        this.processTitle = dto.process.title;
        this.planEntries = dto.planEntries;
    }

    @action.bound
    loadSubjects(subjects: IdTitle[]): void {
        this.subjects = subjects;
    }

    @action.bound
    loadTemplates(templates: IdTitle[]): void {
        this.templates = templates;
    }

    @action.bound
    loadLifeCycles(lifeCycles: CodeTitle[]): void {
        this.lifeCycles = lifeCycles;
    }

    @action.bound
    enableTemplateIsSelected(): void {
        this.templateIsSelected = true;
    }

    @action.bound
    disableTemplateIsSelected(): void {
        this.templateIsSelected = false;
    }

    @action.bound
    enableValidationSubject = (): void => {
        this.validationSubjectsSelectStarted = true;
    };

    @action.bound
    enableValidationTemplate = (): void => {
        this.validationTemplateSelectStarted = true;
    };

    @action.bound
    enableValidationLifeCycle = (): void => {
        this.validationLifeCyclesSelectStarted = true;
    };
}

export default ExpertiseCreateModel;
