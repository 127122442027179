import React from 'react';
import { ExpansionPanel, Grid, TextField, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
import { ExpandMore } from '@material-ui/icons';
import {
    TotObjectFilterPanelDetails,
    TotObjectFilterPanelSummary,
} from '../../../components/tot-object/TotObjectFilterPanel';
import { textFieldProps } from '../../../constants/registryFilterInputs';
import { UserListModel } from '../../../models/users/UserListModel';

type OuterProps = {
    model: UserListModel;
};
type InnerProps = OuterProps & { intl: IntlFormatters };

class UserListFilterPanel extends React.Component<InnerProps, {}> {
    render(): JSX.Element {
        const { intl, model } = this.props;
        const { filtering } = model;
        return (
            <ExpansionPanel>
                <TotObjectFilterPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="common.filters" />
                    </Typography>
                </TotObjectFilterPanelSummary>
                <TotObjectFilterPanelDetails>
                    <Grid container direction="column" spacing={6}>
                        <Grid item container spacing={6}>
                            <Grid item xs={6}>
                                <TextField
                                    {...textFieldProps}
                                    label={<FormattedMessage id="users.fields.login" />}
                                    placeholder={intl.formatMessage({ id: 'common.all' })}
                                    value={filtering.login.value}
                                    onChange={filtering.login.onChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    {...textFieldProps}
                                    label={<FormattedMessage id="users.fields.name" />}
                                    placeholder={intl.formatMessage({ id: 'common.all' })}
                                    value={filtering.name.value}
                                    onChange={filtering.name.onChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </TotObjectFilterPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default compose<InnerProps, OuterProps>(withTheme, injectIntl, observer)(UserListFilterPanel);
