import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField as MUITextField,
} from '@material-ui/core';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { Field, Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CodeTitle } from '../../../../models/CodeTitle';
import { UserForCreate } from '../../../../store/UserStore';
import { generatePath, useHistory } from 'react-router-dom';
import clientRoute from '../../../../clientRoute';
import { useYup } from '../../../../hooks/useYup';
import { en, ru } from '../../../../store/IntlStore';
import { toJS } from 'mobx';
import { ErrorMessage } from '../../../../components/ErrorMessage';
import { ErrorDialog } from '../../../../components/ErrorDialog';
import { useError } from '../../../../hooks/useError';

export type UserAddDialogProps = {
    open: boolean;
    setModalIsClosed: () => void;
    createUser: (user: UserForCreate) => Promise<string>;
    userRoleList: CodeTitle[];
};

type UserAddDialogFormValues = {
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    enFirstName: string;
    enLastName: string;
    enMiddleName: string;
    englishUser: boolean;
    roles: CodeTitle[];
};

const initialValues: UserAddDialogFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    middleName: '',
    enFirstName: '',
    enLastName: '',
    enMiddleName: '',
    englishUser: false,
    roles: [],
};

export const UserAddDialog = (props: UserAddDialogProps): JSX.Element => {
    const { open, setModalIsClosed, createUser, userRoleList } = props;
    const intl = useIntl();
    const { Yup } = useYup();
    const { isError, errorText, setErrorIsClosed, setErrorIsOpen } = useError();
    const history = useHistory();

    const schema = useMemo(
        () =>
            Yup.object().shape({
                email: Yup.string().email().required(),
                firstName: Yup.string().required(),
                lastName: Yup.string().required(),
                roles: Yup.array().min(1),
            }),
        [Yup],
    );

    const handleSubmit = async (values: UserAddDialogFormValues): Promise<void> => {
        const {
            firstName,
            lastName,
            middleName,
            enFirstName,
            enLastName,
            enMiddleName,
            email,
            englishUser,
            roles,
        } = values;

        const user: UserForCreate = {
            lastName,
            firstName,
            middleName,
            email,
            lang: englishUser ? en : ru,
            names: [
                {
                    lastName,
                    firstName,
                    middleName,
                    lang: ru,
                },
                {
                    lastName: enLastName,
                    firstName: enFirstName,
                    middleName: enMiddleName,
                    lang: en,
                },
            ],
            roles: roles.map((item) => item.code),
        };

        try {
            const userId = await createUser(user);
            history.push(
                generatePath(clientRoute.user, {
                    id: userId,
                }),
            );
        } catch (error) {
            const errorText = ErrorMessage(error);
            setErrorIsOpen(errorText);
        }
    };

    return (
        <React.Fragment>
            <ErrorDialog message={errorText} open={isError} onClose={setErrorIsClosed} />
            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
                {({ resetForm, touched, errors }): JSX.Element => {
                    const touchedRoles = touched['roles'];
                    const errorsRoles = errors['roles'];
                    return (
                        <Dialog open={open} fullWidth onExit={(): void => resetForm()}>
                            <DialogTitle>
                                <FormattedMessage id="users.addUser" />
                            </DialogTitle>
                            <Form>
                                <DialogContent>
                                    <Grid container direction="column" spacing={3}>
                                        <Grid item>
                                            <Field
                                                fullWidth
                                                name="email"
                                                component={TextField}
                                                variant="outlined"
                                                color="secondary"
                                                label={intl.formatMessage({ id: 'authentication.email' }) + ' *'}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                multiple
                                                name="roles"
                                                component={Autocomplete}
                                                options={toJS(userRoleList)}
                                                getOptionLabel={(option: CodeTitle): CodeTitle['title'] => option.title}
                                                renderInput={(params: AutocompleteRenderInputParams): JSX.Element => (
                                                    <MUITextField
                                                        {...params}
                                                        name="roles"
                                                        error={touchedRoles && !!errorsRoles}
                                                        helperText={touchedRoles && errorsRoles}
                                                        label={intl.formatMessage({ id: 'users.fields.roles' }) + ' *'}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                fullWidth
                                                name="firstName"
                                                component={TextField}
                                                variant="outlined"
                                                color="secondary"
                                                label={intl.formatMessage({ id: 'authentication.firstName' }) + ' *'}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                fullWidth
                                                name="lastName"
                                                component={TextField}
                                                variant="outlined"
                                                color="secondary"
                                                label={intl.formatMessage({ id: 'authentication.lastName' }) + ' *'}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                fullWidth
                                                name="middleName"
                                                component={TextField}
                                                variant="outlined"
                                                color="secondary"
                                                label={<FormattedMessage id="authentication.middleName" />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                component={CheckboxWithLabel}
                                                type="checkbox"
                                                name="englishUser"
                                                Label={{ label: <FormattedMessage id="users.fields.lang" /> }}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Field
                                                fullWidth
                                                name="enFirstName"
                                                component={TextField}
                                                variant="outlined"
                                                color="secondary"
                                                label={<FormattedMessage id="authentication.firstName" />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                fullWidth
                                                name="enLastName"
                                                component={TextField}
                                                variant="outlined"
                                                color="secondary"
                                                label={<FormattedMessage id="authentication.lastName" />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                fullWidth
                                                name="enMiddleName"
                                                component={TextField}
                                                variant="outlined"
                                                color="secondary"
                                                label={<FormattedMessage id="authentication.middleName" />}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button color="primary" onClick={setModalIsClosed}>
                                        <FormattedMessage id="common.cancel" />
                                    </Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        <FormattedMessage id="users.register" />
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
};
