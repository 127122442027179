import React from 'react';
import { Grid, Link, Tooltip, useTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { AuthorizationCheck } from '../../../../components/AuthorizationCheck';
import { RowData } from '../../../../components/PageHeader/RowData';
import clientRoute from '../../../../clientRoute';
import { entities, permissions } from '../../../../authSchemeConfig';
import { IconButtonLarge } from '../../../../components/buttons/IconButtonLarge';
import { ReactComponent as EditIcon } from '../../../../resources/images/icons/edit.svg';
import { ExpertiseModel } from '../../../../models/expertise/ExpertiseModel';
import { ExpertisePageRouteParams } from '../ExpertisePage';

export type ExpertiseHeaderRightProps = {
    expertiseCampaign: ExpertiseModel['campaign'];
    curatorName: ExpertiseModel['curator']['name'];
    id: ExpertisePageRouteParams['id'];
    setModalIsOpen: () => void;
};

export const ExpertiseHeaderRight = (props: ExpertiseHeaderRightProps): JSX.Element => {
    const { expertiseCampaign, curatorName, id, setModalIsOpen } = props;
    const theme = useTheme();

    const expertiseCampaignValue = expertiseCampaign?.title || '-';
    const expertiseCampaignWrapper =
        expertiseCampaign &&
        ((children: JSX.Element): JSX.Element => {
            return (
                <Link
                    component={NavLink}
                    underline="none"
                    to={generatePath(clientRoute.campaign, {
                        id: expertiseCampaign.id,
                    })}
                    color="textPrimary"
                >
                    {children}
                </Link>
            );
        });

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <RowData
                    label={<FormattedMessage id="expertise.fields.expertiseCampaign" />}
                    value={expertiseCampaignValue}
                    valueWrapper={expertiseCampaignWrapper}
                />
            </Grid>

            <Grid item>
                <Grid container direction="row" alignItems="baseline" justify="flex-start" spacing={2} wrap="nowrap">
                    <Grid item>
                        <RowData label={<FormattedMessage id="expertise.fields.curator" />} value={curatorName} />
                    </Grid>
                    <AuthorizationCheck
                        entityCode={entities.Expertise}
                        permCode={permissions.Expertise.UpdateCurator}
                        entityId={id}
                    >
                        <Grid item style={{ marginTop: `-${theme?.spacing(6)}px` }}>
                            <Tooltip title={<FormattedMessage id="expertise.changeCurator" />}>
                                <div>
                                    <IconButtonLarge onClick={setModalIsOpen}>
                                        <EditIcon />
                                    </IconButtonLarge>
                                </div>
                            </Tooltip>
                        </Grid>
                    </AuthorizationCheck>
                </Grid>
            </Grid>
        </Grid>
    );
};
