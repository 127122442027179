import { withTheme, Link } from '@material-ui/core';
import styled from 'styled-components';

export const ExpertiseLink = withTheme(
    styled(Link)`
        font-size: 15px !important;
        line-height: 1.47 !important;
        letter-spacing: 0.3px !important;
        font-weight: bold !important;
    `,
);
