import React, { ReactNode } from 'react';
import {
    Button,
    CircularProgress,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ExpertiseTaskStore } from '../../../../../store/ExpertiseTaskStore';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { RootStore } from '../../../../../store/RootStore';
import {
    ExpertiseTaskPrintFormModel,
    ExpertiseTaskPrintFormDTO,
    PrintFormFileDTO,
} from '../../../../../models/expertise-task/ExpertiseTaskPrintFormModel';
import { ErrorIcon, LoaderBox, StyledCircularProgress } from './ExpertiseTaskPrintForms.styled';
import ExpertiseTaskPrintFormsModel from '../../../../../models/expertise-task/ExpertiseTaskPrintFormsModel';
import { AuthorizationCheckQuery } from '../../../../../store/AuthorizationStore';
import { permissionsConfig } from '../../../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../../../components/AuthorizationCheck';

type OuterProps = {
    downloadPf(file: PrintFormFileDTO): () => void;
    printForms: ExpertiseTaskPrintFormsModel;
};

type StoreProps = {
    expertiseTaskStore: ExpertiseTaskStore;
};

type RouteParams = {
    id: string;
};

type InnerProps = OuterProps & StoreProps & RouteComponentProps<RouteParams>;

class ExpertiseTaskPrintFormsTable extends React.Component<InnerProps, {}> {
    renderPfRow = (
        printForm: ExpertiseTaskPrintFormModel,
        permission: AuthorizationCheckQuery,
        pfTypeMsgId: string,
    ): ReactNode => {
        const { id, title, file, errorCode, isGenerated } = printForm;
        const startIcon = isGenerated ? <StyledCircularProgress /> : !!errorCode && this.renderErrorTooltip(errorCode);
        const generateMsgId = file
            ? 'expertiseTask.printFormsTable.updateAction'
            : 'expertiseTask.printFormsTable.createAction';
        return (
            <TableRow key={id}>
                <TableCell>
                    {file ? this.renderTooltipFile(file, title) : <FormattedMessage id={pfTypeMsgId} />}
                </TableCell>
                <TableCell>
                    <AuthorizationCheck {...permission}>
                        <Button
                            disabled={isGenerated}
                            onClick={this.handleUpdateAction(printForm)}
                            color="secondary"
                            variant="contained"
                            startIcon={startIcon}
                            style={{ float: 'right' }}
                        >
                            <FormattedMessage id={generateMsgId} />
                        </Button>
                    </AuthorizationCheck>
                </TableCell>
            </TableRow>
        );
    };

    renderTooltipFile = (file: PrintFormFileDTO, templateTitle: string) => {
        return (
            <Tooltip title={<FormattedMessage id="common.downloadFile" values={{ fileName: file.filename }} />}>
                <div>
                    <Link component="button" underline="none" onClick={this.props.downloadPf(file)}>
                        {templateTitle}
                    </Link>
                </div>
            </Tooltip>
        );
    };

    handleUpdateAction = (printForm: ExpertiseTaskPrintFormModel) => () => {
        const { expertiseTaskStore } = this.props;
        const { id: taskPrintFormId, taskId, code: pfCode } = printForm;
        printForm.isGenerated = true;
        return (printForm.file
            ? expertiseTaskStore.updatePrintForm(taskPrintFormId)
            : expertiseTaskStore.createPrintForm(taskId, pfCode)
        )
            .then((dto: ExpertiseTaskPrintFormDTO) => {
                printForm.load(dto);
                printForm.errorCode = '';
            })
            .catch(() => {
                printForm.errorCode = 'expertiseTask.printFormsTable.generationError';
            })
            .finally(() => {
                printForm.isGenerated = false;
            });
    };

    render(): JSX.Element {
        const { printForms } = this.props;
        const { conclusion, loaded } = printForms;
        const { id } = this.props.match.params;
        return (
            <TableContainer>
                <Table>
                    <TableBody>
                        {loaded ? (
                            <React.Fragment>
                                {conclusion &&
                                    this.renderPfRow(
                                        conclusion,
                                        permissionsConfig.updateConclusionPrintForm(id),
                                        'expertiseTask.printFormsTable.conclusion',
                                    )}
                            </React.Fragment>
                        ) : (
                            <TableRow key="loader">
                                <TableCell>
                                    <LoaderBox>
                                        <CircularProgress />
                                    </LoaderBox>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    renderErrorTooltip(errorCode: string): ReactNode {
        return (
            <Tooltip title={<FormattedMessage id={errorCode} />}>
                <div>
                    <ErrorIcon>!</ErrorIcon>
                </div>
            </Tooltip>
        );
    }
}

export default compose<InnerProps, OuterProps>(
    withRouter,
    inject<RootStore, {}, StoreProps, {}>(({ expertiseTaskStore }) => {
        return {
            expertiseTaskStore,
        };
    }),
    observer,
)(ExpertiseTaskPrintFormsTable);
