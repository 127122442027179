import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TemplatesOfExpertiseListPage } from './TemplatesOfExpertiseListPage';
import { TemplateOfExpertisePage } from './TemplateOfExpertisePage';
import clientRoute from '../../clientRoute';

class TemplatesOfExpertisePages extends React.Component<{}, {}> {
    render(): JSX.Element {
        return (
            <Switch>
                <Route path={clientRoute.templateOfExpertise}>
                    <TemplateOfExpertisePage />
                </Route>
                <Route>
                    <TemplatesOfExpertiseListPage />
                </Route>
            </Switch>
        );
    }
}

export default TemplatesOfExpertisePages;
