import React from 'react';
import { Box, Grid, Link } from '@material-ui/core';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { MetaInfoModel } from '../../../models/MetaInfo';
import { TotObjectPanelLabel, TotObjectPanelValue } from '../../../components/tot-object/TotObjectPanel';
import { getOrElse } from '../../../utils/getOrElse';
import { useStore } from '../../../hooks/useStore';
import { ExpertiseTaskServiceInfoWrapper } from './ExpertiseTaskServiceInfoWrapper';

export type ExpertiseTaskServiceInfoProps = {
    metaInfoModel: MetaInfoModel;
    withExpansionPanel?: boolean;
};

export const ExpertiseTaskServiceInfo = observer(
    (props: ExpertiseTaskServiceInfoProps): JSX.Element => {
        const { services } = useStore();
        const { metaInfoModel, withExpansionPanel = true } = props;

        return (
            <ExpertiseTaskServiceInfoWrapper withExpansionPanel={withExpansionPanel}>
                <Grid container direction="column">
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item>
                                <TotObjectPanelLabel>
                                    <FormattedMessage id="common.serviceInfoFields.author" />
                                </TotObjectPanelLabel>
                            </Grid>
                            <Grid item>
                                <TotObjectPanelValue>{metaInfoModel.author.name}</TotObjectPanelValue>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Box pt={8}>
                            <Grid item container direction="row">
                                <Grid item xs={6}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <TotObjectPanelLabel>
                                                <FormattedMessage id="common.serviceInfoFields.created" />
                                            </TotObjectPanelLabel>
                                        </Grid>
                                        <Grid item>
                                            <TotObjectPanelValue>
                                                {getOrElse(
                                                    metaInfoModel.created,
                                                    <FormattedDate value={metaInfoModel.created} />,
                                                )}
                                            </TotObjectPanelValue>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <TotObjectPanelLabel>
                                                <FormattedMessage id="common.serviceInfoFields.lastModified" />
                                            </TotObjectPanelLabel>
                                        </Grid>
                                        <Grid item>
                                            <TotObjectPanelValue>
                                                {getOrElse(
                                                    metaInfoModel.modified,
                                                    <FormattedDate value={metaInfoModel.modified} />,
                                                )}
                                            </TotObjectPanelValue>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box pt={8}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="common.serviceInfoFields.lifeCycle" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {getOrElse(
                                            metaInfoModel.processInfo,
                                            <Link
                                                target="_blank"
                                                href={services.lifecycleProcessLink(metaInfoModel.processInfo?.id)}
                                            >
                                                {metaInfoModel.processInfo?.title}
                                            </Link>,
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box pt={8}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="common.serviceInfoFields.form" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {getOrElse(
                                            metaInfoModel.formInfos,
                                            metaInfoModel.formInfos.map((item) => (
                                                <Link key={item} target="_blank" href={services.formVersionLinks(item)}>
                                                    {item}
                                                </Link>
                                            )),
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </ExpertiseTaskServiceInfoWrapper>
        );
    },
);
