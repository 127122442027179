import React, { ReactNode } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

export type WithModalProps = {
    isModalOpen: boolean;
    setModalIsOpen(): void;
    setModalIsClosed(): void;
};

export default function withModal<P extends object = {}>(
    WrappedComponent: React.ComponentType<P>,
): React.ComponentClass<P & WithModalProps> {
    type OuterProps = P & WithModalProps;
    type InnerProps = OuterProps;

    class ComponentWithModal extends React.Component<InnerProps, {}> {
        @observable isModalOpen = false;

        @action.bound
        setModalIsOpen = (): void => {
            this.isModalOpen = true;
        };

        @action.bound
        setModalIsClosed = (): void => {
            this.isModalOpen = false;
        };

        render(): ReactNode {
            const { isModalOpen, setModalIsOpen, setModalIsClosed, ...restProps } = this.props;
            return (
                <WrappedComponent
                    {...(restProps as P)}
                    isModalOpen={this.isModalOpen}
                    setModalIsOpen={this.setModalIsOpen}
                    setModalIsClosed={this.setModalIsClosed}
                />
            );
        }
    }

    return compose<InnerProps, OuterProps>(observer)(ComponentWithModal);
}
