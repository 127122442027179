import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Container, Dialog, FormControlLabel, Grid, Portal, TextField } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { observable } from 'mobx';
import { Autocomplete } from '@material-ui/lab';
import { renderAutoCompleteInput as renderAutoCompleteInputInj } from '../../components/renderAutoCompleteInput';
import { UserPersonModel } from '../../models/users/UserPersonModel';
import { createViewModel, IViewModel } from 'mobx-utils';
import { ErrorDialog as ErrorDialogInj } from '../../components/ErrorDialog';
import { ErrorMessage as ErrorMessageInj } from '../../components/ErrorMessage';
import { CodeTitle } from '../../models/CodeTitle';
import { en, ru } from '../../store/IntlStore';
import { checkboxProps } from '../../constants/registryFilterInputs';
import { useError } from '../../hooks/useError';
import { useStore } from '../../hooks/useStore';
import { di } from 'react-magnetic-di';

export type UserEditDialogProps = {
    userPersonModel: UserPersonModel;
    isOpen: boolean;
    close: () => void;
};

export const UserEditDialog = observer(
    (props: UserEditDialogProps): JSX.Element => {
        const { userPersonModel, isOpen, close } = props;

        const [renderAutoCompleteInput] = di([renderAutoCompleteInputInj], UserEditDialog);
        const [ErrorDialog] = di([ErrorDialogInj], UserEditDialog);
        const [ErrorMessage] = di([ErrorMessageInj], UserEditDialog);

        const [userRolesSelectData, setUserRolesSelectData] = useState<CodeTitle[]>([]);
        const [viewModel] = useState<UserPersonModel & IViewModel<UserPersonModel>>(createViewModel(userPersonModel));
        const [validationStarted, setValidationStarted] = useState<boolean>(false);

        const { isError, errorText, setErrorIsClosed, setErrorIsOpen } = useError();
        const { userStore } = useStore();
        const intl = useIntl();

        useEffect(() => {
            userStore.userRoleList().then((roles) => {
                setUserRolesSelectData(roles);
            });
        }, [userStore, setUserRolesSelectData]);

        const handleCancel = (): void => {
            viewModel.reset();
            close();
        };

        const handleSave = async (): Promise<void> => {
            const { submit } = viewModel;
            setValidationStarted(true);

            if (!errorRoles && !errorLogin) {
                try {
                    await userStore.saveUserHead(viewModel);
                    submit();
                } catch (e) {
                    const errorText = ErrorMessage(e);
                    setErrorIsOpen(errorText);
                }
                close();
                setValidationStarted(false);
            }
        };

        const onChangeRoles = (event: ChangeEvent<{}>, value: CodeTitle[] | null): void => {
            if (value) {
                viewModel.roles = observable.array(value);
            }
        };

        const errorRoles = useMemo((): string => {
            if (validationStarted && !viewModel.roles.length) {
                return intl.formatMessage({ id: 'validation.required' });
            }
            return '';
        }, [validationStarted, viewModel.roles, intl]);

        const onChangeLogin = (event: ChangeEvent<HTMLInputElement>): void => {
            viewModel.login = event.target.value;
        };

        const errorLogin = useMemo((): string => {
            if (validationStarted && !viewModel.login) {
                return intl.formatMessage({ id: 'validation.required' });
            }
            return '';
        }, [validationStarted, viewModel.login, intl]);

        const onChangeLang = (event: ChangeEvent<HTMLInputElement>): void => {
            viewModel.lang = event.target.checked ? en : ru;
        };

        return (
            <React.Fragment>
                <Portal>
                    <Dialog maxWidth="sm" fullWidth open={isOpen} scroll="paper">
                        <DialogTitle>
                            <Container maxWidth="lg">
                                <FormattedMessage id="users.editUser" />
                            </Container>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Container maxWidth="lg">
                                <Grid container spacing={6} direction="column" justify="center">
                                    <Grid item>
                                        <TextField
                                            required
                                            onChange={onChangeLogin}
                                            fullWidth
                                            label={<FormattedMessage id="users.fields.login" />}
                                            variant="outlined"
                                            error={!!errorLogin}
                                            helperText={errorLogin}
                                            defaultValue={viewModel.login}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Autocomplete
                                            onChange={onChangeRoles}
                                            getOptionSelected={(option, value) => option.code === value.code}
                                            getOptionLabel={(option) => option.title}
                                            options={userRolesSelectData.slice()}
                                            value={viewModel.roles.slice()}
                                            multiple
                                            renderInput={renderAutoCompleteInput(
                                                intl.formatMessage({
                                                    id: 'users.fields.roles',
                                                }),
                                                true,
                                                errorRoles,
                                            )}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            value="top"
                                            control={
                                                <Checkbox
                                                    {...checkboxProps}
                                                    checked={viewModel.lang === en}
                                                    onChange={onChangeLang}
                                                />
                                            }
                                            label={<FormattedMessage id="users.fields.lang" />}
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                </Grid>
                            </Container>
                        </DialogContent>
                        <Container maxWidth="lg">
                            <DialogActions>
                                <Button size="large" fullWidth variant="contained" onClick={handleCancel}>
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                                <Button size="large" fullWidth variant="contained" color="primary" onClick={handleSave}>
                                    <FormattedMessage id="common.save" />
                                </Button>
                            </DialogActions>
                        </Container>
                    </Dialog>
                </Portal>
                <ErrorDialog message={errorText} open={isError} onClose={setErrorIsClosed} />
            </React.Fragment>
        );
    },
);
