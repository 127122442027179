import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    LinearProgress,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { generatePath, NavLink, useHistory } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { observer } from 'mobx-react';
import { AuthorizationCheck } from '../../components/AuthorizationCheck';
import { entities, permissions } from '../../authSchemeConfig';
import { TotTablePagination } from '../../components/TotTablePagination';
import CampaignListModel, { CampaignRowDTO } from '../../models/campaign/CampaignListModel';
import { MenuButton } from '../../components/buttons/MenuButton';
import { DeleteActionMenuItem } from '../../components/DeleteActionMenuItem';
import { ActionsButton } from '../../components/buttons/ActionsButton';
import { useStore } from '../../hooks/useStore';
import { useLoading } from '../../hooks/useLoading';

export const CampaignListPage = observer(
    (): JSX.Element => {
        const { isLoading, enableLoading, disableLoading } = useLoading();
        const rootStore = useStore();
        const { campaignStore } = rootStore;
        const tableModel = useMemo(() => new CampaignListModel(rootStore), [rootStore]);
        const { intlStore } = useStore();
        const history = useHistory();

        const createCampaign = useCallback(async (): Promise<void> => {
            try {
                enableLoading();
                const id = await campaignStore.createCampaign();
                history.push(generatePath(clientRoute.campaignCreate, { id }));
            } catch (error) {
                disableLoading();
            }
        }, [enableLoading, disableLoading, history, generatePath]);

        const deleteCampaign = useCallback(
            (campaignRow: CampaignRowDTO): (() => Promise<void>) => {
                const model = tableModel;
                return () => {
                    return campaignStore.deleteCampaign(campaignRow.id).then(model.reloadData);
                };
            },
            [tableModel, campaignStore],
        );

        const renderActionItems = useCallback((campaignRow: CampaignRowDTO): (() => ReactNode[]) => {
            return (): ReactNode[] => [
                <DeleteActionMenuItem
                    id="delete"
                    key="delete"
                    wrappedComponentProps={{ tabIndex: 0 }}
                    title={<FormattedMessage id="common.confirmDeletion" />}
                    message={
                        <FormattedMessage
                            id="campaign.confirmDeletionInfoText"
                            values={{ title: campaignRow.identifier }}
                        />
                    }
                    onConfirm={deleteCampaign(campaignRow)}
                />,
            ];
        }, []);

        const renderActions = useCallback((campaignRow: CampaignRowDTO): JSX.Element => {
            return (
                <MenuButton
                    disablePortal={true}
                    renderButton={ActionsButton}
                    renderMenuItems={renderActionItems(campaignRow)}
                />
            );
        }, []);

        const model = tableModel;

        useEffect(() => {
            return tableModel.dispose;
        }, [tableModel.dispose]);

        return (
            <Container maxWidth="lg">
                <Box pt={5} pb={5}>
                    <Grid container direction="column" spacing={10}>
                        <Grid item container direction="row" justify="space-between">
                            <Grid item>
                                <Typography variant="h1">
                                    <FormattedMessage id="campaign.campaignListTitle" />
                                </Typography>
                            </Grid>
                            <Grid item>
                                <AuthorizationCheck
                                    entityCode={entities.System}
                                    permCode={permissions.System.AddExpertiseCampaign}
                                >
                                    <Button
                                        disabled={isLoading}
                                        color="primary"
                                        variant="contained"
                                        onClick={createCampaign}
                                    >
                                        <FormattedMessage id="campaign.createCampaign" />
                                    </Button>
                                </AuthorizationCheck>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TableContainer component={Paper}>
                                {model.isLoading && <LinearProgress />}
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="campaign.fields.identifier" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="campaign.fields.title" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="campaign.fields.created" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="campaign.fields.state" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {model.rows.map((c) => (
                                            <TableRow key={c.id} hover>
                                                <TableCell>
                                                    <Link
                                                        component={NavLink}
                                                        underline="none"
                                                        to={generatePath(clientRoute.campaign, { id: c.id })}
                                                    >
                                                        {c.identifier || <FormattedMessage id="common.withoutNumber" />}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{c.title}</TableCell>
                                                <TableCell>
                                                    <FormattedDate value={c.created} />
                                                </TableCell>
                                                <TableCell>{c.state}</TableCell>
                                                <TableCell>
                                                    <AuthorizationCheck
                                                        entityCode={entities.ExpertiseCampaign}
                                                        permCode={permissions.ExpertiseCampaign.Delete}
                                                        entityId={c.id}
                                                    >
                                                        {renderActions(c)}
                                                    </AuthorizationCheck>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TotTablePagination
                                                count={model.rowsCount}
                                                page={model.pageNumber}
                                                onChangePage={model.onChangePage}
                                                onChangeRowsPerPage={model.onChangePageSize}
                                                rowsPerPage={model.pageSize}
                                                rowsPerPageOptions={model.pageSizeOptions}
                                                labelRowsPerPage={intlStore.formatMessage('common.rowsPerPage')}
                                                labelDisplayedRows={(p): string =>
                                                    intlStore.formatMessage('campaign.registryPagingInfo', {
                                                        to: p.to,
                                                        from: p.from,
                                                        count: p.count,
                                                    })
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        );
    },
);
