import React from 'react';
import { Box, Button, ExpansionPanel, Grid, SvgIcon, TextField, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
import { ExpandMore } from '@material-ui/icons';
import { ReactComponent as ClearFilter } from '../../resources/images/icons/clear-filter.svg';
import { Select } from '../../components/selects/Select';
import { SelectionOfExpertListModel } from '../../models/expertise-task/SelectionOfExpertListModel';
import { AutocompleteProps, textFieldProps } from '../../constants/registryFilterInputs';
import {
    TotObjectFilterPanelDetails,
    TotObjectFilterPanelSummary,
} from '../../components/tot-object/TotObjectFilterPanel';

type OuterProps = {
    model: SelectionOfExpertListModel;
};

type InnerProps = OuterProps & { intl: IntlFormatters };

class SelectionOfExpertFilter extends React.Component<InnerProps, {}> {
    render(): JSX.Element {
        const { intl, model } = this.props;
        const { filtering } = model;
        return (
            <ExpansionPanel square variant="outlined" defaultExpanded>
                <TotObjectFilterPanelSummary expandIcon={<ExpandMore />}>
                    <Box pl={2}>
                        <Typography variant="h6">
                            <FormattedMessage id="common.filters" />
                        </Typography>
                    </Box>
                </TotObjectFilterPanelSummary>
                <TotObjectFilterPanelDetails>
                    <Box width="100%" pt={3}>
                        <Grid container direction="column" spacing={6}>
                            <Grid item container justify="space-between" spacing={6}>
                                <Grid item xs={4}>
                                    <TextField
                                        {...textFieldProps}
                                        label={intl.formatMessage({
                                            id: 'expertiseTask.selectionOfExpert.fields.name',
                                        })}
                                        placeholder={intl.formatMessage({ id: 'common.all' })}
                                        value={filtering.name.value}
                                        onChange={filtering.name.onChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        values={filtering.academicDegree.values}
                                        label={<FormattedMessage id="expertiseTask.selectionOfExpert.fields.degree" />}
                                        placeholder={intl.formatMessage({ id: 'common.all' })}
                                        selectData={model.academicDegreeList}
                                        onChange={filtering.academicDegree.onChange}
                                        textFieldProps={{ ...textFieldProps }}
                                        autoCompleteProps={{ ...AutocompleteProps }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        {...textFieldProps}
                                        label={intl.formatMessage({
                                            id: 'expertiseTask.selectionOfExpert.fields.fullNameEmplOrg',
                                        })}
                                        placeholder={intl.formatMessage({ id: 'common.all' })}
                                        value={filtering.fullNameEmplOrg.value}
                                        onChange={filtering.fullNameEmplOrg.onChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container spacing={6} justify="flex-end">
                                <Grid item xs={4}>
                                    <TextField
                                        {...textFieldProps}
                                        label={intl.formatMessage({
                                            id: 'expertiseTask.selectionOfExpert.yearOfBirth',
                                        })}
                                        placeholder={intl.formatMessage({ id: 'common.all' })}
                                        value={filtering.birthDate.value}
                                        onChange={filtering.birthDate.onChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        {...textFieldProps}
                                        label={intl.formatMessage({
                                            id: 'expertiseTask.selectionOfExpert.fields.specialization',
                                        })}
                                        placeholder={intl.formatMessage({ id: 'common.all' })}
                                        value={filtering.specialization.value}
                                        onChange={filtering.specialization.onChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item justify="flex-end">
                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        startIcon={
                                            <SvgIcon>
                                                <ClearFilter />
                                            </SvgIcon>
                                        }
                                        onClick={model.clearFilters}
                                    >
                                        <FormattedMessage id="common.resetFilters" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </TotObjectFilterPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default compose<InnerProps, OuterProps>(withRouter, withTheme, injectIntl, observer)(SelectionOfExpertFilter);
