import { IconButton } from '@material-ui/core';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import React from 'react';
import { BlockUnblockProps } from '../BlockUnblockProps';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

export type UserBlockUnblockButtonProps = BlockUnblockProps & {
    setModalIsOpen: () => void;
};

export const UserBlockUnblockButton = (props: UserBlockUnblockButtonProps) => {
    const { allowed, setModalIsOpen, isBlocked } = props;
    return (
        <IconButton disabled={!allowed} onClick={setModalIsOpen}>
            {isBlocked ? <PersonAddIcon /> : <PersonAddDisabledIcon />}
        </IconButton>
    );
};
