import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import clientRoute from '../../../../clientRoute';
import { observer } from 'mobx-react';
import { FormApi, MultiLangFormEdit } from '@platform/formiojs-react';
import ExpertiseTaskEditHeader from './ExpertiseTaskEditHeader';
import { ExpertiseTaskControlPanel } from './ExpertiseTaskControlPanel/ExpertiseTaskControlPanel';
import { TotObjectMain } from '../../../../components/tot-object/TotObjectMain';
import { ExpertiseTaskModel } from '../../../../models/expertise-task/ExpertiseTaskModel';
import { entities, permissions } from '../../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../../components/AuthorizationCheck';
import { ExpertiseTaskServiceInfo } from '../ExpertiseTaskServiceInfo';
import { AuthorizedRoute } from '../../../../components/AuthorizedRoute';
import { useStore } from '../../../../hooks/useStore';

const queryString = require('query-string');

export type ExpertiseTaskPageRouteParams = {
    id: string;
    backUrl: string;
};

export const ExpertiseTaskEditPage = observer(
    (): JSX.Element => {
        const { expertiseTaskStore, intlStore, history } = useStore();
        const { id } = useParams<ExpertiseTaskPageRouteParams>();
        const [formApi, setFormApi] = useState<FormApi>();
        const [expertiseTaskModel] = useState<ExpertiseTaskModel>(expertiseTaskStore.getExpertiseTaskModel(id));
        const { formModel } = expertiseTaskModel;
        const location = useLocation();

        const backUrl: string = useMemo(() => {
            const queries = queryString.parse(location.search);
            return queries.backUrl || generatePath(clientRoute.expertiseTask, { id });
        }, []);

        useEffect(() => updateCard, [intlStore.locale]);

        const updateCard = (): void => {
            expertiseTaskStore.loadExpertiseTaskDTO(id).then(expertiseTaskModel.loadCard);
        };

        const onFormReady = (form: FormApi): void => {
            setFormApi(form);
        };

        const onSubmit = (): void => {
            if (formApi && formApi.validate()) {
                expertiseTaskStore.saveExpertiseTask(id, formApi.getSubmission()).then(() => {
                    history.push(generatePath(clientRoute.expertiseTask, { id }));
                });
            }
        };

        const goBack = (): void => {
            history.push(backUrl);
        };

        return (
            <AuthorizedRoute
                path={clientRoute.expertiseTaskEdit}
                key={clientRoute.expertiseTaskEdit}
                exact
                entityCode={entities.ExpertiseTask}
                permCode={permissions.ExpertiseTask.Edit}
                entityId={id}
            >
                <Grid container direction="column">
                    <Grid item>
                        <ExpertiseTaskEditHeader expertiseTaskModel={expertiseTaskModel} />
                    </Grid>
                    <TotObjectMain>
                        <Grid container spacing={10}>
                            <Grid item container direction="column" spacing={10}>
                                <AuthorizationCheck
                                    entityCode={entities.System}
                                    permCode={permissions.System.Administration}
                                >
                                    <Grid item>
                                        <ExpertiseTaskServiceInfo metaInfoModel={expertiseTaskModel.metaInfo} />
                                    </Grid>
                                </AuthorizationCheck>
                                <Grid item style={{ maxWidth: '100%' }}>
                                    <MultiLangFormEdit
                                        intlStore={intlStore}
                                        form={formModel}
                                        onFormReady={onFormReady}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </TotObjectMain>
                    <Grid item>
                        <ExpertiseTaskControlPanel onSubmit={onSubmit} goBack={goBack} />
                    </Grid>
                </Grid>
            </AuthorizedRoute>
        );
    },
);
