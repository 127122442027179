import React from 'react';
import { inject, observer } from 'mobx-react';
import { TotObjectHeader } from '../../../../components/tot-object/TotObjectHeader';
import { ExpertiseModel } from '../../../../models/expertise/ExpertiseModel';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import withModal, { WithModalProps } from '../../../../hocs/withModal';
import { compose } from 'recompose';
import { withLoading, WithLoadingProps } from '../../../../hocs/withLoading';
import ExpertiseEditCurator from '../ExpertiseEditCurator';
import { ExpertiseStore } from '../../../../store/ExpertiseStore';
import { RootStore } from '../../../../store/RootStore';
import { ExpertisePageRouteParams } from '../ExpertisePage';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import { PageHeader } from '../../../../components/PageHeader/PageHeader';
import PageHeaderTitle from './PageHeaderTitle';
import { ExpertiseHeaderLeft } from './ExpertiseHeaderLeft';
import { ExpertiseHeaderRight } from './ExpertiseHeaderRight';

type OuterProps = {
    expertiseModel: ExpertiseModel;
    reloadModel: () => void;
};

type StoreProps = {
    expertiseStore: ExpertiseStore;
};

type InnerProps = OuterProps &
    StoreProps &
    RouteComponentProps<ExpertisePageRouteParams> &
    WithModalProps &
    WithLoadingProps &
    ThemedComponentProps;

class ExpertiseHeader extends React.Component<InnerProps, {}> {
    editExpertiseCurator = (curatorUserId: string): void => {
        const { expertiseStore, expertiseModel } = this.props;
        this.props.enableLoading();
        expertiseStore
            .editCurator(expertiseModel.id, curatorUserId)
            .then(() => {
                this.props.reloadModel();
                this.props.setModalIsClosed();
            })
            .finally(() => {
                this.props.disableLoading();
            });
    };

    render(): JSX.Element {
        const { expertiseModel } = this.props;
        const { id } = this.props.match.params;
        const {
            identifier,
            metaInfo,
            template,
            campaign: expertiseCampaign,
            subject: subjectOfExpertise,
        } = expertiseModel;

        const { stateTitle, created } = metaInfo;

        return (
            <TotObjectHeader>
                <ExpertiseEditCurator
                    open={this.props.isModalOpen}
                    onCancel={this.props.setModalIsClosed}
                    onSubmit={this.editExpertiseCurator}
                    curatorUserId={expertiseModel.curator?.userId}
                    isLoading={this.props.isLoading}
                />
                <PageHeader
                    title={<PageHeaderTitle title={subjectOfExpertise?.title} />}
                    left={
                        <ExpertiseHeaderLeft
                            identifier={identifier}
                            stateTitle={stateTitle}
                            created={created}
                            template={template}
                            subjectOfExpertise={subjectOfExpertise}
                        />
                    }
                    right={
                        <ExpertiseHeaderRight
                            expertiseCampaign={expertiseCampaign}
                            curatorName={expertiseModel.curator.name}
                            id={id}
                            setModalIsOpen={this.props.setModalIsOpen}
                        />
                    }
                />
            </TotObjectHeader>
        );
    }
}

export default compose<InnerProps, OuterProps>(
    withRouter,
    withModal,
    withLoading,
    inject<RootStore, {}, StoreProps, {}>(({ expertiseStore }) => {
        return {
            expertiseStore,
        };
    }),
    observer,
)(ExpertiseHeader);
