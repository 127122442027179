import { action, computed, observable } from 'mobx';
import { Api } from '../../store/Api';
import { asyncAction } from 'mobx-utils';
import { ChangeEvent, FormEvent } from 'react';
import { IntlStore } from '../../store/IntlStore';
import apiConfigs from '../../apiConfigs';

export interface RegistrationDTO {
    lastName: string;
    firstName: string;
    middleName?: string;
    email: string;
    password: string;
    lang: string;
}

export default class RegistrationModel {
    @observable protected api: Api;
    @observable intl: IntlStore;

    @observable email = '';
    @observable password = '';
    @observable firstName = '';
    @observable middleName?: string;
    @observable lastName = '';
    @observable termsIsAccepted = false;

    @observable validationStarted = false;

    private emailAlreadyRegisteredError = 'Email already registered';
    private confirmTokenStillNotExpiredError = 'Confirm token still not expired';
    @observable registerStatus?: 'success' | 'failed' | 'Email already registered' | 'Confirm token still not expired';

    constructor(api: Api, intlStore: IntlStore) {
        this.api = api;
        this.intl = intlStore;
    }

    @asyncAction
    @action.bound
    async register(event: FormEvent<HTMLFormElement>): Promise<void> {
        event.preventDefault();
        this.validationStarted = true;
        if (this.isValid) {
            try {
                await this.api.client(apiConfigs.register(this.asJson));
                this.registerStatus = 'success';
            } catch (e) {
                if (e.response && e.response.status === 409) {
                    this.registerStatus = e.response.data;
                } else {
                    this.registerStatus = 'failed';
                }
            }
        }
    }

    @action.bound
    onChangeEmail(event: ChangeEvent<HTMLInputElement>): void {
        this.email = event.target.value;
        if (this.isRegisterConflict()) {
            this.registerStatus = undefined;
        }
    }

    @action.bound
    onPasswordChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.password = event.target.value;
    }

    @computed
    get asJson(): RegistrationDTO {
        return {
            email: this.email,
            password: this.password,
            firstName: this.firstName,
            middleName: this.middleName,
            lastName: this.lastName,
            lang: this.intl.locale,
        };
    }

    @computed
    get registerSucceed(): boolean {
        return this.registerStatus === 'success';
    }

    @computed
    get errorEmail(): string {
        if (this.validationStarted) {
            if (!this.email) {
                return this.intl.formatMessage('validation.required');
            }
            if (this.registerStatus === this.emailAlreadyRegisteredError) {
                return this.intl.formatMessage('authentication.emailAlreadyExists');
            }
            if (this.registerStatus === this.confirmTokenStillNotExpiredError) {
                return this.intl.formatMessage('authentication.confirmingEmailReminder');
            }
        }
        return '';
    }

    @computed
    get errorPassword(): string {
        const minLength = 8;
        if (this.validationStarted && this.password.length < minLength) {
            return this.intl.formatMessage('validation.minLength', { length: minLength });
        }
        return '';
    }

    @computed
    get errorFirstName(): string {
        if (this.validationStarted && !this.firstName) {
            return this.intl.formatMessage('validation.required');
        }
        return '';
    }

    @computed
    get errorLastName(): string {
        if (this.validationStarted && !this.lastName) {
            return this.intl.formatMessage('validation.required');
        }
        return '';
    }

    @computed
    get isValid(): boolean {
        return !this.errorEmail && !this.errorPassword && !this.errorFirstName && !this.errorLastName;
    }

    private isRegisterConflict(): boolean {
        return (
            this.registerStatus === this.emailAlreadyRegisteredError ||
            this.registerStatus === this.confirmTokenStillNotExpiredError
        );
    }
}
