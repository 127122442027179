import React, { PropsWithChildren } from 'react';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

const topPadding = 8;
const minItemCount = 8;

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: (style.top as number) + topPadding,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});
OuterElementType.displayName = 'OuterElementType';

export const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(
    props: PropsWithChildren<any>,
    ref,
) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const childSize = 36;

    const getHeight = (): number => {
        if (itemCount > minItemCount) {
            return minItemCount * childSize;
        }
        return itemData.map(() => childSize).reduce((a, b) => a + b, 0);
    };

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight()}
                    width="100%"
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(): number => childSize}
                    itemCount={itemCount}
                    overscanCount={5}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});
