import React, { ReactNode } from 'react';
import { AuthorizationCheck } from '../AuthorizationCheck';
import { entities, permissions, permissionsConfig } from '../../authSchemeConfig';
import { Button, Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { ReactComponent as FolderIcon } from '../../resources/images/icons/folder.svg';
import { FormattedMessage } from 'react-intl';
import PeopleIcon from '@material-ui/icons/People';
import SubjectIcon from '@material-ui/icons/Subject';
import { ReactComponent as TemplateIcon } from '../../resources/images/icons/template-icon.svg';
import { ReactComponent as ShapeIcon } from '../../resources/images/icons/shape.svg';
import { ReactComponent as TaskIcon } from '../../resources/images/icons/task-icon.svg';
import { ReactComponent as ExpertiseIcon } from '../../resources/images/icons/file-document-box-search.svg';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import { getOrElse } from '../../utils/getOrElse';
import { FormatListNumbered } from '@material-ui/icons';
import { AuthorizationCheckQuery } from '../../store/AuthorizationStore';
import { di } from 'react-magnetic-di';

const DEFAULT_SIZE = 'defaultSize';
const MD_SIZE = 'mdSize';
const SM_SIZE = 'smSize';

type AllHeaderLinksType = Record<string, ReactNode>;
type LinksKeysType = Record<string, string[]>;

export type PermissionHeaderLink = { [key: string]: AuthorizationCheckQuery };
export type PermissionHeaderLinks = { [key: string]: PermissionHeaderLink };

const permissionsHeaderLinks: PermissionHeaderLinks = {
    defaultSize: {
        administration: permissionsConfig.viewHeaderLinks(permissions.System.Administration),
        viewTemplateExpertiseList: permissionsConfig.viewHeaderLinks(permissions.System.ViewTemplateExpertiseList),
        viewPfTemplatesList: permissionsConfig.viewHeaderLinks(permissions.System.ViewPfTemplatesList),
    },
    mdSize: {
        administration: permissionsConfig.viewHeaderLinks(permissions.System.Administration),
        viewTemplateExpertiseList: permissionsConfig.viewHeaderLinks(permissions.System.ViewTemplateExpertiseList),
        viewPfTemplatesList: permissionsConfig.viewHeaderLinks(permissions.System.ViewPfTemplatesList),
        viewUserList: permissionsConfig.viewHeaderLinks(permissions.System.ViewUserList),
    },
    smSize: {
        viewCampaignList: permissionsConfig.viewHeaderLinks(permissions.System.ViewCampaignList),
        viewExpertiseList: permissionsConfig.viewHeaderLinks(permissions.System.ViewExpertiseList),
        viewExpertiseSubjectList: permissionsConfig.viewHeaderLinks(permissions.System.ViewExpertiseSubjectList),
        viewTemplateExpertiseList: permissionsConfig.viewHeaderLinks(permissions.System.ViewTemplateExpertiseList),
        administration: permissionsConfig.viewHeaderLinks(permissions.System.Administration),
        viewPfTemplatesList: permissionsConfig.viewHeaderLinks(permissions.System.ViewPfTemplatesList),
        viewUserList: permissionsConfig.viewHeaderLinks(permissions.System.ViewUserList),
    },
};

export const getPermissionsHeaderLinksInj = (): PermissionHeaderLinks => {
    return permissionsHeaderLinks;
};

const allHeaderLinks: AllHeaderLinksType = {
    campaigns: (
        <AuthorizationCheck key="campaigns" entityCode={entities.System} permCode={permissions.System.ViewCampaignList}>
            <Grid item>
                <Button
                    component={NavLink}
                    to={clientRoute.campaigns}
                    startIcon={<WorkOutlineOutlinedIcon style={{ fontSize: '24px' }} />}
                    variant="text"
                    color="secondary"
                >
                    <FormattedMessage id="headerLinks.campaigns" />
                </Button>
            </Grid>
        </AuthorizationCheck>
    ),
    subjectsOfExpertise: (
        <AuthorizationCheck
            key="subjectsOfExpertise"
            entityCode={entities.System}
            permCode={permissions.System.ViewExpertiseSubjectList}
        >
            <Grid item>
                <Button
                    component={NavLink}
                    to={clientRoute.subjects}
                    startIcon={<ShapeIcon />}
                    variant="text"
                    color="secondary"
                >
                    <FormattedMessage id="headerLinks.subjects" />
                </Button>
            </Grid>
        </AuthorizationCheck>
    ),
    categories: (
        <AuthorizationCheck key="categories" entityCode={entities.System} permCode={permissions.System.Administration}>
            <Grid item>
                <Button
                    component={NavLink}
                    to={clientRoute.categories}
                    variant="text"
                    color="secondary"
                    startIcon={<FolderIcon />}
                >
                    <FormattedMessage id="headerLinks.categories" />
                </Button>
            </Grid>
        </AuthorizationCheck>
    ),
    expertises: (
        <AuthorizationCheck
            key="expertises"
            entityCode={entities.System}
            permCode={permissions.System.ViewExpertiseList}
        >
            <Grid item>
                <Button
                    component={NavLink}
                    to={clientRoute.expertiseList}
                    variant="text"
                    color="secondary"
                    startIcon={<ExpertiseIcon />}
                >
                    <FormattedMessage id="headerLinks.expertiseList" />
                </Button>
            </Grid>
        </AuthorizationCheck>
    ),
    pfTemplates: (
        <AuthorizationCheck
            key="pfTemplates"
            entityCode={entities.System}
            permCode={permissions.System.ViewPfTemplatesList}
        >
            <Grid item>
                <Button
                    component={NavLink}
                    to={clientRoute.pfTemplateList}
                    variant="text"
                    color="secondary"
                    startIcon={<SubjectIcon style={{ fontSize: '24px' }} />}
                >
                    <FormattedMessage id="headerLinks.pfTemplates" />
                </Button>
            </Grid>
        </AuthorizationCheck>
    ),
    templatesOfExpertise: (
        <AuthorizationCheck
            key="templatesOfExpertise"
            entityCode={entities.System}
            permCode={permissions.System.ViewTemplateExpertiseList}
        >
            <Grid item>
                <Button
                    component={NavLink}
                    to={clientRoute.templatesOfExpertise}
                    variant="text"
                    color="secondary"
                    startIcon={<TemplateIcon />}
                >
                    <FormattedMessage id="headerLinks.templatesOfExpertise" />
                </Button>
            </Grid>
        </AuthorizationCheck>
    ),
    expertiseTasks: (
        <AuthorizationCheck
            key="expertiseTasks"
            entityCode={entities.System}
            permCode={permissions.System.ViewExpertiseTaskList}
        >
            <Grid item>
                <Button
                    component={NavLink}
                    to={clientRoute.expertiseTasks}
                    exact
                    variant="text"
                    color="secondary"
                    startIcon={<TaskIcon />}
                >
                    <FormattedMessage id="headerLinks.expertiseTasks">
                        {(message: React.ReactNode): JSX.Element => (
                            <span style={{ whiteSpace: 'nowrap' }}>{message}</span>
                        )}
                    </FormattedMessage>
                </Button>
            </Grid>
        </AuthorizationCheck>
    ),
    expertiseTasksForCurator: (
        <AuthorizationCheck
            key="expertiseTasksForCurator"
            entityCode={entities.System}
            permCode={permissions.System.ViewRegistryCuratorTaskList}
        >
            <Grid item>
                <Button
                    component={NavLink}
                    to={clientRoute.expertiseTasksForCurator}
                    exact
                    variant="text"
                    color="secondary"
                    startIcon={<FormatListNumbered style={{ fontSize: '24px' }} />}
                >
                    <FormattedMessage id="headerLinks.expertiseTasksForCurator" />
                </Button>
            </Grid>
        </AuthorizationCheck>
    ),
    users: (
        <AuthorizationCheck key="users" entityCode={entities.System} permCode={permissions.System.ViewUserList}>
            <Grid item>
                <Button
                    component={NavLink}
                    to={clientRoute.users}
                    variant="text"
                    color="secondary"
                    startIcon={<PeopleIcon style={{ fontSize: '24px' }} />}
                >
                    <FormattedMessage id="headerLinks.users" />
                </Button>
            </Grid>
        </AuthorizationCheck>
    ),
};

export const getAllHeaderLinksInj = (): AllHeaderLinksType => {
    return allHeaderLinks;
};

const getBurgerLinks = (): LinksKeysType => {
    const [getAllHeaderLinks] = di([getAllHeaderLinksInj], getBurgerLinks as any);
    const allHeaderLinks: AllHeaderLinksType = getAllHeaderLinks();
    return {
        defaultSize: ['categories', 'templatesOfExpertise', 'pfTemplates'],
        mdSize: ['users', 'categories', 'templatesOfExpertise', 'pfTemplates'],
        smSize: Object.keys(allHeaderLinks).filter((key) => key !== 'expertiseTasks'),
    };
};

const getMainLinks = (): LinksKeysType => {
    const burgerLinks: LinksKeysType = getBurgerLinks();
    return {
        defaultSize: Object.keys(allHeaderLinks).filter((key) => {
            return getOrElse(
                burgerLinks[DEFAULT_SIZE].find((bKey) => bKey === key),
                false,
                true,
            );
        }),
        mdSize: Object.keys(allHeaderLinks).filter((key) => {
            return getOrElse(
                burgerLinks[MD_SIZE].find((bKey) => bKey === key),
                false,
                true,
            );
        }),
        smSize: Object.keys(allHeaderLinks).filter((key) => {
            return getOrElse(
                burgerLinks[SM_SIZE].find((bKey) => bKey === key),
                false,
                true,
            );
        }),
    };
};

const mapArrays = (size: string, links: LinksKeysType): ReactNode[] => {
    const [getAllHeaderLinks] = di([getAllHeaderLinksInj], getBurgerLinks as any);
    const allHeaderLinks: AllHeaderLinksType = getAllHeaderLinks();
    return links[size].map((link) => {
        return allHeaderLinks[link];
    });
};

export const getMainLinksElements = () => {
    const mainLinks = getMainLinks();
    return {
        defaultSize: mapArrays(DEFAULT_SIZE, mainLinks),
        mdSize: mapArrays(MD_SIZE, mainLinks),
        smSize: mapArrays(SM_SIZE, mainLinks),
    };
};

export const getBurgerLinksElements = () => {
    const burgerLinks = getBurgerLinks();
    return {
        defaultSize: mapArrays(DEFAULT_SIZE, burgerLinks),
        mdSize: mapArrays(MD_SIZE, burgerLinks),
        smSize: mapArrays(SM_SIZE, burgerLinks),
    };
};
