import React from 'react';
import { TablePagination, TablePaginationProps } from '@material-ui/core';
import { observer } from 'mobx-react';

// Вынес в отдельный компонент, хотел доработать, но понял, что много доработок требуется.
// Удалять не стал, т. к. в любом случае дорабатывать позже придется
export const TotTablePagination = observer(
    (props: TablePaginationProps): JSX.Element => {
        return <TablePagination {...props} />;
    },
);
