import React from 'react';
import { Grid } from '@material-ui/core';
import { RowData } from '../../../../components/PageHeader/RowData';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { getOrElse } from '../../../../utils/getOrElse';
import { generatePath, NavLink } from 'react-router-dom';
import clientRoute from '../../../../clientRoute';
import { ExpertiseLink } from './ExpertiseLink';
import { ExpertiseTaskModel } from '../../../../models/expertise-task/ExpertiseTaskModel';
import { entities, permissions } from '../../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../../components/AuthorizationCheck';
import { ExpertiseTaskAuthEditField } from '../edit/ExpertiseTaskAuthEditField';

export type ExpertiseTaskHeaderDescriptionLeftProps = {
    deadline: ExpertiseTaskModel['deadline'];
    stateTitle: ExpertiseTaskModel['metaInfo']['stateTitle'];
    expertise: ExpertiseTaskModel['expertise'];
    viewPoint: ExpertiseTaskModel['viewPoint'];
    started: ExpertiseTaskModel['started'];
    toggleIsOpenStartDateDialog?: () => void;
    toggleIsOpenDueDateDialog?: () => void;
    entityId: string;
};

export const ExpertiseTaskHeaderDescriptionLeft = (props: ExpertiseTaskHeaderDescriptionLeftProps): JSX.Element => {
    const {
        deadline,
        stateTitle,
        expertise,
        viewPoint,
        started,
        toggleIsOpenStartDateDialog,
        toggleIsOpenDueDateDialog,
        entityId,
    } = props;

    const expertiseValue = expertise?.title || '-';
    const expertiseWrapper = expertise?.id
        ? (children: {} | null): JSX.Element => {
              return (
                  <ExpertiseLink
                      component={NavLink}
                      to={generatePath(clientRoute.expertise, {
                          id: expertise.id,
                      })}
                      underline="none"
                      color="textPrimary"
                  >
                      {children}
                  </ExpertiseLink>
              );
          }
        : undefined;

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item container direction="row" spacing={7} wrap="nowrap" alignItems="center">
                <Grid item>
                    <Grid container alignItems="center" wrap="nowrap">
                        <AuthorizationCheck
                            entityCode={entities.ExpertiseTask}
                            permCode={permissions.ExpertiseTask.View}
                        >
                            <React.Fragment>
                                <Grid item>
                                    <RowData
                                        label={<FormattedMessage id="expertiseTask.fields.deadline" />}
                                        value={getOrElse(deadline, <FormattedDate value={deadline} />)}
                                    />
                                </Grid>
                                <ExpertiseTaskAuthEditField
                                    toggleIsOpenDialog={toggleIsOpenDueDateDialog}
                                    messageId={'expertiseTask.editDeadline'}
                                    permCode={permissions.ExpertiseTask.EditDeadlineAndStartedDates}
                                    entityId={entityId}
                                />
                            </React.Fragment>
                        </AuthorizationCheck>
                    </Grid>
                </Grid>
                <Grid item>
                    <RowData
                        label={<FormattedMessage id="expertiseTask.fields.state" />}
                        value={getOrElse(stateTitle)}
                    />
                </Grid>
            </Grid>
            <Grid item container direction="row" spacing={7} wrap="nowrap" alignItems="center">
                <Grid item>
                    <Grid container alignItems="center" wrap="nowrap">
                        <AuthorizationCheck
                            entityCode={entities.ExpertiseTask}
                            permCode={permissions.ExpertiseTask.View}
                        >
                            <React.Fragment>
                                <Grid item>
                                    <RowData
                                        label={<FormattedMessage id="expertiseTask.fields.started" />}
                                        value={getOrElse(started, <FormattedDate value={started} />)}
                                    />
                                </Grid>
                                <ExpertiseTaskAuthEditField
                                    toggleIsOpenDialog={toggleIsOpenStartDateDialog}
                                    messageId={'expertiseTask.editStarted'}
                                    permCode={permissions.ExpertiseTask.EditDeadlineAndStartedDates}
                                    entityId={entityId}
                                />
                            </React.Fragment>
                        </AuthorizationCheck>
                    </Grid>
                </Grid>
                <Grid item>
                    <RowData
                        label={<FormattedMessage id="expertiseTask.fields.expertise" />}
                        value={expertiseValue}
                        valueWrapper={expertiseWrapper}
                    />
                </Grid>
                <Grid item>
                    <RowData
                        label={<FormattedMessage id="expertiseTask.fields.viewPoint" />}
                        value={getOrElse(viewPoint?.title)}
                        valueMaxLength={25}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
