import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import React from 'react';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { useYup } from '../../../hooks/useYup';

export type ExpertiseTaskEditDateProps = {
    open: boolean;
    onCancel: () => void;
    onSubmit: (date: Date) => void;
    currentDate: Date;
    label: string;
    title: JSX.Element;
    maxValue?: Date | undefined;
    minValue?: Date | undefined;
};

type FormikValues = {
    date: Date;
};

const useStyles = makeStyles(() =>
    createStyles({
        textField: {
            width: 300,
        },
    }),
);

const getDateWithoutTime = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const ExpertiseTaskEditDate = (props: ExpertiseTaskEditDateProps): JSX.Element => {
    const classes = useStyles();
    const intl = useIntl();
    const { Yup } = useYup();
    const { open, onCancel, onSubmit, currentDate, label, title, minValue, maxValue } = props;

    const schema = Yup.object().shape({
        date: Yup.date()
            .nullable()
            .required()
            .test('maxDate', intl.formatMessage({ id: 'validation.maxDate' }), (value) => {
                if (value && maxValue) {
                    const valueWithoutTime = getDateWithoutTime(value);
                    const maxValueWithoutTime = getDateWithoutTime(maxValue);
                    if (valueWithoutTime.getTime() >= maxValueWithoutTime.getTime()) {
                        return false;
                    }
                }
                return true;
            })
            .test('minDate', intl.formatMessage({ id: 'validation.minDate' }), (value) => {
                if (value && minValue) {
                    const valueWithoutTime = getDateWithoutTime(value);
                    const minValueWithoutTime = getDateWithoutTime(minValue);
                    if (valueWithoutTime.getTime() <= minValueWithoutTime.getTime()) {
                        return false;
                    }
                }
                return true;
            }),
    });

    return (
        <Formik
            initialValues={{ date: currentDate }}
            validationSchema={schema}
            onSubmit={(values: FormikValues): void => onSubmit(values.date)}
        >
            {(): JSX.Element => (
                <Dialog open={open} onClose={onCancel}>
                    <DialogTitle>{title}</DialogTitle>
                    <Form>
                        <DialogContent>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                                <Field
                                    className={classes.textField}
                                    format="dd.MM.yyyy"
                                    name="date"
                                    component={KeyboardDatePicker}
                                    inputVariant="outlined"
                                    label={label}
                                    ampm={false}
                                    cancelLabel={<FormattedMessage id="common.cancel" />}
                                    okLabel={<FormattedMessage id="common.confirm" />}
                                    invalidDateMessage={intl.formatMessage({ id: 'common.invalidDateMessage' })}
                                />
                            </MuiPickersUtilsProvider>
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={onCancel}>
                                <FormattedMessage id="common.cancel" />
                            </Button>
                            <Button type="submit" color="secondary" variant="contained">
                                <FormattedMessage id="common.save" />
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}
        </Formik>
    );
};
