import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { generatePath, Route, useParams } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { observer } from 'mobx-react';
import { FormApi } from '@platform/formiojs-react';
import TemplateOfExpertiseHeader from './TemplateOfExpertiseHeader/TemplateOfExpertiseHeader';
import { TemplateOfExpertiseControlPanel } from './TemplateOfExpertiseControlPanel/TemplateOfExpertiseControlPanel';
import { TotObjectMain } from '../../components/tot-object/TotObjectMain';
import ExpertisePlan from './ExpertisePlan';
import { ServiceInfoPanel } from '../../components/ServiceInfoPanel';
import { entities, permissions } from '../../authSchemeConfig';
import { AuthorizationCheck } from '../../components/AuthorizationCheck';
import PlanEntryListModel from '../../models/expertise-plan/PlanEntryListModel';
import { TotObjectForm } from '../../components/tot-object/TotObjectForm';
import { useStore } from '../../hooks/useStore';
import { useReload } from '../../hooks/useReload';

type TemplateOfExpertisePageRouteParams = {
    id: string;
};

export const TemplateOfExpertisePage = observer(
    (): JSX.Element => {
        const { templatesOfExpertiseStore, intlStore, history } = useStore();
        const { reloadKey, reloadIncrement } = useReload();
        const { id } = useParams<TemplateOfExpertisePageRouteParams>();
        const [formApi, setFormApi] = useState<FormApi>();
        const planEntryListModel = useMemo(() => {
            return new PlanEntryListModel(id, templatesOfExpertiseStore);
        }, [id, templatesOfExpertiseStore]);

        const templatesOfExpertiseModel = useMemo(() => templatesOfExpertiseStore.getTemplatesOfExpertiseModel(id), [
            id,
        ]);

        useEffect(() => updateCard, [intlStore.locale, reloadKey]);

        const updateCard = (): void => {
            templatesOfExpertiseStore.loadTemplateOfExpertiseDTO(id).then(templatesOfExpertiseModel.loadCard);
        };

        const onFormReady = (form: FormApi): void => {
            setFormApi(form);
        };

        const onSubmit = (): void => {
            if (formApi && formApi.validate()) {
                templatesOfExpertiseStore.saveChangeTemplateOfExpertise(id, formApi.getSubmission()).then(() => {
                    reloadIncrement();
                    history.push(generatePath(clientRoute.templateOfExpertise, { id }));
                });
            }
        };

        const deleteTemplateOfExpertise = (): Promise<void> => {
            return templatesOfExpertiseStore.delete(templatesOfExpertiseModel.id).then(() => {
                history.push(generatePath(clientRoute.templatesOfExpertise));
            });
        };

        const onLifeCycleTransition = (transitionId: string, taskId: string, validate?: boolean): Promise<void> => {
            const { lifeCycleTransition } = templatesOfExpertiseStore;
            const onSuccess = () => lifeCycleTransition(transitionId, taskId, reloadIncrement);
            if (validate) {
                return validateForm(onSuccess);
            } else {
                return onSuccess();
            }
        };

        const validateForm = (onSuccess?: () => Promise<void>): Promise<void> => {
            const isFormValid = (formApi && formApi.validate()) || false;
            if (isFormValid) {
                return onSuccess ? onSuccess() : Promise.resolve();
            }
            return Promise.reject(['validation error']);
        };

        const setCommonValidationState = (state: boolean) => {
            templatesOfExpertiseStore.showValidation = state;
        };

        const { formModel } = templatesOfExpertiseModel;
        return (
            <Grid container direction="column" wrap="nowrap">
                <Grid item>
                    <TemplateOfExpertiseHeader templatesOfExpertiseModel={templatesOfExpertiseModel} />
                </Grid>
                <TotObjectMain>
                    <Grid container spacing={10}>
                        <Grid item container direction="column" spacing={10}>
                            <AuthorizationCheck
                                entityCode={entities.System}
                                permCode={permissions.System.Administration}
                            >
                                <Grid item>
                                    <ServiceInfoPanel metaInfoModel={templatesOfExpertiseModel.metaInfo} />
                                </Grid>
                            </AuthorizationCheck>
                            <Grid item style={{ maxWidth: '100%' }}>
                                <TotObjectForm
                                    reloadKey={reloadKey}
                                    formName="template"
                                    model={formModel}
                                    editPath={clientRoute.templateOfExpertiseEdit}
                                    createPath={clientRoute.templateOfExpertiseCreate}
                                    readPath={clientRoute.templateOfExpertise}
                                    onFormReady={onFormReady}
                                    showReadonlyValidation={templatesOfExpertiseStore.showValidation}
                                    hideReadonlyValidation={() => setCommonValidationState(false)}
                                    editAuthProps={{
                                        entityCode: entities.TemplateExpertise,
                                        permCode: permissions.TemplateExpertise.Edit,
                                        entityId: id,
                                    }}
                                />
                            </Grid>
                            <Route
                                exact
                                path={[
                                    clientRoute.templateOfExpertise,
                                    clientRoute.templateOfExpertisePlanEntryCreate,
                                    clientRoute.templateOfExpertisePlanEntryEdit,
                                ]}
                            >
                                <Grid item>
                                    <ExpertisePlan
                                        key={reloadKey}
                                        createPath={clientRoute.templateOfExpertisePlanEntryCreate}
                                        editPath={clientRoute.templateOfExpertisePlanEntryEdit}
                                        ownerPagePath={clientRoute.templateOfExpertise}
                                        ownerEntityCode={entities.TemplateExpertise}
                                        editPermission={permissions.TemplateExpertise.EditExpertisePlan}
                                        model={planEntryListModel}
                                    />
                                </Grid>
                            </Route>
                        </Grid>
                    </Grid>
                </TotObjectMain>
                <Grid item>
                    <TemplateOfExpertiseControlPanel
                        key={reloadKey}
                        templateOfExpertiseTitle={templatesOfExpertiseModel.title}
                        deleteTemplateOfExpertise={deleteTemplateOfExpertise}
                        onSubmit={onSubmit}
                        onLifeCycleTransition={onLifeCycleTransition}
                    />
                </Grid>
            </Grid>
        );
    },
);
