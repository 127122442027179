import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { generatePath, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { SubjectHeader } from './SubjectHeader/SubjectHeader';
import { SubjectControlPanel } from './SubjectControlPanel/SubjectControlPanel';
import clientRoute from '../../../clientRoute';
import { TotObjectMain } from '../../../components/tot-object/TotObjectMain';
import { SubjectServiceInfo } from './SubjectServiceInfo';
import { FormApi } from '@platform/formiojs-react';
import { TotObjectForm } from '../../../components/tot-object/TotObjectForm';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../components/AuthorizationCheck';
import { useStore } from '../../../hooks/useStore';
import { useReload } from '../../../hooks/useReload';

export type SubjectPageRouteParams = {
    id: string;
};

export const SubjectPage = observer(
    (): JSX.Element => {
        const { subjectStore, intlStore, history } = useStore();
        const { id } = useParams<SubjectPageRouteParams>();
        const { reloadKey, reloadIncrement } = useReload();
        const subjectModel = useMemo(() => subjectStore.getSubjectModel(id), [id]);

        const [commonFormApi, setCommonFormApi] = useState<FormApi>();
        const [hiddenFormApi, setHiddenFormApi] = useState<FormApi>();

        useEffect(() => updateCard, [intlStore.locale, reloadKey]);

        const updateCard = (): void => {
            subjectStore.loadSubject(id).then(subjectModel.loadCard);
        };

        const onCommonFormReady = (form: FormApi): void => {
            setCommonFormApi(form);
        };

        const onHiddenFormReady = (form: FormApi): void => {
            setHiddenFormApi(form);
        };

        const onSubmit = (): void => {
            const isCommonValid = (commonFormApi && commonFormApi.validate()) || false;
            const isHiddenValid = !hiddenFormApi || hiddenFormApi.validate();
            if (commonFormApi && isCommonValid && isHiddenValid) {
                subjectStore
                    .saveForms(id, commonFormApi.getSubmission(), hiddenFormApi?.getSubmission())
                    .then(onSaveSuccess);
            }
        };

        const onLifeCycleTransition = (transitionId: string, subjectId: string, validate?: boolean): Promise<void> => {
            const { lifeCycleTransition } = subjectStore;
            const onSuccess = () => lifeCycleTransition(transitionId, subjectId, reloadIncrement);
            if (validate) {
                return validateForms(onSuccess);
            } else {
                return onSuccess();
            }
        };

        const validateForms = (onSuccess?: () => Promise<void>): Promise<void> => {
            const isCommonValid = (commonFormApi && commonFormApi.validate()) || false;
            const isHiddenValid = !hiddenFormApi || hiddenFormApi.validate();
            if (isCommonValid && isHiddenValid) {
                setCommonValidationState(!isCommonValid);
                setHiddenValidationState(!isHiddenValid);
                return onSuccess ? onSuccess() : Promise.resolve();
            } else if (commonFormApi?.readOnly) {
                setCommonValidationState(!isCommonValid);
                setHiddenValidationState(!isHiddenValid);
            }
            return Promise.reject(['validation error']);
        };

        const onSaveSuccess = () => {
            reloadIncrement();
            history.push(generatePath(clientRoute.subject, { id }));
        };

        const setCommonValidationState = (state: boolean): void => {
            subjectStore.showCommonValidation = state;
        };

        const setHiddenValidationState = (state: boolean): void => {
            subjectStore.showHiddenValidation = state;
        };

        return (
            <Grid container direction="column" wrap="nowrap">
                <Grid item>
                    <SubjectHeader subjectModel={subjectModel} />
                </Grid>
                <TotObjectMain>
                    <Grid container spacing={10}>
                        <Grid item container direction="column" spacing={10}>
                            <AuthorizationCheck
                                entityCode={entities.System}
                                permCode={permissions.System.Administration}
                            >
                                <Grid item>
                                    <SubjectServiceInfo subjectModel={subjectModel} />
                                </Grid>
                            </AuthorizationCheck>
                            <Grid item style={{ maxWidth: '100%' }}>
                                <TotObjectForm
                                    reloadKey={reloadKey}
                                    formName="common"
                                    model={subjectModel.commonFormModel}
                                    editPath={clientRoute.subjectEdit}
                                    createPath={clientRoute.subjectCreate}
                                    readPath={clientRoute.subject}
                                    onFormReady={onCommonFormReady}
                                    showReadonlyValidation={subjectStore.showCommonValidation}
                                    hideReadonlyValidation={() => setCommonValidationState(false)}
                                    editAuthProps={{
                                        entityCode: entities.ExpertiseSubject,
                                        permCode: permissions.ExpertiseSubject.Edit,
                                        entityId: id,
                                    }}
                                />
                            </Grid>
                            <Grid item style={{ maxWidth: '100%' }}>
                                <TotObjectForm
                                    reloadKey={reloadKey}
                                    formName="hidden"
                                    model={subjectModel.hiddenFormModel}
                                    editPath={clientRoute.subjectEdit}
                                    createPath={clientRoute.subjectCreate}
                                    readPath={clientRoute.subject}
                                    onFormReady={onHiddenFormReady}
                                    showReadonlyValidation={subjectStore.showHiddenValidation}
                                    hideReadonlyValidation={() => setHiddenValidationState(false)}
                                    editAuthProps={{
                                        entityCode: entities.ExpertiseSubject,
                                        permCode: permissions.ExpertiseSubject.Edit,
                                        entityId: id,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </TotObjectMain>
                <Grid item>
                    <SubjectControlPanel
                        key={reloadKey}
                        subjectModel={subjectModel}
                        onSubmit={onSubmit}
                        onLifeCycleTransition={onLifeCycleTransition}
                    />
                </Grid>
            </Grid>
        );
    },
);
