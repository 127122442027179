import React from 'react';
import { Route, Switch } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import { TotBackground } from '../../../TotBackground';
import { Grid } from '@material-ui/core';
import EsiaGetAuthUrlPage from './EsiaGetAuthUrlPage';
import EsiaLoginPage from './EsiaLoginPage';

class EsiaPages extends React.Component<{}, {}> {
    render(): JSX.Element {
        return (
            <TotBackground>
                <Grid container item direction="column" alignItems="center" justify="center">
                    <Switch>
                        <Route path={clientRoute.esiaGetAuthUrl}>
                            <EsiaGetAuthUrlPage />
                        </Route>
                        <Route path={clientRoute.esiaLogin}>
                            <EsiaLoginPage />
                        </Route>
                    </Switch>
                </Grid>
            </TotBackground>
        );
    }
}

export default EsiaPages;
