import React, { ReactNode, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    LinearProgress,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import CategoriesOfSubjectsListModel, {
    CategoriesOfSubjectsRowDTO,
} from '../../models/categories/CategoriesOfSubjectsListModel';
import { TotTablePagination } from '../../components/TotTablePagination';
import clientRoute from '../../clientRoute';
import { MenuButton } from '../../components/buttons/MenuButton';
import { DeleteActionMenuItem } from '../../components/DeleteActionMenuItem';
import { entities, permissions } from '../../authSchemeConfig';
import { AuthorizationCheck } from '../../components/AuthorizationCheck';
import { useStore } from '../../hooks/useStore';
import { useLoading } from '../../hooks/useLoading';
import { ActionsButton } from '../../components/buttons/ActionsButton';

export const CategoriesOfSubjectsListPage = observer(
    (): JSX.Element => {
        const { api, categoriesOfSubjectsStore, history } = useStore();
        const intl = useIntl();

        const tableModel = useMemo(() => new CategoriesOfSubjectsListModel(api), [api]);

        useEffect(() => {
            return tableModel.dispose;
        }, [tableModel.dispose]);

        const { isLoading, enableLoading, disableLoading } = useLoading();
        const createCategoryOfSubjects = async (): Promise<void> => {
            try {
                enableLoading();
                const id = await categoriesOfSubjectsStore.createCategoryOfSubjects();
                history.push(generatePath(clientRoute.categoryCreate, { id }));
            } catch (error) {
                disableLoading();
            }
        };

        const deleteCategoryOfSubjects = (categoryRow: CategoriesOfSubjectsRowDTO): (() => Promise<void>) => {
            return (): Promise<void> => {
                return categoriesOfSubjectsStore.deleteCategoryOfSubjects(categoryRow.id).then(tableModel.reloadData);
            };
        };

        const onNotAllowed = () => {
            history.replace(clientRoute.notAllowed);
        };

        const renderActionItems = (categoryRow: CategoriesOfSubjectsRowDTO): (() => ReactNode[]) => {
            return (): ReactNode[] => [
                <DeleteActionMenuItem
                    id="delete"
                    key="delete"
                    wrappedComponentProps={{ tabIndex: 0 }}
                    title={<FormattedMessage id="common.confirmDeletion" />}
                    message={
                        <FormattedMessage id="category.confirmDeletionInfoText" values={{ title: categoryRow.title }} />
                    }
                    onConfirm={deleteCategoryOfSubjects(categoryRow)}
                />,
            ];
        };

        const renderActions = (categoryRow: CategoriesOfSubjectsRowDTO): JSX.Element => {
            return (
                <MenuButton
                    disablePortal={true}
                    renderButton={ActionsButton}
                    renderMenuItems={renderActionItems(categoryRow)}
                />
            );
        };

        return (
            <AuthorizationCheck
                entityCode={entities.System}
                permCode={permissions.System.Administration}
                onNotAllowed={onNotAllowed}
            >
                <Container maxWidth="lg">
                    <Box pt={5} pb={5}>
                        <Grid container direction="column">
                            <Box pb={10}>
                                <Grid item container direction="row" justify="space-between">
                                    <Grid item>
                                        <Typography variant="h1">
                                            <FormattedMessage id="category.categoryListTitle" />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={isLoading}
                                            color="primary"
                                            variant="contained"
                                            onClick={createCategoryOfSubjects}
                                        >
                                            <FormattedMessage id="category.createCategory" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Grid item>
                                <TableContainer component={Paper}>
                                    {tableModel.isLoading && <LinearProgress />}
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: '85%' }}>
                                                    <Typography>
                                                        <FormattedMessage id="category.fields.title" />
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{ width: '15%' }}>
                                                    <Typography>
                                                        <FormattedMessage id="category.fields.created" />
                                                    </Typography>
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tableModel.rows.map((c) => {
                                                return (
                                                    <TableRow key={c.id} hover>
                                                        <TableCell>
                                                            <Link
                                                                component={NavLink}
                                                                underline="none"
                                                                to={generatePath(clientRoute.category, { id: c.id })}
                                                            >
                                                                {c.title}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormattedDate value={c.created} />
                                                        </TableCell>
                                                        <TableCell>{renderActions(c)}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TotTablePagination
                                                    count={tableModel.rowsCount}
                                                    page={tableModel.pageNumber}
                                                    onChangePage={tableModel.onChangePage}
                                                    onChangeRowsPerPage={tableModel.onChangePageSize}
                                                    rowsPerPage={tableModel.pageSize}
                                                    rowsPerPageOptions={tableModel.pageSizeOptions}
                                                    labelRowsPerPage={intl.formatMessage({ id: 'common.rowsPerPage' })}
                                                    labelDisplayedRows={(p): string =>
                                                        intl.formatMessage(
                                                            { id: 'category.pagingInfo' },
                                                            { to: p.to, from: p.from, count: p.count },
                                                        )
                                                    }
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </AuthorizationCheck>
        );
    },
);
