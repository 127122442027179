import React, { useEffect, useMemo } from 'react';
import { Box, Grid, IconButton } from '@material-ui/core';
import { UserButton } from './UserButton';
import { ReactComponent as BurgerIconClosed } from '../../resources/images/icons/burger-icon.svg';
import { ReactComponent as BurgerIconOpen } from '../../resources/images/icons/burger-icon-open.svg';
import { getPermissionsHeaderLinksInj, getMainLinksElements } from './HeaderLinksMap';
import { AuthorizationCheckAll } from '../AuthorizationCheckAll';
import { BurgerMenu } from './BurgerMenu';
import { observer } from 'mobx-react';
import StartPageHeaderLinks from '../../pages/start/StartPageHeaderLinks';
import { useStore } from '../../hooks/useStore';
import { useReload } from '../../hooks/useReload';
import { di } from 'react-magnetic-di';

export type AppHeaderLinksProps = {
    isOpenMenu: boolean;
    handlerBurgerButton: () => void;
    closeBurgerMenu: () => void;
    isSmSize: boolean;
    isMdSize: boolean;
};

export const AppHeaderLinks = observer(
    (props: AppHeaderLinksProps): JSX.Element => {
        const { isSmSize, isMdSize, isOpenMenu, handlerBurgerButton, closeBurgerMenu } = props;

        const [getPermissionsHeaderLinks] = di([getPermissionsHeaderLinksInj], AppHeaderLinks);
        const permissionsHeaderLinks = useMemo(() => getPermissionsHeaderLinks(), [getPermissionsHeaderLinks]);
        const mainLinksElements = useMemo(() => getMainLinksElements(), [getMainLinksElements]);

        const { personStore, api } = useStore();
        const { reloadKey, reloadIncrement } = useReload();

        useEffect(() => {
            reloadIncrement();
        }, [personStore.user.id]);

        useEffect(() => {
            return closeBurgerMenu;
        }, []);

        switch (api.authStatus) {
            case 'pending':
                return <React.Fragment />;
            case 'ok':
                return (
                    <Grid container direction="row" justify="flex-end" spacing={3}>
                        <Grid item>
                            <Grid
                                key={reloadKey}
                                container
                                direction="row"
                                alignItems="center"
                                justify="center"
                                spacing={3}
                                wrap="nowrap"
                            >
                                {isMdSize
                                    ? mainLinksElements.mdSize
                                    : isSmSize
                                    ? mainLinksElements.smSize
                                    : mainLinksElements.defaultSize}
                            </Grid>
                            <Box pt={3}>
                                {isMdSize ? (
                                    <AuthorizationCheckAll
                                        key={reloadKey}
                                        or={true}
                                        queries={permissionsHeaderLinks.mdSize}
                                    >
                                        <BurgerMenu isSmSize={isSmSize} isMdSize={isMdSize} isOpenMenu={isOpenMenu} />
                                    </AuthorizationCheckAll>
                                ) : isSmSize ? (
                                    <AuthorizationCheckAll
                                        key={reloadKey}
                                        or={true}
                                        queries={permissionsHeaderLinks.smSize}
                                    >
                                        <BurgerMenu isSmSize={isSmSize} isMdSize={isMdSize} isOpenMenu={isOpenMenu} />
                                    </AuthorizationCheckAll>
                                ) : (
                                    <AuthorizationCheckAll
                                        key={reloadKey}
                                        or={true}
                                        queries={permissionsHeaderLinks.defaultSize}
                                    >
                                        <BurgerMenu isSmSize={isSmSize} isMdSize={isMdSize} isOpenMenu={isOpenMenu} />
                                    </AuthorizationCheckAll>
                                )}
                            </Box>
                        </Grid>
                        <Grid item>
                            {isMdSize ? (
                                <AuthorizationCheckAll
                                    key={reloadKey}
                                    or={true}
                                    queries={permissionsHeaderLinks.mdSize}
                                >
                                    <IconButton color="secondary" onClick={handlerBurgerButton}>
                                        {isOpenMenu ? <BurgerIconOpen /> : <BurgerIconClosed />}
                                    </IconButton>
                                </AuthorizationCheckAll>
                            ) : isSmSize ? (
                                <AuthorizationCheckAll
                                    key={reloadKey}
                                    or={true}
                                    queries={permissionsHeaderLinks.smSize}
                                >
                                    <IconButton color="secondary" onClick={handlerBurgerButton}>
                                        {isOpenMenu ? <BurgerIconOpen /> : <BurgerIconClosed />}
                                    </IconButton>
                                </AuthorizationCheckAll>
                            ) : (
                                <AuthorizationCheckAll
                                    key={reloadKey}
                                    or={true}
                                    queries={permissionsHeaderLinks.defaultSize}
                                >
                                    <IconButton color="secondary" onClick={handlerBurgerButton}>
                                        {isOpenMenu ? <BurgerIconOpen /> : <BurgerIconClosed />}
                                    </IconButton>
                                </AuthorizationCheckAll>
                            )}
                        </Grid>
                        <Grid item key={reloadKey}>
                            <UserButton />
                        </Grid>
                    </Grid>
                );
            case 'unauthorized':
                return <StartPageHeaderLinks />;
        }
    },
);
