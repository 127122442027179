import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, ExpansionPanel, Grid, Link, Typography } from '@material-ui/core';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { MetaInfoModel } from '../models/MetaInfo';
import { TotObjectPanelDetails, TotObjectPanelSummary } from './tot-object/TotObjectServiceInfo';
import { TotObjectPanelLabel, TotObjectPanelValue } from './tot-object/TotObjectPanel';
import { getOrElse } from '../utils/getOrElse';
import { useStore } from '../hooks';

export type ServiceInfoPanelProps = {
    metaInfoModel: MetaInfoModel;
    withForm?: boolean;
    elevation?: number;
};

export const ServiceInfoPanel = observer(
    (props: ServiceInfoPanelProps): JSX.Element => {
        const { withForm = true, elevation = 0, metaInfoModel } = props;
        const { services } = useStore();
        return (
            <ExpansionPanel defaultExpanded elevation={elevation}>
                <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h3">
                        <FormattedMessage id="common.serviceInfo" />
                    </Typography>
                </TotObjectPanelSummary>
                <TotObjectPanelDetails>
                    <Grid container direction="column">
                        <Grid item container direction="row">
                            <Grid item xs={6}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="common.serviceInfoFields.author" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>{metaInfoModel.author.name}</TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="common.serviceInfoFields.lastModified" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>
                                            {getOrElse(
                                                metaInfoModel.modified,
                                                <FormattedDate value={metaInfoModel.modified} />,
                                            )}
                                        </TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Box pt={8}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="common.serviceInfoFields.lifeCycle" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>
                                            {getOrElse(
                                                metaInfoModel.processInfo,
                                                <Link
                                                    target="_blank"
                                                    href={services.lifecycleProcessLink(metaInfoModel.processInfo?.id)}
                                                >
                                                    {metaInfoModel.processInfo?.title}
                                                </Link>,
                                            )}
                                        </TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        {withForm && (
                            <Grid item>
                                <Box pt={8}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <TotObjectPanelLabel>
                                                <FormattedMessage id="common.serviceInfoFields.form" />
                                            </TotObjectPanelLabel>
                                        </Grid>
                                        <Grid item>
                                            <TotObjectPanelValue>
                                                {getOrElse(
                                                    metaInfoModel.formInfos,
                                                    metaInfoModel.formInfos.map((item) => {
                                                        return (
                                                            <Link
                                                                target="_blank"
                                                                key={item}
                                                                href={services.formVersionLinks(item)}
                                                            >
                                                                {item}
                                                            </Link>
                                                        );
                                                    }),
                                                )}
                                            </TotObjectPanelValue>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </TotObjectPanelDetails>
            </ExpansionPanel>
        );
    },
);
