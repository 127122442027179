import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

const PanelHorizontalContainer = withTheme(
    styled(Grid)`
        height: 100%;
        overflow: hidden;
        padding: 0 !important;
        margin: 0 !important;
        background-color: ${({ theme }): string => theme.variables.expertiseTaskView.viewBackground};
        box-shadow: ${({ theme }): string => theme.variables.expertiseTaskView.viewShadow};
        border: ${({ theme }): string => theme.variables.expertiseTaskView.viewBorder};
        transition: width 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    `,
);

export type PanelHorizontalProps = {
    items: ReactNode[];
    leftSide: boolean;
    fullWidth: boolean;
    isOtherFullWidth: boolean;
    style?: React.CSSProperties;
};

export const PanelHorizontal = (props: PanelHorizontalProps): JSX.Element => {
    const { items, leftSide, fullWidth, isOtherFullWidth, style } = props;
    // const zIndex = leftSide || fullWidth ? '2' : '';
    // const zIndex = leftSide || fullWidth ? '2' : '';
    const position = !leftSide ? 'absolute' : '';
    const width = fullWidth ? '100%' : isOtherFullWidth ? '0' : '50%';
    const right = !leftSide ? '0' : '';
    return (
        <PanelHorizontalContainer
            item
            container
            direction="column"
            wrap="nowrap"
            spacing={10}
            style={{ position, width, right, ...style }}
        >
            {items}
        </PanelHorizontalContainer>
    );
};
