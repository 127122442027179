import React, { useMemo, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    Link,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { PasswordField } from '../../components/fields/PasswordField';
import { EmailField } from '../../components/fields/EmailField';
import { TotBackground } from '../../TotBackground';
import RegistrationModel from '../../models/authentication/RegistrationModel';
import { useStore } from '../../hooks';

export const RegistrationPage = observer(
    (): JSX.Element => {
        const { authenticationStore, env, intlStore } = useStore();
        const [registrationModel] = useState<RegistrationModel>(() => authenticationStore.registrationModel);

        const termsOfService = useMemo((): string | undefined => {
            return intlStore.locale === 'en' ? env.termsOfServiceLinkEn : env.termsOfServiceLinkRu;
        }, [intlStore.locale, env]);

        return (
            <TotBackground withBackdrop={true}>
                <Grid container item direction="column" alignItems="center" justify="center">
                    <Container className="t-registration-page" maxWidth="sm">
                        <Paper elevation={24}>
                            <Box p={12}>
                                <Grid container justify="center">
                                    <Grid item>
                                        <Typography variant="h5" className="t-registration-title">
                                            <Box fontWeight="fontWeightBold">
                                                <FormattedMessage id="authentication.registrationTitle" />
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Box pt={8}>
                                    <form noValidate onSubmit={registrationModel.register}>
                                        <Grid container spacing={6} direction="column">
                                            <Grid item>
                                                <TextField
                                                    inputProps={{
                                                        className: 't-last-name-field',
                                                    }}
                                                    FormHelperTextProps={{
                                                        className: 't-last-name-field-help-text',
                                                    }}
                                                    required
                                                    onChange={(e): string =>
                                                        (registrationModel.lastName = e.target.value)
                                                    }
                                                    fullWidth
                                                    label={<FormattedMessage id="authentication.lastName" />}
                                                    variant="outlined"
                                                    error={!!registrationModel.errorLastName}
                                                    helperText={registrationModel.errorLastName}
                                                />
                                            </Grid>
                                            <Grid item container spacing={6} justify="space-between">
                                                <Grid item xs={6}>
                                                    <TextField
                                                        inputProps={{
                                                            className: 't-first-name-field',
                                                        }}
                                                        FormHelperTextProps={{
                                                            className: 't-first-name-field-help-text',
                                                        }}
                                                        required
                                                        onChange={(e): string =>
                                                            (registrationModel.firstName = e.target.value)
                                                        }
                                                        label={<FormattedMessage id="authentication.firstName" />}
                                                        variant="outlined"
                                                        error={!!registrationModel.errorFirstName}
                                                        helperText={registrationModel.errorFirstName}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        inputProps={{
                                                            className: 't-middle-name-field',
                                                        }}
                                                        onChange={(e): string =>
                                                            (registrationModel.middleName = e.target.value)
                                                        }
                                                        label={<FormattedMessage id="authentication.middleName" />}
                                                        fullWidth
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <EmailField model={registrationModel} />
                                            </Grid>
                                            <Grid item>
                                                <PasswordField model={registrationModel} />
                                            </Grid>
                                            {termsOfService && (
                                                <Grid item>
                                                    <FormControlLabel
                                                        value="top"
                                                        control={
                                                            <Checkbox
                                                                className="t-terms-is-accepted"
                                                                color="primary"
                                                                onChange={(event, checked): boolean =>
                                                                    (registrationModel.termsIsAccepted = checked)
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            <Link
                                                                target="_blank"
                                                                href={termsOfService}
                                                                underline="none"
                                                            >
                                                                <FormattedMessage id="authentication.termsIsAccepted" />
                                                            </Link>
                                                        }
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Button
                                                    className="t-register"
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={!!termsOfService && !registrationModel.termsIsAccepted}
                                                >
                                                    <FormattedMessage id="authentication.register" />
                                                </Button>
                                            </Grid>
                                            <Grid item container justify="center">
                                                <Grid item>
                                                    <Link
                                                        className="t-login"
                                                        component={RouterLink}
                                                        to={clientRoute.login}
                                                        underline="none"
                                                    >
                                                        <FormattedMessage id="authentication.login" />
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Box>
                            </Box>
                        </Paper>
                        {registrationModel.registerSucceed && <Redirect to={clientRoute.registrationInfoSuccess} />}
                    </Container>
                </Grid>
            </TotBackground>
        );
    },
);
