import React, { FormEvent, ReactNode, useEffect, useState } from 'react';
import { Box, Button, Dialog, Grid, Link, Portal, TextField, Typography, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IdTitle } from '../../models/IdTitle';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { TotObjectPanelLabel, TotObjectPanelValue } from '../../components/tot-object/TotObjectPanel';
import PlanEntryExpertise from './PlanEntryExpertise';
import { getOrElse } from '../../utils/getOrElse';
import { NavLink } from 'react-router-dom';
import ExpertiseCreateModel from '../../models/expertise/ExpertiseCreateModel';
import clientRoute from '../../clientRoute';
import { IdTitleNumber } from '../../models/IdTitleNumber';
import { useStore } from '../../hooks/useStore';

export type ExpertiseCreateDialogProps = {
    onClose: () => void;
    onSubmit?: () => void;
    subject?: IdTitle;
    byCampaign?: boolean;
    campaign?: IdTitleNumber;
};

export const ExpertiseCreateDialog = observer(
    (props: ExpertiseCreateDialogProps): JSX.Element => {
        const { subject, byCampaign, campaign, onClose, onSubmit } = props;
        const { expertiseStore } = useStore();
        const [expertiseCreateModel] = useState<ExpertiseCreateModel>(expertiseStore.getExpertiseCreateModel());
        const intl = useIntl();
        const theme = useTheme();

        useEffect(() => {
            if (subject) {
                expertiseCreateModel.setSubject(subject.id);
            }
        }, []);

        const renderInput = (
            label: string,
            required: boolean,
            errorText?: string,
        ): ((params: AutocompleteRenderInputParams) => ReactNode) => {
            return (params: AutocompleteRenderInputParams): ReactNode => {
                return (
                    <TextField
                        {...params}
                        required={required}
                        label={label}
                        variant="outlined"
                        error={!!errorText}
                        helperText={errorText}
                        InputProps={{
                            ...params.InputProps,
                        }}
                    />
                );
            };
        };

        const onSubmitCallback = (event: FormEvent<HTMLFormElement>): void => {
            if (byCampaign && campaign?.id) {
                expertiseCreateModel.onSubmitByCampaign(campaign.id, onClose, onSubmit)(event);
            } else {
                expertiseCreateModel.onSubmitByTemplate(onClose)(event);
            }
        };

        const lightBorder = `1px solid ${theme?.variables.palette.hoverInLists}`;
        return (
            <Portal>
                <Dialog maxWidth="md" fullWidth open={true}>
                    <Box>
                        <Typography variant="h5" className="t-new-expertise-title">
                            <Box pl={6.5} pt={8} pb={7.25} fontWeight="fontWeightBold">
                                <FormattedMessage
                                    id={byCampaign ? 'expertise.newExpertises' : 'expertise.newExpertise'}
                                />
                            </Box>
                        </Typography>

                        <Box>
                            <form noValidate onSubmit={onSubmitCallback}>
                                <Box
                                    borderBottom={lightBorder}
                                    borderTop={lightBorder}
                                    pt={15}
                                    pl={32.25}
                                    pr={32.25}
                                    pb={15}
                                >
                                    <Grid container spacing={6} direction="column" justify="center">
                                        <Grid item>
                                            {byCampaign ? (
                                                <Link component={NavLink} to={clientRoute.subjects}>
                                                    <FormattedMessage
                                                        id="expertise.expertiseSubjectsOfCampaign"
                                                        values={{ ...campaign }}
                                                    />
                                                </Link>
                                            ) : (
                                                <Autocomplete
                                                    onChange={expertiseCreateModel.onChangeSubjectSelect}
                                                    getOptionSelected={(option, value) => option.id === value.id}
                                                    getOptionLabel={(option) => option.title}
                                                    {...(subject && { defaultValue: subject })}
                                                    options={expertiseCreateModel.subjects.slice()}
                                                    renderInput={renderInput(
                                                        intl.formatMessage({
                                                            id: 'expertise.createDialogFields.expertiseSubject',
                                                        }),
                                                        true,
                                                        expertiseCreateModel.errorSubject,
                                                    )}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <Autocomplete
                                                onChange={expertiseCreateModel.onChangeTemplateSelect}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => option.title}
                                                options={expertiseCreateModel.templates.slice()}
                                                renderInput={renderInput(
                                                    intl.formatMessage({
                                                        id: 'expertise.createDialogFields.templateExpertise',
                                                    }),
                                                    !!byCampaign,
                                                    expertiseCreateModel.errorTemplate,
                                                )}
                                            />
                                        </Grid>
                                        <Grid item>
                                            {getOrElse(
                                                expertiseCreateModel.templateIsSelected,
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <TotObjectPanelLabel>
                                                            <FormattedMessage id="expertise.createDialogFields.lifeCycle" />
                                                        </TotObjectPanelLabel>
                                                    </Grid>
                                                    <Grid item>
                                                        <TotObjectPanelValue>
                                                            {expertiseCreateModel.processTitle || '-'}
                                                        </TotObjectPanelValue>
                                                    </Grid>
                                                </Grid>,
                                                <Autocomplete
                                                    onChange={expertiseCreateModel.onChangeLifeCycleSelect}
                                                    getOptionSelected={(option, value) => option.code === value.code}
                                                    getOptionLabel={(option) => option.title}
                                                    options={expertiseCreateModel.lifeCycles.slice()}
                                                    renderInput={renderInput(
                                                        intl.formatMessage({
                                                            id: 'expertise.createDialogFields.lifeCycle',
                                                        }),
                                                        true,
                                                        expertiseCreateModel.errorLifeCycle,
                                                    )}
                                                />,
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>
                                {expertiseCreateModel.templateIsSelected && !!expertiseCreateModel.planEntries.length && (
                                    <Box pb={15} borderBottom={lightBorder}>
                                        <Box>
                                            <Typography variant="h6" className="t-new-expertise-title">
                                                <Box pl={6.5} pt={4} pb={4} borderBottom={lightBorder}>
                                                    <FormattedMessage id="expertise.plan.listTitle" />
                                                </Box>
                                            </Typography>
                                            {expertiseCreateModel.planEntries.map((i, index) => {
                                                const number = ++index;
                                                return (
                                                    <PlanEntryExpertise
                                                        key={number}
                                                        index={number}
                                                        taskTypeTitle={i.taskTypeTitle}
                                                        taskMin={i.tasksMin}
                                                        taskMax={i.tasksMax}
                                                        acceptanceDays={i.acceptanceDays}
                                                        processingDays={i.processingDays}
                                                    />
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                )}
                                <Box pt={6} pb={6} pl={30.75} pr={30.75}>
                                    <Grid container spacing={10} justify="center">
                                        <Grid style={{ flexGrow: 1 }} item>
                                            <Button size="large" fullWidth variant="contained" onClick={onClose}>
                                                <FormattedMessage id="common.cancel" />
                                            </Button>
                                        </Grid>
                                        <Grid style={{ flexGrow: 1 }} item>
                                            <Button
                                                disabled={expertiseCreateModel.isLoading}
                                                size="large"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                            >
                                                <FormattedMessage id="common.save" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </Dialog>
            </Portal>
        );
    },
);
