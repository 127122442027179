import React, { ReactNode } from 'react';
import {
    Button,
    Grid,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { MenuButton } from '../../components/buttons/MenuButton';
import { generatePath, NavLink, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { AuthorizationCheck } from '../../components/AuthorizationCheck';
import { DeleteActionMenuItem } from '../../components/DeleteActionMenuItem';
import PlanEntryListModel from '../../models/expertise-plan/PlanEntryListModel';
import PlanEntryFormDialog from './PlanEntryFormDialog';
import { PlanEntryDTO } from '../../store/ExpertisePlanStore';
import { AuthorizedRoute } from '../../components/AuthorizedRoute';
import { ActionsButton } from '../../components/buttons/ActionsButton';

type OuterProps = {
    createPath: string;
    editPath: string;
    ownerPagePath: string;
    ownerEntityCode: string;
    editPermission: string;
    model: PlanEntryListModel;
};
type RouteParams = {
    id: string;
    entryId: string;
};
type InnerProps = OuterProps & { intl: IntlFormatters } & RouteComponentProps<RouteParams>;

class ExpertisePlan extends React.Component<InnerProps, {}> {
    renderActionItems = (entry: PlanEntryDTO, index: number): (() => ReactNode[]) => {
        const { editPath, match, model } = this.props;
        const ownerId = match.params.id;
        return () => [
            <MenuItem
                dense
                key={'edit'}
                tabIndex={0}
                button={true}
                component={NavLink}
                to={generatePath(editPath, { id: ownerId, entryId: entry.id })}
            >
                <FormattedMessage id="common.edit" />
            </MenuItem>,
            <DeleteActionMenuItem
                id="delete"
                key="delete"
                wrappedComponentProps={{ tabIndex: 1 }}
                title={<FormattedMessage id="common.confirmDeletion" />}
                message={<FormattedMessage id="templatesOfExpertise.plan.confirmDeletionInfoText" values={{ index }} />}
                onConfirm={model.deleteEntry(entry.id)}
            />,
        ];
    };

    renderActions = (entry: PlanEntryDTO, index: number): JSX.Element => {
        return (
            <MenuButton
                disablePortal={true}
                renderButton={ActionsButton}
                renderMenuItems={this.renderActionItems(entry, index)}
            />
        );
    };

    render(): JSX.Element {
        const { createPath, editPath, ownerEntityCode, editPermission, ownerPagePath, match, model } = this.props;
        const ownerId = match.params.id;
        return (
            <Grid container direction="column" spacing={5}>
                <Switch>
                    <AuthorizedRoute
                        entityCode={ownerEntityCode}
                        entityId={ownerId}
                        permCode={editPermission}
                        path={createPath}
                    >
                        <PlanEntryFormDialog
                            getEntryModel={model.newEntry}
                            listModel={model}
                            ownerPagePath={ownerPagePath}
                        />
                    </AuthorizedRoute>
                    <AuthorizedRoute
                        entityCode={ownerEntityCode}
                        entityId={ownerId}
                        permCode={editPermission}
                        path={editPath}
                        render={(route: RouteComponentProps<RouteParams>) => (
                            <PlanEntryFormDialog
                                getEntryModel={() => model.getEntry(route.match.params.entryId)}
                                listModel={model}
                                ownerPagePath={ownerPagePath}
                            />
                        )}
                    />
                </Switch>
                <Grid item container direction="row" justify="space-between">
                    <Grid item>
                        <Typography variant="h3">
                            <FormattedMessage id="templatesOfExpertise.plan.listTitle" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <AuthorizationCheck entityCode={ownerEntityCode} entityId={ownerId} permCode={editPermission}>
                            <Button
                                color="primary"
                                variant="contained"
                                component={NavLink}
                                to={generatePath(createPath, { id: ownerId })}
                            >
                                <FormattedMessage id="templatesOfExpertise.plan.createEntry" />
                            </Button>
                        </AuthorizationCheck>
                    </Grid>
                </Grid>
                <Grid item>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="templatesOfExpertise.plan.fields.number" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="templatesOfExpertise.plan.fields.viewPoint" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="templatesOfExpertise.plan.fields.planCountTasks" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="templatesOfExpertise.plan.fields.acceptanceDays" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="templatesOfExpertise.plan.fields.processingDays" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="templatesOfExpertise.plan.fields.reportForm" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="templatesOfExpertise.plan.fields.taskForm" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="templatesOfExpertise.plan.fields.processTitle" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {model.entries.map((entry, index) => {
                                    const entryIndex = index + 1;
                                    return (
                                        <TableRow key={entry.id} hover>
                                            <TableCell>{entryIndex}</TableCell>
                                            <TableCell>{entry.viewPoint || entry.taskType}</TableCell>
                                            <TableCell>
                                                <FormattedMessage
                                                    id="common.fromToNumber"
                                                    values={{ from: entry.tasksMin, to: entry.tasksMax }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage
                                                    id="common.amountOfDays"
                                                    values={{ count: entry.acceptanceDays }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage
                                                    id="common.amountOfDays"
                                                    values={{ count: entry.processingDays }}
                                                />
                                            </TableCell>
                                            <TableCell>{entry.reportFormCode}</TableCell>
                                            <TableCell>{entry.taskFormCode}</TableCell>
                                            <TableCell>{entry.processTitle}</TableCell>
                                            <AuthorizationCheck
                                                entityCode={ownerEntityCode}
                                                entityId={ownerId}
                                                permCode={editPermission}
                                            >
                                                <TableCell>{this.renderActions(entry, entryIndex)}</TableCell>
                                            </AuthorizationCheck>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        );
    }
}

export default compose<InnerProps, OuterProps>(withRouter, injectIntl, observer)(ExpertisePlan);
