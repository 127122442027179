import { computed, observable } from 'mobx';
import { IntlStore } from '../store/IntlStore';
import { FileModel } from './FileModel';
import { PfTemplateDTO } from '../store/PfTemplateStore';

export default class PfTemplateModel {
    @observable intl: IntlStore;

    @observable id = '';
    @observable title = '';
    @observable file = new FileModel([
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ]);

    @observable validationStarted = false;

    constructor(intl: IntlStore) {
        this.intl = intl;
    }

    load(dto: PfTemplateDTO) {
        this.id = dto.id;
        this.title = dto.title;
        this.file.fileDTO = dto.fileDTO;
    }

    @computed
    get formData(): FormData {
        const data = new FormData();
        data.append('title', this.title);
        if (this.file.fileForUpload) {
            data.append('file', this.file.fileForUpload);
        }
        return data;
    }

    @computed
    get errorTitle(): string {
        if (this.validationStarted && !this.title) {
            return this.intl.formatMessage('validation.required');
        }
        return '';
    }

    @computed
    get errorFile(): string {
        if (this.validationStarted && !(!!this.file.fileForUpload || !!this.file.fileDTO)) {
            return this.intl.formatMessage('validation.required');
        }
        return '';
    }

    @computed
    get isValid(): boolean {
        return !this.errorTitle && !this.errorFile;
    }
}
