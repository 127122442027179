import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { checkboxProps } from '../../../../constants/registryFilterInputs';

export type SwitchViewState = {
    left: boolean;
    right: boolean;
};

export type SwitchViewLabels = {
    left: React.ReactNode;
    right: React.ReactNode;
};

type SwitchExpertiseTaskViewProps = {
    viewState: SwitchViewState;
    labels: SwitchViewLabels;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const SwitchExpertiseTaskView = (props: SwitchExpertiseTaskViewProps) => {
    const { labels, viewState } = props;
    const { left, right } = viewState;
    const taskDisabled = left && !right;
    const questionnaireDisabled = right && !left;
    return (
        <FormGroup aria-label="position" row style={{ flexWrap: 'nowrap' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        {...checkboxProps}
                        style={{ padding: '0' }}
                        checked={left}
                        onChange={props.handleChange}
                        disabled={taskDisabled}
                        name="left"
                    />
                }
                label={labels.left}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        {...checkboxProps}
                        style={{ padding: '6px 0' }}
                        checked={right}
                        onChange={props.handleChange}
                        disabled={questionnaireDisabled}
                        name="right"
                    />
                }
                label={labels.right}
            />
        </FormGroup>
    );
};
