import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { Switch, Route, NavLink, generatePath } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import { FormattedMessage } from 'react-intl';
import { TotObjectTransitions } from '../../../components/tot-object/TotObjectTransitions';
import { TransitionsDTO } from '../../../models/tot-object/TotObjectTransitions';

interface Props {
    onSubmit: () => void;
    id: string;
    goToReadPage: () => void;
    getTransitionButtons: (templateId: string) => Promise<TransitionsDTO>;
    onLifeCycleTransition: (transitionId: string, taskId: string, validate?: boolean | undefined) => Promise<void>;
}

const ControlPanelRight = ({
    onSubmit,
    id,
    goToReadPage,
    getTransitionButtons,
    onLifeCycleTransition,
}: Props): JSX.Element => (
    <Grid spacing={3} alignItems="center" justify="center" container>
        <Switch>
            <Route exact path={clientRoute.templateOfExpertiseCreate}>
                <Grid item>
                    <Button variant="contained" component={NavLink} to={generatePath(clientRoute.templatesOfExpertise)}>
                        <FormattedMessage id="common.cancel" />
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={onSubmit}>
                        <FormattedMessage id="common.save" />
                    </Button>
                </Grid>
            </Route>
            <Route exact path={clientRoute.templateOfExpertiseEdit}>
                <Grid item>
                    <Button
                        variant="contained"
                        component={NavLink}
                        to={generatePath(clientRoute.templateOfExpertise, {
                            id,
                        })}
                    >
                        <FormattedMessage id="common.cancel" />
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={onSubmit}>
                        <FormattedMessage id="common.save" />
                    </Button>
                </Grid>
            </Route>
            <Route exact path={clientRoute.templateOfExpertise}>
                <Grid item>
                    <Grid item container spacing={3}>
                        <TotObjectTransitions
                            objectId={id}
                            updateObjectPage={goToReadPage}
                            getTransitions={getTransitionButtons}
                            lifeCycleTransition={onLifeCycleTransition}
                        />
                    </Grid>
                </Grid>
            </Route>
        </Switch>
    </Grid>
);

export default ControlPanelRight;
