import React from 'react';
import { observer } from 'mobx-react';
import { BottomControlPanel } from '../../../../components/BottomControlPanel';
import { ExpertiseStore } from '../../../../store/ExpertiseStore';
import ControlPanelLeft from './ControlPanelLeft';
import ControlPanelRight from './ControlPanelRight';

type ExpertiseControlPanelProps = {
    expertiseId: string;
    expertiseTitle: string;
    deleteExpertise: () => Promise<void>;
    reloadModel: () => void;
    expertiseStore: ExpertiseStore;
    onLifeCycleTransition(transitionId: string, taskId: string): Promise<void>;
};

class ExpertiseControlPanel extends React.Component<ExpertiseControlPanelProps, {}> {
    render(): JSX.Element {
        const {
            expertiseId,
            expertiseTitle,
            deleteExpertise,
            expertiseStore,
            reloadModel,
            onLifeCycleTransition,
        } = this.props;
        return (
            <BottomControlPanel
                left={
                    <ControlPanelLeft
                        expertiseId={expertiseId}
                        deleteExpertise={deleteExpertise}
                        title={expertiseTitle}
                    />
                }
                right={
                    <ControlPanelRight
                        expertiseId={expertiseId}
                        reloadModel={reloadModel}
                        getLifeCycleTransitions={expertiseStore.getLifeCycleTransitions}
                        onLifeCycleTransition={onLifeCycleTransition}
                    />
                }
            />
        );
    }
}

export default observer(ExpertiseControlPanel);
