import React from 'react';
import { UserListModel, UserRowDTO } from '../../../../models/users/UserListModel';
import { UserInfoMessageValues } from '../UserInfoMessageValues';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { UserBlockButtonWithReason } from './UserBlockButtonWithReason';
import { UserBlockUnblockButtonWithConfirm } from './UserBlockUnblockButtonWithConfirm';
import { useFeature } from 'feature-toggle-jsx';

export type UserBlockUnblockProps = {
    user: UserRowDTO;
    allowed: boolean;
    userInfoMessageValues: UserInfoMessageValues;
    tableModel: UserListModel;
};

enum TooltipTitleId {
    active = 'users.actions.block',
    blocked = 'users.actions.unblock',
    inactive = 'users.actions.block',
}

export const UserBlockUnblock = (props: UserBlockUnblockProps): JSX.Element => {
    const { user, allowed, userInfoMessageValues, tableModel } = props;
    const { blockUser, unblockUser } = tableModel;
    const { login, status } = user;
    const { id } = login;
    const blockCurrentUser = blockUser(id);
    const unblockCurrentUser = unblockUser(id);
    const isBlocked = status === 'blocked';

    let tooltipTitleId = 'users.notAllowed';
    let buttonTitleId = 'users.confirmBlock';
    let buttonMessageId = 'users.confirmBlockInfoText';
    let buttonConfirm = blockCurrentUser;

    if (allowed) {
        tooltipTitleId = TooltipTitleId[status];
    }
    if (isBlocked) {
        buttonTitleId = 'users.confirmUnblock';
        buttonMessageId = 'users.confirmUnblockInfoText';
        buttonConfirm = unblockCurrentUser;
    }

    return (
        <Tooltip title={<FormattedMessage id={tooltipTitleId} />}>
            <span>
                {!isBlocked ? (
                    <UserBlockButtonWithReason
                        allowed={allowed}
                        isBlocked={isBlocked}
                        message={
                            <FormattedMessage
                                id="users.confirmBlockWithReasonInfoText"
                                values={userInfoMessageValues}
                            />
                        }
                        onUserBlock={blockCurrentUser}
                    />
                ) : (
                    <UserBlockUnblockButtonWithConfirm
                        id="block-unblock-user-confirm"
                        wrappedComponentProps={{ allowed, isBlocked }}
                        title={<FormattedMessage id={buttonTitleId} />}
                        message={<FormattedMessage id={buttonMessageId} values={userInfoMessageValues} />}
                        onConfirm={buttonConfirm}
                    />
                )}
            </span>
        </Tooltip>
    );
};
